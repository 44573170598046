import { Button, Popconfirm } from '@components'
import { useRequest, useTranslation } from '@hooks'
import { message } from 'antd'
import { AxiosRequestConfig } from 'axios'
import React from 'react'

const RemoveRowLink: React.FC<TProps> = ({ id, request, onSuccess, title }) => {
	const { t } = useTranslation()

	// Remove user.
	const { fetch: removeUser, fetching: removing } = useRequest<void, any>({
		request: () => request(id),
		onSuccess: () => onSuccess?.(id),
		onError: (error) => message.error(error.message),
	})

	return (
		<Popconfirm title={title || t('Are you sure?')} onConfirm={removeUser} okText={t('Yes')} cancelText={t('No')}>
			<Button loading={removing} danger type="link">
				{t('Remove')}
			</Button>
		</Popconfirm>
	)
}

type TProps = {
	/** ID of removed element. */
	id: number
	/** Request for removing. Id will be passed as param.  */
	request: (id: number) => AxiosRequestConfig | undefined
	/** Function called after successful removing.  */
	onSuccess?: (id: number) => void
	/* Custon title of remove message */
	title?: string
}

export default RemoveRowLink
