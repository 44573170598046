import { Form, Switcher, Radio } from '@components'
import { TFunction } from 'i18next'
import React from 'react'
import { TUserContactWay } from '@typings'

const PrivacySettingsFields: React.FC<TProps> = ({ updating, t, contactWays }) => (
	<>
		<Form.Item
			label={t('Notifications by email')}
			name="notificationsEmail"
			valuePropName="checked"
			normalize={Boolean}
		>
			<Switcher disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Notifications by push')}
			name="notificationsPush"
			valuePropName="checked"
			normalize={Boolean}
		>
			<Switcher disabled={updating} />
		</Form.Item>
		<Form.Item label={t('How LBD contact you')} name="howToContact">
			<Radio.Group>
				{contactWays.map((contactWay) => (
					<Radio key={contactWay} value={contactWay}>
						{contactWay}
					</Radio>
				))}
			</Radio.Group>
		</Form.Item>
		<Form.Item
			label={t('See Wardrobe Values')}
			name="isKnowWardrobeValue"
			valuePropName="checked"
			normalize={Boolean}
		>
			<Switcher disabled={updating} />
		</Form.Item>
	</>
)

type TProps = {
	contactWays: TUserContactWay[]
	updating: boolean
	t: TFunction
}

export default PrivacySettingsFields
