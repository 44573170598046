import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'

export default function draftEditorStateToHtml(editorState: EditorState): string {
	return stateToHTML(editorState.getCurrentContent(), {
		blockRenderers: {
			atomic: (block) => {
				const { type, ...data } = block.getData().toJS()
				switch (type) {
					case 'image':
						return `<img src="${data.src}" data-id="${data['data-id']}" data-key="${data['data-key']}" />`
					case 'wardrobe':
						return `<nav data-content="wardrobe">${data.ids
							.map((id: number) => `<a href="#" data-wardrobe-item-id="${id}" />`)
							.join('')}</nav>`
					case 'lookbooks':
						return `<nav data-content="lookbooks">${data.ids
							.map((id: number) => `<a href="#" data-lookbook-id="${id}" />`)
							.join('')}</nav>`
					default:
						return `<p>не распознано</p>`
				}
			},
		},
	})
		.replace('<p><br></p>', '') // remove empty blocks.
		.replace(/(\r\n|\n|\r)/gm, '') // remove return characters.
}
