import { Tag } from '@components'
import { useMemo } from '@hooks'
import { TStatus } from '@typings'
import React from 'react'

const StatusTag: React.FC<TProps> = ({ status }) => {
	const color = useMemo(() => {
		switch (status) {
			case 'active':
				return 'warning'
			case 'sold':
				return 'success'
			case 'sale':
				return 'processing'
			case 'disabled':
				return 'default'
			default:
				return undefined
		}
	}, [status])

	return color ? <Tag color={color}>{status}</Tag> : null
}

type TProps = {
	/** Status value used for selecting color for tag. */
	status: TStatus | null
}

export default StatusTag
