import ArticleItemsBlock from '../ArticleItemsBlock'
import ArticleItemsBlockButton from '../ArticleItemsBlockButton'
import ArticleImageBlockButton from '../ArticleImageBlockButton'
import ArticleImageBlock from '../ArticleImageBlock'
import ArticleLookbooksBlockButton from '../ArticleLookbooksBlockButton'
import ArticleLookbooksBlock from '../ArticleLookbooksBlock'

export const ItemsBlockPlugin = {
	// Friendly plugin name
	title: 'Wardrobe',
	// A unique plugin name used to identify the plugin and its blocks
	type: 'wardrobe',
	// React component to be rendered in the block sidebar
	buttonComponent: ArticleItemsBlockButton,
	// React component for rendering the content block
	blockComponent: ArticleItemsBlock,
}
export const LookbooksBlockPlugin = {
	// Friendly plugin name
	title: 'Lookbooks',
	// A unique plugin name used to identify the plugin and its blocks
	type: 'lookbooks',
	// React component to be rendered in the block sidebar
	buttonComponent: ArticleLookbooksBlockButton,
	// React component for rendering the content block
	blockComponent: ArticleLookbooksBlock,
}

export const ImageBlockPlugin = {
	// Friendly plugin name
	title: 'image',
	// A unique plugin name used to identify the plugin and its blocks
	type: 'image',
	// React component to be rendered in the block sidebar
	buttonComponent: ArticleImageBlockButton,
	// React component for rendering the content block
	blockComponent: ArticleImageBlock,
}

export const plugins = [ImageBlockPlugin, ItemsBlockPlugin, LookbooksBlockPlugin]
