import { Card, ErrorBoundary, Markdown } from '@components'
import * as React from 'react'
import changelog from '../../../CHANGELOG.md'

/** Display application changelog parsed from markdown file from root. */
const ChangelogScreen: React.FC<TProps> = () => {
	return (
		<ErrorBoundary>
			<Card title="Changelog">
				<Markdown source={changelog} escapeHtml={false} />
			</Card>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default ChangelogScreen
