import {
	Button,
	Card,
	Col,
	DataTable,
	Divider,
	ErrorBoundary,
	Icons,
	LookbookCardMeta,
	Popconfirm,
	Row,
	RemoveCommentLink,
	When,
} from '@components'
import { toNumber } from '@helpers'
import { useCallback, useHistory, useParams, useRequest, useTranslation } from '@hooks'
import { api, message, apiSettings } from '@services'
import { TFilteredRequest, TLook, TLookbook, TResponseWithFilters, TProfile, TComment } from '@typings'
import * as React from 'react'
import { columns, columnsComments } from './LookbookView.tableOptions'
import Axios from 'axios'
import FileSaver from 'file-saver'

const LookbookViewScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const lookbookId = toNumber(params?.id)
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	// Lookbook data.
	const { data: lookbook, fetching: lookbookFetching } = useRequest<TLookbook, void>(
		{
			request: () => api.getLookbook(lookbookId),
			onError: (error) => message.error(error.message),
		},
		[lookbookId],
	)

	// Table data.
	const { data: { data: looks, meta } = {}, fetch: fetchLooks, fetching: looksFetching } = useRequest<
		TResponseWithFilters<TLook[]>,
		TFilteredRequest & Partial<TLook>
	>({
		request: (params) => api.getLooks({ lookbookId, ...params }),
		onError: (error) => message.error(error.message),
	})

	// Actions.
	const goToEditLookbook = useCallback(() => history.push(`/lookbooks/${lookbookId}/edit`), [])
	// const goToLook = useCallback(() => history.push(`/lookbooks/${lookbookId}/looks/add`), [])

	//Comments
	const { data: { data: comments, meta: commentsMeta } = {}, fetch } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TComment>
	>(
		{
			request: () => params?.id && api.getComments({ 'lookbooks.lookbook.id': params?.id }),
			onError: (error) => message.error(error),
		},
		[params?.id],
	)
	const newCommentsData = comments?.map((el) => (el.replies?.length ? { ...el, children: el.replies } : { ...el }))

	const exportData = () => {
		const ids = [
			...new Set([
				lookbook?.asset?.id,
				...(looks?.map((look) => look.assets.map((asset) => asset.assetId)).flat() || []),
			]),
		]
		Axios({
			url: `${apiSettings.getSettings('API_URL')}/assets/download?`,
			params: { ids },
			method: 'GET',
			responseType: 'blob', // Important
		}).then((response) => {
			var blob = new Blob([response.data], { type: 'application/zip' })
			FileSaver.saveAs(blob, 'assets.zip')
		})
	}

	return (
		<ErrorBoundary>
			<Card loading={lookbookFetching}>
				<Row gutter={[16, 16]} align="middle">
					<Col flex="1 1">
						<LookbookCardMeta lookbook={lookbook} />
					</Col>

					<Col flex={0}>
						<Button type="primary" disabled={lookbookFetching} onClick={goToEditLookbook}>
							{t('Edit')}
						</Button>
					</Col>
					<When condition={API_SERVER_NAME === 'staging'}>
						<Button type="primary" icon={<Icons.DownloadOutlined />} onClick={exportData}>
							{t('Export all assets')}
						</Button>
					</When>
				</Row>
			</Card>
			<DataTable<TLookbook, Partial<TLookbook>>
				key={lookbookId}
				loading={looksFetching}
				columns={columns}
				dataSource={looks}
				meta={meta}
				onChange={fetchLooks}
				// HeaderComponent={
				// 	<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToLook}>
				// 		{t('Add look')}
				// 	</Button>
				// }
			/>
			<Card title={t('Comments')}>
				<DataTable<TProfile, Partial<TProfile>>
					columns={columnsComments}
					dataSource={newCommentsData}
					meta={commentsMeta}
					autoFetchOnMount={false}
					renderActionsComponent={(comment: TComment) => (
						<>
							<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Card>
		</ErrorBoundary>
	)
}
type TParams = { id?: string }
type TProps = {
	/** */
}

export default LookbookViewScreen
