import * as React from 'react'
import { useTranslation, useRequest, useHistory, useTableLocationParams } from '@hooks'
import { ErrorBoundary, DataTable, Button, PostRow, Icons, SurveyRow } from '@components'
import { api, message } from '@services'
import { TResponseWithFilters, TFilteredRequest } from 'src/typings/api'
import { TPost } from 'src/typings/posts'
import { TWardrobeItem } from 'src/typings/wardrobe'
import { columns } from './Surveys.tableOptions'
import { TSurvey } from 'src/typings/surveys'

const SurveysScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const [searchParams, setSearchParams] = useTableLocationParams()
	console.log(searchParams, 'searchParamss')

	// API data..
	const { data: { data: surveys, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TPost[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getSurveys({ order: ['id', 'desc'], ...searchParams }),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddPost = () => history.push(`/surveys/add`)

	return (
		<ErrorBoundary>
			<DataTable<TPost, Partial<TPost>>
				loading={fetching}
				columns={columns}
				dataSource={surveys}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} onClick={goToAddPost}>
						{t('Add survey')}
					</Button>
				}
				expandable={{
					expandedRowRender: (survey: TSurvey) => <SurveyRow survey={survey} />,
				}}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default SurveysScreen
