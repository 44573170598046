import { BooleanIcon, Link } from '@components'
import { formatToDay } from '@helpers'
import { TBulkUpload } from '@typings'
import React from 'react'
import { i18n } from '@services'

export const formFieldRules = {
	title: [
		{ required: true, message: i18n.t('Title is required.') },
		{ max: 20, message: i18n.t('Username can contain alphanumeric values up to 20 symbols.') },
		{ regexp: /^[a-zA-Z0-9()_.]+$/, message: i18n.t('Username can contain alphanumeric values and underscore') },
		{
			validator: async (rule, value) => {
				if (value === toString(parseInt(value, 10))) {
					return Promise.reject(i18n.t('Username cannot consist only of numbers'))
				}
				return Promise.resolve()
			},
		},
	],
	email: [
		{ required: true, message: i18n.t('Email is required') },
		{ type: 'email', message: i18n.t('Invalid email address') },
	],
}

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 100,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 150,
		searchKey: 'title',
	},
	{
		title: 'Spreadsheet',
		dataIndex: 'link',
		width: 280,
		render: (link: string, record: TBulkUpload) => (
			<a href={record.link} target="_blank" rel="noopener noreferrer">
				{record.key}
			</a>
		),
	},

	{
		title: 'Uploaded?',
		dataIndex: 'isUploaded',
		width: 120,
		align: 'center',
		render: (isPromotional: boolean) => <BooleanIcon value={isPromotional} />,
		sorter,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'No',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'User ID',
		dataIndex: 'userId',
		align: 'center',
		width: 120,
		render: (link: string, record: TBulkUpload) => <Link to={`/users/${record.userId}`}>{record.userId}</Link>,
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
]
