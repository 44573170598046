import { Form, FormSpy, Upload, Modal } from '@components'
import { config } from '@constants'
import { compileImageUri, takeRight } from '@helpers'
import { useAuthContext, useCallback, useEffect, useMemo, useRef, useState, useRequest } from '@hooks'
import { message, apiSettings, api } from '@services'
import {
	FormInstance,
	TAsset,
	HttpRequestHeader,
	RcFile,
	FormItemProps,
	UploadProps,
	Rule,
	TImageTag,
	UploadFile,
} from '@typings'
import React from 'react'
import { renderUploadChildren, TChangeFileEvent, TFileList, showUploadList } from './UploadImageField.utils'

import styles from './UploadImageField.module.css'

const initialModalState = {
	isVisible: false,
	image: null,
}

/** Form field for handling image uploading. */
const UploadImageField: React.FC<TProps> = ({
	name,
	multiple,
	renderChildren = renderUploadChildren,
	rules,
	tag,
	withFileList = true,
	...uploadProps
}) => {
	const formInstanceRef = useRef<FormInstance>()
	const { authToken } = useAuthContext()
	const [fileList, setFileList] = useState<TFileList>([], '')
	const [modalState, setModalState] = useState<TModalState>(initialModalState, '')
	const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false, 'isRemoveModalVisible')

	const toggleRemoveModal = () => setIsRemoveModalVisible(!isRemoveModalVisible)

	const toggleModal = useCallback(
		({ response = null }) => setModalState((st) => ({ ...st, image: response?.data, isVisible: !st.isVisible })),
		[modalState],
	)

	const requestHeaders = useMemo(
		() => ({
			[String(config.API_AUTH_HEADER)]: authToken,
			'X-Requested-With': null,
		}),
		[authToken],
	)

	const getValueFromEvent = useCallback(
		(event: TChangeFileEvent) => {
			// event could be ProgressEvent due to we can pick only data from UploadChangeEvent.
			const fileIds = event?.fileList
				?.filter((file) => file.status === 'done')
				.map((file) => file?.response?.data)

			const value = multiple ? fileIds : fileIds[0]
			return value
		},
		[multiple],
	)

	const beforeUpload = useCallback((file: RcFile, FileList: RcFile[]) => {
		const isSupportedFormat = file.type.startsWith('image/')
		if (!isSupportedFormat) {
			message.error('Unsupported format of file!')
			return false
		}
		return true
	}, [])

	const handleChange = useCallback((event: TChangeFileEvent) => {
		const status = String(event?.file?.status)
		if (status === 'uploading') {
			// return;
		}

		if (status === 'error') {
			message.error(event?.file?.error)
		} else {
			const newFileList = multiple ? event.fileList : takeRight(event.fileList)
			console.log(newFileList, 'newFileList')
			setTimeout(() => {
				setFileList(newFileList)
			}, 1000)
			withFileList && setFileList(newFileList)
		}
	}, [])

	const { fetch: handleRemove } = useRequest<void, UploadFile<{ data: TAsset }>>({
		request: (file) => {
			const assetId = file?.response?.data?.id
			return assetId ? api.removeImage(assetId) : null
		},
		onError: (error) => message.error(error.message),
	})

	const init = useCallback(() => {
		const avatarValue = formInstanceRef.current?.getFieldValue(name)

		if (!avatarValue || !!avatarValue?.videoKey) return
		const assets: TAsset[] = Array.isArray(avatarValue) ? avatarValue : [avatarValue]
		const initialFileList: TFileList = assets.map((asset) => {
			return {
				uid: String(asset.id),
				size: 0,
				name: `image-${asset.id}`,
				type: 'image/jpg',
				url: compileImageUri(asset, false),
				status: 'done',
				thumbUrl: compileImageUri(asset, true),
				response: {
					data: asset,
				},
			}
		})
		setFileList(initialFileList)
	}, [])

	useEffect(() => init(), [])

	const onGalleryFileRemove = (file) => {
		const { confirm } = Modal
		return new Promise((resolve, reject) => {
			confirm({
				title: 'Are you sure you want to Delete ?',
				onOk: () => {
					resolve(true)
					handleRemove(file)
				},
				onCancel: () => {
					reject(true)
				},
			})
		})
	}

	return (
		<>
			<FormSpy ref={formInstanceRef} />
			<Form.Item name={name} valuePropName="image" getValueFromEvent={getValueFromEvent} noStyle rules={rules}>
				{withFileList ? (
					<Upload
						name="image"
						listType="picture-card"
						fileList={withFileList ? fileList : undefined}
						multiple={multiple}
						showUploadList={showUploadList}
						action={`${apiSettings.getSettings('API_URL')}/assets/images`}
						headers={requestHeaders as HttpRequestHeader}
						onChange={handleChange}
						beforeUpload={beforeUpload}
						data={tag ? { tag } : undefined}
						onRemove={onGalleryFileRemove}
						onPreview={toggleModal}
						{...uploadProps}
					>
						{renderChildren?.({ fileList, multiple })}
					</Upload>
				) : (
					<Upload
						name="image"
						listType="picture-card"
						multiple={multiple}
						showUploadList={showUploadList}
						action={`${apiSettings.getSettings('API_URL')}/assets/images`}
						headers={requestHeaders as HttpRequestHeader}
						onChange={handleChange}
						beforeUpload={beforeUpload}
						data={tag ? { tag } : undefined}
						// onRemove={handleRemove}
						onPreview={toggleModal}
						{...uploadProps}
					>
						{renderChildren?.({ fileList, multiple })}
					</Upload>
				)}
			</Form.Item>
			<Modal title="Basic Modal" visible={isRemoveModalVisible} onOk={handleRemove} onCancel={toggleRemoveModal}>
				<p>Some contents...</p>
				<p>Some contents...</p>
				<p>Some contents...</p>
			</Modal>
			<Modal
				visible={modalState.isVisible}
				centered
				onOk={toggleModal}
				onCancel={toggleModal}
				bodyStyle={{
					textAlign: 'center',
				}}
				width="60%"
			>
				<img src={compileImageUri(modalState.image, true)} alt="" className={styles.image} />
			</Modal>
		</>
	)
}

type TProps = UploadProps &
	Pick<FormItemProps, 'rules' | 'required' | 'hasFeedback' | 'name'> & {
		/** Allow multiple image uploading */
		multiple: boolean
		/** Function that can be render children of Antd Upload component */
		renderChildren?: (props: { fileList: TFileList; multiple: boolean }) => React.ReactNode
		/** Form Item validation rules. */
		rules?: Rule[]
		/** Custome tag for uploading images */
		tag?: TImageTag
		withFileList?: boolean
	}
type TModalState = {
	isVisible: boolean
	image: null | TAsset
}

export default UploadImageField
