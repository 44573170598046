import { DataTable, Row, Col, Card, CardsList } from '@components'
import { toNumber, compileImageUri } from '@helpers'
import { useCallback, useHistory, useMemo, useParams, useRequest, useTranslation } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TResponseWithFilters, TWardrobeItem, TLookbook } from '@typings'
import * as React from 'react'
import { getColumns, getExpandable, searchColumns } from './UserViewOrders.tableOptions'

const UserViewLookbooks: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const userId = toNumber(params?.id)

	// API data.
	const { data: { data: lookbooks, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TLookbook[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getLookbooks({ userId }),
			onError: (error) => message.error(error.message),
		},
		[],
	)

	// Actions.
	const goToLookbook = useCallback((lookbookId) => history.push(`/lookbooks/${lookbookId}`), [])

	return <CardsList items={lookbooks} onPress={goToLookbook} />
}

type TProps = {
	/** */
}
type TParams = { id?: string }

export default UserViewLookbooks
