import {
	TCategoryItem,
	TFilteredRequest,
	TGetItemAttributeItem,
	TMaterial,
	TRequestBuilder,
	TSuggestedBrand,
	TBrand,
} from '@typings'
import { upDataTransformer } from '../transformers'

const cacheConfig = {
	maxAge: 5 * 60 * 1000, // 5 minutes,
	exclude: {
		query: false, // Allow request with query parameters.
		filter: () => false, // Allow request.
	},
}

export const getCategoriesTree: TRequestBuilder<Partial<TCategoryItem>> = ({ parentId, ...params } = {}) => ({
	method: 'get',
	url: `/categories/tree`,
	params: {
		parentId: parentId || 'null',
		status: 'active',
		...params,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})

export const getCategory: TRequestBuilder<TCategoryItem['id']> = (categoryId) => ({
	method: 'get',
	url: `/categories/${categoryId}`,
	transformResponse: upDataTransformer,
})

export const getMyCategories = ({ parentId }: { parentId?: TCategoryItem['id'] | null } = {}) => ({
	method: 'get',
	url: `/categories/my`,
	params: {
		parentId: parentId || 'null',
		status: 'active',
	},
	transformResponse: upDataTransformer,
})
export const createCategory: TRequestBuilder<Partial<TCategoryItem>> = (category) => ({
	method: 'post',
	url: `/categories`,
	data: category,
})
export const updateCategory: TRequestBuilder<Partial<TCategoryItem>> = ({ id, ...category }) => ({
	method: 'put',
	url: `/categories/${id}`,
	data: category,
})
export const removeCategory: TRequestBuilder<TCategoryItem['id']> = (id) => ({
	method: 'delete',
	url: `/categories/${id}`,
})

export const getBodyShapes: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/body-shapes',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getWardrobeStyles: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/clothes-styles',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getBrands: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/brands',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getBrandsTable: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/brands',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
})
export const updateBrand: TRequestBuilder<Partial<TBrand>> = ({ id, ...values }) => ({
	method: 'put',
	url: `/lists/brands/${id}`,
	data: values,
})
export const getSuggestedBrandsTable: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/suggested-brands',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
})
export const confirmSuggestedBrand: TRequestBuilder<TSuggestedBrand['id']> = (id) => ({
	method: 'post',
	url: `/lists/suggested-brands/${id}/confirm`,
})
export const rejectSuggestedBrand: TRequestBuilder<TSuggestedBrand['id']> = (id) => ({
	method: 'post',
	url: `/lists/suggested-brands/${id}/reject`,
})
// export const updateSuggestedBrand: TRequestBuilder<Partial<TSuggestedBrand>> = ({ id, ...form }) => ({
// 	method: 'put',
// 	url: `/lists/suggested-brands/${id}`,
// 	data: form,
// })
export const getDesigners: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/designers',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getCountries: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/countries',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getOnboardingInfluencers: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/onboarding-influencers',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getFashionSources: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/fashion-sources',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getStores: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/stores',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getStoresTable: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/stores',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
})
export const getSeasons: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/seasons',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getMaterials: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/materials',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getMaterialsTable: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/materials',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
})
export const removeMaterial: TRequestBuilder<TMaterial['id']> = (materialId) => ({
	method: 'delete',
	url: `/lists/materials/${materialId}`,
})
export const getCareTypes: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/care-types',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getOccasions: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/occasions',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getColours: TRequestBuilder<TFilteredRequest> = (filters) => ({
	method: 'get',
	url: '/lists/colors',
	params: {
		order: ['title', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getItemConditions: TRequestBuilder<TFilteredRequest | void> = (filters) => ({
	method: 'get',
	url: '/lists/item-conditions',
	params: {
		order: ['id', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getMarketplaceItemCondition: TRequestBuilder<TFilteredRequest | void> = (filters) => ({
	method: 'get',
	url: '/lists/marketplace-item-conditions',
	params: {
		order: ['id', 'asc'],
		...filters,
	},
	transformResponse: upDataTransformer,
})
export const getItemAttributes: TRequestBuilder<TGetItemAttributeItem> = ({ parentId, categoryId } = {}) => ({
	method: 'get',
	url: `/attributes`,
	params: parentId ? { parentId } : { categoryId },
	transformResponse: upDataTransformer,
})
export const getItemAttributesTree: TRequestBuilder<TGetItemAttributeItem> = ({
	parentId = 'null',
	categoryId,
	...params
} = {}) => ({
	method: 'get',
	url: `/attributes/tree`,
	params: categoryId ? { categoryId, ...params } : { parentId, ...params },
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
// export const getCurrencies: TRequestBuilder<void> = () => ({
// 	method: 'get',
// 	url: `/lists/currencies`,
// 	transformResponse: currencyTransformer,
// })
