import React from 'react'
import { useEffect, useCallback, useMemo } from '@hooks'
import { Title, Text, Card, Row, Avatar, Icons } from '@components'
import styles from './TopItemCard.module.css'
import { compileImageUri } from '@helpers'
import { TProfile } from 'src/typings/profile'
import { TAsset } from 'src/typings/assets'

const TopItemCard: React.FC<TProps> = ({ onClick, title, count, avatar }) => {
	return (
		<Card
			onClick={onClick}
			hoverable
			style={{ width: 95, borderRadius: 15, margin: 2 }}
			bodyStyle={{ textAlign: 'center', backgroundColor: '#E8CF62', borderRadius: 15 }}
		>
			<Avatar size="large" icon={<Icons.UserOutlined />} src={compileImageUri(avatar, true)} />
			<p>{title}</p>
			<Text strong>{count}</Text>
		</Card>
	)
}

type TProps = {
	onClick: () => void
	title: string
	count: number | null
	avatar: TAsset
}

export default TopItemCard
