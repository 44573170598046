import React from 'react'
import { useUserProfileContext } from '@hooks'
import { Avatar, Icons } from '@components'
import styles from './LayoutProfileDock.module.css'
import { compileImageUri, toString } from '@helpers'

const LayoutProfileDock: React.FC<TProps> = () => {
	const { profile } = useUserProfileContext()
	const imageSource = compileImageUri(profile.avatar, true)
	const fullName = `${toString(profile.firstName)} ${toString(profile.lastName)}`
	const userName = `@${profile.username}`

	return (
		<div className={styles.dock}>
			<Avatar size="large" icon={<Icons.UserOutlined />} src={imageSource} />
			<div className={styles.dockBody}>
				<span className={styles.dockBodyText}>{fullName}</span>
				<span className={styles.dockBodySubText}>{userName}</span>
			</div>
		</div>
	)
}

type TProps = {
	/**  */
}

export default LayoutProfileDock
