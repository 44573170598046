import { TLook } from 'src/typings/lookbook'
import { last } from '@helpers'

export function formatLookWardrobeItems(lookItems: TLook['wardrobeItems'] = []) {
	const wardrobeItemsListData = lookItems.map((lookItem) => ({
		id: lookItem.wardrobeItem.id,
		title: lookItem.wardrobeItem.name,
		description: last(lookItem.wardrobeItem?.categories)?.category.title,
		asset: last(lookItem.wardrobeItem?.assets)?.asset,
	}))

	return wardrobeItemsListData
}
