import { i18n } from '@services'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { wrapperCol: { offset: 6, span: 12 } }

export const formFieldRules = {
	priceEstimated: [
		{ type: 'number', required: true, message: i18n.t('Estimated price is required') },
		{ type: 'number', message: i18n.t('Price must be a number.') },
		{ type: 'number', min: 1, message: i18n.t('Price too small.') },
	],
}
export const normalize = (value: any) => value ?? null
