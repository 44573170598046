import { Switcher } from '@components'
import { useEffect, useRequest, useState } from '@hooks'
import { api, message } from '@services'
import { TWardrobeItem } from '@typings'
import React from 'react'

const IsRecommendedItemSwitcher: React.FC<TProps> = ({ defaultValue, wardrobeItemId }) => {
	// Change status.
	const [isRecommended, setIsRecommended] = useState(defaultValue, '')
	useEffect(() => void setIsRecommended(defaultValue), [])

	const { fetch: updateIsRecommended, fetching: isRecommendedUpdating } = useRequest<TWardrobeItem, boolean>({
		request: (isRecommended) => api.updateWardrobeItem({ id: wardrobeItemId, isRecommended }),
		onSuccess: (wardrobeItem) => setIsRecommended(wardrobeItem.isRecommended),
		onError: (error) => message.error(error.message),
	})

	return (
		<Switcher
			loading={isRecommendedUpdating}
			checked={isRecommended}
			checkedChildren="recommended"
			unCheckedChildren="not recommended"
			onChange={updateIsRecommended}
		/>
	)
}

type TProps = {
	/** Initial value for isRecommended status. */
	defaultValue: boolean
	/** Target wardrobe item ID */
	wardrobeItemId: TWardrobeItem['id']
}

export default IsRecommendedItemSwitcher
