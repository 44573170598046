import {
	ApiListSelectField,
	Button,
	Card,
	DatePickerField,
	ErrorBoundary,
	Form,
	FormSpy,
	Input,
	PriceField,
	Spin,
	Switcher,
	TreeSelectField,
	UploadImageField,
	When,
	StatusTag,
	Row,
	Col,
	LocationField,
	IsRecommendedItemSwitcher,
	Space,
	RemoveCommentLink,
	DataTable,
	Icons,
	Link,
	RemoveWardrobeItemLink,
	RestoreWardrobeItemLink,
} from '@components'
import { toNumber, showFormErrors } from '@helpers'
import {
	useCallback,
	useEffect,
	useHistory,
	useMemo,
	useParams,
	useRef,
	useRequest,
	useState,
	useTranslation,
} from '@hooks'
import { api, message, apiSettings } from '@services'
import {
	FormInstance,
	TCategoriesTree,
	TCategoryItem,
	TSizeChart,
	TTree,
	TWardrobeItem,
	TWardrobeItemForm,
	TResponseWithFilters,
	TComment,
	TFilteredRequest,
	TProfile,
} from '@typings'
import * as React from 'react'
import {
	castWardrobeItemToForm,
	formLayout,
	formButtonLayout,
	normalize,
	yearNormalize,
	formFieldRules,
} from './EditWardrobeItem.formOptions'
import { columns } from './EditWardrobeItem.tableOptions'
import FileSaver from 'file-saver'
import Axios from 'axios'
import styles from './EditWardrobeItem.module.css'

const EditWardrobeItemScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const wardrobeItemId = toNumber(params?.id)
	const formInstanceRef = useRef<FormInstance>()
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	// Wardrobe item requests.
	const { data: wardrobeItem, fetching, fetched } = useRequest<TWardrobeItem, TWardrobeItem['id']>(
		{
			request: () => api.getWardrobeItem(wardrobeItemId),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const initialValues = useMemo(() => castWardrobeItemToForm(wardrobeItem), [wardrobeItem])
	const { fetch: updateWardrobeItem, fetching: updating } = useRequest<TWardrobeItem, TWardrobeItemForm>({
		request: ({ assets = [], ...form }) =>
			api.updateWardrobeItem({ id: wardrobeItemId, assetIds: assets?.map((asset) => asset.id), ...form }) || null,
		onSuccess: () => {
			history.goBack()
			message.success(t('Item has been saved!'))
		},
		onError: (error) => message.error(error.message),
	})

	// Form state handling.
	const [selectedCategoryId, setSelectedCategoryId] = useState<TWardrobeItemForm['categoryId']>(
		null,
		'EditUserScreen:selectedCategoryIds',
	)
	useEffect(() => void setSelectedCategoryId(initialValues?.categoryId), [initialValues?.categoryId])

	const handleValuesChange = useCallback(
		(changedValues: Partial<TWardrobeItemForm>, values: Partial<TWardrobeItemForm>) => {
			if ('categoryId' in changedValues) {
				// Save current category.
				setSelectedCategoryId(changedValues.categoryId)
				// Reset attributes and size after changing category.
				const fieldsValue = formInstanceRef.current?.getFieldsValue
				formInstanceRef.current?.setFieldsValue({ ...fieldsValue, attributeIds: [], sizeId: null })
			}
		},
		[],
	)

	const [chartTypeId, setChartTypeId] = useState<TSizeChart['id'] | null>(null, 'EditUserScreen:chartTypeId')
	useRequest<TCategoryItem>(
		{
			request: () => (selectedCategoryId ? api.getCategory(selectedCategoryId) : null),
			onSuccess: (category) => {
				if (category?.chartTypeId !== chartTypeId) {
					setChartTypeId(category?.chartTypeId)
				}
			},
		},
		[selectedCategoryId],
	)

	// List requests.
	const { data: categoriesTree } = useRequest<TCategoriesTree, void>(
		{
			request: () => api.getCategoriesTree({}),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const { data: attributes } = useRequest<TTree<'subattributes'>>(
		{ request: () => (selectedCategoryId ? api.getItemAttributesTree({ categoryId: selectedCategoryId }) : null) },
		[selectedCategoryId],
	)
	const { data: sizes } = useRequest<TSizeChart[]>(
		{ request: () => (chartTypeId ? api.getSizeCharts({ chartTypeId }) : null) },
		[chartTypeId],
	)
	//Comments
	const { data: { data: comments, meta } = {}, fetch } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TProfile>
	>(
		{
			request: () =>
				wardrobeItemId ? api.getComments({ 'wardrobeItems.wardrobeItemId': wardrobeItemId }) : undefined,
			onError: (error) => message.error(error),
		},
		[wardrobeItemId],
	)
	const newCommentsData = comments?.map((el) => (el.replies?.length ? { ...el, children: el.replies } : { ...el }))

	//Export Data

	const exportData = () => {
		const ids = [...new Set(wardrobeItem?.assets?.map((asset) => asset.assetId))]
		Axios({
			url: `${apiSettings.getSettings('API_URL')}/assets/download?`,
			params: { ids },
			method: 'GET',
			responseType: 'blob', // Important
		}).then((response) => {
			var blob = new Blob([response.data], { type: 'application/zip' })
			FileSaver.saveAs(blob, 'assets.zip')
		})
	}

	return (
		<ErrorBoundary>
			<Spin spinning={fetching}>
				<Card
					title={
						<>
							<span>{t('Edit wardrobe item')}</span>
							<span>&nbsp;</span>
							<StatusTag status={wardrobeItem?.status} />
						</>
					}
					extra={
						<Row gutter={[16, 16]} align="middle">
							<Col flex={0}>
								<IsRecommendedItemSwitcher
									defaultValue={wardrobeItem?.isRecommended}
									wardrobeItemId={wardrobeItemId}
								/>
							</Col>
							<When condition={API_SERVER_NAME === 'staging'}>
								<Button type="primary" icon={<Icons.DownloadOutlined />} onClick={exportData}>
									{t('Export all Photos')}
								</Button>
							</When>
							<When condition={wardrobeItem?.isArchived}>
								<Col flex={0}>
									<RestoreWardrobeItemLink
										wardrobeItemId={wardrobeItemId}
										onSuccess={() => history.replace('/wardrobe-items')}
									/>
								</Col>
							</When>

							<Col flex={0}>
								<RemoveWardrobeItemLink
									wardrobeItemId={wardrobeItemId}
									onSuccess={() => history.replace('/wardrobe-items')}
									isArchived={wardrobeItem?.isArchived}
								/>
							</Col>
						</Row>
					}
				>
					<When condition={fetched}>
						<Form
							{...formLayout}
							onFinish={updateWardrobeItem}
							initialValues={initialValues}
							onValuesChange={handleValuesChange}
							onFinishFailed={showFormErrors}
						>
							<Form.Item
								normalize={normalize}
								label={t('Name')}
								name="name"
								rules={formFieldRules.name}
								required
							>
								<Input disabled={updating} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Description')} name="description">
								<Input disabled={updating} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Photos')} required wrapperCol={{ span: 15 }}>
								<UploadImageField name="assets" multiple rules={formFieldRules.assets} />
							</Form.Item>
							<When condition={!!categoriesTree}>
								<Form.Item
									normalize={normalize}
									label="Categories"
									name="categoryId"
									rules={formFieldRules.categoryId}
									required
								>
									<TreeSelectField<'subcategories'>
										placeholder={t('Choose category')}
										childrenKey="subcategories"
										tree={categoriesTree}
									/>
								</Form.Item>
							</When>
							<Form.Item normalize={normalize} label={t('Attributes')} name="attributeIds">
								<TreeSelectField<'subattributes'>
									multiple
									placeholder={t('Choose attributes')}
									childrenKey="subattributes"
									tree={selectedCategoryId ? attributes : []}
								/>
							</Form.Item>
							<Form.Item
								normalize={normalize}
								label={t('Brand')}
								name="brandId"
								rules={formFieldRules.brandId}
								required
							>
								<ApiListSelectField placeholder={t('Select brand')} request={api.getBrands} />
							</Form.Item>
							<Form.Item normalize={yearNormalize} label={t('Year')} name="year">
								<DatePickerField placeholder={t('Choose year')} />
							</Form.Item>
							<Form.Item
								// normalize={normalize}
								label={t('Location')}
								name="location"
							>
								<LocationField />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Price purchased')} name="pricePurchased">
								<PriceField />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Price estimated ')} name="priceEstimated">
								<PriceField />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Price actual')} name="priceActual">
								<PriceField />
							</Form.Item>

							<Form.Item normalize={normalize} label={t('Designer')} name="designerId">
								<ApiListSelectField placeholder={t('Select designer')} request={api.getDesigners} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Season')} name="seasonId">
								<ApiListSelectField placeholder={t('Select season')} request={api.getSeasons} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Styles')} name="clothesStyleIds">
								<ApiListSelectField
									mode="multiple"
									placeholder={t('Select style')}
									request={api.getWardrobeStyles}
								/>
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Occasions')} name="occasionIds">
								<ApiListSelectField
									mode="multiple"
									placeholder={t('Select occasion')}
									request={api.getOccasions}
								/>
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Store')} name="storeId">
								<ApiListSelectField placeholder={t('Select store')} request={api.getStores} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Color')} name="colorId">
								<ApiListSelectField placeholder={t('Select color')} request={api.getColours} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Size')} name="sizeId">
								<TreeSelectField<'sizes'>
									placeholder={t('Choose size')}
									childrenKey="sizes"
									tree={chartTypeId ? sizes : []}
								/>
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Material')} name="materialId">
								<ApiListSelectField placeholder={t('Select material')} request={api.getMaterials} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Made In ')} name="countryId">
								<ApiListSelectField placeholder={t('Select country')} request={api.getCountries} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Care type')} name="careTypeId">
								<ApiListSelectField placeholder={t('Select care type')} request={api.getCareTypes} />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Serial Number')} name="serialNumber">
								<Input />
							</Form.Item>

							<Form.Item
								normalize={normalize}
								label={t('Dustbag')}
								name="dustbag"
								valuePropName="checked"
							>
								<Switcher />
							</Form.Item>

							<Form.Item
								normalize={normalize}
								label={t('Receipt Included')}
								name="receiptIncluded"
								valuePropName="checked"
							>
								<Switcher />
							</Form.Item>

							<Form.Item
								normalize={normalize}
								label={t('Authenticity Card')}
								name="authenticityCard"
								valuePropName="checked"
							>
								<Switcher />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Condition')} name="itemConditionId">
								<ApiListSelectField
									placeholder={t('Select condition')}
									request={api.getItemConditions}
								/>
							</Form.Item>
							<Form.Item
								normalize={normalize}
								label={t('Personal id reference')}
								name="personalIdReference"
							>
								<Input />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Order number')} name="orderNumber">
								<Input />
							</Form.Item>
							<Form.Item normalize={normalize} label={t('Purchased date')} name="purchasedDate">
								<DatePickerField placeholder={t('Choose date')} />
							</Form.Item>
							<When condition={!!wardrobeItem?.user}>
								<Form.Item normalize={normalize} label={t('Owner')}>
									<Link
										to={`/users/${wardrobeItem?.user?.id}`}
									>{`${wardrobeItem?.user?.firstName} ${wardrobeItem?.user?.lastName}`}</Link>
								</Form.Item>
							</When>

							<FormSpy ref={formInstanceRef} />
							<Form.Item normalize={normalize} wrapperCol={formButtonLayout}>
								<Space>
									<Button type="primary" htmlType="submit" loading={updating}>
										{t('Save changes')}
									</Button>
									<Button htmlType="button" onClick={history.goBack}>
										{t('Cancel')}
									</Button>
								</Space>
							</Form.Item>
						</Form>
					</When>
					<Card title={t('Comments')}>
						<DataTable<TProfile, Partial<TProfile>>
							columns={columns}
							dataSource={newCommentsData}
							meta={meta}
							autoFetchOnMount={false}
							renderActionsComponent={(comment: TComment) => (
								<>
									<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
								</>
							)}
						/>
					</Card>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}
type TParams = { id?: string }

export default EditWardrobeItemScreen
