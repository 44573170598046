import * as React from 'react'
import { useEffect, useCallback, useMemo, useTranslation, useRequest, useState } from '@hooks'
import { ErrorBoundary } from '@components'
import { api, message } from '@services'
import styles from './ExportButton.module.css'

const ExportButtonScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()

	// .
	// const { data: myData, fetching, fetched } = useRequest<[], void>({
	// 	request: api.getSomething(),
	// 	onError: (error) => alert.error(error)
	// })

	return (
		<ErrorBoundary>
			<div>ExportButtonScreen</div>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default ExportButtonScreen
