import { Avatar, Case, Default, Empty, Icons, IconText, Space, Switch, Tag, When, Card } from '@components'
import { compileImageUri, getYouTubeVideoId, prettytime, toString } from '@helpers'
import { useTranslation } from '@hooks'
import { TPost } from '@typings'
import React from 'react'
import styles from './PostRow.module.css'
import { Tooltip } from 'antd'

/** Preview of post for list or expanded table row. */
const PostRow: React.FC<TProps> = ({ post }) => {
	const { t } = useTranslation()

	return (
		<Card
			key={post.id}
			actions={[
				<IconText icon={Icons.HeartOutlined} key="likeCount" title={t('Number of likes')}>
					{String(post.likeCount)}
				</IconText>,
				<IconText
					icon={Icons.ReloadOutlined}
					key="scheduleSettingsRepetitionsLeft"
					title={t('Repetitions left')}
				>
					{String(post.scheduleSettingsRepetitionsLeft)}
				</IconText>,
				<IconText icon={Icons.ClockCircleOutlined} key="scheduleSettingsInterval" title={t('Interval')}>
					{post.scheduleSettingsInterval ? prettytime(post.scheduleSettingsInterval) : '—'}
				</IconText>,
			]}
		>
			<div className={styles.itemContainer}>
				<div className={styles.mediaContainer}>
					<Switch>
						<Case condition={!!post.asset}>
							<img className={styles.image} alt="logo" src={compileImageUri(post.asset, true)} />
						</Case>
						<Case condition={!!post.videoUrl}>
							<iframe
								title="YouTube video"
								className={styles.image}
								src={
									`https://www.youtube.com/embed/${getYouTubeVideoId(
										post.videoUrl as string,
									)}` as string
								}
							/>
						</Case>
						<Default>
							<Empty />
						</Default>
					</Switch>
				</div>
				<div className={styles.contentContainer}>
					<div className={styles.cardMetaContainer}>
						<Card.Meta
							avatar={
								<Tooltip
									placement="top"
									title={`${post.postCreator.firstName} ${post.postCreator.lastName} `}
								>
									<Avatar src={compileImageUri(post.postCreator?.avatar, true)} />
								</Tooltip>
							}
							title={post.title || '...'}
							description={
								<Space>
									<span>{`#${post.id}`}</span>
									<When condition={post.isPromotional}>
										<Tag color="gold">{t('promotional')}</Tag>
									</When>
									<When condition={post.status === 'disabled'}>
										<Tag color="red">{t('disabled')}</Tag>
									</When>
								</Space>
							}
						/>
					</div>
					<span
						className={styles.description}
						dangerouslySetInnerHTML={{ __html: toString(post.description) }}
					/>
				</div>
			</div>
		</Card>
	)
}

type TProps = {
	/** Post data. */
	post: TPost
}

export default PostRow
