import { convertFromRaw, EditorState, RawDraftContentState, ContentState, DraftDecorator } from 'draft-js'
import convertFromHtml from 'html-to-draft'

/** Cast html to DraftJS editor state. Using for convert html value for suitable draft js fields format. */
export default function HtmlToDraftEditorState(html: string, decorator?: DraftDecoratorType): EditorState {
	const contentState = convertFromRaw(
		convertFromHtml(html, {
			parseBlock: (element: HTMLElement): TextBlock | AtomicBlock | undefined => {
				switch (element.tagName?.toLowerCase()) {
					case 'img': {
						const {
							src,
							dataset: { id, key },
						} = element
						return { type: 'atomic', data: { type: 'image', src, 'data-id': id, 'data-key': key } }
					}
					case 'nav': {
						switch (element.dataset?.content) {
							case 'lookbooks': {
								const ids = Array.from(element.children).map((anchorElement) =>
									Number(anchorElement.dataset?.lookbookId),
								)
								return { type: 'atomic', data: { type: 'lookbooks', ids } }
							}
							case 'wardrobe':
							default: {
								const ids = Array.from(element.children).map((anchorElement) =>
									Number(anchorElement.dataset?.wardrobeItemId),
								)
								return { type: 'atomic', data: { type: 'wardrobe', ids } }
							}
						}
					}
					default:
						return undefined
				}
			},
		}),
	)

	// SORRY. Remove first block. Due to first block always empty. I couldn't find better solution.
	const blockMap = contentState.getBlockMap()
	const newBlockMap = blockMap.remove(blockMap.first().getKey())
	const newContentState = contentState.merge({ blockMap: newBlockMap }) as ContentState

	const editorState = EditorState.createWithContent(newContentState, decorator)

	return editorState
}

type TConvertFromHtml = (
	html: string,
	options: {
		parseBlock: (element: HTMLElement) => TextBlock | AtomicBlock
		parseTextFragment: (element: HTMLElement) => TextFragment
	},
) => RawDraftContentState
type TextFragment = {
	offset: number
	length: number
	inlineStyleRanges: string[]
	entity: {
		type: string
		data: {}
	}
}

type AtomicBlock = {
	type: 'atomic'
	entity?: {
		type: string
		data: {}
	}
	data?: {}
}

type TextBlock = {
	type?: string
	depth?: number
	data?: Record<string, any>
}
