import { ArticleItemsBlockDialog, When, Icons, Button } from '@components'
import { useBoolean } from '@hooks'
import { TWardrobeItem } from '@typings'
import { EditorState } from 'draft-js'
import React from 'react'

/** Custom button for DraftJS ArticleEditor component. Allow add wardrobe items to article. */
const ArticleItemsBlockButton: React.FC<TProps> = ({ initialValue, onChange, editorState, ...props }) => {
	const isVisibleBoolean = useBoolean(false)

	return (
		<>
			<Button shape="circle" icon={<Icons.SkinOutlined />} onClick={isVisibleBoolean.toggle} />
			<When condition={isVisibleBoolean.value}>
				<ArticleItemsBlockDialog
					initialValue={initialValue}
					onChange={onChange}
					editorState={editorState}
					onClose={isVisibleBoolean.setFalse}
				/>
			</When>
		</>
	)
}

type TProps = {
	/** Current list of wardrobe item ids (for updating). */
	initialValue: TWardrobeItem['id'][]
	/** Current EditorState passed by draftjs editor. */
	editorState: EditorState
	/** Function for changing EditorState passed by draftjs editor. */
	onChange: (editorState: EditorState) => void
}

export default ArticleItemsBlockButton
