import { Link } from '@components'
import { TStore } from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		searchKey: 'id',
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TStore) => <Link to={`/stores/${record.id}`}>{text}</Link>,
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 200,
		searchKey: 'title',
	},
]
