import { Link, Tag } from '@components'
import { TSuggestedBrand, TSuggestedBrandStatus, TUser } from '@typings'
import React from 'react'

const statusColors: { [status in TSuggestedBrandStatus]: string } = {
	rejected: 'error',
	'on-review': 'processing',
	approved: 'success',
}

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
		render: (text: string, record: TSuggestedBrand) => <Link to={`/brands/${record.id}`}>{text}</Link>,
		defaultSortOrder: 'descend',
	},
	{
		title: 'Title',
		dataIndex: 'title',
		searchKey: 'title',
		width: 200,
	},
	{
		title: 'User',
		dataIndex: 'user',
		width: 180,
		render: (text: string, record: TSuggestedBrand) => (
			<Link to={`/users/${record.user.id}`}>{`${record.user.firstName} ${record.user.lastName}`}</Link>
		),
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 120,
		sorter,
		filters: [
			{
				text: 'Rejected',
				value: 'rejected',
			},
			{
				text: 'On-review',
				value: 'on-review',
			},
			{
				text: 'Approved',
				value: 'approved',
			},
		] as { text: string; value: TSuggestedBrandStatus }[],
		defaultFilteredValue: ['on-review'],
		render: (text: TSuggestedBrandStatus) => <Tag color={statusColors[text]}>{text}</Tag>,
	},
	{
		title: 'Brand ID',
		dataIndex: 'brandId',
		width: 80,
		render: (text: string, record: TSuggestedBrand) => <Link to={`/brands/${record.brandId}`}>{text}</Link>,
	},
]
