import React from 'react'
import { Input } from 'antd'
import { TableProps, InputProps } from '@typings'
import styles from './SearchableTableHeaderCell.module.css'

const SearchableTableHeaderCell: React.FC<TProps> = ({ children, onClick, value, onChange, ...props }) => {
	const title = children?.[1]?.props?.children?.[0]?.props?.children || children?.[1] || '' // extract title from chidren

	return (
		<th {...cellProps} title={title}>
			<div className={styles.thContainer}>
				<span onClick={onClick}>{children}</span>
				<Input allowClear value={value} onChange={onChange} />
			</div>
		</th>
	)
}

type TProps = React.HTMLAttributes<HTMLElement> & Pick<InputProps, 'value' | 'onChange'> & {}

export default SearchableTableHeaderCell
