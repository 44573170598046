import { Card, ErrorBoundary, Form, UploadImageField, When, Button, Icons } from '@components'
import { useRequest, useTranslation } from '@hooks'
import { api, message, apiSettings } from '@services'
import * as React from 'react'
import { showFormErrors } from '@helpers'
import { formLayout } from './ImageSets.formOptions'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { TAsset } from 'src/typings/assets'

const ImageSetsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	// API data.
	const { data: sharingEmoji = [], fetching: sharingEmojiFetching } = useRequest<[], void>(
		{
			request: () => api.getImages({ tag: 'sharing-emoji' }),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const { data: uploadItemSuggestions = [], fetching: uploadItemSuggestionsFetching } = useRequest<TAsset[], void>(
		{
			request: () => api.getImages({ tag: 'upload-item-image-suggestion' }),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	//Export Data

	const exportData = () => {
		const ids = [...new Set(uploadItemSuggestions?.map((asset) => asset.id))]
		Axios({
			url: `${apiSettings.getSettings('API_URL')}/assets/download?`,
			params: { ids },
			method: 'GET',
			responseType: 'blob', // Important
		}).then((response) => {
			var blob = new Blob([response.data], { type: 'application/zip' })
			FileSaver.saveAs(blob, 'assets.zip')
		})
	}

	return (
		<ErrorBoundary>
			<Card title={t('Sharing emojis')} loading={sharingEmojiFetching}>
				<Form
					key={`sharingEmoji-${sharingEmoji.length}`}
					initialValues={{ assets: sharingEmoji }}
					onFinishFailed={showFormErrors}
					{...formLayout}
				>
					<Form.Item wrapperCol={{ span: 15 }}>
						<UploadImageField multiple name="assets" tag="sharing-emoji" />
					</Form.Item>
				</Form>
			</Card>
			<Card
				title={t('Upload item image suggestions 3')}
				loading={uploadItemSuggestionsFetching}
				extra={
					<When condition={API_SERVER_NAME === 'staging'}>
						<Button type="primary" icon={<Icons.DownloadOutlined />} onClick={exportData}>
							{t('Export all Photos')}
						</Button>
					</When>
				}
			>
				<Form
					key={`uploadItemSuggestions-${uploadItemSuggestions.length}`}
					initialValues={{ assets: uploadItemSuggestions }}
					onFinishFailed={showFormErrors}
					{...formLayout}
				>
					<Form.Item wrapperCol={{ span: 15 }}>
						<UploadImageField multiple name="assets" tag="upload-item-image-suggestion" />
					</Form.Item>
				</Form>
			</Card>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default ImageSetsScreen
