import React from 'react'
import { useMemo } from '@hooks'
import { Spin } from '@components'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import moment from 'moment'
import { UTCDate, TStatisticsPeriod } from '@typings'

const bars = [
	{ title: 'article', color: '#8B94A3' },
	{ title: 'look', color: '#F2E2D2' },
	{ title: 'lookbook', color: '#BCC1BA' },
	{ title: 'post', color: '#9FB7B9' },
	{ title: 'video', color: '#84C0C6' },
	{ title: 'wardrobeItem', color: '#46B1C9' },
]

const StatisticStackedBarChart: React.FC<TProps> = ({ loading, data = [], period }) => {
	const formatDate = (date: UTCDate) => {
		switch (period) {
			case 'year':
				return moment(date, 'YYYY-MM-DD').year()
			case 'month':
				return moment(date, 'YYYY-MM-DD').format('MMM YYYY')
			case 'week':
				return moment(date, 'YYYY-MM-DD').format('wo YYYY')
			case 'day':
				return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
			default:
				return date
		}
	}
	const chartData = useMemo(() => {
		const chart = Object.entries(data)
		let chartData: any = []
		for (var i = 0; i < chart.length; i++) {
			for (var j = 0; j < chart[i].length; j++) {
				if (typeof chart[i][j] !== 'string') {
					const name = chart[i][0].replace(/StatisticFields|CommentStatisticFields/, '')
					chart[i][j].map((el) => {
						if (!chartData.find((obj) => obj.name === formatDate(el.startDate))) {
							chartData.push({ name: formatDate(el.startDate), [name]: el.count })
						}
						chartData = chartData.map((obj) => {
							return obj.name === formatDate(el.startDate) ? { ...obj, [name]: el.count } : { ...obj }
						})
					})
				}
			}
		}
		return chartData
	}, [data])
	return (
		<Spin spinning={loading}>
			<BarChart width={900} height={450} data={chartData}>
				<CartesianGrid strokeDasharray="1 1" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />
				{bars.map((bar) => (
					<Bar dataKey={bar.title} stackId="a" fill={bar.color} />
				))}
			</BarChart>
		</Spin>
	)
}

type TProps = {
	loading: boolean
	data: any[]
	period: TStatisticsPeriod
	bars: string[]
	barLabels?: string[]
}

export default StatisticStackedBarChart
