import { Button, DataTable, ErrorBoundary, PostRow, SurveyRow } from '@components'
import { useHistory, useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TPost, TResponseWithFilters, TWardrobeItem, TSurvey } from '@typings'
import * as React from 'react'
import { columns } from './Posts.tableOptions'

/** Display all posts, created manually. This page won;t be display automatically generated posts. */
const PostsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// API data..
	const { data: { data: posts, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TPost[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getPosts({ order: ['id', 'desc'], ...searchParams, isAutomaticallyGenerated: false }),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddPost = () => history.push(`posts/add`)

	return (
		<ErrorBoundary>
			<DataTable<TPost, Partial<TPost>>
				loading={fetching}
				columns={columns}
				dataSource={posts}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" onClick={goToAddPost}>
						{t('Add post')}
					</Button>
				}
				expandable={{
					expandedRowRender: (post: TPost) => <PostRow key={post.id} post={post} />,
				}}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default PostsScreen
