import { first } from '@helpers'
import { useRequest, useState } from '@hooks'
import { api } from '@services'
import { TResponseWithFilters, TUser } from '@typings'

/** Async hook for obtain userId of LBD official user account. */
const useLBDOfficialUserId = (): number | undefined => {
	const [LBDOfficialUserId, setLBDOfficialUserId] = useState<number | undefined>(undefined, '')
	useRequest<TResponseWithFilters<TUser[]>>(
		{
			request: () => api.getUsers({ additionalIdentifier: 'lbd-global-account' }),
			onSuccess: (response) => setLBDOfficialUserId(first(response.data)?.id),
		},
		[],
	)

	return LBDOfficialUserId
}

export default useLBDOfficialUserId
