import { set, toString } from '@helpers'

const putSearchParamToLocation = <T>(location, key: string, value: string | number): T => {
	const currentSearchParams = new URLSearchParams(location.search)
	currentSearchParams.set(key, toString(value))
	const newLocation = set(location, 'search', currentSearchParams.toString())
	return newLocation
}

export default putSearchParamToLocation
