import { compileImageUri } from '@helpers'
import { TLookbook } from '@typings'
import React from 'react'
import styles from './ArticleLookbooksBlockRow.module.css'

const ArticleLookbooksBlockRow: React.FC<TProps> = ({ item }) => {
	if (!item) return null

	const itemName = item.title
	const image = compileImageUri(item.asset, false)
	const fullName = `${item.user.firstName} ${item.user.lastName}`

	return (
		<div className={styles.itemContainer}>
			<img className={styles.image} src={image} alt="" />
			<span className={styles.brandTitle}>{itemName}</span>
			<span className={styles.pricePurchased}>{fullName}</span>
		</div>
	)
}

type TProps = {
	/**  */
	item: TLookbook
}

export default ArticleLookbooksBlockRow
