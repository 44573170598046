import React from 'react'
import { useEffect, useCallback, useMemo, useBoolean } from '@hooks'
import { Title, Text, Button, Icons, ArticleImageBlockDialog } from '@components'
import styles from './ArticleImageBlockButton.module.css'
import { When } from 'react-if'
import { TAsset } from '@typings'
import { EditorState } from 'draft-js'

const ArticleImageBlockButton: React.FC<TProps> = ({ initialValue, onChange, editorState }) => {
	const isVisibleBoolean = useBoolean(false)

	return (
		<>
			<Button shape="circle" icon={<Icons.FileImageOutlined />} onClick={isVisibleBoolean.toggle} />
			<When condition={isVisibleBoolean.value}>
				<ArticleImageBlockDialog
					initialValue={initialValue}
					onChange={onChange}
					editorState={editorState}
					onClose={isVisibleBoolean.setFalse}
				/>
			</When>
		</>
	)
}

type TProps = {
	/** ID of current image (if editing). */
	initialValue: TAsset['id']
	/** Current EditorState passed by draftjs editor. */
	editorState: EditorState
	/** Function for changing EditorState passed by draftjs editor. */
	onChange: (editorState: EditorState) => void
}

export default ArticleImageBlockButton
