import * as React from 'react'
import { useTranslation, useRequest, useState, useTableLocationParams } from '@hooks'
import { ErrorBoundary, DataTable, RemoveCodeLink, RemoveCommentLink } from '@components'
import { api, message } from '@services'
import { TResponseWithFilters, TProfile, TFilteredRequest, TComment } from '@typings'
import { columns } from './Comments.tableOptions'

const CommentsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = React.useRef<any>(null)
	const [searchParams, setSearchParams] = useTableLocationParams()

	const { data: { data: comments, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TProfile>
	>(
		{
			request: () => api.getComments(searchParams),
			onError: (error) => message.error(error),
		},
		[searchParams],
	)

	return (
		<ErrorBoundary>
			<DataTable<TProfile, Partial<TProfile>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={comments}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				renderActionsComponent={(comment: TComment) => (
					<>
						<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
					</>
				)}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default CommentsScreen
