import { Badge, Card } from '@components'
import { formatPrice } from '@helpers'
import React from 'react'
import { Cell, Pie, PieChart } from 'recharts'
import { TNumberOption } from '@typings'
import styles from './ChartPie.module.css'

const COLORS = ['#a88bde', '#23d7db', '#f8608c', '#09c3aa', '#FFBB28', '#FF8042', '#41d99d']

const ChartPie: React.FC<TProps> = ({ data, loading, onClick, title, type = 'price' }) => {
	const RADIAN = Math.PI / 180
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5
		const x = cx + radius * Math.cos(-midAngle * RADIAN)
		const y = cy + radius * Math.sin(-midAngle * RADIAN)

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		)
	}

	return (
		<Card loading={loading} hoverable onClick={onClick} className={styles.container} title={title}>
			<PieChart width={300} height={272}>
				<Pie
					data={data}
					cx={118}
					cy={100}
					labelLine={false}
					label={renderCustomizedLabel}
					outerRadius={100}
					fill="#8884d8"
					dataKey="value"
				>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}-${entry.value}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
			{data.map((entry, index) => (
				<div>
					<Badge
						color={COLORS[index % COLORS.length]}
						text={`${entry.title} - ${
							type === 'price' ? formatPrice(entry.value) : `${entry.value} items`
						}`}
					/>
				</div>
			))}
		</Card>
	)
}

type TProps = {
	data: TNumberOption[]
	loading: boolean
	onClick: () => void
	title?: string
	type?: string
}

export default ChartPie
