import { useRequest, useHistory } from '@hooks'
import { message, api, i18n } from '@services'
import { TSurvey } from '@typings'
import { useTranslation } from 'react-i18next'

const EMPTY_ARTICLE_ID_ERROR = i18n.t('useSurveyAPI: surveyId not passed to mutate request.')

export default function useSurveyAPI(
	id: TSurvey['id'] | undefined,
	{ onCreate, onUpdate, onRemove, onAction }: TUseSurveyCallbacks = {},
): TSurveyAPI {
	const history = useHistory()
	const { t } = useTranslation()
	const { data: article, fetch: fetchSurvey, fetching, fetched } = useRequest<TSurvey, void>(
		{
			request: () => {
				if (!id) return // create mode
				return api.getSurvey(id)
			},
			onError: (error) => message.error(error.message),
		},
		[id],
	)

	const { fetch: create, fetching: creating, fetched: created } = useRequest<TSurvey, Partial<TSurvey>>({
		request: (form) => api.createSurvey(form),
		onSuccess: (article) => {
			message.success(t('Survey has been created successfully.'))
			history.goBack()
			onAction?.()
			onCreate?.(article)
			fetchSurvey()
		},
		onError: (error) => message.error(error.message),
	})

	const { fetch: update, fetching: updating, fetched: updated } = useRequest<TSurvey, Partial<TSurvey>>({
		request: (form) => api.updateSurvey({ id, ...form }),
		onSuccess: (article) => {
			onAction?.()
			onUpdate?.(article)
			fetchSurvey()
			history.goBack()
		},
		onError: (error) => message.error(error.message),
	})

	const { fetch: remove, fetching: removing, fetched: removed } = useRequest<TSurvey, void>({
		request: () => {
			if (!id) throw new Error(EMPTY_ARTICLE_ID_ERROR)
			return api.removeSurvey(id)
		},
		onSuccess: () => {
			onAction?.()
			onRemove?.(article as TSurvey)
			history.goBack()
		},
		onError: (error) => message.error(error.message),
	})

	return {
		data: article,
		create,
		update,
		remove,
		fetching,
		fetched,
		creating,
		created,
		updating,
		updated,
		removing,
		removed,
	}
}

type TUseSurveyCallbacks = {
	onCreate?: (article: TSurvey) => void
	onUpdate?: (article: TSurvey) => void
	onRemove?: (article: TSurvey) => void
	onAction?: () => void
}
type TSurveyAPI = {
	data: TSurvey | undefined
	create: (form: Partial<TSurvey>) => void | Promise<void>
	update: (form: Partial<TSurvey>) => void | Promise<void>
	remove: (args: any) => void | Promise<void>
	fetching: boolean
	fetched: boolean
	creating: boolean
	created: boolean
	updating: boolean
	updated: boolean
	removing: boolean
	removed: boolean
}
