import { Col, ErrorBoundary, Route, Row } from '@components'
import { last, toNumber } from '@helpers'
import { useEffect, useEntityListItemAPI, useHistory, useRequest, useState, useTranslation } from '@hooks'
import { AttributesList, EditAttribute } from '@screens'
import { api, message } from '@services'
import { TCategoriesTree, TItemAttributeItem, TItemAttributesTree, TVisenzeAttribute } from '@typings'
import * as React from 'react'

const AttributesScreen: React.FC<TProps> = () => {
	const { t } = useTranslation()
	const history = useHistory()

	// Manage categories data.
	const { data: attributesTree = [], fetch: fetchAttributesTree, fetching } = useRequest<TItemAttributesTree, void>(
		{
			request: () => api.getItemAttributesTree({ status: undefined }),
			onError: (error) => message.error(error.message),
		},
		[],
	)

	// Handle select tree node.
	const [selectedKeys, setSelectedKeys] = useState<string[]>([], 'AttributesScreen:selectedKeys')
	const selectedAttributeId = selectedKeys?.length ? toNumber(last(selectedKeys)) : undefined
	useEffect(() => {
		const targetPath = selectedAttributeId ? `/attributes/${selectedAttributeId}` : `/attributes`
		history.push(targetPath) // push new route on select category by user.
	}, [selectedAttributeId])

	// Select lists for form.
	const { data: visenzeAttributes, fetch: fetchVisenzeAttributes } = useRequest<TVisenzeAttribute[]>(
		{ request: api.getVisenzeAttributes },
		[],
	)
	const { data: categoriesTree } = useRequest<TCategoriesTree>({ request: api.getCategoriesTree }, [])

	// Attribute API.
	const Attribute = useEntityListItemAPI<TItemAttributeItem, Partial<TItemAttributeItem>>(
		'/attributes',
		selectedAttributeId,
		{
			onAction: () => {
				fetchAttributesTree()
				fetchVisenzeAttributes() // because list of visenze items have bundle with categories.
			},
			onCreate: () => history.goBack(),
			onRemove: (attribute) => setSelectedKeys(attribute.parentId ? [String(attribute.parentId)] : []),
		},
	)

	return (
		<ErrorBoundary>
			<Row gutter={[16, 16]}>
				<Col span={8}>
					<AttributesList
						attributesTree={attributesTree}
						Attribute={Attribute}
						loading={fetching || Attribute.updating}
						selectedKeys={selectedKeys}
						setSelectedKeys={setSelectedKeys}
					/>
				</Col>
				<Col span={16}>
					<Route
						path="/attributes/:id(\d+)"
						exact
						render={() => (
							<EditAttribute
								isAdding={false}
								visenzeAttributes={visenzeAttributes}
								Attribute={Attribute}
								categoriesTree={categoriesTree}
							/>
						)}
					/>
					<Route
						path="/attributes/:id(\d+)/add"
						exact
						render={() => (
							<EditAttribute
								isAdding
								visenzeAttributes={visenzeAttributes}
								Attribute={Attribute}
								categoriesTree={categoriesTree}
							/>
						)}
					/>
					<Route
						path="/attributes/add"
						exact
						render={() => (
							<EditAttribute
								isAdding
								visenzeAttributes={visenzeAttributes}
								Attribute={Attribute}
								categoriesTree={categoriesTree}
							/>
						)}
					/>
				</Col>
			</Row>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default AttributesScreen
