import { ValidateErrorEntity } from '@typings'
import { message } from '@services'

export default function showFormErrors({ values, errorFields, outOfDate }: ValidateErrorEntity) {
	try {
		errorFields.forEach((errorField) => errorField.errors.forEach((error) => message.warning(error)))
	} catch (error) {
		console.error(error)
	}
}
