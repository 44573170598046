import React from 'react'
import { useCallback } from '@hooks'
import { Route, Redirect } from '@components'
import { RouteProps } from '@typings'

function PrivateRoute({ children, ...rest }: TProps) {
	const isLoggedIn = false
	const renderRoute = useCallback(
		({ location }) => (isLoggedIn ? children : <Redirect to={{ pathname: '/login', state: { from: location } }} />),
		[isLoggedIn, children],
	)

	return <Route {...rest} render={renderRoute} />
}

type TProps = RouteProps

export default PrivateRoute
