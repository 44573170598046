import React from 'react'
import { useHistory, useEffect, useCallback, useMemo } from '@hooks'
import { Breadcrumb } from '@components'
import { compact, startCase } from '@helpers'

import styles from './Breadcrumbs.module.css'

const Breadcrumbs: React.FC<TProps> = () => {
	const history = useHistory()

	const breadcrumbs = compact(history.location.pathname.split('/')).map((key) => startCase(key))

	return (
		<Breadcrumb style={{ margin: '16px 0' }}>
			{breadcrumbs.map((breadcrumb) => (
				<Breadcrumb.Item key={breadcrumb}>{breadcrumb}</Breadcrumb.Item>
			))}
		</Breadcrumb>
	)
}

type TProps = {
	/**  */
}

export default Breadcrumbs
