import { Col, Form, Radio, RangePickerField, Row, StatisticsBarChart } from '@components'
import { useCallback, useEffect, useState, useTranslation } from '@hooks'
import { api, apiRequest } from '@services'
import { TResponseWithFilters, TStatisticsChart, TStatisticsSearchParams, AxiosRequestConfig } from '@typings'
import React from 'react'

const LookbooksStatisticsChart: React.FC<TProps> = ({ searchParams, onError }) => {
	const { t } = useTranslation()

	const [data, setData] = useState([], '')
	const [fetching, setFetching] = useState(false, '')
	const fetch = useCallback(async (params) => {
		setFetching(true)
		try {
			const [{ data: privateLookbooks }, { data: publicLookbooks }] = await Promise.all([
				apiRequest<TResponseWithFilters<TStatisticsChart[]>>(
					api.getLookbooksIncrease({
						...params,
						isPrivate: true,
						order: ['startDate', 'desc'],
						limit: 12,
					}) as AxiosRequestConfig,
				),
				apiRequest<TResponseWithFilters<TStatisticsChart[]>>(
					api.getLookbooksIncrease({
						...params,
						isPrivate: false,
						order: ['startDate', 'desc'],
						limit: 12,
					}) as AxiosRequestConfig,
				),
			])
			const preparedData = publicLookbooks.map(({ count, ...dates }, index) => ({
				...dates,
				public: count,
				private: privateLookbooks[index]?.count || 0,
			}))
			setData(preparedData)
		} catch (error) {
			onError(error.message)
		} finally {
			setFetching(false)
		}
	}, [])
	useEffect(() => {
		fetch(searchParams)
	}, [searchParams])

	return (
		<>
			<Row gutter={16}>
				<Col>
					<Form.Item name="groupBy">
						<Radio.Group>
							<Radio.Button value="day">{t('per day')}</Radio.Button>
							<Radio.Button value="week">{t('per week')}</Radio.Button>
							<Radio.Button value="month">{t('per month')}</Radio.Button>
							<Radio.Button value="year">{t('per year')}</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="date">
						<RangePickerField />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				<StatisticsBarChart
					data={data}
					period={searchParams.groupBy}
					loading={fetching}
					bars={['public', 'private']}
				/>
			</Row>
		</>
	)
}

type TProps = {
	/**  */
	searchParams: TStatisticsSearchParams
	onError: (errorMessage: string) => void
}

export default LookbooksStatisticsChart
