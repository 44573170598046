import cx from 'classnames'
import React, { CSSProperties } from 'react'
import styles from './VisuallyHidden.module.css'

const VisuallyHidden: React.FC<TProps> = ({ children, condition = false, wrapperStyle }) => {
	return (
		<div style={wrapperStyle} className={cx({ [styles.visuallyHidden]: condition })}>
			{children}
		</div>
	)
}

type TProps = {
	/**  */
	condition?: boolean
	wrapperStyle?: CSSProperties
}

export default VisuallyHidden
