import { Else, Icons, If, Then, Upload } from '@components'
import { config } from '@constants'
import { compileImageUri, takeRight } from '@helpers'
import { useAuthContext, useState, useTranslation, useCallback, useEffect, useMemo } from '@hooks'
import { message, apiSettings } from '@services'
import { TAsset } from '@typings'
import { HttpRequestHeader, RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import React from 'react'

const showUploadList = {
	showRemoveIcon: true,
	showPreviewIcon: false,
	showDownloadIcon: false,
}

/** Image uploader  */
const UploadImage: React.FC<TProps> = ({ multiple, value, onChange }) => {
	const { t } = useTranslation()
	// const formInstanceRef = useRef<FormInstance>()
	const { authToken } = useAuthContext()
	const [fileList, setFileList] = useState<TFileList>([], '')

	const requestHeaders = useMemo(
		() => ({
			[String(config.API_AUTH_HEADER)]: authToken,
			'X-Requested-With': null,
		}),
		[authToken],
	)

	const callOnChange = useCallback(
		(event: TChangeFileEvent) => {
			const fileIds = event?.fileList?.map((file) => file?.response?.data) // event could be ProgressEvent due to we can pick only data from UploadChangeEvent.
			const value = multiple ? fileIds : fileIds[0]
			onChange(value)
		},
		[multiple],
	)

	const beforeUpload = useCallback((file: RcFile, FileList: RcFile[]) => {
		const isSupportedFormat = file.type.startsWith('image/')
		if (!isSupportedFormat) {
			message.error('Unsupported format of file!')
			return false
		}
		return true
	}, [])

	const handleChange = useCallback((event: TChangeFileEvent) => {
		const status = String(event?.file?.status)
		if (status === 'error') {
			message.error(event?.file?.error)
		} else {
			const newFileList = multiple ? event.fileList : takeRight(event.fileList)
			setFileList(newFileList)
		}
		callOnChange(event)
	}, [])

	useEffect(() => {
		if (!value) return
		const assets: TAsset[] = Array.isArray(value) ? value : [value]
		const initialFileList: TFileList = assets.map((asset) => ({
			uid: String(asset.id),
			size: 0,
			name: `image-${asset.id}`,
			type: 'image/jpg',
			url: compileImageUri(asset, false),
			status: 'done',
			thumbUrl: compileImageUri(asset, true),
			response: {
				data: asset,
			},
		}))
		setFileList(initialFileList)
	}, [])

	return (
		<>
			<Upload.Dragger
				name="image"
				listType="picture-card"
				fileList={fileList}
				multiple={multiple}
				showUploadList={showUploadList}
				action={`${apiSettings.getSettings('API_URL')}/assets/images`}
				headers={requestHeaders as HttpRequestHeader}
				onChange={handleChange}
				beforeUpload={beforeUpload}
			>
				<p className="ant-upload-drag-icon">
					<Icons.InboxOutlined />
				</p>
				<p className="ant-upload-text">{t('Click or drag file to this area to upload')}</p>
				<If condition={multiple}>
					<Then>
						<p className="ant-upload-hint">{t('Support for a single or bulk upload.')}</p>
					</Then>
					<Else>
						<p className="ant-upload-hint">{t('Support for a single upload.')}</p>
					</Else>
				</If>
			</Upload.Dragger>
		</>
	)
}

type TChangeFileEvent = UploadChangeParam<UploadFile<{ data: TAsset }>>
type TFileList = UploadFile<{ data: TAsset }>[]
type TProps = {
	/** Value of editor. */
	value?: TAsset | TAsset[] // string | undefined
	/**  Function called on each change of editor value. */
	onChange?: (value: TAsset | TAsset[]) => void | Promise<void>
	/** Allow multiple image uploading */
	multiple: boolean
}

export default UploadImage
