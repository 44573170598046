import { Avatar, Icons, Link } from '@components'
import { compileImageUri, first, formatToDay } from '@helpers'
import { TLook, TLookbook } from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		columnKey: 'id',
		render: (text: string, record: TLook) => (
			<Link to={`/lookbooks/${record.lookbookId}/looks/${record.id}`}>{text}</Link>
		),
	},
	{
		title: 'Image',
		dataIndex: 'assets',
		width: 60,
		render: (assets: TLook['assets']) => (
			<Avatar
				shape="square"
				size="large"
				icon={<Icons.UserOutlined />}
				src={compileImageUri(first(assets)?.asset, true)}
			/>
		),
	},
	{
		title: 'Description',
		dataIndex: 'description',
		width: 360,
		columnKey: 'description',
	},
	{
		title: 'Likes count',
		dataIndex: 'likeCount',
		width: 40,
		sorter,
	},
]
export const columnsComments = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		fixed: 'left',
		sorter,
	},
	{
		title: 'User',
		dataIndex: 'user',
		width: 100,
		render: (user: TLookbook['user']) => (
			<Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
		),
	},
	{
		title: 'Comment',
		dataIndex: 'text',
		width: 200,
		sorter,
		render: (comment) => <p>{comment.replace(/<usertag data-user-id="(.*?)">|<\/usertag>/gi, '')}</p>,
	},

	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
