import { TRequestBuilder, TChartType } from '@typings'
import { upDataTransformer } from '../transformers'

const cacheConfig = {
	maxAge: 5 * 60 * 1000, // 5 minutes,
	exclude: {
		query: false, // Allow request with query parameters.
		filter: () => false, // Allow request.
	},
}

export const getSizeCharts: TRequestBuilder<{ chartTypeId?: TChartType }> = ({ chartTypeId = undefined } = {}) => ({
	method: 'get',
	url: `/sizes/charts`,
	params: { chartTypeId },
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
export const getChartTypes: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/sizes/chart-types`,
	transformResponse: upDataTransformer,
	cache: cacheConfig,
})
