import { TLocation, TLocationItem, SelectProps } from '@typings'
import React from 'react'
import { Select } from '@components'
import { toString, compact } from '@helpers'

export const filterOption: SelectProps<TLocation>['filterOption'] = (inputValue = '', option) => {
	const query = option?.children || ''
	return !!~query.toUpperCase().indexOf(inputValue.toUpperCase())
}

export const castSelectCountryToLocation: SelectProps<TLocation>['onSelect'] = (_, option): TLocation => ({
	countryName: option.children || null,
	countryPlaceId: option.value || null,
	cityName: null,
	cityPlaceId: null,
})
export const castSelectCityToLocation: SelectProps<TLocation>['onSelect'] = (value, option): TLocation => ({
	countryName: value?.countryName || null,
	countryPlaceId: value?.countryPlaceId || null,
	cityName: option.children || null,
	cityPlaceId: option.value || null,
})

export const getCountrySuggestions = (countrySuggestions: TLocationItem[], value: TLocation | null | undefined) =>
	compact(countrySuggestions).length // Google can return [null] as a result I use compact() function.
		? countrySuggestions.map((country) => (
				<Select.Option key={country.placeId} value={country.placeId}>
					{country.title}
				</Select.Option>
		  ))
		: value?.countryName
		? [
				// default option for displaying title.
				<Select.Option key={String(value?.countryPlaceId)} value={String(value?.countryPlaceId)}>
					{toString(value?.countryName)}
				</Select.Option>,
		  ]
		: []

export const getCitySuggestions = (citySuggestions: TLocationItem[], value: TLocation | null | undefined) =>
	compact(citySuggestions).length // Google can return [null] as a result I use compact() function.
		? citySuggestions.map((city) => (
				<Select.Option key={city.placeId} value={city.placeId}>
					{city.title}
				</Select.Option>
		  ))
		: value?.cityName
		? [
				// default option for displaying title.
				<Select.Option key={String(value?.cityPlaceId)} value={String(value?.cityPlaceId)}>
					{toString(value?.cityName)}
				</Select.Option>,
		  ]
		: []
