import { TWardrobeItem, TFilteredRequest } from '@typings'

export const limitConfig = { lowerLimit: 20, upperLimit: 100, step: 20 }

export const prepareRequestParams: TPrepareRequestParams = ({ query, selectedWardrobeItems = [], limit }) => {
	const globalSearch = query
		? {
				id: `[substring]:${query}`,
				name: `[substring]:${query}`,
				'categories.category.title': `[substring]:${query}`,
				'brand.title': `[substring]:${query}`,
		  }
		: undefined
	const excludedItemIds = selectedWardrobeItems.map((wardrobeItem) => wardrobeItem.id)
	const id = excludedItemIds.length ? `[notIn]:[${excludedItemIds}]` : undefined

	return { limit, globalSearch, id, order: ['id', 'asc'] }
}

type TPrepareRequestParams = (data: {
	query: string | undefined
	selectedWardrobeItems: TWardrobeItem[]
	limit: number
}) => TFilteredRequest & Partial<TWardrobeItem>
