import * as React from 'react'
import { useTranslation, useRequest, useState, useHistory } from '@hooks'
import { ErrorBoundary, Form, Card, Row, Col, Button, Radio, Input } from '@components'
import { api, message } from '@services'
import styles from './CreatePromotion.module.css'
import { showFormErrors } from '@helpers'
import { formRules } from './CreatePromotion.formOptions'
import { TResponseWithFilters, TSendPushNotificationPayload, TSendMailBoxNotificationPayload } from '@typings'
import { FormInstance } from 'antd/lib/form/hooks/useForm'

const CreatePromotionScreen: React.FC<TProps> = () => {
	// Main screen data.
	const history = useHistory()
	const { t } = useTranslation()
	const [type, setType] = useState('push', '')

	const { fetch: sendPushNotification, fetching: fetchingPush } = useRequest<
		TResponseWithFilters<TSendPushNotificationPayload>,
		TSendPushNotificationPayload
	>({
		request: api.sendPushNotification,
		onSuccess: () => {
			formRef.current?.resetFields()
			message.success(t('Push Notification has been sent successfully.'))
		},
		onError: (error) => message.error(error),
	})

	const { fetch: sendMailBoxNotification, fetching: fetchingMailBox } = useRequest<
		TResponseWithFilters<TSendMailBoxNotificationPayload>,
		TSendMailBoxNotificationPayload
	>({
		request: api.sendMailBoxNotification,
		onSuccess: () => {
			formRef.current?.resetFields()
			message.success(t('Mail-box Notification has been sent successfully.'))
		},
		onError: (error) => message.error(error),
	})
	const sendAllNotifications = (form) => {
		sendMailBoxNotification({ name: form.title, body: `<color value="info">${form.title}<\/color>\n${form.body}` }),
			sendPushNotification(form)
	}

	const submitForm = (form) => {
		switch (type) {
			case 'push':
				return sendPushNotification(form)
			case 'mail-box':
				return sendMailBoxNotification({
					name: form.title,
					body: `<color value="info">${form.title}<\/color>\n${form.body}`,
				})
			case 'all':
				return sendAllNotifications(form)
		}
	}

	const chooseMethod = (e) => setType(e.target.value)
	const formRef = React.useRef<FormInstance>(null)

	return (
		<ErrorBoundary>
			<Form ref={formRef} onFinish={submitForm} onFinishFailed={showFormErrors}>
				<Card
					title={t('Promotion editor')}
					extra={
						<Row gutter={[16, 16]} align="middle">
							<Col flex={0}>
								<Button htmlType="button" onClick={history.goBack}>
									{t('Cancel')}
								</Button>
							</Col>
							<Col flex={0}>
								<Button
									type="primary"
									htmlType="submit"
									disabled={fetchingMailBox || fetchingPush}
									loading={fetchingMailBox || fetchingPush}
								>
									{t('Send')}
								</Button>
							</Col>
						</Row>
					}
				>
					<Row align="middle" justify="center">
						<Radio.Group onChange={chooseMethod} value={type}>
							<Radio value={'push'}>{t('Push Notification')}</Radio>
							<Radio value={'mail-box'}>{t('Mail-box Notification')}</Radio>
							<Radio value={'all'}>{t('All Notification')}</Radio>
						</Radio.Group>
					</Row>
					<div className={styles.cardContent}>
						<div className={styles.mobileContainer}>
							<Form.Item name="title" required noStyle rules={formRules.title} hasFeedback>
								<Input.TextArea
									autoSize
									className={styles.headerInputField}
									placeholder="Title"
									maxLength={255}
								/>
							</Form.Item>
							<Form.Item name="body" required noStyle rules={formRules.body} hasFeedback>
								<Input.TextArea
									className={styles.headerInputField}
									placeholder="Wright a message here..."
									maxLength={255}
									rows={4}
								/>
							</Form.Item>
						</div>
					</div>
				</Card>
			</Form>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default CreatePromotionScreen
