import { Avatar, Button, Comment, Divider, ErrorBoundary, Form, Input, List, Tooltip } from '@components'
import { compileImageUri, getUserFullName, showFormErrors } from '@helpers'
import { useCallback, useTranslation, useUserProfileContext } from '@hooks'
import { TComment, TCommentForm } from '@typings'
import moment from 'moment'
import React from 'react'
import { formFieldRules } from './CommentsBar.formOptions'

/** Chat allows view and add comments. Originally intended for displaying marketplace comments */
const CommentsBar: React.FC<TProps> = ({ comments = [], header, sending = false, disabled = false, onSend }) => {
	const { t } = useTranslation()
	const { profile } = useUserProfileContext()
	const initialValues: Partial<TCommentForm> = { userId: profile.id }

	return (
		<ErrorBoundary>
			<List
				dataSource={comments}
				header={header}
				itemLayout="horizontal"
				renderItem={useCallback(
					(comment) => (
						<Comment
							key={comment.id}
							author={getUserFullName(comment.user)}
							avatar={<Avatar src={compileImageUri(comment.user.avatar, true)} />}
							content={comment.text}
							datetime={
								<Tooltip title={moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
									<span>{moment(comment.createdAt).fromNow()}</span>
								</Tooltip>
							}
						/>
					),
					[],
				)}
			/>
			<Divider />
			<Form
				key={`add-mkt-comment-${comments.length}`} // reset form after sending (update number of comments)
				initialValues={initialValues}
				onFinish={onSend}
				onFinishFailed={showFormErrors}
			>
				<Form.Item name="text" rules={formFieldRules.text}>
					<Input.TextArea allowClear rows={3} disabled={sending || disabled} />
				</Form.Item>
				<Form.Item name="userId" noStyle>
					<span />
				</Form.Item>
				<Form.Item>
					<Button disabled={disabled} htmlType="submit" type="primary" loading={sending}>
						{t('Add Comment')}
					</Button>
				</Form.Item>
			</Form>
		</ErrorBoundary>
	)
}

type TProps = {
	/** Array of comments. */
	comments: TComment[]
	/** Processing form. */
	sending?: boolean
	/** Callback with new message, sent by user. */
	onSend: (comment: TComment) => void
	/** Header tet or component */
	header?: React.ReactNode
	/** Can deny send new messages. */
	disabled?: boolean
}

export default CommentsBar
