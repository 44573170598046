import React from 'react'
import { useRequest, useTranslation } from '@hooks'
import { Popconfirm, Button } from '@components'
import { api, message } from '@services'
import { ButtonType, TUser } from '@typings'

const VerifyShopLink: React.FC<TProps> = ({ shopId, buttonType = 'default', onSuccess }) => {
	const { t } = useTranslation()
	// Remove user.
	const { fetch: removeUser, fetching: removing } = useRequest<void, any>({
		request: () => api.sendShopCode(shopId),
		onSuccess: () => onSuccess?.(),
		onError: (error) => message.error(error.message),
	})
	return (
		<Popconfirm title={t('Are you sure?')} okText={t('Yes')} cancelText={t('No')} onConfirm={removeUser}>
			<Button loading={removing} type={buttonType}>
				{t('Send code')}
			</Button>
		</Popconfirm>
	)
}

type TProps = {
	/** Id of terget user.  */
	shopId: TUser['id']
	/** Callback called after successful removing. */
	onSuccess: () => void
	/** Type of button link */
	buttonType?: ButtonType
}

export default VerifyShopLink
