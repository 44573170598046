import { Avatar, Icons, Link, Tag, Badge } from '@components'
import { compileImageUri, formatPrice, formatToDay } from '@helpers'
import {
	TBrand,
	TCategory,
	TColor,
	TCondition,
	TDesigner,
	TItemAttribute,
	TMaterial,
	TMktStatus,
	TStyle,
	TWardrobeItem,
} from '@typings'
import React from 'react'

const statusColors: { [status in TMktStatus]: string } = {
	rejected: 'error',
	'on-review': 'processing',
	approved: 'success',
}

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TWardrobeItem) => (
			<Link to={`/marketplace-items-on-review/${record.id}`}>{text}</Link>
		),
		searchKey: 'id',
	},
	{
		title: 'Image',
		dataIndex: ['marketplaceInformation', 'assets'],
		width: 80,
		render: (assets: TWardrobeItem['marketplaceInformation']['assets']) => (
			<Avatar
				shape="square"
				size="large"
				icon={<Icons.UserOutlined />}
				src={compileImageUri(assets?.[0]?.asset, true)}
			/>
		),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		width: 180,
		searchKey: 'name',
	},
	{
		title: 'Unviewed',
		dataIndex: ['marketplaceInformation', 'unviewedByAdmin'],
		width: 120,
		align: 'center',
		sorter,
		render: (unviewedByAdmin) =>
			unviewedByAdmin > 0 ? <Badge count={unviewedByAdmin} style={{ backgroundColor: 'orange' }} /> : 0,
	},
	{
		title: 'Status',
		dataIndex: ['marketplaceInformation', 'status'],
		width: 120,
		render: (mktStatus: TMktStatus) => (
			<Tag key={mktStatus} color={statusColors[mktStatus]}>
				{mktStatus}
			</Tag>
		),
		filters: [
			{
				text: 'Rejected',
				value: 'rejected',
			},
			{
				text: 'On-review',
				value: 'on-review',
			},
			{
				text: 'Approved',
				value: 'approved',
			},
		] as { text: string; value: TMktStatus }[],
		defaultFilteredValue: ['on-review'],
	},
	{
		title: 'Condition',
		dataIndex: 'itemConditions',
		width: 200,
		render: (itemConditions: TCondition[]) =>
			itemConditions?.map((item) => (
				<Tag key={item.itemCondition.id} color="silver">
					{item.itemCondition.title}
				</Tag>
			)),
		searchKey: 'itemConditions.itemCondition.title',
	},
	{
		title: 'User ID',
		dataIndex: 'userId',
		width: 120,
		align: 'center',
		sorter,
		render: (text: string, record: TWardrobeItem) => <Link to={`/users/${record.userId}`}>{text}</Link>,
		searchKey: 'userId',
	},

	// -----

	{
		title: 'Categories',
		dataIndex: 'categories',
		width: 240,
		render: (categories: TCategory[]) =>
			categories.map((item) => (
				<Tag key={item.category.id} color="orange">
					{item.category.title}
				</Tag>
			)),
		searchKey: 'categories.category.title',
	},
	{
		title: 'Attributes',
		dataIndex: 'attributes',
		width: 240,
		render: (attributes: TItemAttribute[]) =>
			attributes?.map((item) => (
				<Tag key={item.attribute.id} color="purple">
					{item.attribute.title}
				</Tag>
			)),
		searchKey: 'attributes.attribute.title',
	},

	{
		title: 'Purchased price',
		dataIndex: 'pricePurchased',
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
	{
		title: 'Estimated price',
		dataIndex: 'priceEstimated',
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},

	{
		title: 'Styles',
		dataIndex: 'clothesStyles',
		width: 200,
		render: (clothesStyles: TStyle[]) =>
			clothesStyles?.map((item) => (
				<Tag key={item.clothesStyle.id} color="orange">
					{item.clothesStyle.title}
				</Tag>
			)),
		searchKey: 'clothesStyles.clothesStyle.title',
	},
	{
		title: 'Brands',
		dataIndex: 'brand',
		width: 180,
		render: (brand: TBrand) => brand?.title,
		searchKey: 'brand.title',
	},
	{
		title: 'Designers',
		dataIndex: 'designers',
		width: 200,
		render: (designers: TDesigner[]) =>
			designers?.map((item) => (
				<Tag key={item.id} color="gold">
					{item.title}
				</Tag>
			)),
		searchKey: 'designer.title',
	},
	{
		title: 'Material',
		dataIndex: 'materials',
		width: 200,
		render: (materials: TMaterial[]) =>
			materials?.map((item) => (
				<Tag key={item.id} color="lightblue">
					{item.title}
				</Tag>
			)),
		searchKey: 'material.title',
	},
	{
		title: 'Color',
		dataIndex: 'color',
		width: 120,
		render: (color: TColor) => color?.title,
		searchKey: 'color.title',
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
		defaultSortOrder: 'descend',
	},
]
