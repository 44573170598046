import { Avatar, BooleanIcon, Icons, Link } from '@components'
import { compileImageUri, formatToDay } from '@helpers'
import { TLookbook } from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
		render: (text: string, record: TLookbook) => <Link to={`/lookbooks/${record.id}`}>{text}</Link>,
	},
	{
		title: 'Image',
		dataIndex: 'asset',
		width: 60,
		render: (asset: TLookbook['asset']) => (
			<Avatar shape="square" size="large" icon={<Icons.UserOutlined />} src={compileImageUri(asset, true)} />
		),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 160,
		sorter,
		searchKey: 'title',
	},
	{
		title: 'User',
		dataIndex: 'user',
		width: 160,
		render: (user: TLookbook['user']) => (
			<Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
		),
	},
	{
		title: 'Is Private',
		dataIndex: 'isPrivate',
		width: 80,
		align: 'center',
		render: (isPrivate: TLookbook['isPrivate']) => <BooleanIcon value={isPrivate} />,
		filters: [
			{
				text: 'Private',
				value: true,
			},
			{
				text: 'Public',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 80,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 80,
		render: formatToDay,
		sorter,
	},
]
