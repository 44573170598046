import { AutoComplete, Spin } from '@components'
import { extractUserName, debounce } from '@helpers'
import { useMemo, useRequest, useState, useTranslation } from '@hooks'
import { api } from '@services'
import { TResponseWithFilters, TUser } from '@typings'
import React from 'react'

/** Autocomplete field with auto fetching users suggestions from API during typing. Field allow select only one existing user. */
const SearchableUserField: React.FC<TProps> = ({
	value,
	onChange,
	suggestionsCount: optionsLimit = 7,
	debounceDelay = 400,
}) => {
	const { t } = useTranslation()

	// Search users by query.
	const { data: { data: users = [] } = {}, fetch: searchUsers } = useRequest<TResponseWithFilters<TUser[]>, string>({
		request: (query) =>
			query
				? api.getUsers({
						globalSearch: {
							firstName: `[substring]:${query}`,
							lastName: `[substring]:${query}`,
							username: `[substring]:${query}`,
						},
						limit: optionsLimit,
				  })
				: null,
	})
	const options = useMemo(() => users.map((user) => ({ label: extractUserName(user), value: user.id })), [users])

	// User input state.
	const [inputValue, setInputValue] = useState('', '')

	// Preload selected user by value.
	const { data: selectedUser, fetching } = useRequest<TUser, number>(
		{
			request: () => (value ? api.getUser(value) : undefined),
			onSuccess: (user) => setInputValue(extractUserName(user)),
		},
		[value],
	)

	// Handle input query.
	const debounceSearchUsers = useMemo(() => debounce(searchUsers, debounceDelay), [])
	const handleSelect = (_: string, option) => onChange?.(option.value)
	const handleBlur = () => setInputValue(selectedUser ? extractUserName(selectedUser) : '')

	return (
		<Spin spinning={fetching}>
			<AutoComplete
				allowClear
				defaultActiveFirstOption={false}
				value={inputValue}
				onChange={setInputValue}
				placeholder={t('Input user name...')}
				options={options}
				onSelect={handleSelect}
				onBlur={handleBlur}
				onSearch={debounceSearchUsers}
			/>
		</Spin>
	)
}

type TProps = {
	/** Form field userId value */
	value?: number
	/** Callback with new userId, changed by user. */
	onChange?: (userId: number | undefined) => void
	/** Number of suggestions with found users. Default: 7 */
	suggestionsCount?: number
	/** Debounce delay affecting calling onSearch */
	debounceDelay?: number
}

export default SearchableUserField
