import { BooleanIcon, Link } from '@components'
import { formatToDay } from '@helpers'
import { TBulkUpload } from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 200,
		searchKey: 'title',
	},
	{
		title: 'Spreadsheet',
		dataIndex: 'link',
		width: 280,
		render: (link: string, record: TBulkUpload) => (
			<a href={record.link} target="_blank" rel="noopener noreferrer">
				{record.key}
			</a>
		),
	},

	{
		title: 'Uploaded?',
		dataIndex: 'isUploaded',
		width: 120,
		align: 'center',
		render: (isPromotional: boolean) => <BooleanIcon value={isPromotional} />,
		sorter,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'No',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'User ID',
		dataIndex: 'userId',
		align: 'center',
		width: 120,
		render: (link: string, record: TBulkUpload) => <Link to={`/users/${record.userId}`}>{record.userId}</Link>,
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
]
