import { Button, Card, ErrorBoundary, Spin, Icons, Tooltip, Row, Col, TreeView } from '@components'
import { last } from '@helpers'
import { useHistory, useMemo, useTranslation } from '@hooks'
import { TCategoriesTree, TreeProps, TUpdateCategoryItem, TCategoryItem, TUseEntityListItemAPI } from '@typings'
import * as React from 'react'
import { prepareCategoriesTree } from './CategoriesList.utils'

/** Tree with all categories. It supports drag&drop order to change nesting. */
const CategoriesListScreen: React.FC<TProps> = ({
	categoriesTree,
	loading,
	Category,
	selectedKeys,
	setSelectedKeys,
}) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Handle tree.
	const categoriesTreeData: TreeProps['treeData'] = useMemo(() => prepareCategoriesTree(categoriesTree), [
		categoriesTree,
	])

	// Actions.
	const goToAdding = () => {
		const key = last(selectedKeys)
		history.push(key ? `/categories-taxonomy/${key}/add` : `/categories-taxonomy/add`)
	}
	const handleDrop: TreeProps['onDrop'] = ({ node, dragNode, dropToGap }) => {
		const parentId = dropToGap ? node?.parentId || null : node?.id
		Category.update({ id: dragNode.id, parentId })
	}

	return (
		<ErrorBoundary>
			<Spin spinning={loading}>
				<Card
					title={t('Categories-Taxonomy')}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Tooltip
									placement="top"
									title={t('You have ability to change position of category using drag&drop')}
								>
									<Icons.QuestionCircleOutlined />
								</Tooltip>
							</Col>
							<Col>
								<Button onClick={goToAdding}>{t('Add')}</Button>
							</Col>
						</Row>
					}
				>
					<TreeView<TCategoriesTree>
						draggable
						tree={categoriesTreeData}
						selectedKeys={selectedKeys}
						onSelect={setSelectedKeys}
						onDrop={handleDrop}
						childrenKey="subcategories"
					/>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TProps = {
	/** Tree of all categories in database. */
	categoriesTree: TCategoriesTree
	/** Category which had been selected by user. */
	selectedKeys: string[]
	/** Callback for changing selected category. */
	setSelectedKeys: (selectedKeys: string[]) => void
	/** API for manipulating category. */
	Category: TUseEntityListItemAPI<TCategoryItem, TUpdateCategoryItem>
	/** Should display loading state. */
	loading: boolean
}

export default CategoriesListScreen
