import {
	TRequestBuilder,
	TWardrobeItem,
	TWardrobeItemForm,
	TWardrobeItemsReqPayload,
	TFilteredRequest,
	TAsset,
	TSendOnMarketplace,
	TConvertPriceRequestPayload,
} from '@typings'
import { upDataTransformer } from '../transformers'

// const formatWardrobeItemReqParams = ({
// 	id,
// 	categoryId,
// 	status,
// 	mktStatus,
// 	...filterOptions
// }: TFilteredRequest & TWardrobeItemsReqPayload) => ({
// 	id,
// 	status,
// 	'categories.categoryId': categoryId,
// 	'marketplaceInformation.status': mktStatus,
// 	offset: 0,
// 	...filterOptions,
// })

export const getWardrobeItem: TRequestBuilder<TWardrobeItem['id']> = (wardrobeItemId) => ({
	method: 'get',
	url: `/wardrobe-items/${wardrobeItemId}`,
	transformResponse: upDataTransformer,
})
export const getTopWardrobeItems: TRequestBuilder<(TFilteredRequest & Partial<TWardrobeItem>) | void> = (
	params = {},
) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/top-wardrobe-items`,
	params,
	transformResponse: upDataTransformer,
})
export const getWardrobeItems: TRequestBuilder<(TFilteredRequest & Partial<TWardrobeItem>) | void> = (params = {}) => ({
	method: 'get',
	url: `/wardrobe-items`,
	params,
})
// export const getMyWadrobeItems: TRequestBuilder<TFilteredRequest & TWardrobeItemsReqPayload> = (params = {}) => ({
// 	method: 'get',
// 	url: `/wardrobe-items/my`,
// 	params: formatWardrobeItemReqParams(params),
// })

// export const createWardrobeItem: TRequestBuilder<TWardrobeItemForm> = form => ({
// 	method: 'post',
// 	url: '/wardrobe-items/my',
// 	data: form,
// 	transformResponse: upDataTransformer,
// })
export const updateWardrobeItem: TRequestBuilder<TWardrobeItemForm> = ({ id, ...form }) => ({
	method: 'put',
	url: `/wardrobe-items/${id}`,
	data: form,
	transformResponse: upDataTransformer,
})
export const deleteWardrobeItem: TRequestBuilder<TWardrobeItem['id']> = (wardrobeItemId) => ({
	method: 'delete',
	url: `/wardrobe-items/${wardrobeItemId}`,
	transformResponse: upDataTransformer,
})
export const archiveWardrobeItem: TRequestBuilder<TWardrobeItem['id']> = (wardrobeItemId) => ({
	method: 'post',
	url: `/wardrobe-items/${wardrobeItemId}/archive`,
	transformResponse: upDataTransformer,
})
export const unarchiveWardrobeItem: TRequestBuilder<TWardrobeItem['id']> = (wardrobeItemId) => ({
	method: 'post',
	url: `/wardrobe-items/${wardrobeItemId}/unarchive`,
	transformResponse: upDataTransformer,
})

export const getUsersStatistic: TRequestBuilder<(TFilteredRequest & Partial<TWardrobeItem>) | void> = (
	params = {},
) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/all`,
	params,
	transformResponse: upDataTransformer,
})

export const getWardrobeItemsByCategoryStatistic: TRequestBuilder<
	(TFilteredRequest & Partial<TWardrobeItem>) | void
> = (params = {}) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/breakdown-total-wardrobe-items-by-categories`,
	params,
	transformResponse: upDataTransformer,
})

export const getTopUsersStatistic: TRequestBuilder<
	| (TFilteredRequest &
			Partial<TWardrobeItem & { basedOn: 'followers' | 'wardrobe_item_count' | 'wardrobe_item_price_purchase' }>)
	| void
> = (params = {}) => ({
	method: 'get',
	url: `/users/statistics/top-users`,
	params,
	transformResponse: upDataTransformer,
})
// export const recognizeWardrobeItem: TRequestBuilder<{ assetId: TImage['id'] }> = data => ({
// 	method: 'post',
// 	url: '/services/visenze/recognize-wardrobe-item',
// 	data,
// 	transformResponse: upDataTransformer,
// })
// export const createShopifyProduct = (id: TWardrobeItem['id']) => ({
// 	method: 'post',
// 	url: `/wardrobe-items/my/${id}/sale`,
// 	transformResponse: upDataTransformer,
// })
// export const sendOnMarketplaceReview = (id: TWardrobeItem['id'], data: TSendOnMarketplace) => ({
// 	method: 'post',
// 	url: `/wardrobe-items/my/${id}/send-on-marketplace-review`,
// 	data,
// 	transformResponse: upDataTransformer,
// })
// export const cancelMarketplaceRequest = (id: TWardrobeItem['id']) => ({
// 	method: 'post',
// 	url: `/wardrobe-items/my/${id}/cancel-marketplace`,
// 	transformResponse: upDataTransformer,
// })
// export const getWishlistItems: TRequestBuilder<TFilteredRequest> = params => ({
// 	method: 'get',
// 	url: `/wardrobe-items/wishlists/my`,
// 	params: formatWardrobeItemReqParams(params),
// })
// export const setItemWishlistStatus = ({
// 	id,
// 	inWishlists,
// }: {
// 	id: TWardrobeItem['id']
// 	inWishlists: TWardrobeItem['inWishlists']
// }) =>
// 	Boolean(inWishlists)
// 		? {
// 				method: 'post',
// 				url: `/wardrobe-items/${id}/wishlists/my`,
// 		  }
// 		: {
// 				method: 'delete',
// 				url: `/wardrobe-items/${id}/wishlists/my`,
// 		  }

// export const convertPriceToGBP: TRequestBuilder<TConvertPriceRequestPayload> = pricePayload => ({
// 	method: 'post',
// 	url: '/lists/currencies/convert-to-gbp',
// 	data: pricePayload,
// 	transformResponse: upDataTransformer,
// })
