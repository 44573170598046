import { TArticle, TArticleForm, TArticleEdit, TSurvey, TLookbook } from '@typings'
import { draftEditorStateToHtml, HtmlToDraftEditorState, formatToDay } from '@helpers'
import { i18n } from '@services'
import { Link } from 'react-router-dom'
import React from 'react'

export const formRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
	body: [{ required: true, message: i18n.t('Body is required') }],
}
