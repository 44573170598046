import React from 'react'
import { useEffect, useCallback, useMemo, useTranslation } from '@hooks'
import { Title, Text, Card, Row, Col, Form, Radio, RangePickerField, Space, Badge } from '@components'
import { Cell, Pie, PieChart } from 'recharts'
import styles from './SurveyRow.module.css'
import { TSurvey } from 'src/typings/surveys'
import { compileImageUri, formatPrice, toNumber } from '@helpers'

const COLORS = ['#00BFFF', '#FFB4CE', '#00FA9A', '#FF8042']

const SurveyRow: React.FC<TProps> = ({ survey }) => {
	const { t } = useTranslation()
	const RADIAN = Math.PI / 180
	const data = survey.surveyAnswerOptions.map((answer) => ({
		title: answer.answerOption,
		value: (toNumber(answer?.votesCount) * 100) / survey.allVotesCount || 0,
	}))
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5
		const x = cx + radius * Math.cos(-midAngle * RADIAN)
		const y = cy + radius * Math.sin(-midAngle * RADIAN)

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		)
	}
	return (
		<Card key={survey.id}>
			<Title level={4}>{survey.question}</Title>
			<Row gutter={16} align="middle" justify="space-between">
				<Col>
					{survey.allVotesCount ? (
						<PieChart width={420} height={252}>
							<Pie
								data={data}
								cx={100}
								cy={104}
								labelLine={false}
								label={renderCustomizedLabel}
								outerRadius={90}
								fill="#8884d8"
								dataKey="value"
							>
								{data.map((entry, index) => (
									<Cell key={`cell-${index}-${entry.value}`} fill={COLORS[index % COLORS.length]} />
								))}
							</Pie>
						</PieChart>
					) : (
						<p>{t('No one has voted yet')}</p>
					)}
				</Col>
				<Col span={8}>
					<p>
						{' '}
						{t('Total votes count:')} {survey.allVotesCount}
					</p>
					<Space size="large" direction="vertical">
						{data.map((entry, index) => (
							<div>
								<Badge
									color={COLORS[index % COLORS.length]}
									text={`${entry.title} - ${Math.round(entry.value)}%`}
								/>
							</div>
						))}
					</Space>
				</Col>
				<Col>
					{survey?.assets?.map((assetItem) => (
						<img className={styles.image} alt="logo" src={compileImageUri(assetItem.asset, true)} />
					))}
				</Col>
			</Row>
		</Card>
	)
}

type TProps = {
	survey: TSurvey
}

export default SurveyRow
