import React from 'react'
import { Icons } from '@components'
import { UploadFile, UploadChangeParam, TAsset } from '@typings'

export const renderUploadChildren = ({ fileList, multiple }: { fileList: TFileList; multiple: boolean }) =>
	!!fileList.length && !multiple ? null : (
		<div>
			<Icons.PlusSquareOutlined />
			<div className="ant-upload-text">Upload</div>
		</div>
	)

export type TChangeFileEvent = UploadChangeParam<UploadFile<{ data: TAsset }>>
export type TFileList = UploadFile<{ data: TAsset }>[]

export const showUploadList = {
	showRemoveIcon: true,
	showPreviewIcon: true,
	showDownloadIcon: false,
}
