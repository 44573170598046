import { TRequestBuilder, TSharingRequestPayload, TWardrobeItem, TSharingRequest } from '@typings'
import { upDataTransformer } from '../transformers'

// // Borrowing. Getters

// export const getMyBorrowings: TRequestBuilder<TSharingRequestPayload> = params => ({
// 	url: `/sharings/borrowings/my`,
// 	params,
// })
// export const getMyBorrowing: TRequestBuilder<TSharingRequest['id']> = requestId => ({
// 	url: `/sharings/borrowings/my/${requestId}`,
// 	transformResponse: upDataTransformer,
// })
// export const getMyBorrowingsStatistics: TRequestBuilder<void> = () => ({
// 	url: `/sharings/borrowings/my/statistic`,
// 	transformResponse: upDataTransformer,
// })

// // Borrowing. Actions

// export const createBorrowing: TRequestBuilder<TWardrobeItem['id']> = wardrobeItemId => ({
// 	url: `/sharings/borrowings/my`,
// 	method: 'post',
// 	data: { wardrobeItemId },
// 	transformResponse: upDataTransformer,
// })
// export const cancelBorrowing: TRequestBuilder<TSharingRequest['id']> = requestId => ({
// 	url: `/sharings/borrowings/my/${requestId}/cancel-sharing-request`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const confirmBorrowingReturn: TRequestBuilder<TWardrobeItem['id']> = sharingRequestID => ({
// 	url: `/sharings/borrowings/my/${sharingRequestID}/confirm-item-has-been-returned`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const wishToReturnBorrowing: TRequestBuilder<TSharingRequest['id']> = sharingRequestID => ({
// 	url: `/sharings/borrowings/my/${sharingRequestID}/wish-to-return`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const markBorrowingAsViewed: TRequestBuilder<TSharingRequest['id']> = sharingRequestID => ({
// 	url: `/sharings/borrowings/my/${sharingRequestID}/mark-as-viewed`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })

// // Lending. Getters

// export const getMyLendings: TRequestBuilder<TSharingRequestPayload> = params => ({
// 	url: `/sharings/lendings/my`,
// 	params,
// })
// export const getMyLending: TRequestBuilder<TSharingRequest['id']> = requestId => ({
// 	url: `/sharings/lendings/my/${requestId}`,
// 	transformResponse: upDataTransformer,
// })
// export const getMyLendingsStatistics: TRequestBuilder<void> = () => ({
// 	url: `/sharings/lendings/my/statistic`,
// 	transformResponse: upDataTransformer,
// })

// // Lending. Actions

// export const approveLendingRequest: TRequestBuilder<TSharingRequest['id']> = sharingRequestId => ({
// 	url: `/sharings/lendings/my/${sharingRequestId}/approve-sharing-request`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const rejectLendingRequest: TRequestBuilder<TSharingRequest['id']> = sharingRequestId => ({
// 	url: `/sharings/lendings/my/${sharingRequestId}/reject-sharing-request`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const askForLoanBack: TRequestBuilder<TSharingRequest['id']> = sharingRequestId => ({
// 	url: `/sharings/lendings/my/${sharingRequestId}/ask-for-loan-back`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const cancelAskForLoanBack: TRequestBuilder<TSharingRequest['id']> = sharingRequestId => ({
// 	url: `/sharings/lendings/my/${sharingRequestId}/cancel-ask-for-loan-back`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
// export const markLendingAsViewed: TRequestBuilder<TSharingRequest['id']> = sharingRequestId => ({
// 	url: `/sharings/lendings/my/${sharingRequestId}/mark-as-viewed`,
// 	method: 'post',
// 	transformResponse: upDataTransformer,
// })
