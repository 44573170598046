import { DatePicker } from '@components'
import { useCallback, useMemo } from '@hooks'
import { DatePickerProps, UTCDate } from '@typings'
import * as moment from 'moment'
import React from 'react'

const DatePickerField: React.FC<TProps> = ({
	value,
	onChange,
	format = 'YYYY-MM-DD', // YYYY-MM-DDTHH:mm:ss.SSS[Z]
	...datePickerProps
}) => {
	const preparedValue = useMemo(() => (value ? moment.default(String(value), format) : undefined), [value])
	const handleChange = useCallback((value: moment.Moment | null, dateString: string) => onChange?.(dateString), [
		onChange,
	])

	return <DatePicker value={preparedValue} onChange={handleChange} format={format} {...datePickerProps} />
}

type TProps = DatePickerProps & {
	/** Form field value. */
	value?: UTCDate
	/** Function for changing form field value. */
	onChange?: (value: UTCDate) => void
}

export default DatePickerField
