export const roles = [
	{
		title: 'unlocked (active user)',
		id: 'user',
	},
	{
		title: 'super-admin',
		id: 'super-admin',
	},
	{
		title: 'admin',
		id: 'admin',
	},
]
export const statuses = [
	{
		title: 'active',
		id: 'active',
	},
	{
		title: 'inactive',
		id: 'disabled',
	},
]
