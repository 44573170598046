export type TTabs = 'info' | 'wardrobe_items' | 'lookbooks' | 'orders'
export const tabList: { key: TTabs; tab: string }[] = [
	{
		key: 'info',
		tab: 'Information',
	},
	{
		key: 'wardrobe_items',
		tab: 'Wardrobe items',
	},
	{
		key: 'lookbooks',
		tab: 'Lookbooks',
	},
	{
		key: 'orders',
		tab: 'Orders',
	},
]
