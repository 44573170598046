import { TRequestBuilder, TCategorizedFiltersTree } from '@typings'
import { upDataTransformer } from '../transformers'

export const getCategorizedFilters: TRequestBuilder<Partial<TCategorizedFiltersTree> | void> = (
	params = { parentId: 'null' },
) => ({
	method: 'get',
	url: `/categorized-filters`,
	params,
})
export const getCategorizedFiltersTree: TRequestBuilder<Partial<TCategorizedFiltersTree> | void> = (
	params = { parentId: 'null' },
) => ({
	method: 'get',
	url: `/categorized-filters/tree`,
	params,
	transformResponse: upDataTransformer,
})
export const getCategorizedFilterRules: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/categorized-filters/filters`,
})
// export const getMyCategorizedFilters: TRequestBuilder<void> = () => ({
// 	method: 'get',
// 	url: `/categorized-filters/me`,
// })
// export const getMyCategorizedFiltersTree: TRequestBuilder<void> = () => ({
// 	method: 'get',
// 	url: `/categorized-filters/me/tree`,
// })
