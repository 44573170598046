import * as React from 'react'
import { useRequest, useRef, useCsvDownloader, useTableLocationParams, useTranslation } from '@hooks'
import {
	ErrorBoundary,
	DataTable,
	VerifyUserLink,
	RemoveCodeLink,
	ExportButton,
	Button,
	Icons,
	Card,
	Space,
	Row,
	Col,
} from '@components'
import { api, message } from '@services'
import { TResponseWithFilters, TFilteredRequest } from 'src/typings/api'
import { TProfile } from 'src/typings/profile'
import { columns } from './AwaitingVerification.tableOptions'

type TLongTermCode = {
	code: string
	id: number
}

const AwaitingVerificationScreen: React.FC<TProps> = () => {
	// Main screen data.
	const tableRef = useRef<any>(null)
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useTableLocationParams()
	const [longTermCode, setLongTermCode] = React.useState<null | TLongTermCode>(null)

	// Users data.
	const { data: { data: users, meta } = {}, fetch, fetching } = useRequest<TResponseWithFilters<TProfile[]>, void>(
		{
			request: () => api.getUsersCodes(searchParams),
			onError: (error) => message.error(error),
		},
		[searchParams],
	)
	const { fetch: requestLongTermCode } = useRequest<any, any>({
		request: api.requestLongTermCode,
		onSuccess: (data) => setLongTermCode({ code: data?.code, id: data?.id }),
		onError: (error) => message.error(error),
	})
	const { fetch: deleteLongTermCode } = useRequest<TResponseWithFilters<TProfile[]>, number>({
		request: (id: number) => api.deleteLongTermCode(id),
		onSuccess: () => setLongTermCode(null),
		onError: (error) => message.error(error),
	})
	useRequest<any, any>(
		{
			request: () => api.getUsersCodes({ status: 'long_term' }),
			onSuccess: (data) => setLongTermCode({ code: data?.data[0]?.code, id: data?.data[0]?.id }),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const handleDeleteLongTermCode = React.useCallback(() => longTermCode && deleteLongTermCode(longTermCode.id), [
		longTermCode,
	])

	return (
		<ErrorBoundary>
			<Space size="large" direction="vertical">
				<Card title="Temporary access code" bordered={false} style={{ width: 300 }}>
					<Row align="middle" justify="center">
						<p>{longTermCode?.code || `- - - -`}</p>
					</Row>
					<Row align="middle" justify="space-between">
						<Button
							type="primary"
							shape="round"
							icon={<Icons.PlusOutlined />}
							size={'middle'}
							onClick={requestLongTermCode}
						>
							{t('Generate')}
						</Button>
						<Button
							type="dashed"
							danger
							shape="round"
							icon={<Icons.CloseOutlined />}
							size="middle"
							onClick={handleDeleteLongTermCode}
						>
							{t('Remove')}
						</Button>
					</Row>
				</Card>

				<DataTable<TProfile, Partial<TProfile>>
					ref={tableRef}
					loading={fetching}
					columns={columns}
					dataSource={users}
					meta={meta}
					searchInputValues={searchParams}
					autoFetchOnMount={false}
					onChange={setSearchParams}
					HeaderComponent={<ExportButton fileName="awaiting_verification_users.csv" />}
					renderActionsComponent={(profile: TProfile) => (
						<>
							<VerifyUserLink
								buttonType="link"
								userId={profile.id}
								onSuccess={tableRef.current.refresh}
							/>
							<RemoveCodeLink buttonType="link" userId={profile.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Space>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default AwaitingVerificationScreen
