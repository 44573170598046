import { Button, DataTable, ErrorBoundary, Icons, RemoveUserLink, Space } from '@components'
import {
	useHistory,
	useRef,
	useRequest,
	useTranslation,
	useCallback,
	useTableLocationParams,
	useCsvDownloader,
} from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TProfile, TResponseWithFilters } from '@typings'
import * as React from 'react'
import { columns } from './Users.tableOptions'

const UsersScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = useRef()
	const downloadCsv = useCsvDownloader()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Users data.
	const { data: { data: users, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TProfile[]>,
		TFilteredRequest & Partial<TProfile>
	>(
		{
			request: () => api.getUsers(searchParams),
			onError: (error) => message.error(error),
		},
		[searchParams],
	)

	// Actions.
	const goToAddUser = useCallback(() => history.push('/users/add'), [])

	const exportData = async () => {
		downloadCsv(users, 'users.csv')
		// try {
		// 	setExporting(true)
		// 	const {
		// 		data: { data },
		// 	} = await apiClient(api.getWardrobeItems({ ...searchParams, limit: meta?.totalCounts, offset:0 }))
		// 	setExporting(false)
		// 	downloadCsv(data,'wardrobeItems.csv')
		// } catch (error) {
		// 	console.error(error)
		// }
	}

	return (
		<ErrorBoundary>
			<DataTable<TProfile, Partial<TProfile>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={users}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Space>
						<Button type="primary" icon={<Icons.DownloadOutlined />} size="middle" onClick={exportData}>
							{t('Export')}
						</Button>
						<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToAddUser}>
							{t('Add user')}
						</Button>
					</Space>
				}
				renderActionsComponent={(profile: TProfile) => (
					<>
						<RemoveUserLink buttonType="link" userId={profile.id} onSuccess={tableRef.current.refresh} />
					</>
				)}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default UsersScreen
