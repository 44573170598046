import { i18n } from '@services'
import { TUpdateCategoryItem, TCategoryItem, TVisenzeCategory } from '@typings'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 12 },
}
export const formButtonLayout = { offset: 6, span: 12 }
export const formFieldRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
}

export const DEFAULT_INITIAL_VALUES: Partial<TUpdateCategoryItem> = { status: 'active', isShowingInWardrobe: false }

export const normalize = (value: any) => value ?? null

/** Function helps extract assetId from TImage object. */
export function prepareForm(form: Partial<TUpdateCategoryItem>): TUpdateCategoryItem {
	const { asset, ...otherValues } = form
	return { ...otherValues, assetId: asset?.id || null }
}

export function parseDefaultVisenzeCategoryIds(
	categoryId: TCategoryItem['id'] | undefined,
	visenzeCategories: TVisenzeCategory[],
) {
	return categoryId && !!visenzeCategories?.length
		? visenzeCategories
				.filter((visenzeCategory) =>
					visenzeCategory.categories.some((category) => category.categoryId === categoryId),
				)
				.map((visenzeCategory) => visenzeCategory.id)
		: []
}
