import {
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Input,
	Popconfirm,
	Row,
	SelectField,
	TreeSelectField,
	When,
	StatusSwitcher,
	Spin,
	Space,
} from '@components'
import { showFormErrors, toNumber } from '@helpers'
import { useMemo, useHistory, useTranslation, useUserProfileContext, useParams } from '@hooks'
import {
	TItemAttributeItem,
	TUseEntityListItemAPI,
	TVisenzeAttribute,
	TCategoriesTree,
	TUpdateAttributeForm,
} from '@typings'
import * as React from 'react'
import {
	DEFAULT_INITIAL_VALUES,
	formButtonLayout,
	formFieldRules,
	formLayout,
	normalize,
	parseDefaultVisenzeAttributeIds,
	parseDefaultCategoryIds,
} from './EditAttribute.formOptions'

const EditAttributeScreen: React.FC<TProps> = ({ isAdding, visenzeAttributes, Attribute, categoriesTree }) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const user = useUserProfileContext()
	const params = useParams<TParams>()
	const id = params?.id ? toNumber(params?.id) : null

	// Initial form values.
	const defaultVisenzeAttributeIds = useMemo(
		() => parseDefaultVisenzeAttributeIds(Attribute.data?.id, visenzeAttributes),
		[Attribute.data?.id, visenzeAttributes],
	)
	const defaultCategoryIds = useMemo(() => parseDefaultCategoryIds(Attribute.data), [Attribute.data])
	const initialValues = isAdding
		? DEFAULT_INITIAL_VALUES
		: { ...Attribute.data, visenzeAttributeIds: defaultVisenzeAttributeIds, categoryIds: defaultCategoryIds }

	// Actions.
	const handleSubmit = (form: TUpdateAttributeForm) =>
		isAdding ? Attribute.create({ parentId: id, ...form }) : Attribute.update({ id: Attribute.data?.id, ...form })

	return (
		<ErrorBoundary>
			<Spin spinning={Attribute.creating || Attribute.updating}>
				<Card
					loading={Attribute.fetching}
					title={
						isAdding
							? t('Add new attribute to {{attributeName}}', {
									attributeName: id ? Attribute.data?.title : 'root',
							  })
							: Attribute.data?.title
					}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Popconfirm
									title={t('Are you sure?')}
									onConfirm={Attribute.remove}
									okText={t('Yes')}
									cancelText={t('No')}
								>
									<Button
										disabled={user.profile.role !== 'super-admin'}
										loading={Attribute.removing}
										danger
									>
										{t('Remove')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					}
				>
					<Form
						key={`attribute-form-${Attribute.data?.id}`} // Need for reinitialize form on change category.
						{...formLayout}
						onFinish={handleSubmit}
						initialValues={initialValues}
						onFinishFailed={showFormErrors}
					>
						<Form.Item required name="title" label="Title" rules={formFieldRules.title}>
							<Input />
						</Form.Item>
						<Form.Item name="visenzeAttributeIds" label={t('Visenze attributes')}>
							<SelectField
								showSearch
								selectOptions={visenzeAttributes}
								filterOption
								optionFilterProp="title"
								allowClear
								placeholder={t('Choose visenze attributes...')}
								mode="multiple"
							/>
						</Form.Item>

						<When condition={!id || Attribute.data?.parentId === null}>
							<Form.Item
								normalize={normalize}
								label="Categories"
								name="categoryIds"
								rules={formFieldRules.categoryIds}
								required
							>
								<TreeSelectField<'subcategories'>
									multiple
									placeholder={t('Choose category')}
									childrenKey="subcategories"
									tree={categoriesTree}
								/>
							</Form.Item>
						</When>
						<When condition={!isAdding}>
							<Form.Item name="status" label={t('Status')}>
								<StatusSwitcher />
							</Form.Item>
						</When>
						<Form.Item wrapperCol={formButtonLayout}>
							<Space>
								<Button
									type="primary"
									htmlType="submit"
									loading={Attribute.updating || Attribute.creating}
								>
									{t('Save changes')}
								</Button>
								<Button htmlType="button" onClick={history.goBack}>
									{t('Cancel')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TParams = { id?: string }
type TProps = {
	/** Form mode. Should form work in adding new subattribute mode. Otherwise form will edit current attribute. */
	isAdding: boolean
	/** API for manipulating category. */
	Attribute: TUseEntityListItemAPI<TItemAttributeItem, {}>
	/** List of visenze categories for select component */
	visenzeAttributes: TVisenzeAttribute[]
	/** Tree of categories for select field. */
	categoriesTree: TCategoriesTree
}

export default EditAttributeScreen
