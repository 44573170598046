import { TAsset, TImageQuality } from '@typings'
import { apiSettings } from '@services'

const compileImageUri = (asset: TAsset | undefined | null, isThumbnail: boolean): string | undefined => {
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	if (!asset) return undefined
	const quality: TImageQuality = isThumbnail ? 'thumbnail' : 'medium'
	if (!asset.imageFormat) {
		return `${apiSettings.getSettings('IMAGE_BASE_URL')}/${asset.imageKey}/${quality}`
	}

	const imageUrl = `${apiSettings.getSettings('IMAGE_BASE_URL')}/${asset.imageKey}/${quality}.${asset?.imageFormat}`
	return imageUrl
}

export default compileImageUri
