import { Col, ErrorBoundary, Route, Row } from '@components'
import { last, toNumber } from '@helpers'
import { useEffect, useEntityListItemAPI, useHistory, useRequest, useState, useTranslation } from '@hooks'
import {
	CategorizedFilterItem,
	CategorizedFiltersList,
	EditCategorizedFilter,
	EditCategorizedFilterRule,
} from '@screens'
import { api, message } from '@services'
import { TCategorizedFilter, TCategorizedFiltersTree, TUpdateCategorizedFilter } from '@typings'
import * as React from 'react'

/** Flow for viewing and editing categorized filters. Categorized filters are showing on app's user wardrobe section as navigation menu. */
const CategorizedFiltersScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Manage categories data.
	const { data: categorizedFiltersTree = [], fetch: fetchCategorizedFiltersTree, fetching } = useRequest<
		TCategorizedFiltersTree,
		void
	>(
		{
			request: () => api.getCategorizedFiltersTree(),
			onError: (error) => message.error(error.message),
		},
		[],
	)

	// Handle select tree node.
	const [selectedKeys, setSelectedKeys] = useState<string[]>([], 'categorized-filters:selectedKeys')
	const selectedCategoryId = selectedKeys?.length ? toNumber(last(selectedKeys)) : undefined
	useEffect(() => {
		const targetPath = selectedCategoryId ? `/categorized-filters/${selectedCategoryId}` : `/categorized-filters`
		history.push(targetPath) // push new route on select category by user.
	}, [selectedCategoryId])

	// Category API.
	const CategorizedFilter = useEntityListItemAPI<TCategorizedFilter, TUpdateCategorizedFilter>(
		'/categorized-filters',
		selectedCategoryId,
		{
			onAction: () => fetchCategorizedFiltersTree(),
			onCreate: () => history.goBack(),
			onRemove: (category) => setSelectedKeys(category.parentId ? [String(category.parentId)] : []),
		},
	)

	return (
		<ErrorBoundary>
			<Row gutter={[16, 16]}>
				<Col span={8}>
					<CategorizedFiltersList
						categorizedFiltersTree={categorizedFiltersTree}
						CategorizedFilter={CategorizedFilter}
						loading={fetching || CategorizedFilter.updating}
						selectedKeys={selectedKeys}
						setSelectedKeys={setSelectedKeys}
					/>
				</Col>
				<Col span={16}>
					<Route
						path="/categorized-filters/:id(\d+)"
						exact
						render={() => <CategorizedFilterItem CategorizedFilter={CategorizedFilter} />}
					/>
					<Route
						path="/categorized-filters/:id(\d+)/edit"
						exact
						render={() => <EditCategorizedFilter isAdding={false} CategorizedFilter={CategorizedFilter} />}
					/>
					<Route
						path="/categorized-filters/:id(\d+)/add"
						exact
						render={() => (
							<EditCategorizedFilter
								isAdding
								CategorizedFilter={CategorizedFilter}
								categorizedFiltersTree={categorizedFiltersTree}
							/>
						)}
					/>
					<Route
						path="/categorized-filters/add"
						exact
						render={() => (
							<EditCategorizedFilter
								isAdding
								CategorizedFilter={CategorizedFilter}
								categorizedFiltersTree={categorizedFiltersTree}
							/>
						)}
					/>
					<Route
						path="/categorized-filters/:id(\d+)/rules/add"
						exact
						render={() => <EditCategorizedFilterRule CategorizedFilter={CategorizedFilter} />}
					/>
					<Route
						path="/categorized-filters/:id(\d+)/rules/:ruleId(\d+)"
						exact
						render={() => <EditCategorizedFilterRule CategorizedFilter={CategorizedFilter} />}
					/>
				</Col>
			</Row>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default CategorizedFiltersScreen
