import { List, PostRow } from '@components'
import { useCallback, useEffect } from '@hooks'
import { PaginationProps, TFilteredRequest, TMetaResponse, TPost } from '@typings'
import React from 'react'

/** List of news fedd posts. */
const PostList: React.FC<TProps> = ({ posts = [], meta, limit = 10, payload, onFetch, loading }) => {
	const onChange = (page: number) =>
		onFetch({
			...payload,
			limit,
			offset: (page - 1) * limit,
		})

	// Pagination.
	const pagination: PaginationProps = {
		total: meta?.totalCounts,
		current: Math.ceil(meta?.offset / limit) + 1 || 1,
		hideOnSinglePage: true,
		showSizeChanger: true,
		pageSize: limit,
		defaultCurrent: 1,
		defaultPageSize: meta?.limit,
		onChange,
	}

	useEffect(() => {
		onChange(1)
	}, [payload])

	return (
		<List
			loading={loading}
			itemLayout="vertical"
			size="large"
			pagination={pagination}
			dataSource={posts}
			renderItem={useCallback(
				(post: TPost) => (
					<PostRow post={post} />
				),
				[],
			)}
		/>
	)
}

type TPayload = { [key: string]: any }
type TProps = {
	/** Posts array */
	posts: TPost[] | undefined
	/** Response meta data needed for pagination. */
	meta: TMetaResponse | undefined
	/** Callback that cause fetching posts with initial or new payload. */
	onFetch: (payload: TFilteredRequest & TPayload) => void | Promise<void>
	/** Max number posts per page. */
	limit: number
	/** Is loading state indicator needed. */
	loading: boolean
	/** Additional object with any data, passed to onFetch function. Changing payload cause onFetch, keep payload immutable! */
	payload: TPayload
}

export default PostList
