import React from 'react'
import { useEffect, useCallback, useMemo } from '@hooks'
import { Title, Text, Row, Col, Card } from '@components'
import styles from './CardsList.module.css'
import { compileImageUri } from '@helpers'
import { TAsset } from '@typings'

const CardsList: React.FC<TProps> = ({ items = [], onPress }) => {
	return (
		<Row gutter={[16, 16]}>
			{items.map(({ id, title, description, asset }) => (
				<Col key={id}>
					<Card
						hoverable
						className={styles.card}
						cover={<img className={styles.image} alt="" src={compileImageUri(asset, true)} />}
						onClick={() => onPress(id)}
					>
						<Card.Meta
							title={title}
							description={<div className={styles.description}>{description}</div>}
						/>
					</Card>
				</Col>
			))}
		</Row>
	)
}

type TProps = {
	/**  */
	items: {
		id: number
		title: string
		description?: string | null
		asset?: TAsset | null
	}[]
	onPress: (id: number) => void
}

export default CardsList
