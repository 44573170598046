import React from 'react'
import { useTranslation, useCsvDownloader } from '@hooks'
import { Button, Icons } from '@components'
import { apiClient, api } from '@services'

const ExportButton: React.FC<TProps> = ({ fileName }) => {
	const { t } = useTranslation()
	const downloadCsv = useCsvDownloader()

	const exportData = async () => {
		try {
			const {
				data: { data },
			} = await apiClient(api.getUsersCodes({}))
			downloadCsv(
				data.map((item) => ({ 'Email Address': item.email, ...item })),
				fileName,
			)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Button type="primary" icon={<Icons.DownloadOutlined />} size="middle" onClick={exportData}>
			{t('Export')}
		</Button>
	)
}

type TProps = {
	fileName: string
}

export default ExportButton
