import { useMemo } from '@hooks'
import { TStatisticsChart, TStatisticsPeriod, UTCDate } from '@typings'
import moment from 'moment'
import React from 'react'
import { CartesianGrid, Legend, Tooltip, XAxis, YAxis, BarChart, Bar } from 'recharts'
import { Spin } from '@components'

const colors = ['#13c2c2', '#fa8c16', '#52c41a', '#722ed1']

const StatisticsBarChart: React.FC<TProps> = ({ data = [], period, loading = false, bars = [], barLabels }) => {
	const formatDate = (date: UTCDate) => {
		switch (period) {
			case 'year':
				return moment(date, 'YYYY-MM-DD').year()
			case 'month':
				return moment(date, 'YYYY-MM-DD').format('MMM YYYY')
			case 'week':
				return moment(date, 'YYYY-MM-DD').format('wo YYYY')
			case 'day':
				return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD')
			default:
				return date
		}
	}
	const chart = useMemo(() => data.map((item) => ({ ...item, startDate: formatDate(item.startDate) })), [data])

	return (
		<Spin spinning={loading}>
			<BarChart width={900} height={450} data={chart}>
				<CartesianGrid strokeDasharray="1 1" />
				<XAxis dataKey="startDate" />
				<YAxis />
				<Tooltip />
				<Legend />
				{bars.map((bar, index) => (
					<Bar key={bar} name={(barLabels || bars)?.[index]} dataKey={bar} stackId="a" fill={colors[index]} />
				))}
			</BarChart>
		</Spin>
	)
}

type TProps = {
	/**  */
	data: TStatisticsChart[]
	/** Displays spinner when true */
	loading?: boolean
	period: TStatisticsPeriod
	bars: string[]
	/** Optional. Can rename bars to another name different from dataKey. */
	barLabels?: string[]
}

export default StatisticsBarChart
