import { BulkUploadProcessModal, Button, DataTable, ErrorBoundary, Icons, RemoveRowLink } from '@components'
import { useCallback, useHistory, useRef, useRequest, useTranslation } from '@hooks'
import { api, message } from '@services'
import {
	TBulkUpload,
	TBulkUploadValidation,
	TFilteredRequest,
	TPost,
	TResponseWithFilters,
	TWardrobeItem,
} from '@typings'
import * as React from 'react'
import { columns } from './BulkUpload.tableOptions'

/** Display all bulk upload documents and run importing wardrobe items from Google Spreadsheet. */
const BulkUploadScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = useRef<any>()
	const history = useHistory()

	// API data.
	const { data: { data: posts, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TPost[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>({
		request: (params) => api.getBulkUploads({ ...params, order: ['id', 'desc'] }),
		onError: (error) => message.error(error.message),
	})

	// Actions.
	const goToAddBulkUpload = useCallback(() => history.push('/bulk-upload/add'), [])

	// Bulk upload process.
	const processModal = useRef<any>(null)
	const { fetch: runBulkUpload, data: bulkUploadValidation, fetching: bulkUploading } = useRequest<
		TBulkUploadValidation,
		TBulkUpload
	>({
		request: (bulkUpload) => api.runBulkUpload(bulkUpload),
		onRequest: () => processModal.current?.open(),
		onSuccess: (validationResult) => {
			if (validationResult.isValid) tableRef.current?.refresh()
		},
		onError: (error) => message.error(error.message),
	})

	return (
		<ErrorBoundary>
			<DataTable<TPost, Partial<TPost>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={posts}
				meta={meta}
				onChange={fetch}
				HeaderComponent={
					<Button
						type="primary"
						icon={<Icons.PlusCircleOutlined />}
						size="middle"
						onClick={goToAddBulkUpload}
					>
						{t('Add')}
					</Button>
				}
				renderActionsComponentWidth={180}
				renderActionsComponent={(item: TBulkUpload) => [
					<Button key="1" type="link" disabled={item.isUploaded} onClick={() => runBulkUpload(item)}>
						{t('Upload')}
					</Button>,
					<RemoveRowLink
						key="2"
						id={item.id}
						request={api.removeBulkUpload}
						onSuccess={tableRef.current?.refresh}
					/>,
				]}
			/>
			<BulkUploadProcessModal
				ref={processModal}
				bulkUploadValidation={bulkUploadValidation}
				loading={bulkUploading}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default BulkUploadScreen
