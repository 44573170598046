import { i18n } from '@services'

const getFormatConfig = (currency: 'GBP', isFloat: boolean) => ({
	currency,
	style: 'currency',
	minimumFractionDigits: isFloat ? 2 : 0,
	maximumFractionDigits: isFloat ? 2 : 0,
})

export default function formatPrice(price?: number | null, isFloat = false): string {
	const formatConfig: Intl.NumberFormatOptions = getFormatConfig('GBP', isFloat)
	const parsedPrice = parseFloat(String(price))
	const formattedPrice = new Intl.NumberFormat(i18n.language, formatConfig).format(parsedPrice || 0)
	return formattedPrice
}
