import { Col, ErrorBoundary, Route, Row } from '@components'
import { useEffect, useEntityListItemAPI, useHistory, useRequest, useState, useTranslation } from '@hooks'
import { CategoriesList, EditCategory, CategoriesInWardrobe } from '@screens'
import { api, message } from '@services'
import { TCategoryItem, TUpdateCategoryItem, TVisenzeCategory, TSizeChartType, TCategoriesTree } from '@typings'
import { last, toNumber } from '@helpers'
import * as React from 'react'

/** Parent route for CategoriesListScreen and EditCategoryScreen which help managing routes and data manipulations. */
const CategoriesTaxonomyScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Manage categories data.
	const { data: categoriesTree = [], fetch: fetchCategoriesTree, fetching } = useRequest<TCategoriesTree, void>(
		{
			request: () => api.getCategoriesTree({ status: undefined }),
			onError: (error) => message.error(error.message),
		},
		[],
	)

	// Handle select tree node.
	const [selectedKeys, setSelectedKeys] = useState<string[]>([], 'CategoriesScreen:selectedKeys')
	const selectedCategoryId = selectedKeys?.length ? toNumber(last(selectedKeys)) : undefined
	useEffect(() => {
		const targetPath = selectedCategoryId ? `/categories-taxonomy/${selectedCategoryId}` : `/categories-taxonomy`
		history.push(targetPath) // push new route on select category by user.
	}, [selectedCategoryId])

	// Select lists for form.
	const { data: visenzeCategories, fetch: fetchVisenzeCategories } = useRequest<TVisenzeCategory[]>(
		{ request: api.getVisenzeCategories },
		[],
	)
	const { data: chartTypes } = useRequest<TSizeChartType[]>({ request: api.getChartTypes }, [])

	// Category API.
	const Category = useEntityListItemAPI<TCategoryItem, TUpdateCategoryItem>('/categories', selectedCategoryId, {
		onAction: () => {
			fetchCategoriesTree()
			fetchVisenzeCategories() // because list of visenze items have bundle with categories.
		},
		onCreate: () => history.goBack(),
		onRemove: (category) => setSelectedKeys(category.parentId ? [String(category.parentId)] : []),
	})

	return (
		<ErrorBoundary>
			<Row gutter={[16, 16]}>
				<Col span={8}>
					<CategoriesList
						categoriesTree={categoriesTree}
						Category={Category}
						loading={fetching || Category.updating}
						selectedKeys={selectedKeys}
						setSelectedKeys={setSelectedKeys}
					/>

					<CategoriesInWardrobe categoriesTree={categoriesTree} />
				</Col>
				<Col span={16}>
					<Route
						path="/categories-taxonomy/:id(\d+)"
						exact
						render={() => (
							<EditCategory
								isAdding={false}
								visenzeCategories={visenzeCategories}
								chartTypes={chartTypes}
								Category={Category}
							/>
						)}
					/>
					<Route
						path="/categories-taxonomy/:id(\d+)/add"
						exact
						render={() => (
							<EditCategory
								isAdding
								visenzeCategories={visenzeCategories}
								chartTypes={chartTypes}
								Category={Category}
							/>
						)}
					/>
					<Route
						path="/categories-taxonomy/add"
						exact
						render={() => (
							<EditCategory
								isAdding
								visenzeCategories={visenzeCategories}
								chartTypes={chartTypes}
								Category={Category}
								categoriesTree={categoriesTree}
							/>
						)}
					/>
				</Col>
			</Row>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default CategoriesTaxonomyScreen
