import React from 'react'
import { Avatar, Icons, Link } from '@components'
import { ColumnProps, TArticle, TArticleStatus, TAsset } from '@typings'
import { formatToDay, compileImageUri } from '@helpers'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TArticle) => <Link to={`/articles/${record.id}`}>{text}</Link>,
		searchKey: 'id',
		defaultSortOrder: 'descend',
	},
	{
		title: 'Image',
		width: 80,
		dataIndex: 'asset',
		render: (asset: TAsset) => (
			<Avatar size="large" shape="square" icon={<Icons.UserOutlined />} src={compileImageUri(asset, true)} />
		),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 200,
		sorter,
		searchKey: 'title',
	},
	{
		title: 'Author',
		dataIndex: 'author',
		width: 200,
		sorter,
		searchKey: 'author',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		sorter,
		width: 80,
		filters: [
			{
				text: 'Draft',
				value: 'draft',
			},
			{
				text: 'Public',
				value: 'published',
			},
			{
				text: 'Hidden',
				value: 'hidden',
			},
		] as { text: string; value: TArticleStatus }[],
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		sorter,
		width: 80,
		render: formatToDay,
	},
]
