import { TRequestBuilder, TFilteredRequest, TUserTransaction, TShopifyOrder } from '@typings'
import { upDataTransformer } from '../transformers'

export const getUserTransactions: TRequestBuilder<(TFilteredRequest & Partial<TUserTransaction>) | void> = (
	params = {},
) => ({
	method: 'get',
	url: `/transactions/user`,
	params,
})

export const getOrders: TRequestBuilder<(TFilteredRequest & Partial<TShopifyOrder>) | void> = (params = {}) => ({
	method: 'get',
	url: `/orders`,
	params,
})
