import { last, mapArrayEntityItems, toNumber } from '@helpers'
import { TWardrobeItem, TWardrobeItemForm } from '@typings'
import { i18n } from '@services'

export function castWardrobeItemToForm(wardrobeItem: TWardrobeItem): Partial<TWardrobeItemForm> {
	return !wardrobeItem
		? wardrobeItem
		: {
				// Text.
				name: wardrobeItem.name ?? null,
				description: wardrobeItem.description ?? null,
				serialNumber: wardrobeItem?.serialNumber ?? null,
				orderNumber: wardrobeItem?.orderNumber ?? null,
				personalIdReference: wardrobeItem?.personalIdReference ?? null,
				// Date.
				year: wardrobeItem?.year ?? null,
				purchasedDate: wardrobeItem?.purchasedDate,
				// Price.
				pricePurchased: wardrobeItem?.pricePurchased ?? null,
				priceEstimated: wardrobeItem?.priceEstimated ?? null,
				priceActual: wardrobeItem?.priceActual ?? null,
				// Images.
				assets: mapArrayEntityItems(wardrobeItem?.assets, 'asset') ?? null,
				// Id.
				brandId: wardrobeItem.brandId ?? null,
				categoryId: last(mapArrayEntityItems(wardrobeItem?.categories, 'category.id')) ?? null,
				designerId: wardrobeItem?.designer?.id ?? null,
				seasonId: wardrobeItem?.season?.id ?? null,
				storeId: wardrobeItem?.store?.id ?? null,
				colorId: wardrobeItem?.color?.id ?? null,
				materialId: wardrobeItem?.material?.id ?? null,
				countryId: wardrobeItem?.country?.id ?? null,
				careTypeId: wardrobeItem?.careType?.id ?? null,
				sizeId: wardrobeItem?.sizeId,
				// Id arrays.
				attributeIds:
					mapArrayEntityItems(
						wardrobeItem?.attributes?.filter((item) => !!item.attribute.parentId), // cut parent nodes
						'attribute.id',
					) ?? null,
				clothesStyleIds: mapArrayEntityItems(wardrobeItem?.clothesStyles, 'clothesStyle.id') ?? null,
				occasionIds: mapArrayEntityItems(wardrobeItem?.occasions, 'occasion.id') ?? null,
				itemConditionIds: mapArrayEntityItems(wardrobeItem?.itemConditions, 'itemCondition.id') ?? null,
				// Flags.
				dustbag: wardrobeItem?.dustbag ?? null,
				receiptIncluded: wardrobeItem?.receiptIncluded ?? null,
				authenticityCard: wardrobeItem?.authenticityCard ?? null,
				// Location
				location: wardrobeItem?.location,
		  }
}

export const normalize = (value: any) => value ?? null
export const yearNormalize = (value: any) => toNumber(value) || null

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { offset: 6, span: 12 }
export const formFieldRules = {
	name: [{ required: true, message: i18n.t('Value required.') }],
	assets: [{ required: true, message: i18n.t('Value required.') }],
	categoryId: [{ required: true, message: i18n.t('Value required.') }],
	brandId: [{ required: true, message: i18n.t('Value required.') }],
}
