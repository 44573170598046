import { Button, Card, ErrorBoundary, Form, Input, Popconfirm, When, StatusSwitcher, DataTable } from '@components'
import { showFormErrors } from '@helpers'
import { useEntityListItemAPI, useHistory, useParams, useTranslation, useRequest } from '@hooks'
import { TBrand, TWardrobeItem, TResponseWithFilters, TFilteredRequest } from '@typings'
import { formFieldRules, formLayout, formButtonLayout, initialValues } from './EditBrand.formOptions'
import * as React from 'react'
import { columns } from './EditBrand.tableOptions'
import { api, message } from '@services'

const EditBrandScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const id = Number(params?.id)

	const Brand = useEntityListItemAPI<TBrand>('/lists/brands', id, {
		onCreate: history.goBack,
		onUpdate: history.goBack,
		onRemove: history.goBack,
	})

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>({
		request: (payload) => (id ? api.getWardrobeItems({ ...payload, 'brand.id': id }) : null),
		onError: (error) => message.error(error.message),
	})

	return (
		<ErrorBoundary>
			<Card
				loading={(id && Brand.fetching) || Brand.creating || Brand.updating}
				title={t('Material')}
				extra={
					<Popconfirm title="Are you sure?" onConfirm={Brand.remove} okText={t('Yes')} cancelText={t('No')}>
						<Button disabled={!Brand?.data?.id} loading={Brand.removing} type="danger">
							{t('Remove brand')}
						</Button>
					</Popconfirm>
				}
			>
				<When condition={Brand.fetched || !id}>
					<Form
						onFinish={id ? Brand.update : Brand.create}
						onFinishFailed={showFormErrors}
						initialValues={Brand.data || initialValues}
						{...formLayout}
					>
						<Form.Item label={t('Title')} name="title" rules={formFieldRules.title}>
							<Input required disabled={Brand.updating} />
						</Form.Item>
						<Form.Item name="status" label={t('Status')}>
							<StatusSwitcher />
						</Form.Item>
						<Form.Item wrapperCol={formButtonLayout}>
							<Button type="primary" htmlType="submit" loading={Brand.creating || Brand.updating}>
								{Brand?.data?.id ? t('Save') : t('Create')}
							</Button>
							<Button htmlType="button" onClick={history.goBack}>
								{t('Cancel')}
							</Button>
						</Form.Item>
					</Form>
				</When>
			</Card>
			<div style={{ height: '16px' }} />
			<When condition={!!id}>
				<Card title={t('Relative wardrobe items')}>
					<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
						loading={fetching}
						columns={columns}
						dataSource={wardrobeItems}
						meta={meta}
						onChange={fetch}
					/>
				</Card>
			</When>
		</ErrorBoundary>
	)
}

type TParams = { id?: string }
type TProps = {
	/** */
}

export default EditBrandScreen
