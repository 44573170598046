import {
	TCreateLookBookPayload,
	TCreateLookPayload,
	TUpdateLookPayload,
	TFilteredRequest,
	TRequestBuilder,
	TLookbook,
	TLook,
} from '@typings'
import { upDataTransformer } from '../transformers'

export const getLookbooks: TRequestBuilder<TFilteredRequest & Partial<TLookbook>> = (params) => ({
	method: 'get',
	url: `/lookbooks`,
	params,
})
export const getLookbook: TRequestBuilder<TLookbook['id']> = (lookbookId) => ({
	method: 'get',
	url: `/lookbooks/${lookbookId}`,
	transformResponse: upDataTransformer,
})
export const removeLookbook: TRequestBuilder<TLookbook['id']> = (lookbookId) => ({
	method: 'delete',
	url: `/lookbooks/${lookbookId}`,
	transformResponse: upDataTransformer,
})

export const getLooks: TRequestBuilder<TFilteredRequest & Partial<TLook> & { lookbookId: TLookbook['id'] }> = ({
	lookbookId,
	...params
}) => ({
	method: 'get',
	url: `/lookbooks/${lookbookId}/looks`,
	params,
})

export const makeGlobalLookbook: TRequestBuilder<TLookbook['id']> = (lookbookId) => ({
	method: 'post',
	url: `/lookbooks/${lookbookId}/copy-to-global`,
	data: { additionalIdentifier: 'lbd-global-account' },
	transformResponse: upDataTransformer,
})

// export const getUserLookbooks = () => ({
// 	method: 'get',
// 	url: `/lookbooks/my`,
// 	transformResponse: upDataTransformer,
// })

// export const getSuggestedLookbooks = (params?: TFilteredRequest) => ({
// 	method: 'get',
// 	url: `/lookbooks/lbd-suggested`,
// 	params,
// })
// export const getAvailableForMeLookbooks = (params?: TFilteredRequest) => ({
// 	method: 'get',
// 	url: '/lookbooks/available-for-me',
// 	params,
// })

// export const getAvailableForMeLookBook = (id: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/available-for-me/${id}`,
// })
// export const getSuggestedLookbook = (id: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/lbd-suggested/${id}`,
// 	transformResponse: upDataTransformer,
// })
// export const getUserLookbook = (id: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/my/${id}`,
// 	transformResponse: upDataTransformer,
// })

// export const createNewLookBook = (payload: TCreateLookBookPayload) => ({
// 	method: 'post',
// 	url: '/lookbooks/my',
// 	transformResponse: upDataTransformer,
// 	data: payload,
// })
// export const createNewLook = (id: number, payload: TCreateLookPayload) => ({
// 	method: 'post',
// 	url: `/lookbooks/${id}/looks/my`,
// 	transformResponse: upDataTransformer,
// 	data: payload,
// })

// export const getUserLooks = (lookbookId: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/${lookbookId}/looks/my`,
// 	transformResponse: upDataTransformer,
// })

// export const getForeignPublicLookBooks = (params?: any) => ({
// 	method: 'get',
// 	url: `/lookbooks/foreign-public`,
// 	params,
// })

// export const getForeingPublickLookBook = (id: number, params?: any) => ({
// 	method: 'get',
// 	url: `/lookbooks/foreign-public/${id}`,
// 	params,
// })

// export const getSuggestedLook = (lookbookId: number, lookId: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/${lookbookId}/looks/lbd-suggested/${lookId}`,
// 	transformResponse: upDataTransformer,
// })

// export const getForeingPublickLook = (lookbookId: number, lookId: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/${lookbookId}/looks/foreign-public/${lookId}`,
// 	transformResponse: upDataTransformer,
// })

// export const getUserLook = (lookbookId: number, id: number) => ({
// 	method: 'get',
// 	url: `/lookbooks/${lookbookId}/looks/my/${id}`,
// 	transformResponse: upDataTransformer,
// })
// export const updateUserLookBook = (lookbookId: number, wardrobeItemIds: Partial<TUpdateLookPayload>) => ({
// 	method: 'put',
// 	url: `/lookbooks/my/${lookbookId}`,
// 	data: wardrobeItemIds,
// 	transformResponse: upDataTransformer,
// })
// export const updateUserLook = (lookbookId: number, id: number, wardrobeItemIds: Partial<TUpdateLookPayload>) => ({
// 	method: 'put',
// 	url: `/lookbooks/${lookbookId}/looks/my/${id}`,
// 	data: wardrobeItemIds,
// 	transformResponse: upDataTransformer,
// })
// export const onLikeLook = (lookbookId: number, lookId: number) => ({
// 	method: 'post',
// 	url: `/lookbooks/${lookbookId}/looks/${lookId}/like`,
// 	transformResponse: upDataTransformer,
// })

// export const onUnLikeLook = (lookbookId: number, lookId: number) => ({
// 	method: 'post',
// 	url: `/lookbooks/${lookbookId}/looks/${lookId}/unlike`,
// 	transformResponse: upDataTransformer,
// })

// export const deleteUserLookbook = (lookbookId: number) => ({
// 	method: 'delete',
// 	url: `/lookbooks/my/${lookbookId}`,
// 	transformResponse: upDataTransformer,
// })

// export const deleteUserLook = (lookbookId: number, lookId: number) => ({
// 	method: 'delete',
// 	url: `/lookbooks/${lookbookId}/looks/my/${lookId}`,
// 	transformResponse: upDataTransformer,
// })
