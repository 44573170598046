import constate from 'constate'
import { default as useAuth } from './useAuth'
import { default as useUserProfile } from './useUserProfile'
import { default as useTablesSettings } from './useTablesSettings'

// https://reactjs.org/docs/hooks-reference.html
export {
	useEffect,
	useContext,
	useCallback,
	useMemo,
	useRef,
	useImperativeHandle,
	useLayoutEffect,
	useDebugValue,
} from 'react'
export { useState, useReducer } from 'reinspect'

// https://github.com/DmitriyNikolenko/react-axios-helpers#usage
/*
const { data, error, fetching, fetched, fetch, cancel, canceled } = useRequest({
    request: delay => ({
      method: 'put',
      url: `https://www.mocky.io/v2/5185415ba171ea3a00704eed?mocky-delay=${delay}ms`
    }),
    onRequest: (params) => null,
    onSuccess: (data, params) => null,
	onError: (error, params) => null,
	cancelOnUnmount: false, // default true
  }, [delay])
*/
export { useRequest } from 'react-axios-helpers'

// https://github.com/xnimorz/use-debounce#usedebounce-react-hook
export {
	useDebounce, // const [value] = useDebounce(text, 1000);
	useDebouncedCallback, // useDebouncedCallback(value => null, 1000);
} from 'use-debounce'

// https://react.i18next.com/latest/usetranslation-hook
export { useTranslation } from 'react-i18next' // const { t, i18n } = useTranslation();

// https://github.com/streamich/react-use
export { default as useLocalStorage } from 'react-use/lib/useLocalStorage' // useLocalStorage(key, initialValue); // React side-effect hook that manages a single localStorage key.
export { useMediatedState } from 'react-use' // const [state, setState] = useMediatedState(s => s.toLowerCase()), ''); // A lot like the standard useState, but with mediation process.
// export { default as useMount } from 'react-use/lib/useMount'; // useMount(() => null)
// export { default as useUnmount } from 'react-use/lib/useUnmount'; // useUnmount(() => null)
// export { default as useUpdateEffect } from 'react-use/lib/useUpdateEffect' // useUpdateEffect(() => null)
// export { default as usePromise } from 'react-use/lib/usePromise'; // usePromise(() => null) // - resolves promise only while component is mounted.
// export { default as useSetState } from 'react-use/lib/useSetState'; // const [state, setState] = useSetState({}) — creates setState method which works like this.setState
// export { default as usePrevious } from 'react-use/lib/usePrevious' // const prevCount = usePrevious(count) — returns the previous state or props.
// export { default as useDefault } from 'react-use/lib/useDefault'; // const [value, setValue] = useDefault(defaultValue, initialValue); — returns the default value when state is null or undefined.
// export { default as useInterval } from 'react-use/lib/useInterval'; // useInterval(() => null, delay) - allow you using declarative setInterval
// export { default as useTimeoutFn } from 'use-timeout'; // useTimeoutFn(() => null, delay) - Automatically cancels timeout on component unmount. Automatically resets timeout on delay change.

// https://github.com/kalcifer/react-powerhooks#usemap
// export {
// 	useMap, // const { set, get, has, delete, clear, reset, values } = useMap({ name: "PK", age: "30" });
// } from 'react-powerhooks';

//
export {
	useBoolean, // const { value, setValue, toggle, setTrue, setFalse } = useBoolean()
	useMap, // const { value, set } = useMap([["key", "value"]]);
	useSetState, // const { state, setState, resetState } = useSetState({ loading: false });
	useArray, // const { value, push, unshift, pop, shift, clear, removeIndex, removeById, move } = useArray([])
	useNumber, // const { value, increase, decrease } = useNumber(3, { upperLimit: 5, lowerLimit: 3, loop: true, step: 10 })
} from 'react-hanger'

export { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
export const [UseAuthContextProvider, useAuthContext] = constate(useAuth)
export const [UseUserProfileContextProvider, useUserProfileContext] = constate(useUserProfile)
export const [UseTablesSettingsContextProvider, useTablesSettingsContext] = constate(useTablesSettings)
export { default as useArticleAPI } from './useArticleAPI'
export { default as useSurveyAPI } from './useSurveyAPI'
export { default as useEntityListItemAPI } from './useEntityListItemAPI'
export { default as useLBDOfficialUserId } from './useLBDOfficialUserId'
export { default as useMarketplaceItemAPI } from './useMarketplaceItemAPI'
export { default as useCsvDownloader } from './useCsvDownloader'
export { default as useTableLocationParams } from './useTableLocationParams'
