import { Button, Icons, Popconfirm } from '@components'
import React from 'react'
import styles from './WithRemoveButton.module.css'
import { useTranslation } from 'react-i18next'

/* FIX Can't edit after removing container (https://github.com/globocom/megadraft/issues/243#issuecomment-525193990 )*/
const stopPropogation = (event: React.FocusEvent<HTMLDivElement>) => event.stopPropagation()

const WithRemoveButton: React.FC<TProps> = ({ children, onRemove }) => {
	const { t } = useTranslation()

	return (
		<div className={styles.absoluteContainer} onFocus={stopPropogation} onBlur={stopPropogation}>
			<div className={styles.removeButtonContainer}>
				<Popconfirm title={t('Are you sure?')} onConfirm={onRemove} okText={t('Yes')} cancelText={t('No')}>
					<Button shape="circle" icon={<Icons.DeleteOutlined />} />
				</Popconfirm>
			</div>
			{children}
		</div>
	)
}

type TProps = {
	/** Function called after click on remove button  */
	onRemove: () => void
}

export default WithRemoveButton
