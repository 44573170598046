import React from 'react'
import { useRequest, useTranslation } from '@hooks'
import { Popconfirm, Button } from '@components'

import { message } from 'antd'
import { TUser } from 'src/typings/profile'
import { ButtonType } from 'antd/lib/button'
import { api } from '@services'

const RemoveShopCodeLink: React.FC<TProps> = ({ shopId, buttonType = 'default', onSuccess }) => {
	const { t } = useTranslation()
	// Remove user.
	const { fetch: removeUser, fetching: removing } = useRequest<void, any>({
		request: () => api.deleteShopCode(shopId),
		onSuccess: () => onSuccess?.(),
		onError: (error) => message.error(error.message),
	})

	return (
		<Popconfirm title={t('Are you sure?')} okText={t('Yes')} cancelText={t('No')} onConfirm={removeUser}>
			<Button loading={removing} danger type={buttonType}>
				{t('Remove')}
			</Button>
		</Popconfirm>
	)
}

type TProps = {
	/** Id of terget user.  */
	shopId: TUser['id']
	/** Callback called after successful removing. */
	onSuccess: (props: void) => void
	/** Type of button link */
	buttonType?: ButtonType
}

export default RemoveShopCodeLink
