import { useLocation, useMemo, useHistory, useTablesSettingsContext, useLayoutEffect } from '@hooks'
import { queryString, mapValues, isPlainObject } from '@helpers'
import { useCallback } from 'react'

const EMPTY_OBJ = {}
const SOURCE: 'state' | 'search' = 'state' // Initially we planned to use "search" but later decided to use "state" and fill free to change source of data.

/** Parse table parameters from location and take ability to change it. */
const useTableLocationParams = () => {
	const history = useHistory()
	const location = useLocation()
	const searchParams = useMemo(() => queryString.parse(location[SOURCE], { arrayFormat: 'bracket' }) || EMPTY_OBJ, [
		location[SOURCE],
	])
	const [tablesSettings] = useTablesSettingsContext()

	// Redirect if limit param is absent.
	useLayoutEffect(() => {
		if (!searchParams.limit) {
			history.push({
				...location,
				[SOURCE]: queryString.stringify(Object.assign(searchParams, { limit: tablesSettings.pageSize })),
			})
		}
	}, [searchParams])

	const setSearchParams = useCallback((props) => {
		// Convert nested objects to string due to query-string limitations.
		const preparedProps = mapValues(props, (value) => (isPlainObject(value) ? JSON.stringify(value) : value))
		const state = queryString.stringify(preparedProps, { arrayFormat: 'bracket' })

		history.push({ ...location, [SOURCE]: state })
	}, [])

	return [searchParams, setSearchParams]
}

export default useTableLocationParams
