import { Card, Col, Row, Statistic, StatisticsCardSlice, CountUp } from '@components'
import { useTranslation } from '@hooks'
import { TStatisticsSet } from '@typings'
import styles from './StatisticsCard.module.css'
import React from 'react'

const StatisticsCard: React.FC<TProps> = ({
	totalValue,
	valueOfLastDay,
	valueOfLastWeek,
	valueOfLastMonth,
	valueOfLastYear,
	format = (value) => value,
	title,
	loading,
	onClick,
}) => {
	const { t } = useTranslation()
	return (
		<Card hoverable onClick={onClick} loading={loading} className={styles.container}>
			<CountUp start={0} end={totalValue} duration={2} isCounting={!!totalValue}>
				{({ value }) => <Statistic title={title} value={format(value)} />}
			</CountUp>
			<Row>
				<Col span={8}>
					<CountUp start={0} end={valueOfLastDay} duration={1} isCounting={!!valueOfLastDay}>
						{({ value }) => (
							<StatisticsCardSlice
								title={`${t('Current day')}:`}
								value={value}
								displayValue={format(value)}
							/>
						)}
					</CountUp>
				</Col>
				<Col span={8}>
					<CountUp start={0} end={valueOfLastWeek} duration={1} isCounting={!!valueOfLastWeek}>
						{({ value }) => (
							<StatisticsCardSlice
								title={`${t('Current week')}:`}
								value={value}
								displayValue={format(value)}
							/>
						)}
					</CountUp>
				</Col>
				<Col span={8}>
					<CountUp start={0} end={valueOfLastMonth} duration={1} isCounting={!!valueOfLastMonth}>
						{({ value }) => (
							<StatisticsCardSlice
								title={`${t('Current month')}:`}
								value={value}
								displayValue={format(value)}
							/>
						)}
					</CountUp>
				</Col>
			</Row>
		</Card>
	)
}

type TProps = TStatisticsSet & {
	title: string
	loading: boolean
	onClick: () => void
	format?: (value: any) => any
}

export default StatisticsCard
