import axios, { AxiosTransformer } from 'axios'

export const upDataTransformer = ((axios?.defaults?.transformResponse as AxiosTransformer[]) || []).concat(
	(response: { data: any }) => response.data,
)

/** Rename "visenzeKey" key to "title" */
export const visenzeListTransformer = (
	(axios?.defaults?.transformResponse as AxiosTransformer[]) || []
).concat((response: { data: TVisenzeCategory[] }) =>
	response.data.map(({ visenzeKey, ...otherValues }) => ({ ...otherValues, title: visenzeKey })),
)

type TVisenzeCategory = {
	visenzeKey: string
}
