import { Input } from '@components'
import { debounce } from '@helpers'
import { useCallback, useTranslation } from '@hooks'
import React from 'react'

/** Search input in header cell of table. */
const TableSearchBar: React.FC<TProps> = ({ searchable = false, value, debounceDelay = 400, onChange, onSearch }) => {
	const { t } = useTranslation()
	const handleSearch = useCallback(
		debounce((queryText: string) => {
			onSearch(queryText)
		}, debounceDelay),
		[onSearch],
	)

	const changeHandler = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			event.persist()
			const queryText = event.target.value
			onChange(queryText)
			handleSearch(queryText)
		},
		[handleSearch, onChange],
	)

	return searchable ? (
		<Input.Search allowClear enterButton placeholder={t('search...')} value={value} onChange={changeHandler} />
	) : null
}

type TProps = {
	/** Enable search input. */
	searchable: boolean
	/** Value of search input. */
	value: string | undefined
	/**  Function called on each change of search input. */
	onChange: (value?: string) => void | Promise<void>
	/**  Function called on debounce change of search input. */
	onSearch: (value?: string) => void | Promise<void>
	/** Debounce delay affecting calling onSearch */
	debounceDelay?: number
}

export default TableSearchBar
