import { compileImageUri, first, formatPrice } from '@helpers'
import { TWardrobeItem } from '@typings'
import React from 'react'
import styles from './ArticleItemsBlockRow.module.css'

const ArticleItemsBlockRow: React.FC<TProps> = ({ item }) => {
	const brandTitle = item.brand.title
	const itemName = item.name
	const { pricePurchased } = item
	const image = compileImageUri(first(item.assets)?.asset, false)

	return (
		<div className={styles.itemContainer}>
			<img className={styles.image} src={image} alt="" />
			<span className={styles.brandTitle}>{brandTitle}</span>
			<span className={styles.itemName}>{itemName}</span>
			<span className={styles.pricePurchased}>{formatPrice(pricePurchased)}</span>
		</div>
	)
}

type TProps = {
	/**  */
	item: TWardrobeItem
}

export default ArticleItemsBlockRow
