import { TGlobalSearchParams } from '@typings'

/** Create settings for using globalSearch in API. Result needs to merge with re4quest params. */
export default function buildGlobalSearchParams(query: string, searchColumns: string[]): TGlobalSearchParams {
	return query
		? {
				globalSearch: searchColumns?.reduce(
					(result, columnName: string) => ({ ...result, [columnName]: `[substring]:${query}` }),
					{},
				),
		  }
		: { globalSearch: undefined }
}
