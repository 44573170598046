import {
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Input,
	LocationField,
	Popconfirm,
	Row,
	Switcher,
	UploadImageField,
	When,
	Text,
	Space,
	SearchableUserField,
	Radio,
	UploadVideo,
} from '@components'
import { showFormErrors, toNumber, formatToDay } from '@helpers'
import { useEntityListItemAPI, useHistory, useParams, useTranslation, useRequest, useState } from '@hooks'
import { TLookbook, TLookbookForm } from '@typings'
import * as React from 'react'
import { formButtonLayout, formFieldRules, formLayout, normalize, prepareForm } from './EditLookbook.formOptions'
import { api, message } from '@services'

const EditLookbookScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const lookbookId = params?.id ? toNumber(params?.id) : undefined
	const [asset, setAsset] = useState('image', '')

	// Form data.
	const Lookbook = useEntityListItemAPI<TLookbook, TLookbookForm>('/lookbooks', lookbookId, {
		onCreate: history.goBack,
		onUpdate: history.goBack,
		onRemove: () => history.go(-2),
	})
	const handleSubmit = (form: Partial<TLookbook>) => {
		if (lookbookId) {
			Lookbook.update(prepareForm(form, lookbookId))
		} else {
			Lookbook.create(prepareForm(form))
		}
	}

	const { fetch: makeGlobalLookbook, fetching: globalLookbookMaking } = useRequest<TLookbook, any>({
		request: () => api.makeGlobalLookbook(lookbookId as number),
		onSuccess: (lookbook: TLookbook) => history.push(`/lookbooks/${lookbook.id}`),
		onError: (error) => message.error(error.message),
	})

	const onChangeAsset = (e) => {
		setAsset(e.target.value)
	}
	React.useEffect(() => {
		Lookbook?.data?.asset?.videoKey && setAsset('video')
	}, [Lookbook.data])

	// Render helpers.
	const isGlobalLookbook = !!Lookbook.data?.user?.additionalIdentifier

	return (
		<ErrorBoundary>
			<Card
				title={t('Edit lookbook')}
				loading={!!lookbookId && Lookbook.fetching}
				extra={
					<Row gutter={16} align="middle">
						<Col flex={0}>
							<Popconfirm
								title={t('Are you sure?make this lookbook global?')}
								onConfirm={makeGlobalLookbook}
								okText={t('Yes')}
								cancelText={t('No')}
							>
								<Button
									type="dashed"
									disabled={Lookbook.fetching || isGlobalLookbook}
									loading={globalLookbookMaking}
								>
									{t('Make global')}
								</Button>
							</Popconfirm>
						</Col>
						<Col>
							<Popconfirm
								title={t('Are you sure?')}
								onConfirm={Lookbook.remove}
								okText={t('Yes')}
								cancelText={t('No')}
							>
								<Button disabled={!Lookbook?.data?.id} loading={Lookbook.removing} type="danger">
									{t('Remove lookbook')}
								</Button>
							</Popconfirm>
						</Col>
					</Row>
				}
			>
				<Form
					key={`lookbook-form-${Lookbook.data?.id}`}
					initialValues={{ userId: 505, ...Lookbook.data } || { isPrivate: true }}
					onFinish={handleSubmit}
					onFinishFailed={showFormErrors}
					{...formLayout}
				>
					<Form.Item name="title" required label={t('Title')} rules={formFieldRules.title}>
						<Input />
					</Form.Item>
					<Form.Item name="description" label={t('Description')} normalize={normalize}>
						<Input />
					</Form.Item>
					<Row align="middle" justify="center">
						<Radio.Group onChange={onChangeAsset} value={asset}>
							<Radio value={'image'}>{t('image')}</Radio>
							<Radio value={'video'}>{t('video')}</Radio>
						</Radio.Group>
					</Row>
					<Form.Item label={asset === 'image' ? t('Image') : t('Video')} required>
						{asset === 'image' ? (
							<UploadImageField name="asset" multiple={false} rules={formFieldRules.titleAssetId} />
						) : (
							<UploadVideo name="asset" rules={formFieldRules.titleAssetId} />
						)}
					</Form.Item>

					<Form.Item name="location" label={t('Location')}>
						<LocationField />
					</Form.Item>
					<Form.Item name="isPrivate" label={t('Is private')} valuePropName="checked">
						<Switcher checkedChildren={t('Private')} unCheckedChildren={t('Public')} />
					</Form.Item>
					<Form.Item required label={t('Assigning user')} name="userId" normalize={normalize}>
						<SearchableUserField />
					</Form.Item>
					<When condition={!!lookbookId}>
						<Form.Item label={t('Creation date')}>
							<Text>{formatToDay(Lookbook.data?.createdAt)}</Text>
						</Form.Item>
						<Form.Item label={t('Last update date')}>
							<Text>{formatToDay(Lookbook.data?.updatedAt)}</Text>
						</Form.Item>
					</When>
					<Form.Item wrapperCol={formButtonLayout}>
						<Space>
							<Button type="primary" htmlType="submit" loading={Lookbook.updating || Lookbook.removing}>
								{t('Save changes')}
							</Button>
							<Button onClick={history.goBack}>{t('Cancel')}</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
		</ErrorBoundary>
	)
}

type TParams = { id?: string }
type TProps = {
	/** */
}

export default EditLookbookScreen
