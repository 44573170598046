import { Button, ItemList, ItemTagsSelection, Modal } from '@components'
import { useArray, useCallback, useNumber, useRequest, useState, useTranslation } from '@hooks'
import { api } from '@services'
import { TResponseWithFilters, TWardrobeItem } from '@typings'
import { Input } from 'antd'
import { EditorState } from 'draft-js'
import isEmpty from 'lodash/isEmpty'
import { insertDataBlock } from 'megadraft'
import React from 'react'
import { limitConfig, prepareRequestParams } from './ArticleItemsBlockDialog.utils'

/** Custom button for DraftJS ArticleEditor component. Allow add wardrobe items to article. */
const ArticleItemsBlockDialog: React.FC<TProps> = ({ initialValue, onChange, editorState, updateData, onClose }) => {
	const { t } = useTranslation()

	// Selected items.
	const {
		value: selectedWardrobeItems,
		setValue: setSelectedWardrobeItems,
		removeIndex: removeSelectedWardrobeItemById,
		push: pushSelectedWardrobeItem,
	} = useArray<TWardrobeItem>([])
	useRequest<TResponseWithFilters<TWardrobeItem[]>>( // Upload initial items if initialValue have been passed.
		{
			request: () => (isEmpty(initialValue) ? null : api.getWardrobeItems({ id: initialValue })),
			onSuccess: (response) => setSelectedWardrobeItems(response.data),
		},
		[],
	)

	// States.
	const [query, setQuery] = useState<string | undefined>('', '')
	const { value: limit, setValue: setLimit, increase: increaseLimit } = useNumber(limitConfig.lowerLimit, limitConfig)

	// Fetch wardrobe items.
	const { data: response, fetching } = useRequest<TResponseWithFilters<TWardrobeItem[]>>(
		{
			request: () => api.getWardrobeItems(prepareRequestParams({ query, selectedWardrobeItems, limit })),
		},
		[query, selectedWardrobeItems, limit],
	)
	const wardrobeItems = response?.data || []

	// Actions.
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			event.persist()
			const query = event.target.value
			setQuery(query)
			setLimit(limitConfig.lowerLimit)
		},
		[setQuery],
	)
	const insertBlock = () => {
		const blockData = { type: 'wardrobe', ids: selectedWardrobeItems.map((item) => item.id) }
		if (initialValue) {
			updateData?.(blockData)
		} else {
			onChange(insertDataBlock(editorState, blockData))
		}
		onClose()
	}

	return (
		<>
			<Modal
				visible
				title={t('Attaching wardrobe items')}
				centered
				destroyOnClose
				onCancel={onClose}
				footer={[
					<Button key="back" onClick={onClose}>
						{t('Cancel')}
					</Button>,
					<Button key="submit" type="primary" disabled={!selectedWardrobeItems.length} onClick={insertBlock}>
						{t('Attach')}
					</Button>,
				]}
			>
				<Input.Search
					allowClear
					enterButton
					placeholder={t('search...')}
					value={query}
					onChange={handleChange}
				/>
				<ItemTagsSelection items={selectedWardrobeItems} onRemove={removeSelectedWardrobeItemById} />
				<ItemList
					loading={fetching}
					canLoadMore={limit > limitConfig.upperLimit}
					dataSource={wardrobeItems}
					onLoadMore={() => increaseLimit()}
					renderActions={(item: TWardrobeItem) => [
						<Button type="link" key="add-item" onClick={() => pushSelectedWardrobeItem(item)}>
							{t('Add')}
						</Button>,
					]}
				/>
			</Modal>
		</>
	)
}

type TProps = {
	initialValue: TWardrobeItem['id'][]
	/** Current EditorState passed by draftjs editor */
	editorState: EditorState
	/** Function for changing EditorState passed by draftjs editor */
	onChange: (editorState: EditorState) => void
	/** Callback passed for updating selected block. */
	updateData?: (blockData: any) => void
	/** Callback called after releasing dialog. */
	onClose: () => void
}

export default ArticleItemsBlockDialog
