import { i18n } from '@services'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { offset: 6, span: 12 }

export const formFieldRules = {
	assets: [{ required: true, message: i18n.t('Images are required') }],
}
