import { Icons } from '@components'
import React from 'react'

const BooleanIcon: React.FC<TProps> = ({ value }: TProps) =>
	value ? <Icons.CheckCircleTwoTone twoToneColor="#52c41a" /> : <Icons.CloseCircleTwoTone twoToneColor="#eb2f96" />

type TProps = {
	/** If true, will displayed positive icon, otherwise negative icon.  */
	value: boolean | undefined
}

export default BooleanIcon
