import { TArticle, TArticleForm, TArticleEdit, TSurvey, TLookbook } from '@typings'
import { draftEditorStateToHtml, HtmlToDraftEditorState, formatToDay } from '@helpers'
import { i18n } from '@services'
import { Link } from 'react-router-dom'
import React from 'react'

export const formRules = {
	type: [{ required: true, message: i18n.t('Type is required') }],
	topic: [{ required: true, message: i18n.t('Topic is required') }],
	question: [{ required: true, message: i18n.t('Question is required') }],
	asset1: [{ required: true, message: i18n.t('Image are required') }],
	asset2: [{ required: true, message: i18n.t('Image are required') }],
	answerOption1: [{ required: true, message: i18n.t('All answer options are required') }],
	answerOption2: [{ required: true, message: i18n.t('All answer options are required') }],
	answerOption3: [{ required: true, message: i18n.t('All answer options are required') }],
}
const getSurveyPayload = (form, survey?) => {
	console.log(survey, 'survey')
	const type = form.type === 'one-image-options' ? 'two-answer-options' : form.type
	console.log(form, 'form.type')
	switch (form.type) {
		case 'one-image-options':
			return {
				type,
				surveyAnswerOptions: [{ answerOption: form.answerOption1 }, { answerOption: form.answerOption2 }],
				surveyAnswerEdit: [
					{ answerOption: form.answerOption1, id: survey?.surveyAnswerOptions[0]?.id },
					{ answerOption: form.answerOption2, id: survey?.surveyAnswerOptions[1]?.id },
				],
				assets: [{ assetId: form.asset1.id }],
				assetId: form.asset1.id,
			}
		case 'two-answer-options':
			return {
				type,
				surveyAnswerOptions: [{ answerOption: form.answerOption1 }, { answerOption: form.answerOption2 }],
				assets: [{ assetId: form.asset1?.id }, { assetId: form.asset2?.id }],
				surveyAnswerEdit: [
					{ answerOption: form.answerOption1, id: survey?.surveyAnswerOptions[0]?.id },
					{ answerOption: form.answerOption2, id: survey?.surveyAnswerOptions[1]?.id },
				],
			}

		default:
			return {
				type,
				surveyAnswerOptions: [
					{ answerOption: form.answerOption1 },
					{ answerOption: form.answerOption2 },
					{ answerOption: form.answerOption3 },
				],
				surveyAnswerEdit: [
					{ answerOption: form.answerOption1, id: survey?.surveyAnswerOptions[0]?.id },
					{ answerOption: form.answerOption2, id: survey?.surveyAnswerOptions[1]?.id },
					{ answerOption: form.answerOption3, id: survey?.surveyAnswerOptions[2]?.id },
				],
				assets: [{ assetId: form.asset1?.id }],
			}
	}
}

export const castSurveyToSurveyForm = (survey: any): any =>
	survey
		? {
				topic: survey.topic,
				question: survey.question,
				type:
					survey.type === 'two-answer-options' && survey.assets.length === 1
						? 'one-image-options'
						: survey.type,
				icon: survey.icon,
				answerOption1: survey.surveyAnswerOptions[0].answerOption,
				answerOption2: survey.surveyAnswerOptions[1].answerOption,
				answerOption3: survey?.surveyAnswerOptions[2]?.answerOption,
				asset1: survey.assets[0].asset,
				asset2: survey?.assets[1]?.asset,
		  }
		: ({
				type: 'two-answer-options',
				topic: 'Vote',
				icon: {
					id: 16466,
					imageKey: 'c30b2421-1b90-44ee-9311-afec0fb13c6b',
				},
		  } as Partial<TSurvey>)

export const castSurveyFormToSurveyCreate = (form: any): Partial<TSurvey> => {
	const { type, surveyAnswerOptions, assets } = getSurveyPayload(form)

	return {
		topic: form.topic,
		question: form.question,
		type,
		iconId: form.icon.id,
		surveyAnswerOptions,
		assets,
	}
}

export const castSurveyFormToSurveyEdit = (form: any, survey: Partial<TSurvey>): Partial<TSurvey> => {
	const { surveyAnswerEdit, assets, type, assetId } = getSurveyPayload(form, survey)
	return {
		topic: form.topic,
		question: form.question,
		iconId: form.icon.id,
		surveyAnswerOptions: surveyAnswerEdit,
		assets,
	}
}
const sorter = () => 0
export const columnsComments = (surveyId) => [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 50,
		sorter,
		render: (id: number) => <Link to={`/users/${id}`}>{id}</Link>,
	},
	{
		title: 'First Name',
		dataIndex: 'firstName',
		width: 100,
		sorter,
	},
	{
		title: 'Last Name',
		dataIndex: 'lastName',
		width: 100,
		sorter,
	},
	{
		title: 'Answer',
		dataIndex: 'surveyGivenAnswers',
		width: 100,
		sorter,
		searchKey: 'surveyGivenAnswers.surveyAnswerOption.answerOption',
		render: (surveyGivenAnswers) => {
			const surveyAnswerOption = surveyGivenAnswers.filter(
				(surveyAnswerOption) => surveyAnswerOption.surveyAnswerOption.surveyId === surveyId,
			)
			return <p>{surveyAnswerOption[0].surveyAnswerOption?.answerOption}</p>
		},
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 100,
		sorter,
	},
]
