import { TArticle, TArticleForm, TArticleEdit } from '@typings'
import { draftEditorStateToHtml, HtmlToDraftEditorState } from '@helpers'
import { i18n } from '@services'

export const formRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
	asset: [{ required: true, message: i18n.t('Image is required') }],
}

export const castArticleToArticleForm = (article: TArticle | undefined): TArticleForm =>
	article
		? {
				userId: article.userId,
				title: article.title,
				author: article.author,
				body: HtmlToDraftEditorState(article.body),
				asset: article.asset,
		  }
		: ({} as TArticleForm)

export const castArticleFormToArticleEdit = (form: TArticleForm): TArticleEdit => ({
	userId: form.userId,
	title: form.title,
	author: form.author,
	assetId: form.asset?.id,
	body: draftEditorStateToHtml(form.body),
})
