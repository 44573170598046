import { DataTable, ErrorBoundary, Button, Icons, RemoveRowLink } from '@components'
import { useRequest, useTranslation, useHistory, useCallback, useRef, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TLookbook, TResponseWithFilters } from '@typings'
import * as React from 'react'
import { columns } from './Lookbooks.tableOptions'

const LookbooksScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TLookbook[]>,
		TFilteredRequest & Partial<TLookbook>
	>(
		{
			request: () => api.getLookbooks(searchParams),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddLookbook = useCallback(() => history.push('/lookbooks/add'), [])
	const goToEditLookbook = useCallback(
		(lookbookId: TLookbook['id']) => history.push(`/lookbooks/${lookbookId}/edit`),
		[],
	)

	return (
		<ErrorBoundary>
			<DataTable<TLookbook, Partial<TLookbook>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={wardrobeItems}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				pageSize={10} // TODO Temporary reduce number of displayed rows due to backend issue
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToAddLookbook}>
						{t('Add lookbook')}
					</Button>
				}
				renderActionsComponent={(item: TLookbook) => [
					<Button key="1" type="link" key="add-item" onClick={() => goToEditLookbook(item.id)}>
						{t('Edit')}
					</Button>,
					<RemoveRowLink
						key="2"
						id={item.id}
						request={api.removeLookbook}
						onSuccess={tableRef.current?.refresh}
					/>,
				]}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default LookbooksScreen
