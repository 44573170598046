import { EditorState, RichUtils } from 'draft-js'

/** Put link to DraftJS editor state. */
export default function insertLinkToEditorState(editorState: EditorState, linkUrl: string): EditorState {
	const contentState = editorState.getCurrentContent()
	const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: linkUrl })
	const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
	const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
	const readyEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey)
	return readyEditorState
}
