import { Card, Case, ErrorBoundary, Switch } from '@components'
import { useHistory, useMemo, useState, useTranslation } from '@hooks'
import { BrandsList, SuggestedBrandsList } from '@screens'
import * as React from 'react'

const BrandsListRoutesScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Tabs.
	const tabList: { key: TTabs; tab: string }[] = useMemo(
		() => [
			{
				key: 'current',
				tab: t('Current brands'),
			},
			{
				key: 'suggested',
				tab: t('Suggested brands'),
			},
		],
		[],
	)
	const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0].key, '')

	return (
		<ErrorBoundary>
			<Card tabList={tabList} activeTabKey={activeTabKey} onTabChange={setActiveTabKey}>
				<Switch>
					<Case condition={activeTabKey === 'current'}>
						<BrandsList />
					</Case>
					<Case condition={activeTabKey === 'suggested'}>
						<SuggestedBrandsList />
					</Case>
				</Switch>
			</Card>
		</ErrorBoundary>
	)
}

type TTabs = 'current' | 'suggested'
type TProps = {
	/** */
}

export default BrandsListRoutesScreen
