export type TTabs = 'chat' | 'details'
export const tabList: { key: TTabs; tab: string }[] = [
	{
		key: 'details',
		tab: 'Wardrobe item details',
	},
	{
		key: 'chat',
		tab: 'Chat',
	},
]
