import { Button, DataTable, ErrorBoundary, Popconfirm } from '@components'
import { useRef, useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TResponseWithFilters, TSuggestedBrand } from '@typings'
import * as React from 'react'
import { columns } from './SuggestedBrandsList.tableOptions'

const SuggestedBrandsListScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Obtain data.
	const { data: { data: suggestedBrands, meta } = {}, fetch, fetching, fetched } = useRequest<
		TResponseWithFilters<TSuggestedBrand[]>,
		TFilteredRequest & Partial<TSuggestedBrand>
	>(
		{
			request: () => api.getSuggestedBrandsTable(searchParams),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const { fetch: approveBrand } = useRequest<void, TSuggestedBrand['id']>({
		request: (brandId) => api.confirmSuggestedBrand(brandId),
		onSuccess: () => {
			message.success('Brand was approved!')
			tableRef.current.refresh()
		},
		onError: (error) => message.error(error.message),
	})

	// Actions.
	const { fetch: rejectBrand } = useRequest<void, TSuggestedBrand['id']>({
		request: (brandId) => api.rejectSuggestedBrand(brandId),
		onSuccess: () => {
			message.success('Brand was rejected!')
			tableRef.current.refresh()
		},
		onError: (error) => message.error(error.message),
	})

	return (
		<ErrorBoundary>
			<DataTable<TSuggestedBrand, Partial<TSuggestedBrand>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={suggestedBrands}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				renderActionsComponent={(suggestedBrand: TSuggestedBrand) => (
					<>
						<Button
							type="link"
							disabled={suggestedBrand.status !== 'on-review'}
							onClick={() => approveBrand(suggestedBrand.id)}
						>
							{t('Approve')}
						</Button>

						<Button
							type="link"
							danger
							disabled={suggestedBrand.status !== 'on-review'}
							onClick={() => rejectBrand(suggestedBrand.id)}
						>
							{t('Reject')}
						</Button>
					</>
				)}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default SuggestedBrandsListScreen
