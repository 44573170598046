import React from 'react'
import { Avatar, Icons, Link, BooleanIcon } from '@components'
import { TPost, TStatus, TAsset } from '@typings'
import { formatToDay, compileImageUri, getYouTubeVideoId, prettytime } from '@helpers'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TPost) => <Link to={`/posts/${record.id}`}>{text}</Link>,
		searchKey: 'id',
		defaultSortOrder: 'descend',
	},
	{
		title: 'Image',
		dataIndex: 'asset',
		width: 80,
		render: (asset: TAsset) => (
			<Avatar size="large" icon={<Icons.PictureOutlined />} shape="square" src={compileImageUri(asset, true)} />
		),
	},
	{
		title: 'Video',
		dataIndex: 'videoUrl',
		width: 80,
		render: (videoUrl: string) => (
			<Avatar
				size="large"
				shape="square"
				icon={<Icons.YoutubeOutlined />}
				src={`https://img.youtube.com/vi/${getYouTubeVideoId(videoUrl as string)}/mqdefault.jpg` as string}
			/>
		),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 200,
		searchKey: 'title',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 80,
		filters: [
			{
				text: 'Active',
				value: 'active',
			},
			{
				text: 'Disabled',
				value: 'disabled',
			},
		] as { text: string; value: TStatus }[],
	},
	{
		title: 'Promotional',
		dataIndex: 'isPromotional',
		align: 'center',
		width: 80,
		render: (isPromotional: boolean) => <BooleanIcon value={isPromotional} />,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'Now',
				value: false,
			},
		] as { text: string; value: boolean }[],
		sorter,
	},
	{
		title: 'Repetitions done',
		dataIndex: 'scheduleSettingsRepetitionsDone',
		width: 150,
		align: 'center',
		sorter,
	},
	{
		title: 'Repetitions left',
		dataIndex: 'scheduleSettingsRepetitionsLeft',
		width: 150,
		align: 'center',
		sorter,
	},
	{
		title: 'Interval',
		dataIndex: 'scheduleSettingsInterval',
		width: 120,
		sorter,
		render: (interval: number) => prettytime(interval),
	},
	{
		title: 'Start time',
		dataIndex: 'scheduleSettingsStartTime',
		render: formatToDay,
		width: 120,
		sorter,
	},
	{
		title: 'Next run',
		dataIndex: 'scheduleSettingsNextRun',
		render: formatToDay,
		width: 120,
		sorter,
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Published at',
		dataIndex: 'publishedAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
]
