import { InputNumber } from '@components'
import { InputNumberProps } from '@typings'
import React from 'react'

const PriceField: React.FC<TProps> = (inputNumberProps: TProps) => {
	return <InputNumber min={0} max={1000000} step={1} precision={2} decimalSeparator="." {...inputNumberProps} />
}

type TProps = InputNumberProps & {}

export default PriceField
