import { Button, Card, ErrorBoundary, Form, RemoveUserLink, Space, Spin, VisuallyHidden } from '@components'
import { sha256, showFormErrors, toNumber, isEmpty } from '@helpers'
import { useHistory, useMemo, useParams, useRequest, useState, useTranslation } from '@hooks'
import { api, message } from '@services'
import { TUser, TUserUpdateForm, TRequestCodeForm } from '@typings'
import * as moment from 'moment'
import * as React from 'react'
import { formLayout, parseInitialValues, contactWays, sanitizeObject } from './EditUser.formOptions'
import { tabList, TTabs } from './EditUser.tabsOptions'
import { AccountFields, BillingAddressFields, BodyFields, PrivacySettingsFields, StyleFields } from './fieldSets'

const EditUserScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const userId = toNumber(params?.id)

	// Tabs state.
	const [activeTab, setActiveTab] = useState<TTabs>(tabList[0].key, 'EditUserScreen:activeTab')

	// Get user.
	const { data: user, fetching: userFetching } = useRequest<TUser, TUser['id'] | undefined>(
		{
			request: () => (userId ? api.getUser(userId) : null),
		},
		[],
	)
	const { fetch: updateUser, fetching: updating } = useRequest<TUser, TUserUpdateForm>({
		request: ({
			newPassword,
			confirmNewPassword,
			birthdayDate,
			billingAddress,
			avatar,
			avatarBackground,
			...values
		}) => {
			const form = {
				...values,
				birthdayDate: birthdayDate
					? moment.default(String(birthdayDate), 'DD-MM-YYYY').format('YYYY-MM-DD')
					: null,
				billingAddress: isEmpty(sanitizeObject(billingAddress)) ? null : billingAddress,
				avatarId: avatar?.id ?? null,
				avatarBackgroundId: avatarBackground?.id ?? null,
				password: newPassword ? sha256(newPassword) : undefined,
			}
			delete form.instagramAccountId
			return userId ? api.updateUser({ id: userId, ...form }) : api.createUser(form)
		},
		onSuccess: (data, values) => {
			const payload = {
				email: values.email,
				fullName: `${values.firstName} ${values.lastName}`,
			}
		},
		onError: (error) => message.error(error.message),
	})
	const { fetch: createCode, fetching } = useRequest<any, TRequestCodeForm>({
		request: api.createCode,
		onSuccess: (data, form) => {
			history.goBack()
			message.success(t('User profile has been updated.'))
		},
		onError: (error) => message.error(error.message),
	})

	// Form helpers.
	const initialValues = useMemo(() => parseInitialValues(user), [user])

	return (
		<ErrorBoundary>
			<Spin spinning={!!userId && userFetching}>
				<Form
					preserve
					key={`edit-user-from-${user?.id}`}
					{...formLayout}
					name="basic"
					onFinish={updateUser}
					initialValues={initialValues}
					onFinishFailed={showFormErrors}
				>
					<Card
						title={userId ? t('Edit user') : t('Add user')}
						extra={<RemoveUserLink userId={userId} onSuccess={() => history.replace('/users')} />}
						tabList={tabList}
						onTabChange={setActiveTab}
					>
						<VisuallyHidden condition={activeTab !== 'account'}>
							<AccountFields updating={updating} t={t} isAdding={!userId} />
						</VisuallyHidden>
						<VisuallyHidden condition={activeTab !== 'body'}>
							<BodyFields updating={updating} t={t} />
						</VisuallyHidden>
						<VisuallyHidden condition={activeTab !== 'style'}>
							<StyleFields updating={updating} t={t} />
						</VisuallyHidden>
						<VisuallyHidden condition={activeTab !== 'billing_address'}>
							<BillingAddressFields updating={updating} t={t} />
						</VisuallyHidden>
						<VisuallyHidden condition={activeTab !== 'privacy_settings'}>
							<PrivacySettingsFields updating={updating} t={t} contactWays={contactWays} />
						</VisuallyHidden>
						<Form.Item>
							<Space>
								<Button type="primary" htmlType="submit" loading={updating}>
									{t('Save changes')}
								</Button>
								<Button onClick={history.goBack}>{t('Cancel')}</Button>
							</Space>
						</Form.Item>
					</Card>
				</Form>
			</Spin>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}
type TParams = { id?: string }

export default EditUserScreen
