import { api } from '@services'

/** List of supported charts. */
export const requestsMap = {
	// Wardrobe items.
	newWardrobeItems: {
		request: (params) =>
			api.getWardrobeItemsIncrease({ ...params, status: ['active', 'sale', 'gift_accepted', 'gift_offered'] }),
	},

	newWardrobeSum: {
		request: (params) =>
			api.getWardrobeSumIncrease({ ...params, status: ['active', 'sale', 'gift_accepted', 'gift_offered'] }),
	},
	newWardrobeNotListed: {
		request: (params) => api.getWardrobeItemsIncrease({ ...params, 'brand.title': '*BRAND NOT LISTED' }),
	},
	newWardrobeBrandUnknown: {
		request: (params) => api.getWardrobeItemsIncrease({ ...params, 'brand.title': 'UNKNOWN' }),
	},
	newItemLikes: {
		request: (params) => api.getWardrobeLikesIncrease(params),
	},
	// Users.
	newUsers: {
		request: (params) => api.getUsersIncrease(params),
		barLabels: ['users'],
	},
	activeUsers: {
		request: (params) => api.getUsersIncrease({ ...params, isCreatedFromBrand: false }),
		barLabels: ['users'],
	},
	unlockedUsers: {
		request: (params) => api.getUsersIncrease({ ...params, role: 'user', isCreatedFromBrand: false }),
		barLabels: ['users'],
	},
	brandUsers: {
		request: (params) => api.getUsersIncrease({ ...params, isCreatedFromBrand: true }),
		barLabels: ['users'],
	},
	newUsersFollowers: {
		request: (params) => api.getUsersFollowersIncrease(params),
	},
	newUsersWishlist: {
		request: (params) => api.getUsersWishlistIncrease(params),
	},
	newInfluencerUsers: {
		request: (params) => api.getTotalInfluencersIncrease(params),
	},
	// Lookbooks.
	newPublicLookbooks: {
		request: (params) => api.getLookbooksIncrease({ ...params, isPrivate: false }),
	},
	newPrivateLookbooks: {
		request: (params) => api.getLookbooksIncrease({ ...params, isPrivate: true }),
	},
	newLookbooksContributings: {
		request: (params) => api.getLookbooksContributingsIncrease(params),
	},
	newLookbooksContributors: {
		request: (params) => api.getLookbooksContributorsIncrease(params),
	},
	newLookbooksLikes: {
		request: (params) => api.getLookbooksLikesIncrease(params),
	},
	// Sharing.
	requestedSharings: {
		request: (params) => api.getSharingsIncrease({ ...params, status: 'requested' }),
	},
	loanedSharings: {
		request: (params) => api.getSharingsIncrease({ ...params, status: 'approved' }),
	},
	returnedSharings: {
		request: (params) => api.getSharingsIncrease({ ...params, status: 'returned' }),
	},
	uncollectedSharings: {
		request: (params) => api.getSharingsIncrease({ ...params, status: 'expired_ready_for_collection' }),
	},
	// Assist.
	newAssistClearedItems: {
		request: (params) => api.getAssistIncrease({ ...params, assistType: 'clear' }),
	},
	newAssistUploadedItems: {
		request: (params) => api.getAssistIncrease({ ...params, assistType: 'upload' }),
	},
	newAssistStylistItems: {
		request: (params) => api.getAssistIncrease({ ...params, assistType: 'styling' }),
	},
	// MKT.
	newMarketplaceItems: {
		request: (params) => api.getWardrobeItemsIncrease({ ...params, status: ['sale'] }),
	},
	newMarketplaceItemsSum: {
		request: (params) => api.getWardrobeSumIncrease({ ...params, status: ['sold'] }),
	},
	newPotentialWardrobeItems: {
		request: (params) => api.getPotentialMKTItemsIncrease(params),
	},

	newNotPotentialWardrobeItems: {
		request: (params) => api.getNotPotentialMKTItemsIncrease(params),
	},
	newMarketplaceItemsOnReview: {
		request: (params) => api.getMarketplaceInformationIncrease({ ...params, status: 'on-review' }),
	},
	newMarketplaceRejectedItems: {
		request: (params) => api.getMarketplaceInformationIncrease({ ...params, status: 'rejected' }),
	},
	newWardrobeItemsBeingSold: {
		request: (params) => api.getWardrobeItemsIncrease({ ...params, status: ['sold'] }),
	},
	// Feed.
	newArticles: {
		request: (params) => api.getArticlesIncrease(params),
	},
	newPosts: {
		request: (params) => api.getPostsIncrease(params),
	},
	newSurveys: {
		request: (params) => api.getSurveysIncrease(params),
	},
	newComments: {
		request: (params) => api.getCommentsIncrease(params),
	},
	newCommentsByCategory: {
		request: (params) => api.getCommentsByTypeIncrease(params),
	},
}
