import { useMemo, useLocalStorage, useRequest, useUserProfileContext } from '@hooks'
import { useCallback, useEffect, useLayoutEffect } from 'react'
import { TAuthResponse, TLoginForm, AxiosResponse } from '@typings'
import { config, httpCodes } from '@constants'
import { api, apiClient, message } from '@services'

/** Store authorization status and token. Closely connected with useUserProfileContext() because call fetchProfile{} after successful authorization. */
const useAuth = (args: TUseAuth): TUseAuthData => {
	const [authToken, setAuthToken] = useLocalStorage('lbdadmin:authToken', '')
	const isLoggedIn = !!authToken
	const { setProfile, clearProfile, fetchProfile } = useUserProfileContext()

	const { fetch: login, fetching: logging } = useRequest<TAuthResponse, TLoginForm>({
		request: (loginForm) => api.login(loginForm),
		onSuccess: ({ token, user }) => {
			setAuthToken(token)
			setProfile(user)
		},
		onError: (error) => message.error(error.message),
	})

	const logout = useCallback(() => {
		clearProfile()
		setAuthToken('')
	}, [])

	useEffect(() => {
		apiClient.interceptors.response.use(
			(response: AxiosResponse) => {
				return response
			},
			(error: any) => {
				// Format request error. https://github.com/axios/axios/issues/960
				error.data = error?.request?.response?.error
				error.message = error?.data?.message
				const responseStatus = error?.response?.status ?? 0 // 0 = 'Network Error'

				// Handle backend auth errors.
				if ([httpCodes.UNAUTHORIZED, httpCodes.FORBIDDEN].includes(responseStatus)) {
					logout()
				}
				// Handle 'Network Error'
				else if (!responseStatus) {
					console.warn('Network Error')
				}
				// Send report about error to bug tracker.
				else {
					console.error('error')
				}

				return Promise.reject(error)
			},
		)
	}, [])

	useLayoutEffect(() => {
		apiClient.defaults.headers.common[String(config.API_AUTH_HEADER)] = authToken
		fetchProfile()
	}, [authToken])

	return useMemo(() => ({ isLoggedIn, logging, login, authToken, setAuthToken, logout }), [
		isLoggedIn,
		authToken,
		logging,
	])
}

type TUseAuth = void
type TUseAuthData = {
	isLoggedIn: boolean
	authToken: string
	setAuthToken: (authToken: string) => void
	login: (props: TLoginForm) => Promise<void>
	logging: boolean
	logout: () => void
}

export default useAuth
