import { Form } from '@components'
import { FormInstance } from '@typings'
import React, { useImperativeHandle, useRef } from 'react'

const FormSpy: React.ForwardRefRenderFunction<FormInstance | undefined, TProps> = ({}, ref) => {
	const formInstanceRef = useRef<FormInstance>()
	useImperativeHandle(ref, () => formInstanceRef.current)

	return (
		<Form.Item shouldUpdate style={{ display: 'none' }}>
			{(formInstance) => {
				formInstanceRef.current = formInstance
				return <span />
			}}
		</Form.Item>
	)
}

type TProps = {
	/**  */
}

export default React.forwardRef(FormSpy)
