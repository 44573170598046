import { i18n } from '@services'
import { TCreatePost } from 'src/typings/posts'
import * as moment from 'moment'
import { EditorState } from 'draft-js'
import { editorDecorator } from './EditPost.editorDecorator'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { wrapperCol: { offset: 6, span: 12 } }

export const formFieldRules = {
	title: [
		{ required: true, message: i18n.t('Title is required') },
		{ max: 128, message: i18n.t('Description too long') },
	],
	description: [{ max: 500, message: i18n.t('Description too long') }],
	videoUrl: [
		{ pattern: /http(s)?:\/\/((w){3}.)?(m\.)?youtu(be|.be)?(\.com)?\/.+/, message: i18n.t('Invalid YouTube link') },
	],
	scheduleSettingsStartTime: [{ required: true, message: i18n.t('Start time is required') }],
}

export const defaultFormValues: TCreatePost = {
	status: 'active',
	isCommentsAllowed: true,
	description: EditorState.createEmpty(editorDecorator),
	scheduleSettingsStartTime: moment.default().toISOString(),
	scheduleSettingsRepetitionsLeft: 1,
	scheduleSettingsInterval: 0,
}

export const normalize = (value: any) => value ?? null
