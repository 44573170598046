import { DataTable, ErrorBoundary } from '@components'
import { useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TResponseWithFilters, TWardrobeItem } from '@typings'
import * as React from 'react'
import { columns } from './MarketplaceItems.tableOptions'

const MarketplaceItemsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	// const tableRef = React.useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getWardrobeItems({ ...searchParams, status: 'sale' }),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	return (
		<ErrorBoundary>
			<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
				// ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={wardrobeItems}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default MarketplaceItemsScreen
