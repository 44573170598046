import { AxiosInstance } from 'axios'
import { TAPIServers, TAPISettings, TAPISettingsMap } from '@typings'

const LOCAL_STORAGE_KEY = 'api@serverName'

/** API servers settings manager. */
export default class ApiSetting {
	private options: TAPISettingsMap = {
		staging: {
			API_SERVER_NAME: 'staging',
			API_URL: 'https://api-staging.littleblackdoor.io/v2',
			IMAGE_BASE_URL: 'https://lbd-staging-main-bucket.s3.eu-west-2.amazonaws.com',
		},
		production: {
			API_SERVER_NAME: 'production',
			API_URL: 'https://api.littleblackdoor.io/v2',
			IMAGE_BASE_URL: 'https://lbd-production-main-bucket.s3.eu-west-2.amazonaws.com',
		},
		development: {
			API_SERVER_NAME: 'development',
			API_URL: 'https://api-dev.littleblackdoor.io/v2',
			IMAGE_BASE_URL: 'https://dev-lbd.s3.eu-central-1.amazonaws.com',
		},
	}
	private settings: TAPISettings = this.options.staging
	private axiosInstance: AxiosInstance

	constructor(defaultServerName: TAPIServers, axiosInstance: AxiosInstance) {
		this.axiosInstance = axiosInstance
		const savedServerName = localStorage.getItem(LOCAL_STORAGE_KEY) as TAPIServers
		const serverName = savedServerName in this.options ? savedServerName : defaultServerName
		this.setOption(serverName)
	}

	getSettings(settingsName: keyof TAPISettings): string {
		return this.settings[settingsName]
	}
	getOptions(): TAPISettingsMap {
		return this.options
	}
	setOption(serverName: TAPIServers): void {
		if (!(serverName in this.options)) {
			return console.error(`Settings for server "${serverName}" not found.`)
		}
		this.settings = this.options[serverName]
		this.axiosInstance.defaults.baseURL = this.settings.API_URL
		localStorage.setItem(LOCAL_STORAGE_KEY, serverName)
	}
}
