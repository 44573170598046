import { upDataTransformer } from '../transformers'
import { TWardrobeItem, TRequestBuilder } from '@typings'

// export const getCart: TRequestBuilder<void> = () => ({
// 	method: 'get',
// 	url: `/users/cart`,
// 	transformResponse: upDataTransformer,
// })

// export const addItemToCart: TRequestBuilder<TWardrobeItem['id']> = wardrobeItemId => ({
// 	method: 'post',
// 	url: `/users/cart/${wardrobeItemId}`,
// 	transformResponse: upDataTransformer,
// })

// export const removeItemFromCart: TRequestBuilder<TWardrobeItem['id'][]> = wardrobeItemIds => ({
// 	method: 'delete',
// 	url: `/users/cart`,
// 	data: { wardrobeItemIds },
// 	transformResponse: upDataTransformer,
// })
