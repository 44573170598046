import {
	ArticleEditor,
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Icons,
	Input,
	Popconfirm,
	Row,
	Spin,
	Switcher,
	UploadImageField,
	When,
	DataTable,
	RemoveCommentLink,
} from '@components'
import { compileImageUri, showFormErrors, toNumber } from '@helpers'
import {
	useArticleAPI,
	useCallback,
	useHistory,
	useParams,
	useTranslation,
	useMemo,
	useLBDOfficialUserId,
	useRequest,
} from '@hooks'
import {
	TArticleForm,
	TResponseWithFilters,
	TComment,
	TFilteredRequest,
	TProfile,
	TPost,
	TWardrobeItem,
} from '@typings'
import * as React from 'react'
import { castArticleFormToArticleEdit, castArticleToArticleForm, formRules } from './EditArticle.formOptions'
import styles from './EditArticle.module.css'
import * as moment from 'moment'
import { api, message } from '@services'
import { columnsComments } from '../LookbookView/LookbookView.tableOptions'

/** Form for creating and editing articles for news feed. */
const EditArticleScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const articleId = toNumber(params?.id)

	const Article = useArticleAPI(articleId, { onAction: () => history.goBack() })
	const initialValues = useMemo(() => castArticleToArticleForm(Article.data), [Article.data])

	const userId = useLBDOfficialUserId() || null
	const submitForm = useCallback(
		(form: TArticleForm) =>
			articleId
				? Article.update(castArticleFormToArticleEdit({ ...form, userId }))
				: Article.create(castArticleFormToArticleEdit({ ...form, userId })),
		[articleId, userId],
	)

	//Comments

	// API data..
	const { data: { data: posts, meta } = {}, fetching } = useRequest<
		TResponseWithFilters<TPost[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => articleId && api.getPosts({ articleId: params?.id }),
			onError: (error) => message.error(error.message),
		},
		[params?.id],
	)
	const { data: { data: comments, meta: commentsMeta } = {}, fetch } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TComment>
	>(
		{
			request: () => posts?.[0]?.id && api.getComments({ 'posts.post.id': posts?.[0]?.id }),
			onError: (error) => message.error(error),
		},
		[posts],
	)
	const newCommentsData = comments?.map((el) => (el.replies?.length ? { ...el, children: el.replies } : { ...el }))

	return (
		<ErrorBoundary>
			<Spin spinning={(articleId && Article.fetching) || Article.creating || Article.updating}>
				<When condition={Article.fetched || !articleId}>
					<Form initialValues={initialValues} onFinish={submitForm} onFinishFailed={showFormErrors}>
						<Card
							title={t('Article editor')}
							extra={
								<Row gutter={[16, 16]} align="middle">
									<Col flex={0}>
										<Switcher
											loading={Article.publishing}
											checked={Article?.data?.status === 'published'}
											checkedChildren={t('Published')}
											unCheckedChildren={t('Hidden')}
											onChange={Article.setPublish}
										/>
									</Col>
									<Col flex={0}>
										<Button htmlType="button" onClick={history.goBack}>
											{t('Cancel')}
										</Button>
									</Col>
									<Col flex={0}>
										<Popconfirm
											title={t('Are you sure?delete this article?')}
											onConfirm={Article.remove}
											okText={t('Yes')}
											cancelText={t('No')}
										>
											<Button
												disabled={!Article?.data?.id}
												loading={Article.removing}
												type="danger"
											>
												{t('Remove article')}
											</Button>
										</Popconfirm>
									</Col>
									<Col flex={0}>
										<Button
											type="primary"
											htmlType="submit"
											loading={Article.creating || Article.updating}
										>
											{Article?.data?.id ? t('Save') : t('Create')}
										</Button>
									</Col>
								</Row>
							}
						>
							<div className={styles.cardContent}>
								<div className={styles.mobileContainer}>
									<div className={styles.imageContainer}>
										<UploadImageField
											name="asset"
											multiple={false}
											listType="text"
											required
											hasFeedback
											rules={formRules.asset}
											renderChildren={({ fileList }) => (
												<>
													<div className={styles.imageButton}>
														<Button icon={<Icons.UploadOutlined />}>
															{t('Click to Upload')}
														</Button>
													</div>
													<img
														className={styles.backgroundImage}
														src={compileImageUri(fileList[0]?.response?.data, false)}
														alt="article cover"
													/>
												</>
											)}
										/>
									</div>

									<div className={styles.mobileContent}>
										<div className={styles.mobileLayout}>
											<Form.Item
												name="title"
												required
												noStyle
												rules={formRules.title}
												hasFeedback
											>
												<Input.TextArea autoSize className={styles.headerInputField} />
											</Form.Item>
											<Form.Item name="author" noStyle>
												<Input
													placeholder={t("you can write author's name")}
													prefix={t('SHARED BY')}
													suffix={moment.default().format('DD ddd')}
												/>
											</Form.Item>
											<div className={styles.editorContainer}>
												<Form.Item name="body" noStyle>
													<ArticleEditor />
												</Form.Item>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Card>
					</Form>
				</When>
			</Spin>
			<Card title={t('Comments')}>
				<DataTable<TProfile, Partial<TProfile>>
					columns={columnsComments}
					dataSource={newCommentsData}
					meta={commentsMeta}
					autoFetchOnMount={false}
					renderActionsComponent={(comment: TComment) => (
						<>
							<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Card>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}
type TParams = { id?: string }
export default EditArticleScreen
