import { Button, Card, Col, ErrorBoundary, Icons, Row, Spin, Tooltip, TreeView } from '@components'
import { useHistory, useTranslation } from '@hooks'
import { TItemAttributesTree, TreeProps, TUseEntityListItemAPI, TItemAttributeItem } from '@typings'
import { last, toNumber } from '@helpers'
import { message } from '@services'
import * as React from 'react'

const canAddSubAttribute = (attribute: TItemAttributeItem | undefined): boolean => !attribute?.parentId

const AttributesListScreen: React.FC<TProps> = ({
	attributesTree,
	loading,
	selectedKeys,
	setSelectedKeys,
	Attribute,
}) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const selectedAttributeId = selectedKeys?.length ? toNumber(last(selectedKeys)) : undefined

	// Actions.
	const handleDrop: TreeProps['onDrop'] = ({ node, dragNode, dropToGap }) => {
		const parentId = dropToGap ? node?.parentId || null : node?.id
		if (node?.parentId !== null) return message.info(t('You cannot create additional nesting.'))
		Attribute.update({ id: dragNode.id, parentId })
	}
	const goToAdding = () => {
		const key = last(selectedKeys)
		history.push(key ? `/attributes/${key}/add` : `/attributes/add`)
	}

	return (
		<ErrorBoundary>
			<Spin spinning={loading}>
				<Card
					title={t('Attributes')}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Tooltip
									placement="top"
									title={t('You have ability to change position of attribute using drag&drop')}
								>
									<Icons.QuestionCircleOutlined />
								</Tooltip>
							</Col>
							<Col>
								<Button
									disabled={!(canAddSubAttribute(Attribute.data) || !selectedAttributeId)}
									onClick={goToAdding}
								>
									{t('Add')}
								</Button>
							</Col>
						</Row>
					}
				>
					<TreeView<TItemAttributesTree>
						draggable
						tree={attributesTree}
						selectedKeys={selectedKeys}
						onSelect={setSelectedKeys}
						onDrop={handleDrop}
						childrenKey="subattributes"
					/>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TProps = {
	/** Tree of all categories in database. */
	attributesTree: TItemAttributesTree
	/** Category which had been selected by user. */
	selectedKeys: string[]
	/** Callback for changing selected category. */
	setSelectedKeys: (selectedKeys: string[]) => void
	/** Should display loading state. */
	loading: boolean
	/** API for manipulating category. */
	Attribute: TUseEntityListItemAPI<TItemAttributeItem, {}>
}

export default AttributesListScreen
