import { Option, TAsset, TStatus, TTree, TUser, TValueView, UTCDate } from '@typings'
import { TProfileShort, TProfile } from './profile'

export type TSizeChart = Option & {
	chartType: Option
	sizes: TItemSize[]
}
export type TSizeChartShort = Option & { sizeChartsId: number }
export type TSize = {
	crossSizeId: number
	sizeChartsId: number
	title: string
	sizeChart: TSizeChartShort
}
export type TChartTypeItem = Option
export type TCategoryItem = Option & {
	chartType: TChartTypeItem
	chartTypeId: TChartTypeItem['id']
	createdAt: UTCDate
	currentCategoryId: number
	attributes: TItemAttribute[]
	isShowingInWardrobe: boolean
	parentId: number | null
	status: TStatus
	subcategoryCount: number
	wardrobeItemsCount: number
	updatedAt: UTCDate
}

export type TCategoriesTree = TTree<'subcategories', TCategoryItem>
export type TItemAttributesTree = TTree<'subattributes', TItemAttributeItem>
export type TCategorizedFiltersTree = TTree<'subCategorizedFilters', TItemAttributeItem>

export type TCategory = {
	categoryId: number
	wardrobeItemId: number
	category: Option & {
		parentId: number | null
		chartTypeId: TChartType | null
		status: TStatus
	}
}
export type TItemAttributeItemCatBundle = { categoryId: TCategoryItem['id']; attributeId: TItemAttributeItem['id'] }
export type TItemAttributeItem = Option & {
	createdAt: string
	parentId?: number | null
	status: TStatus
	updatedAt: string | null
	subattributeCount: number
	categories: TItemAttributeItemCatBundle[]
	subattributes: TItemAttributeItem[]
}
export type TItemAttribute = {
	attribute: TItemAttributeItem
	attributeId: number
	wardrobeItemId: number
}
export type TGetItemAttributeItem = Partial<TItemAttributeItem> & {
	categoryId?: TCategoryItem['id']
	parentId?: TItemAttributeItem['parentId'] | 'null'
}
export type TClothesStyle = Option & {
	assetId: TAsset['id']
	asset: TAsset
}

export type TWardrobeItemImage = {
	asset: TAsset
	assetId: TAsset['id']
	orderNumber: number
	wardrobeItemId: number
}
export type TItemCategory = Option & {
	parentId: null | number
	chartTypeId: null | TChartType
	status: TStatus
	createdAt: UTCDate
	updatedAt: UTCDate
	chartType: TChartType
	attributes: TItemAttribute[]
	subcategoryCount: number
}
export type TBrand = Option & {
	status: TStatus
}
export type TInfluencer = Option
export type TStore = Option
export type TFashionSource = Option
export type TReselPlatforms = Option
export type TBodyShape = {
	id: number
	title: string
}

export type TWardrobeItemStatus = 'active' | 'disabled' | 'sale' | 'sold' | 'gift_accepted' | 'gift_offered'
export type TUserStatisticsItem = {
	status: TWardrobeItemStatus
	pricePurchased: number | null
	priceEstimated: number | null
	priceActual: number | null
	count: number | null
}
export type TUserStatistics = [
	TUserStatisticsItem & { status: 'active' },
	TUserStatisticsItem & { status: 'disabled' },
	TUserStatisticsItem & { status: 'sale' },
	TUserStatisticsItem & { status: 'sold' },
]
export enum TChartType {
	'CLOTHES' = 1,
	'SHOES',
	'RINGS',
	'GLOVES',
	'BELTS',
	'HATS',
	'JEANS',
}
export type TItemSize = Option & {
	crossSizeId: number
}
export type TSizeChartType = Option
export type TLocation = {
	id?: number
	countryPlaceId: string | null
	countryName: string | null
	cityPlaceId: string | null
	cityName: string | null
}
export type TLocationItem = {
	placeId: string
	title: string
}
export type TCareType = Option
export type TDesigner = Option
export type TSeason = Option
export type TColor = Option & {
	hex: string
}
export type TCountry = Option & {
	status: TStatus
}
export type TMaterial = Option
export type TCondition = {
	wardrobeItemId: TWardrobeItem['id']
	itemConditionId: TItemCondition['id']
	itemCondition: TItemCondition
}
export type TItemCondition = Option
export type TOccasionItem = Option
export type TOccasion = {
	occasionId: number
	wardrobeItemId: TWardrobeItem['id']
	occasion: TOccasionItem
	id: number
	title: string
}
export type TPassSelectScreenResult<T> = {
	[key in keyof T]: [Option['id'], Option | undefined]
}

export type TMktStatus = 'approved' | 'on-review' | 'rejected'
export type TMktCommentItem = {
	marketplaceInformationId: TMktInformation['id']
	commentId: TComment['id']
	comment: TComment
}
export type TComment = {
	id: number
	userId: TUser['id']
	text: string
	createdAt: UTCDate
	user: TProfileShort
	replies: TComment[]
}
export type TCommentForm = Pick<TComment, 'userId' | 'text'>
export type TMktInformation = {
	id: number
	itemId: number
	status: TMktStatus
	unviewedByUser: number
	unviewedByAdmin: number
	itemConditionId: TItemCondition['id']
	itemCondition: TItemCondition
	images: {
		marketplaceInformationId: number
		assetId: number
		asset: TAsset
	}[]
	comments: TMktCommentItem[]
}

export type TWardrobeItemForm = {
	id?: number
	name?: string | null
	description?: string | null
	parentId?: number | null
	status?: TWardrobeItemStatus | null
	categoryId?: TCategory['category']['id'] | null
	categoryIds?: TCategory['category']['id'][] | null
	attributeIds?: TItemAttribute['attribute']['id'][][] | null
	clothesStyleIds?: TClothesStyle['id'][] | null
	occasionIds?: TOccasion['id'][] | null
	assetIds?: TAsset['id'][] | null
	assets?: TAsset[] | null // temp for form handling
	brandId?: TBrand['id'] | null
	careTypeId?: TCareType['id'] | null
	designerId?: TDesigner['id'] | null
	seasonId?: TSeason['id'] | null
	storeId?: TStore['id'] | null
	colorId?: TColor['id'] | null
	sizeId?: number | null
	countryId?: TCountry['id'] | null
	materialId?: TMaterial['id'] | null
	itemConditionIds?: TItemCondition['id'][] | null
	year?: number | null // 1970.. | null
	pricePurchased?: number | null
	priceEstimated?: number | null
	priceActual?: number | null
	serialNumber?: number | null
	dustbag?: boolean | null
	receiptIncluded?: boolean | null
	authenticityCard?: boolean | null
	personalIdReference?: number | null
	location?: TLocation | null
	marketplaceInformation?: {
		assetIds: number[]
	}
	marketplaceInformationId?: string
	orderNumber?: string
	purchasedDate?: UTCDate
	isRecommended?: boolean
}
export enum EWishlistStatus {
	'absent',
	'present',
}
export type TStyle = {
	wardrobeItemId: TWardrobeItem['id']
	clothesStyleId: TClothesStyle['id']
	clothesStyle: TClothesStyle
}

export type TWardrobeItem = {
	id: number
	title: string
	userId: number
	user: TProfile
	name: string
	description: string | null
	parentId: number | null
	status: TWardrobeItemStatus | null
	categories: TCategory[] | null
	attributes: TItemAttribute[] | null
	clothesStyles: TStyle[] | null
	assets: TWardrobeItemImage[]
	assetIds: TWardrobeItemImage['asset']['id'] | null
	brand: TBrand
	brandId: TBrand['id'] | null
	careType: TCareType | null
	designer: TDesigner | null
	season: TSeason | null
	store: TStore | null
	color: TColor | null
	size: TSize
	sizeId: number
	country: TCountry | null
	material: TMaterial | null
	itemConditions: TCondition[] | null
	year: number | null
	pricePurchased: number | null
	priceEstimated: number | null
	priceActual: number | null
	serialNumber: number | null
	dustbag: boolean | null
	receiptIncluded: boolean | null
	authenticityCard: boolean | null
	personalIdReference: number | null
	occasions: TOccasion[] | null
	location: TLocation | null
	careTypeId: TCareType['id']
	designerId: TDesigner['id']
	seasonId: TSeason['id']
	storeId: TStore['id']
	colorId: TColor['id']
	countryId: TCountry['id']
	materialId: TMaterial['id']
	itemConditionId: TItemCondition['id']
	wardrobeItemId?: number
	marketplaceInformation: Omit<TMktInformation, 'comments'>
	inWishlists: EWishlistStatus
	asset: TAsset
	isArchived: boolean
	isBorrowed: 1 | 0
	isReadyToShip: boolean
	isRecommended: boolean
	purchasedDate: string
	orderNumber: string
	isRequestedByCurrentUser: boolean
	marketplaceInformationId: number | null
	createdAt: UTCDate
	updatedAt: UTCDate
}

export type TLocationOption = {
	id?: string
	title?: string
}
export type TLocationForm = {
	country?: TLocationOption
	city?: TLocationOption
}

export type TWardrobeItems = {
	id: number
	lookId: number
	orderNumber: number
	wardrobeItem: TWardrobeItem
	wardrobeItemId: number
}
export type TMktFilters = {
	'brand.id'?: TBrand['id'][]
	'size.crossSizeId'?: TSize['crossSizeId']
	'color.id'?: TColor['id'][]
	'itemCondition.id'?: TItemCondition['id'][]
	'occasions.occasion.id'?: TOccasionItem['id'][]
	'location.countryPlaceId'?: TLocation['countryPlaceId']
	'location.cityPlaceId'?: TLocation['cityPlaceId']
}
export type TMktFiltersData = {
	brand?: TBrand[]
	size?: TSize
	color?: TColor[]
	condition?: TItemCondition[]
	occasion?: TOccasionItem[]
	location?: TLocation
}
export type TChartsCatalogs = { [key in string]: TValueView[][] }

export type TWardrobeItemCardType = 'simple' | 'checkBox' | 'arrow' | 'borrow' | 'requested'

export type TConvertPriceRequestPayload = {
	value: number
	currency: string
}

export type TAddBrandForm = {
	brandName: TBrand['title'] // TODO Change brandName to real field name.
}
export type TSuggestedBrandStatus = 'rejected' | 'on-review' | 'approved'
export type TSuggestedBrand = {
	id: number
	userId: TUser['id']
	user: TUser
	title: string
	status: TSuggestedBrandStatus
	brandId: TBrand['id'] | null
}
