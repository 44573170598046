import {
	Avatar,
	Button,
	Card,
	Col,
	ErrorBoundary,
	Popconfirm,
	Row,
	Tag,
	CategorizedFilterRuleList,
	When,
} from '@components'
import { compileImageUri, toNumber } from '@helpers'
import { useHistory, useParams, useTranslation } from '@hooks'
import { TCategorizedFilter, TUpdateCategorizedFilter, TUseEntityListItemAPI } from '@typings'
import * as React from 'react'

/** Display categorized filter item content. */
const CategorizedFilterItemScreen: React.FC<TProps> = ({ CategorizedFilter }) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const categorizedFilterId = params?.id ? toNumber(params?.id) : undefined

	const { title, asset, status, subCategorizedFilterCount } = CategorizedFilter.data || {}
	const filters = React.useMemo(() => CategorizedFilter.data?.filters.map((filterItem) => filterItem.filter), [
		CategorizedFilter.data,
	])

	const goToEdit = () => history.push(`/categorized-filters/${categorizedFilterId}/edit`)
	const goToAddFilterItem = () => history.push(`/categorized-filters/${categorizedFilterId}/rules/add`)
	const isArchivedCategory = title?.toLocaleLowerCase() === 'archive'

	return (
		<ErrorBoundary>
			<Card
				title={
					<Card.Meta
						avatar={<Avatar src={compileImageUri(asset, true)} size="large" shape="square" />}
						title={title}
						description={
							<>
								<span>
									{t('{{count}} subcategories', {
										count: subCategorizedFilterCount,
									})}
								</span>
								{` `}
								<Tag color={status === 'active' ? 'success' : 'error'}>{status}</Tag>
							</>
						}
					/>
				}
				extra={
					// <When condition={!isArchivedCategory}>
					<Row gutter={16}>
						<Col>
							<Popconfirm
								title={t('Are you sure?')}
								onConfirm={CategorizedFilter.remove}
								okText={t('Yes')}
								cancelText={t('No')}
							>
								<Button type="link" danger>
									{t('Remove')}
								</Button>
							</Popconfirm>
						</Col>
						<Col>
							<Button onClick={goToEdit}>{t('Edit')}</Button>
						</Col>
						<Col>
							<Button type="primary" onClick={goToAddFilterItem}>
								{t('Add rule')}
							</Button>
						</Col>
					</Row>
					// </When>
				}
			>
				<CategorizedFilterRuleList
					filters={filters}
					loading={CategorizedFilter.fetching}
					disabled={isArchivedCategory}
				/>
			</Card>
		</ErrorBoundary>
	)
}
type TParams = { id?: string }
type TProps = {
	/** API for manipulating category. */
	CategorizedFilter: TUseEntityListItemAPI<TCategorizedFilter, TUpdateCategorizedFilter>
}

export default CategorizedFilterItemScreen
