import React from 'react'
import { Avatar, Icons, Link } from '@components'
import { TRole, TStatus, TUser, TLookbook, TWardrobeItem, TLook } from '@typings'
import { formatToDay, compileImageUri } from '@helpers'

const sorter = () => 0

type TComment = {
	lookbooks: { lookbook: TLookbook; lookbookId }[]
	wardrobeItems: { wardrobeItem: TWardrobeItem; wardrobeItemId }[]
	looks: { look: TLook; lookId }[]
}

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
	},
	{
		title: 'User',
		dataIndex: 'user',
		width: 100,
		render: (user: TLookbook['user']) => (
			<Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
		),
	},
	{
		title: 'Comment',
		dataIndex: 'text',
		width: 200,
		sorter,
		searchKey: 'text',
		render: (comment) => <p>{comment.replace(/<usertag data-user-id="(.*?)">|<\/usertag>/gi, '')}</p>,
	},
	{
		title: 'Wardrobe Item',
		dataIndex: 'wardrobeItems',
		width: 100,
		render: (wardrobeItems: TComment['wardrobeItems']) =>
			wardrobeItems.length ? (
				<Link
					to={`/wardrobe-items/${wardrobeItems[0]?.wardrobeItemId}`}
				>{`${wardrobeItems[0]?.wardrobeItem?.name}`}</Link>
			) : (
				<p>-----</p>
			),
	},
	{
		title: 'LookBook',
		dataIndex: 'lookbooks' || 'wardrobeItems',
		width: 100,
		render: (lookbooks: TComment['lookbooks']) =>
			lookbooks.length ? (
				<Link to={`/lookbooks/${lookbooks[0]?.lookbookId}`}>{`${lookbooks[0]?.lookbook?.title}`}</Link>
			) : (
				<p>-----</p>
			),
	},
	{
		title: 'Look',
		dataIndex: 'looks',
		width: 100,
		render: (looks: TComment['looks']) =>
			looks.length ? (
				<Link to={`/lookbooks/${looks[0]?.look.lookbookId}/looks/${looks[0]?.lookId}`}>{`${
					looks[0]?.look?.title || 'no name'
				}`}</Link>
			) : (
				<p>-----</p>
			),
	},
	{
		title: 'Post',
		dataIndex: 'posts',
		width: 100,
		render: (posts: TComment['looks']) =>
			posts.length ? (
				<Link to={`/posts/${posts[0]?.postId}`}>{`${posts[0]?.post?.title || 'no name'}`}</Link>
			) : (
				<p>-----</p>
			),
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
