import React from 'react'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import { omit } from '@helpers'

/** Context for saving values of table search inputs and avoid re-rendering table after change inputs values */
const Context = React.createContext<TTableInputsContext>({
	columnSearch: {},
	updateColumnSearch: () => {},
})

export const TableInputsProvider = ({ children, initialValues = {} }: TTableInputsProviderProps) => {
	const [columnSearch, setColumnSearch] = React.useState(
		omit(initialValues, (value) => isPlainObject(value) || isArray(value)),
	)
	const updateColumnSearch: TTableInputsContext['updateColumnSearch'] = (columnName, value) =>
		setColumnSearch((state) => ({ ...state, [columnName]: value }))

	return <Context.Provider value={{ columnSearch, updateColumnSearch }}>{children}</Context.Provider>
}

export const TableInputConsumer = ({ children, dataIndex, onInput }: TTableInputConsumerProps) => {
	const { columnSearch, updateColumnSearch } = React.useContext<TTableInputsContext>(Context)
	const value = columnSearch[dataIndex]
	const onChange = React.useCallback((value) => {
		const newValue = value || undefined
		updateColumnSearch(dataIndex, newValue)
		onInput?.(dataIndex, newValue)
	}, [])

	return children({ value, onChange })
}

type TTableInputsContext = {
	columnSearch: { [key: string]: string | undefined }
	updateColumnSearch: (columnName: string, value: string | undefined) => void
}

type TTableInputsProviderProps = {
	children: React.ReactNode
}

type TTableInputConsumerProps = {
	children: ({
		value,
		onChange,
	}: {
		value: string | undefined
		onChange: (value: string | undefined) => void
	}) => React.ReactElement | null
	dataIndex: string
	onInput?: TTableInputsContext['updateColumnSearch']
}
