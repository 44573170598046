import { Avatar, List, Skeleton } from '@components'
import { compileImageUri } from '@helpers'
import { ListItemProps, TLookbook } from '@typings'
import React from 'react'

/** Component for single item of ItemList. */
const LookbookListRow: React.FC<TProps> = ({ item, actions, loading = false }) => {
	const { description } = item
	const imageSource = compileImageUri(item.asset, true)

	return (
		<List.Item actions={actions}>
			<Skeleton avatar title={false} loading={loading} active>
				<List.Item.Meta avatar={<Avatar src={imageSource} />} title={item.title} description={description} />
				<div>{item.id}</div>
			</Skeleton>
		</List.Item>
	)
}

type TProps = {
	/** Single wardrobe item.  */
	item: TLookbook
	/** Set of action for list item. */
	actions: ListItemProps['actions']
	/** Loading item status. If one equals to false then Skeleton will be displayed. */
	loading?: boolean
}

export default React.memo(LookbookListRow)
