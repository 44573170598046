import {
	BulkUploadProcessModal,
	Button,
	DataTable,
	ErrorBoundary,
	Icons,
	RemoveRowLink,
	Card,
	Form,
	Input,
	Radio,
	BulkUploadUsersProcessModal,
} from '@components'
import { useCallback, useHistory, useRef, useRequest, useTranslation } from '@hooks'
import { api, message } from '@services'
import {
	TBulkUpload,
	TBulkUploadValidation,
	TFilteredRequest,
	TPost,
	TResponseWithFilters,
	TWardrobeItem,
	TSpreadSheetForUsersBulkUpload,
} from '@typings'
import * as React from 'react'
import { columns, formFieldRules } from './BulkUpload.tableOptions'
import { showFormErrors } from '@helpers'
const layout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 14 },
}

const BulkUploadUsersScreen: React.FC<TProps> = () => {
	const { t } = useTranslation()
	const tableRef = React.useRef<any>()
	const history = useHistory()
	const [form] = Form.useForm()

	// API data.
	const { data: { data: posts, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TPost[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>({
		request: (params) => api.getUserBulkUploads({ ...params, order: ['id', 'desc'] }),
		onError: (error) => message.error(error.message),
	})

	// Bulk upload process.
	const processModal = React.useRef<any>()
	const { fetch: runUserBulkUpload, data: bulkUploadValidation, fetching: bulkUploading } = useRequest<
		TBulkUploadValidation,
		TBulkUpload
	>({
		request: (bulkUpload) => api.runUserBulkUpload(bulkUpload),
		onRequest: () => processModal.current?.open(),
		onSuccess: (validationResult) => {
			// if (!validationResult.errors) tableRef.current?.refresh()
		},
		onError: (error) => message.error(error.message),
	})
	console.log(bulkUploadValidation, 'bulkUploadValidationbulkUploadValidationbulkUploadValidation')

	//Create Users Bulk Upload
	const { fetch: submitForm, fetching: submittingForm, data } = useRequest<
		TSpreadSheetForUsersBulkUpload,
		TSpreadSheetForUsersBulkUpload
	>({
		request: api.createSpreadSheetForUsersBulkUpload,
		onSuccess: (validationResult) => {
			form.resetFields(), fetch({})
		},
		onError: (error) => message.error(error.message),
	})

	return (
		<ErrorBoundary>
			<Card title={t('New bulk upload')}>
				<Form form={form} {...layout} onFinish={submitForm} onFinishFailed={showFormErrors}>
					<Form.Item label={t('Title')} name="title" rules={formFieldRules.title}>
						<Input disabled={fetching} />
					</Form.Item>
					<Form.Item label={t('Receiver email')} name="receiver_email" rules={formFieldRules.email}>
						<Input disabled={fetching} />
					</Form.Item>
					<Button
						type="primary"
						icon={<Icons.PlusCircleOutlined />}
						size="middle"
						htmlType="submit"
						loading={submittingForm}
					>
						{t('Create')}
					</Button>
				</Form>
			</Card>
			<DataTable<TPost, Partial<TPost>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={posts}
				meta={meta}
				onChange={fetch}
				renderActionsComponentWidth={350}
				renderActionsComponent={(item: TBulkUpload) => {
					return [
						<Form
							layout="inline"
							onFinish={(form) => runUserBulkUpload({ id: item.id, ...form })}
							initialValues={{ role: 'user' }}
						>
							<Form.Item name="role">
								<Radio.Group disabled={item.isUploaded}>
									<Radio value={'user'}>{t('Active')}</Radio>
								</Radio.Group>
							</Form.Item>
							<Button
								key="1"
								type="link"
								htmlType="submit"
								disabled={item.isUploaded}
								//onClick={() => runUserBulkUpload(item)}
							>
								{t('Upload')}
							</Button>
							<RemoveRowLink
								key="2"
								id={item.id}
								request={api.removeUserBulkUpload}
								onSuccess={tableRef.current?.refresh}
							/>
						</Form>,
					]
				}}
			/>
			<BulkUploadUsersProcessModal
				ref={processModal}
				bulkUploadValidation={bulkUploadValidation}
				loading={bulkUploading}
				description={t('Trying to create users')}
				type="users"
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default BulkUploadUsersScreen
