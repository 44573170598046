import { Tooltip } from 'antd'
import { CompositeDecorator } from 'draft-js'
import React from 'react'

/** Decorator for putting custom component in spite of plain <a /> tag. */
export const editorDecorator = new CompositeDecorator([
	{
		strategy: (contentBlock, callback, contentState) => {
			contentBlock.findEntityRanges((character) => {
				const entityKey = character.getEntity()
				return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
			}, callback)
		},
		component: ({ children, contentState, entityKey }) => {
			const { url } = contentState.getEntity(entityKey).getData()
			return React.createElement(
				Tooltip,
				{ placement: 'top', title: url },
				React.createElement(
					'a',
					{
						href: url,
						style: { color: '#3b5998', textDecoration: 'underline', title: url, target: '_blank' },
					},
					children,
				),
			)
		},
	},
])
