import { i18n } from '@services'
import { TBrand } from 'src/typings/wardrobe'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { offset: 6, span: 12 }

export const formFieldRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
}

export const initialValues: Partial<TBrand> = { status: 'active' }
