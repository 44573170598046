import { ErrorBoundary, Radio } from '@components'
import * as React from 'react'
import styles from './ApiDocumentation.module.css'
import { useTranslation, useState } from '@hooks'

/** Documentation section with embedded styleguidist application. */
const APIDocumentationScreen: React.FC<TProps> = () => {
	const { t } = useTranslation()
	const [version, setVersion] = useState('v1', 'version')

	const onChangeVersion = (e) => {
		setVersion(e.target.value)
	}
	return (
		<ErrorBoundary>
			<Radio.Group onChange={onChangeVersion} value={version}>
				<Radio value={'v1'}>{t('Version 1')}</Radio>
				<Radio value={'v2'}>{t('Version 2')}</Radio>
			</Radio.Group>
			<div
				className={styles.iFrameContainer}
				dangerouslySetInnerHTML={{
					__html: `<iframe src="https://api-staging.littleblackdoor.io/${version}/api-docs/" width="100%" ></iframe>`,
				}}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default APIDocumentationScreen
