import { Switcher } from '@components'
import { useTranslation } from '@hooks'
import { SwitchChangeEventHandler, TStatus } from '@typings'
import React from 'react'

const StatusSwitcher: React.FC<TProps> = ({ value: status, loading = false, disabled = false, onChange }) => {
	const { t } = useTranslation()
	const checked = status === 'active'
	const handleChange: SwitchChangeEventHandler = (checked) => onChange?.(checked ? 'active' : 'disabled')

	return (
		<Switcher
			loading={loading}
			checked={checked}
			checkedChildren={t('Active')}
			unCheckedChildren={t('Disabled')}
			onChange={handleChange}
			disabled={disabled}
		/>
	)
}

type TProps = {
	/** Status value. ('active' | 'disabled') */
	value?: TStatus
	/** Function that called when switcher toggled. It pass new status ('active' | 'disabled') */
	onChange?: (status: TStatus) => void
	/** Should display loading indicator */
	loading?: boolean
	disabled?: boolean
}

export default StatusSwitcher
