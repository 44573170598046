import { i18n } from '@services'
import { TLook, TLookForm, TUpdateLook } from '@typings'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 12 },
}
export const formButtonLayout = { offset: 6, span: 12 }
export const formFieldRules = {
	assets: [{ required: true, message: i18n.t('Images are required') }],
}

export const normalize = (value: any) => value ?? null

export const castLookToForm = (look: TLook): TLookForm => ({
	...look,
	assets: look.assets.map((lookImage) => lookImage.asset),
})
export const prepareForm = (form: TLookForm, lookId?: TLook['id']): TUpdateLook => {
	const { assets, ...formValues } = form

	return {
		...formValues,
		assetIds: assets.map((asset) => asset.id),
		id: lookId,
	}
}
