import { DataTable, Table } from '@components'
import { useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TResponseWithFilters, TShopifyOrder, TWardrobeItem } from '@typings'
import * as React from 'react'
import { columns, wardrobeItemsColumns } from './MarketplaceOrders.tableOptions'

const MarketplaceOrdersScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = React.useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getOrders(searchParams),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	return (
		<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
			ref={tableRef}
			bordered
			loading={fetching}
			columns={columns}
			dataSource={wardrobeItems}
			meta={meta}
			searchInputValues={searchParams}
			autoFetchOnMount={false}
			onChange={setSearchParams}
			expandable={{
				expandedRowRender: (record: TShopifyOrder) => (
					<Table
						key={record.id}
						rowKey="id"
						columns={wardrobeItemsColumns}
						dataSource={record.wardrobeItems}
						pagination={false}
					/>
				),
			}}
		/>
	)
}

type TProps = {
	/** */
}

export default MarketplaceOrdersScreen
