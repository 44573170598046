const Images: IHashMap<any> = {
	// Surveys
	TWO_OPTIONS: require('./two-options.png'),
	THREE_OPTIONS: require('./three-options.png'),
	ONE_IMAGE: require('./one-image.png'),
}
export interface IHashMap<T> {
	[key: string]: T
}

export default Images
