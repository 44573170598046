import { useEffect, useHistory } from '@hooks'
import * as Sentry from '@sentry/browser'
import React from 'react'

const RoutingListenManager: React.FC<TProps> = () => {
	const history = useHistory()
	useEffect(() => {
		return history.listen((location, action) => {
			Sentry.addBreadcrumb({
				category: 'navigation',
				message: location.pathname,
				data: location.state ?? {},
				level: Sentry.Severity.Info,
			})
		})
	})

	return null
}

type TProps = {
	/**  */
}

export default RoutingListenManager
