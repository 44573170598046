import { Button, Card, Col, ErrorBoundary, Form, Input, Row, ServerSelector } from '@components'
import { useAuthContext, useTranslation } from '@hooks'
import React from 'react'
import { showFormErrors } from '@helpers'
import { formFieldRules, formLayout, formButtonLayout, rowStyle } from './Login.formOptions'

/** Screen with authorization form. */
function LoginScreen() {
	const { t } = useTranslation()
	const { login, logging } = useAuthContext()

	return (
		<ErrorBoundary>
			<Row align="middle" justify="center" style={rowStyle}>
				<Col span={10}>
					<Card title="Login" bordered={false}>
						<Form {...formLayout} name="basic" onFinish={login} onFinishFailed={showFormErrors}>
							<Form.Item label="Email" name="email" rules={formFieldRules.email}>
								<Input disabled={logging} />
							</Form.Item>

							<Form.Item label="Password" name="password" rules={formFieldRules.password}>
								<Input.Password disabled={logging} />
							</Form.Item>

							<Form.Item wrapperCol={formButtonLayout}>
								<ServerSelector />
							</Form.Item>

							<Form.Item wrapperCol={formButtonLayout}>
								<Button type="primary" htmlType="submit" loading={logging}>
									{t('Submit')}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</ErrorBoundary>
	)
}

export default LoginScreen
