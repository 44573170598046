import { Col, Form, Radio, RangePickerField, Row, StatisticsBarChart, StatisticStackedBarChart } from '@components'
import { useRequest, useTranslation } from '@hooks'
import { TResponseWithFilters, TStatisticsChart, TStatisticsSearchParams } from '@typings'
import React from 'react'
import { requestsMap } from './CommonStatisticsChart.utils'

const CommonStatisticsChart: React.FC<TProps> = ({ chart, searchParams, onError }) => {
	const { t } = useTranslation()

	const chartSettings = requestsMap[chart]

	// Fetch chart data from API.
	const { data: { data = [] } = {}, fetching } = useRequest<TResponseWithFilters<TStatisticsChart[]>>(
		{
			request: () => chartSettings.request(searchParams) || onError(t('Unknown path')) || null,
			onError: (error) => onError(error.message),
		},
		[chart, searchParams],
	)

	return (
		<>
			<Row gutter={16}>
				<Col>
					<Form.Item name="groupBy">
						<Radio.Group>
							<Radio.Button value="day">{t('per day')}</Radio.Button>
							<Radio.Button value="week">{t('per week')}</Radio.Button>
							<Radio.Button value="month">{t('per month')}</Radio.Button>
							<Radio.Button value="year">{t('per year')}</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col>
					<Form.Item name="date">
						<RangePickerField />
					</Form.Item>
				</Col>
			</Row>
			<Row>
				{chart === 'newCommentsByCategory' ? (
					<StatisticStackedBarChart
						data={data}
						period={searchParams.groupBy}
						loading={fetching}
						bars={['count']}
					/>
				) : (
					<StatisticsBarChart
						data={data}
						period={searchParams.groupBy}
						loading={fetching}
						bars={['count']}
						barLabels={chartSettings?.barLabels}
					/>
				)}
			</Row>
		</>
	)
}

type TProps = {
	/**  */
	searchParams: TStatisticsSearchParams
	chart: keyof typeof requestsMap
	onError: (errorMessage: string) => void
}

export default CommonStatisticsChart
