import { TRequestBuilder } from '@typings'
import { upDataTransformer } from '../transformers'

export const searchCountry: TRequestBuilder<{ query: string }> = ({ query }) => ({
	method: 'get',
	url: '/services/google-map/countries',
	params: { input: query },
	transformResponse: upDataTransformer,
})
export const searchCity: TRequestBuilder<{ query: string; countryPlaceId: string }> = ({ query, countryPlaceId }) => ({
	method: 'get',
	url: '/services/google-map/cities',
	params: { input: query, countryPlaceId },
	transformResponse: upDataTransformer,
})
