import { Button, Descriptions, List, Tag, When } from '@components'
import { useCallback, useTranslation, useHistory, useParams } from '@hooks'
import { TCategorizedFilterRule } from '@typings'
import { toNumber } from '@helpers'
import cx from 'classnames'
import React from 'react'
import styles from './CategorizedFilterRuleList.module.css'

/** Displaying list of filters in categorized filter item. */
const CategorizedFilterRuleList: React.FC<TProps> = ({ filters = [], loading = false, disabled = false }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const categorizedFilterId = params?.id ? toNumber(params?.id) : undefined

	// Actions.
	const goToEditFilterItem = (ruleId: number) =>
		history.push(`/categorized-filters/${categorizedFilterId}/rules/${ruleId}`)

	return (
		<List
			loading={loading}
			itemLayout="horizontal"
			dataSource={filters}
			renderItem={useCallback(
				({
					id,
					categories,
					attributes,
					clothesStyles,
					occasions,
					brands,
					designers,
					seasons,
					stores,
					materials,
					colors,
				}: TCategorizedFilterRule) => {
					return (
						<List.Item
							actions={[
								// <When condition={!disabled}>
								<Button key="edit" type="link" onClick={() => goToEditFilterItem(id)}>
									{t('edit')}
								</Button>,
								// </When>,
							]}
						>
							<Descriptions column={1} title={t('Rule #{{id}}', { id })} size="small" bordered>
								<Descriptions.Item
									label={t('Categories')}
									className={cx({ [styles.hidden]: !categories.length })}
								>
									{categories?.map((item) => (
										<Tag key={item.category.id} color="orange">
											{item.category.title}
										</Tag>
									))}
								</Descriptions.Item>
								<Descriptions.Item
									label={t('Attributes')}
									className={cx({ [styles.hidden]: !attributes.length })}
								>
									{attributes?.map((item) => (
										<Tag key={item.attribute.id} color="purple">
											{item.attribute.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Clothes styles')}
									className={cx({ [styles.hidden]: !clothesStyles.length })}
								>
									{clothesStyles?.map((item) => (
										<Tag key={item.clothesStyle.id} color="orange">
											{item.clothesStyle.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Occasions')}
									className={cx({ [styles.hidden]: !occasions.length })}
								>
									{occasions?.map((item) => (
										<Tag key={item.occasion.id} color="volcano">
											{item.occasion.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Brands')}
									className={cx({ [styles.hidden]: !brands.length })}
								>
									{brands?.map((item) => (
										<Tag key={item.brand.id} color="volcano">
											{item.brand.title}
										</Tag>
									))}
								</Descriptions.Item>
								<Descriptions.Item
									label={t('Designers')}
									className={cx({ [styles.hidden]: !designers.length })}
								>
									{designers?.map((item) => (
										<Tag key={item.designer.id} color="gold">
											{item.designer.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Seasons')}
									className={cx({ [styles.hidden]: !seasons.length })}
								>
									{seasons?.map((item) => (
										<Tag key={item.season.id} color="geekblue">
											{item.season.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Stores')}
									className={cx({ [styles.hidden]: !stores.length })}
								>
									{stores?.map((item) => (
										<Tag key={item.store.id} color="geekblue">
											{item.store.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Materials')}
									className={cx({ [styles.hidden]: !materials.length })}
								>
									{materials?.map((item) => (
										<Tag key={item.material.id} color="lightblue">
											{item.material.title}
										</Tag>
									))}
								</Descriptions.Item>

								<Descriptions.Item
									label={t('Colors')}
									className={cx({ [styles.hidden]: !colors.length })}
								>
									{colors?.map((item) => (
										<Tag key={item.color.id} color="lightblue">
											{item.color.title}
										</Tag>
									))}
								</Descriptions.Item>
							</Descriptions>
						</List.Item>
					)
				},
				[loading],
			)}
		/>
	)
}

type TParams = { id?: string }
type TProps = {
	/** List of categorized filters.  */
	filters: TCategorizedFilterRule[] | undefined
	/** Loading state. */
	loading?: boolean
	disabled?: boolean
}

export default CategorizedFilterRuleList
