import { Button, DataTable, ErrorBoundary, Icons } from '@components'
import { useCallback, useHistory, useRef, useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TStore, TFilteredRequest, TResponseWithFilters } from '@typings'
import * as React from 'react'
import { columns } from './StoresList.tableOptions'

const StoresListScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Obtain data.
	const { data: { data: stores, meta } = {}, fetch, fetching, fetched } = useRequest<
		TResponseWithFilters<TStore[]>,
		TFilteredRequest & Partial<TStore>
	>(
		{
			request: () => api.getStoresTable(searchParams),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddStore = useCallback(() => history.push('/stores/add'), [])

	return (
		<ErrorBoundary>
			<DataTable<TStore, Partial<TStore>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={stores}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToAddStore}>
						{t('Add store')}
					</Button>
				}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default StoresListScreen
