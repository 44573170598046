import * as React from 'react'
import { Modal } from '@components'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends React.Component {
	state = { hasError: false }

	static getDerivedStateFromError(error: Error) {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: any) {
		console.error(error)
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo)
		})
		Modal.error({
			title: 'Error',
			content: error.message,
		})
	}

	render() {
		if (this.state.hasError) {
			return <span>Something went wrong...</span>
		}

		return this.props.children
	}
}

export default ErrorBoundary
