import { ArticleLookbooksBlockRow, When, WithRemoveButton } from '@components'
import { useBoolean, useRequest } from '@hooks'
import { api } from '@services'
import { TResponseWithFilters, TWardrobeItem, TLookbook } from '@typings'
import React from 'react'
import ArticleLookbooksBlockDialog from '../ArticleLookbooksBlockDialog'
import styles from './ArticleLookbooksBlock.module.css'

const ArticleLookbooksBlock: React.FC<TProps> = ({ data, container, blockProps }) => {
	const ids = data.ids as TWardrobeItem['id'][]
	const { data: response } = useRequest<TResponseWithFilters<TLookbook[]>>(
		{
			request: () => api.getLookbooks({ id: ids }),
		},
		[ids],
	)
	const wardrobeItems = response?.data || []

	const isVisibleBoolean = useBoolean(false)

	return (
		<WithRemoveButton onRemove={container.remove}>
			<div className={styles.itemsContainer} onClick={isVisibleBoolean.toggle}>
				{wardrobeItems.map((item) => (
					<ArticleLookbooksBlockRow key={item.id} item={item} />
				))}
			</div>

			<When condition={isVisibleBoolean.value}>
				<ArticleLookbooksBlockDialog
					initialValue={ids}
					onChange={blockProps.onChange}
					updateData={container.updateData}
					editorState={blockProps.editorState}
					onClose={isVisibleBoolean.setFalse}
				/>
			</When>
		</WithRemoveButton>
	)
}

type TProps = {
	/** Any data passed into insertDataBlock function after editorState */
	data: any
	/** Instance of Media Component with helper functions remove to remove the block from editorState and updateData to update plugin data */
	container: any
	/** Object containing editorState, ReadOnly getters & setters from MegadraftEditor, onChange as a proxy to container.updateData, as well as the defined plugin object structure. */
	blockProps: any
}

export default ArticleLookbooksBlock
