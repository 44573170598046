import React from 'react'
import { useTranslation } from '@hooks'
import { Title, Text } from '@components'
import styles from './LayoutLogo.module.css'

const LayoutLogo: React.FC<TProps> = () => {
	const { t } = useTranslation()

	return (
		<Title level={2} style={{ color: 'white', margin: 0 }}>
			{t('LBD Admin')}
		</Title>
	)
}

type TProps = {
	/**  */
}

export default LayoutLogo
