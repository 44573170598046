import React from 'react'
import styles from './ImageSquare.module.css'

const ImageSquare: React.FC<TProps> = ({ alt = '', ...props }) => {
	return <img {...props} className={styles.image} alt={alt} style={props.style} />
}

type TProps = React.ImgHTMLAttributes<HTMLImageElement>

export default ImageSquare // src={src}
