import { Button, Card, ErrorBoundary, Form, Input, DefferedUploadField, When } from '@components'
import { showFormErrors, chunk } from '@helpers'
import { useHistory, useRequest, useTranslation, useState } from '@hooks'
import { api, message, apiRequest } from '@services'
import { TBulkUpload, TAsset, TMakeGoogleSheetFromBulkUpload, AxiosRequestConfig, RcFile } from '@typings'
import * as React from 'react'
import { formButtonLayout, formFieldRules, formLayout } from './AddBulkUpload.formOptions'
import { Progress, Space } from 'antd'

const IMAGE_UPLOAD_CHUNK_SIZE = 10

/** Form for making Google Sheet in order to bulk upload wardrobe items to API. */
const AddBulkUploadScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Actions.
	const [progressSteps, setProgressSteps] = useState(0, '')
	const [progressPercent, setProgressPercent] = useState(0, '')

	const { fetch: makeGoogleSheetFromBulkUpload, fetching } = useRequest<TBulkUpload, TMakeGoogleSheetFromBulkUpload>({
		request: (formValues) => api.makeGoogleSheetFromBulkUpload(formValues),
		onSuccess: (bulkUpload) => {
			const newTab = window.open()
			if (newTab) newTab.location.href = bulkUpload.link
			history.goBack()
			message.success(t('Google sheet has been created successfully.'))
		},
		onError: (error) => message.error(error.message),
	})

	const submit = async ({ title, assets }) => {
		setProgressSteps(assets.length)
		const progressStepSize = Math.ceil(100 / assets.length)
		const uploadedImages: TAsset[] = []

		const imageChunks = chunk<RcFile>(assets, IMAGE_UPLOAD_CHUNK_SIZE)
		// eslint-disable-next-line no-restricted-syntax
		for (const imageChunk of imageChunks) {
			const uploadTasks = imageChunk.map(
				(image) =>
					new Promise((resolve, reject) => {
						apiRequest(api.uploadImage(image) as AxiosRequestConfig)
							.then((response) => uploadedImages.push(response as TAsset))
							.then(() => setProgressPercent((percent) => Math.min(percent + progressStepSize, 100)))
							.then(resolve)
							.catch(reject)
					}),
			)
			await Promise.all(uploadTasks) // eslint-disable-line no-await-in-loop
		}

		setProgressSteps(0)
		const assetIds = uploadedImages.map((image) => image.id)
		makeGoogleSheetFromBulkUpload({ title, assetIds })
	}

	return (
		<ErrorBoundary>
			<Card title={t('New bulk upload')}>
				<Form onFinish={submit} onFinishFailed={showFormErrors} {...formLayout}>
					<Form.Item label={t('Title')} name="title">
						<Input disabled={fetching} />
					</Form.Item>
					<Form.Item
						required
						name="assets"
						rules={formFieldRules.assets}
						label={t('Images')}
						wrapperCol={{ span: 15 }}
					>
						<DefferedUploadField />
					</Form.Item>

					<Form.Item wrapperCol={formButtonLayout}>
						<Space direction="vertical">
							<When condition={!!progressSteps}>
								<Progress
									type="circle"
									status="active"
									percent={progressPercent}
									format={(percent) => (percent === 100 ? t('Done') : `${percent}%`)}
								/>
							</When>
							<Button type="primary" htmlType="submit" disabled={progressSteps > 0} loading={fetching}>
								{t('Submit')}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default AddBulkUploadScreen
