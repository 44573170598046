import { Radio } from '@components'
import { useMediatedState, useMemo } from '@hooks'
import React from 'react'
import { apiSettings } from '@services'
import { TAPIServers, RadioChangeEvent } from '@typings'

/** Selector for switching between API servers.  */
const ServerSelector: React.FC<TProps> = () => {
	const [serverName, setServerName] = useMediatedState<TAPIServers>((event: RadioChangeEvent) => {
		const serverName = event.target.value
		apiSettings.setOption(serverName)
		return serverName
	}, apiSettings.getSettings('API_SERVER_NAME') as TAPIServers)
	const serverList = useMemo(() => Object.keys(apiSettings.getOptions()), [])

	return (
		<Radio.Group value={serverName} onChange={setServerName}>
			{serverList.map((server) => (
				<Radio key={server} value={server}>
					{server}
				</Radio>
			))}
		</Radio.Group>
	)
}

type TProps = {
	/**  */
}

export default ServerSelector
