import React from 'react'

const sizes: Record<TSize, string> = {
	small: '8px',
	medium: '16px',
	large: '24px',
}

/** Put free space between components. */
const Gap: React.FC<TProps> = ({ size = 'medium' }) => {
	return <div style={{ height: sizes[size] || 0 }} />
}

type TSize = 'small' | 'medium' | 'large'
type TProps = {
	/** Small or medium or large gap between components. Default: medium. */
	size?: TSize
}

export default Gap
