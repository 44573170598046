import { DataTable, Card, Title, Descriptions } from '@components'
import { toNumber, formatPrice } from '@helpers'
import { useParams, useRequest, useTranslation, useMemo } from '@hooks'
import { api, message } from '@services'
import { TResponseWithFilters, TWardrobeItem, TFilteredRequest, TUserStatistics } from '@typings'
import * as React from 'react'
import { columns } from './UserViewWardrobeItems.tableOptions'

const UserViewWardrobeItemsTab: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const params = useParams<TParams>()
	const userId = toNumber(params?.id)
	const tableRef = React.useRef()

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>({
		request: (params) => api.getWardrobeItems({ ...params, userId }),
		onError: (error) => message.error(error.message),
	})

	//getUsersStatistic
	const { data: statistic } = useRequest<TWardrobeItem[], TFilteredRequest & Partial<TWardrobeItem>>(
		{
			request: (params) => api.getUsersStatistic({ ...params, userId }),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const { data: archiveStatistic } = useRequest<TUserStatistics, void>(
		{
			request: () => api.getUsersStatistic({ userId, isArchived: true }),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const totalArchiveSpent = formatPrice(
		archiveStatistic?.reduce((sum, item) => sum + Number(item?.pricePurchased), 0),
	)

	const totalSpend = useMemo(
		() => formatPrice(statistic?.map((stat) => stat.pricePurchased).reduce((a, b) => Number(a) + Number(b), 0)),
		[statistic],
	)

	return (
		<Card>
			<Descriptions
				bordered={false}
				title={t('Total wardrobe spend : {{totalSpend}}', { totalSpend })}
				size="small"
			/>
			<Descriptions
				bordered={false}
				title={t('Total archived  : {{totalArchiveSpent}}', { totalArchiveSpent })}
				size="small"
			/>
			<Descriptions
				bordered={false}
				title={t('Amount of items : {{amount}}', { amount: meta?.totalCounts })}
				size="small"
			/>
			<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={wardrobeItems}
				meta={meta}
				onChange={fetch}
			/>
		</Card>
	)
}

type TProps = {
	/** */
}
type TParams = { id?: string }

export default UserViewWardrobeItemsTab
