import React from 'react'
import { Avatar, Icons, Link } from '@components'
import { TRole, TStatus, TUser, TLookbook, TWardrobeItem, TLook } from '@typings'
import { formatToDay, compileImageUri } from '@helpers'

const sorter = () => 0

type TComment = {
	lookbooks: { lookbook: TLookbook; lookbookId }[]
	wardrobeItems: { wardrobeItem: TWardrobeItem; wardrobeItemId }[]
	looks: { look: TLook; lookId }[]
}

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		fixed: 'left',
		sorter,
	},
	{
		title: 'User',
		dataIndex: 'user',
		width: 100,
		render: (user: TLookbook['user']) => (
			<Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
		),
	},
	{
		title: 'Comment',
		dataIndex: 'text',
		width: 200,
		sorter,
		render: (comment) => <p>{comment.replace(/<usertag data-user-id="(.*?)">|<\/usertag>/gi, '')}</p>,
	},

	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
