import { TStatisticsSearchParams } from '@typings'
import { putSearchParamToLocation } from '@helpers'

/** Build Record with request payload. */
export const defineSearchParams = (locationSearch: string): TStatisticsSearchParams => {
	const parsedSearch = Object.fromEntries(new URLSearchParams(locationSearch).entries())
	const parsedDate = parsedSearch?.date?.split(',')
	const date = parsedDate?.length === 2 ? parsedDate : undefined

	return {
		groupBy: 'day',
		offset: 0,
		...parsedSearch,
		date,
		limit: date ? undefined : 12,
		order: ['startDate', 'desc'],
	}
}

/** Put values to routing location. */
export const collectSearchParamsToLocation = (location, changedValues: Record<string, any>) =>
	Object.entries(changedValues).reduce((loc, [key, value]) => putSearchParamToLocation(loc, key, value), location)
