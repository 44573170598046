import i18n from 'i18next'

export type TTabs = 'account' | 'body' | 'style' | 'billing_address' | 'privacy_settings'
export const tabList: { key: TTabs; tab: string }[] = [
	{
		key: 'account',
		tab: i18n.t('Account'),
	},
	{
		key: 'body',
		tab: i18n.t('Body'),
	},
	{
		key: 'style',
		tab: i18n.t('Style'),
	},
	{
		key: 'billing_address',
		tab: i18n.t('Billing address'),
	},
	{
		key: 'privacy_settings',
		tab: i18n.t('Privacy settings'),
	},
]
