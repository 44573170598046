import React, { useEffect } from 'react'
import {
	Layout,
	Menu,
	Breadcrumbs,
	Icons,
	LayoutLogo,
	LayoutLogoutButton,
	LayoutProfileDock,
	AppVersionLabel,
} from '@components'
import { useTranslation, useHistory, useState, useUserProfileContext } from '@hooks'
import cx from 'classnames'
import styles from './AppShell.module.css'
import { compact } from '@helpers'
import { apiSettings } from '@services'

const breadcrumbsStyle = { padding: '0 24px 24px' }
const menuStyle = { height: '100%', borderRight: 0, overflowY: 'scroll' }

/** Layout of app consisting of header, side navigation menu and breadcrumbs. */
function AppShell({ children }: TProps) {
	const { t } = useTranslation()
	const history = useHistory()
	const { profile } = useUserProfileContext()
	const isSuperAdmin = profile.role === 'super-admin'
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	const defaultSelectedKeys = ['dashboard']
	const [navigationKeys, setNavigationKeys] = useState<string[]>(defaultSelectedKeys, 'app:navigationKeys')
	const onSelectMenuItem = ({ key }) => {
		history.replace(`/${key}`)
	}
	useEffect(() => {
		const key = compact(history.location.pathname.split('/'))[0]
		setNavigationKeys([key])
	}, [history.location])

	return (
		<Layout>
			<Layout.Header style={{ backgroundColor: '#000000' }}>
				<div className={styles.headerContent}>
					<div className={styles.headerRightContent}>
						<LayoutLogo />
						<AppVersionLabel />
					</div>
					<div className={styles.headerRightContent}>
						<LayoutProfileDock />
						<LayoutLogoutButton />
					</div>
				</div>
			</Layout.Header>
			<Layout>
				<Layout.Sider width={200} className="site-layout-background">
					<Menu
						mode="inline"
						selectedKeys={navigationKeys}
						onSelect={onSelectMenuItem}
						onClick={onSelectMenuItem}
						style={menuStyle}
					>
						<Menu.Item key="dashboard">
							<Icons.DashboardOutlined />
							{t('Dashboard')}
						</Menu.Item>
						<Menu.Item key="users">
							<Icons.UserOutlined />
							{t('Users')}
						</Menu.Item>
						<Menu.Item key="promotions">
							<Icons.MailOutlined />
							{t('Promotions')}
						</Menu.Item>

						<Menu.SubMenu
							key="subscriber"
							title={
								<>
									<Icons.OrderedListOutlined />
									{t('Subscriber List')}
								</>
							}
						>
							<Menu.Item key="website-subscriber-list">
								<Icons.UserAddOutlined />
								{t('Website subscriber list')}
							</Menu.Item>
							<Menu.Item key="shopify-subscriber-list">
								<Icons.ShopOutlined />
								{t('Shopify subscriber list')}
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.Item key="wardrobe-items">
							<Icons.SkinOutlined />
							{t('Wardrobe items')}
						</Menu.Item>
						<Menu.SubMenu
							key="Bulk upload"
							title={
								<>
									<Icons.CloudUploadOutlined />
									{t('Bulk upload')}
								</>
							}
						>
							<Menu.Item key="bulk-upload">
								<Icons.CloudUploadOutlined />
								{t('Wardrobe items')}
							</Menu.Item>
							<Menu.Item key="bulk-upload-users">
								<Icons.CloudUploadOutlined />
								{t('Pre-populated users')}
							</Menu.Item>
							<Menu.Item key="bulk-upload-shops">
								<Icons.CloudUploadOutlined />
								{t('Shops')}
							</Menu.Item>
						</Menu.SubMenu>

						<Menu.SubMenu
							key="Feed"
							title={
								<>
									<Icons.ProfileOutlined />
									{t('Feed')}
								</>
							}
						>
							<Menu.Item key="posts">
								<Icons.FileTextOutlined />
								{t('Posts')}
							</Menu.Item>
							<Menu.Item key="articles">
								<Icons.SolutionOutlined />
								{t('Articles')}
							</Menu.Item>
							<Menu.Item key="surveys">
								<Icons.AreaChartOutlined />
								{t('Surveys')}
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.Item key="comments">
							<Icons.CommentOutlined />
							{t('Comments')}
						</Menu.Item>
						<Menu.Item key="lookbooks">
							<Icons.BookOutlined />
							{t('Lookbooks')}
						</Menu.Item>
						<Menu.SubMenu
							key="Lists"
							title={
								<>
									<Icons.OrderedListOutlined />
									{t('Lists')}
								</>
							}
						>
							<Menu.Item key="categorized-filters">
								<Icons.FolderViewOutlined />
								{t('Categorized filters')}
							</Menu.Item>
							<Menu.Item key="categories-taxonomy">
								<Icons.FolderOpenOutlined />
								{t('Categories-Taxonomy')}
							</Menu.Item>
							<Menu.Item key="attributes">
								<Icons.FolderViewOutlined />
								{t('Attributes')}
							</Menu.Item>
							<Menu.Item key="brands">
								<Icons.CopyrightOutlined />
								{t('Brands')}
							</Menu.Item>
							<Menu.Item key="materials">
								<Icons.SketchOutlined />
								{t('Materials')}
							</Menu.Item>
							<Menu.Item key="stores">
								<Icons.CopyrightOutlined />
								{t('Stores')}
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.SubMenu
							key="Marketplace"
							title={
								<>
									<Icons.ShoppingOutlined />
									{t('Marketplace')}
								</>
							}
						>
							<Menu.Item key="marketplace-items-on-review">
								<Icons.EyeOutlined />
								{t('Items on review')}
							</Menu.Item>
							<Menu.Item key="marketplace-items">
								<Icons.InfoCircleOutlined />
								{t('Items on MKT')}
							</Menu.Item>
							<Menu.Item key="items-can-be-sold">
								<Icons.PoundOutlined />
								{t('Can be sold')}
							</Menu.Item>
							<Menu.Item key="marketplace-orders">
								<Icons.ShoppingCartOutlined />
								{t('Orders')}
							</Menu.Item>
						</Menu.SubMenu>
						<Menu.Item key="image-sets">
							<Icons.FileImageOutlined />
							{t('Image sets')}
						</Menu.Item>
						{isSuperAdmin ? ( // cannot use <When />
							<Menu.SubMenu
								key="developer"
								title={
									<>
										<Icons.UserSwitchOutlined />
										{t('Developer')}
									</>
								}
							>
								<Menu.Item key="documentation">
									<Icons.BulbOutlined />
									{t('Documentation')}
								</Menu.Item>
								<Menu.Item key="api-documentation">
									<Icons.DatabaseOutlined />
									{t('API Swagger')}
								</Menu.Item>
								<Menu.Item key="changelog">
									<Icons.FieldTimeOutlined />
									{t('Changelog')}
								</Menu.Item>
							</Menu.SubMenu>
						) : null}
					</Menu>
				</Layout.Sider>
				<Layout style={breadcrumbsStyle}>
					<Breadcrumbs />
					<Layout.Content className={cx('site-layout-background', styles.layoutContent)}>
						{children}
					</Layout.Content>
				</Layout>
			</Layout>
		</Layout>
	)
}

type TProps = {
	children: React.ReactNode
}

export default AppShell
