import { Button, ItemListRow, List, When } from '@components'
import { useCallback, useTranslation } from '@hooks'
import { ListItemProps, TWardrobeItem } from '@typings'
import React from 'react'
import styles from './ItemList.module.css'

/** List of wardrobe items with lazy loading. */
function ItemList<TItem = TWardrobeItem>({
	dataSource,
	onLoadMore,
	renderActions,
	canLoadMore,
	loading,
	ItemComponent = ItemListRow,
}: TProps<TItem>) {
	const { t } = useTranslation()
	const renderItem = useCallback(
		(item: TWardrobeItem) => <ItemComponent item={item} actions={renderActions?.(item)} />,
		[],
	)

	return (
		<div className={styles.listContainer}>
			<List
				loading={loading}
				itemLayout="horizontal"
				loadMore={
					<When condition={!!dataSource?.length}>
						<div className={styles.moreButtonContainer}>
							<Button disabled={canLoadMore} loading={loading} onClick={onLoadMore}>
								{t('load more')}
							</Button>
						</div>
					</When>
				}
				dataSource={dataSource}
				renderItem={renderItem}
			/>
		</div>
	)
}

type TProps<TItem> = {
	/** Array of wardrobe items. */
	dataSource: TWardrobeItem[]
	/** Handler for "Load more" button */
	onLoadMore: () => void
	/** Renderer for list item actions. Passed wardrobe item. */
	renderActions?: (item: TItem) => ListItemProps['actions']
	/** Enable or disable "Load more" button. */
	canLoadMore: boolean
	/** Loading status of list. */
	loading: boolean
	/**  */
	ItemComponent: (props: {
		/** Single wardrobe item.  */
		item: TItem
		/** Set of action for list item. */
		actions: ListItemProps['actions']
		/** Loading item status. If one equals to false then Skeleton will be displayed. */
		loading?: boolean
	}) => any
}

export default ItemList
