import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { apiClient } from '@services'
import { UseAuthContextProvider, UseUserProfileContextProvider, UseTablesSettingsContextProvider } from '@hooks'
import { AxiosProvider } from 'react-axios-helpers'
import { StateInspector } from 'reinspect'
import { config } from '@constants'
import * as Sentry from '@sentry/browser'

Sentry.init({ dsn: config.SENTRY_DSN, enabled: process.env.NODE_ENV === 'production' })

ReactDOM.render(
	<StateInspector name="app">
		<AxiosProvider instance={apiClient}>
			<UseUserProfileContextProvider>
				<UseTablesSettingsContextProvider>
					<UseAuthContextProvider>
						<App />
					</UseAuthContextProvider>
				</UseTablesSettingsContextProvider>
			</UseUserProfileContextProvider>
		</AxiosProvider>
	</StateInspector>,
	document.getElementById('root'),
)
