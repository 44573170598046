import { DataTable, ErrorBoundary, ExportButton, Button, Icons } from '@components'
import { useRequest, useTableLocationParams, useCsvDownloader, useTranslation, useState } from '@hooks'
import { api, message, apiClient } from '@services'
import { TFilteredRequest, TResponseWithFilters, TWardrobeItem } from '@typings'
import * as React from 'react'
import { columns } from './WardrobeItems.tableOptions'

const UserViewWardrobeItemsTab: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = React.useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()
	const [exporting, setExporting] = useState(false, 'exporting')
	const downloadCsv = useCsvDownloader()

	// API data.
	const { data: { data: wardrobeItems, meta } = {}, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>(
		{
			request: () => api.getWardrobeItems({ order: ['id', 'desc'], ...searchParams }),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	const exportData = async () => {
		// downloadCsv(wardrobeItems,'wardrobeItems.csv')
		try {
			setExporting(true)
			const {
				data: { data },
			} = await apiClient(api.getWardrobeItems({ ...searchParams, limit: meta?.totalCounts, offset: 0 }))
			setExporting(false)
			downloadCsv(data, 'wardrobeItems.csv')
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<ErrorBoundary>
			<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={wardrobeItems}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button
						disabled={exporting}
						loading={exporting}
						type="primary"
						icon={<Icons.DownloadOutlined />}
						size="middle"
						onClick={exportData}
					>
						{t('Export Page')}
					</Button>
				}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default UserViewWardrobeItemsTab
