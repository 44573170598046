import { Table, TableSearchBar, TableColumnTitle, RangePickerField, Space, When } from '@components'
import {
	useMemo,
	useRef,
	useEffect,
	useTablesSettingsContext,
	useImperativeHandle,
	useCallback,
	useState,
} from '@hooks'
import { PaginationProps, TableProps, TFilteredRequest, TMetaResponse, TOrder, TOrderDirection } from '@typings'
import React, { useLayoutEffect } from 'react'
import {
	APISortToTable,
	defaultMeta,
	tableSortToAPI,
	getDefaultFilters,
	getGlobalSearchColumns,
	prepareDataIndex,
	getDefaultSorting,
	calcTableWidth,
	parseSearchInputValues,
} from './utils'
import { buildGlobalSearchParams, throttle, delay } from '@helpers'
import { TableInputsProvider, TableInputConsumer } from './TableInputsContext'
import styles from './DataTable.module.css'

/**
 * Display data as table prepared for sorting, searching, pagination.
 * How to use:
 *  * sorting - for sorting you need to add function to "sorter" field in column object. Function must return either undefined (will used dataIndex as key) or name of sorted key (for example () => "brand.id").
 * */
function DataTable<RecordType extends object = any, TPayload extends object = any>(
	{
		columns,
		dataSource,
		meta = defaultMeta,
		payload = {},
		loading,
		onChange,
		HeaderComponent = null,
		renderActionsComponent,
		renderActionsComponentWidth = 100,
		autoFetchOnMount = true,
		pageSize,
		withDateRange = true,
		searchInputValues: searchParams,
		...antdTableProps
	}: TProps<RecordType, TPayload>,
	ref: React.MutableRefObject<{ refresh: () => void }>,
) {
	const [tablesSettings, setTablesSettings] = useTablesSettingsContext()
	const tableChangeParams = useRef<TPayload & TFilteredRequest>({
		...payload,
		limit: pageSize || tablesSettings.pageSize,
		...getDefaultFilters<RecordType>(columns),
		...getDefaultSorting(columns),
	})

	/** Call fetch for updating data in table with same parameters.
	 * @public
	 */
	const refresh = useCallback(() => onChange(tableChangeParams.current), [])
	useImperativeHandle(ref, () => ({ refresh }))
	useEffect(() => {
		if (autoFetchOnMount) refresh()
	}, [])

	const globalSearchColumns = useMemo(() => getGlobalSearchColumns(columns), [columns])
	const confirmSearch = useCallback(
		(query: string | undefined) => {
			tableChangeParams.current = {
				...tableChangeParams.current,
				offset: undefined,
				...buildGlobalSearchParams(String(query), globalSearchColumns),
			}
			refresh()
		},
		[onChange, globalSearchColumns, meta],
	)
	const onRangeDate = useCallback(
		(query: string[] | null) => {
			console.log(query, 'query')
			tableChangeParams.current = {
				...tableChangeParams.current,
				offset: undefined,
				'range[datePicker]': query,
			}
			refresh()
		},
		[onChange, globalSearchColumns, meta],
	)

	// Pagination.
	const pagination: PaginationProps = {
		total: meta?.totalCounts,
		current: Math.ceil(meta?.offset / meta?.limit) + 1 || 1,
		hideOnSinglePage: true,
		showSizeChanger: true,
		pageSize: meta?.limit || pageSize || tablesSettings.pageSize,
		defaultCurrent: 1,
		defaultPageSize: pageSize || tablesSettings.pageSize,
		pageSizeOptions: ['10', '15', '20', '25', '30', '35', '40', '100'],
		onShowSizeChange: (current: number, pageSize: number) => {
			setTablesSettings({ pageSize })
		},
	}

	// Table actions.
	const onChangeTable: TableProps<RecordType>['onChange'] = (pagination, filters, sorter, extra) => {
		const paginationConfig = {
			limit: pagination.pageSize,
			offset: ((pagination?.current || 0) - 1) * meta.limit,
		}
		const sortDirection = tableSortToAPI(sorter.order)
		const sortField =
			sorter?.field instanceof Array
				? sorter.field.join('.')
				: columns?.find?.((column) => column.dataIndex === sorter?.sorter?.()) || sorter?.field
		const order: TOrder | undefined =
			sortField && sortDirection ? [sortField as string, sortDirection as TOrderDirection] : undefined

		tableChangeParams.current = {
			...tableChangeParams.current,
			...payload,
			...paginationConfig,
			...filters,
			order,
			// ...buildGlobalSearchParams(String(searchValue), globalSearchColumns),
		}
		refresh()
	}

	// Prepare columns data.
	const preparedColumns: TableProps<RecordType>['columns'] = useMemo(() => {
		let sortedColumns = (columns || [])?.map((column) => ({
			...column,
			sortOrder:
				searchParams?.order?.[0] === column.dataIndex ? APISortToTable(searchParams?.order?.[1]) : undefined,
			...(column.filters ? { filteredValue: searchParams?.[column.dataIndex] || null } : {}), // Set filters from input params.
		}))
		if (renderActionsComponent) {
			sortedColumns = sortedColumns.concat({
				title: ' ',
				key: 'actions',
				fixed: 'right',
				render: renderActionsComponent,
				width: renderActionsComponentWidth,
			})
		}

		return sortedColumns
	}, [columns, meta?.order, renderActionsComponent, searchParams])

	// Resize table on resize window.
	const tableColumnRef = useRef()
	const [scrollY, setScrollY] = useState(600, '')
	const scroll = useMemo(
		() => ({
			y: scrollY,
			x: calcTableWidth(preparedColumns),
			scrollToFirstRowOnChange: true,
		}),
		[preparedColumns, scrollY],
	)
	useLayoutEffect(() => {
		const listener = throttle((innerHeight) => {
			const y = innerHeight - tableColumnRef.current?.getBoundingClientRect?.()?.top - 190
			if (!Number.isNaN(y)) setScrollY(y || 600)
		}, 300)

		window.addEventListener('resize', (event) => delay(listener, 1000, event.target?.innerHeight))
		return window.removeEventListener('resize', listener)
	}, [])

	return (
		<TableInputsProvider key={JSON.stringify(searchParams)} initialValues={parseSearchInputValues(searchParams)}>
			<div ref={tableColumnRef} />
			<Table
				title={() => (
					<>
						<div className={styles.titleContainer}>
							<div className={styles.searchContainer}>
								<Space size="middle">
									<TableInputConsumer dataIndex="globalSearch">
										{({ value, onChange }) => (
											<TableSearchBar
												searchable={!!globalSearchColumns?.length}
												value={value}
												onChange={onChange}
												onSearch={confirmSearch}
											/>
										)}
									</TableInputConsumer>
									<When condition={withDateRange}>
										<RangePickerField onChange={onRangeDate} />
									</When>
								</Space>
							</div>
							<div className={styles.searchRightContainer}>{HeaderComponent}</div>
						</div>
					</>
				)}
				rowKey=""
				loading={loading}
				dataSource={dataSource}
				pagination={pagination}
				onChange={onChangeTable}
				size="small"
				scroll={{ ...scroll, y: scrollY }}
				{...antdTableProps}
			>
				{preparedColumns?.map((column) => (
					<Table.Column
						key={prepareDataIndex(column.dataIndex)}
						{...column}
						title={(props) => (
							<TableInputConsumer
								dataIndex={column.searchKey}
								onInput={(dataIndex, value) => {
									tableChangeParams.current = {
										...tableChangeParams.current,
										offset: undefined,
										[prepareDataIndex(dataIndex)]: value ? `[substring]:${value}` : value,
									}
								}}
							>
								{({ value, onChange }) => (
									<TableColumnTitle
										searchable={!!column.searchKey}
										dataIndex={column.searchKey}
										value={value}
										onChange={onChange}
										onSearch={refresh}
										{...props}
									>
										{String(column.title)}
									</TableColumnTitle>
								)}
							</TableInputConsumer>
						)}
						width={column.width}
					/>
				))}
			</Table>
		</TableInputsProvider>
	)
}

type TProps<RecordType extends object = any, TPayload extends object = any> = TableProps<RecordType> & {
	/** Enable loading indicator */
	loading: boolean
	/** Response meta data needed for pagination. */
	meta: TMetaResponse | undefined
	/** Callback called for reloading data after paginating, sorting, filtering. New payload passed as first argument. */
	onChange: (payload: TPayload & TFilteredRequest) => void | Promise<void>
	/** Enable calling onChange on mount table with passing payload and limit. */
	autoFetchOnMount?: boolean
	/** Extra data passed to each change callback. */
	payload?: TPayload
	/** Number of rows displayed on each page. */
	pageSize?: number
	/** Component rendered on right side of table header. Might be used for displaying control buttons. */
	HeaderComponent?: React.ReactNode
	/** Render function for extra column in table. Might be used for adding actions to each row. */
	renderActionsComponent?: (value: RecordType, record: RecordType, index: number) => React.ReactNode
	/** Width of component that will be returned by renderActionsComponent() function */
	renderActionsComponentWidth?: number
	/** Search params for initializing search fields. */
	searchInputValues?: Record<string, string>
	withDateRange: boolean
}

export default React.forwardRef(DataTable)
