import { TRequestBuilder, TFilteredRequest, TProfile } from '@typings'

// Codes verification
export const getShopCodes: TRequestBuilder<(TFilteredRequest & Partial<TProfile>) | void> = (params = {}) => ({
	method: 'get',
	url: '/pilot-stage/shopify-app-codes',
	params,
})

export const sendShopCode: TRequestBuilder<number> = (id) => ({
	method: 'post',
	url: `/pilot-stage/shopify-app-codes/${id}/send-code`,
})

export const deleteShopCode: TRequestBuilder<number> = (id) => ({
	method: 'delete',
	url: `/pilot-stage/shopify-app-codes/${id}`,
})
