import { TRequestBuilder, TWardrobeItem } from '@typings'

export type TSendPushNotificationPayload = {
	title: string
	body: string
}

export type TSendMailBoxNotificationPayload = {
	name: string
	body: string
}
export const sendPushNotification: TRequestBuilder<TSendPushNotificationPayload> = (data) => ({
	method: 'post',
	data,
	url: `/services/notifications/send-push-notifications`,
})

export const sendMailBoxNotification: TRequestBuilder<TSendMailBoxNotificationPayload> = (data) => ({
	method: 'post',
	data,
	url: `/services/notifications/send-mail-box-notifications`,
})

// export const disableFCMToken: TRequestBuilder<string> = fcmToken => ({
// 	method: 'post',
// 	data: { fcmToken },
// 	url: `/users/fcm-tokens/my/disable`,
// })
// export const activateFCMToken: TRequestBuilder<string> = fcmToken => ({
// 	method: 'post',
// 	data: { fcmToken },
// 	url: `/users/fcm-tokens/my/activate`,
// })
