import React from 'react'
import { Avatar, Icons, Link, BooleanIcon, Row } from '@components'
import { TPost, TStatus, TAsset } from '@typings'
import { formatToDay, compileImageUri, getYouTubeVideoId, prettytime } from '@helpers'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		sorter,
		render: (text: string, record: TPost) => <Link to={`/surveys/${record.id}`}>{text}</Link>,
		searchKey: 'id',
		defaultSortOrder: 'ascend',
	},
	{
		title: 'Question',
		dataIndex: 'question',
		width: 200,
		align: 'center',
		sorter,
		searchKey: 'question',
		defaultSortOrder: 'descend',
	},
	{
		title: 'Answer Options',
		dataIndex: 'surveyAnswerOptions',
		width: 200,
		sorter,
		defaultSortOrder: 'descend',
		render: (surveyAnswerOptions: { answerOption: string; votesCount: number }[]) =>
			surveyAnswerOptions.map((el, index) => (
				<p>
					{index + 1}) {el.answerOption}
				</p>
			)),
	},
	{
		title: 'Topic',
		dataIndex: 'topic',
		width: 100,
		align: 'center',
		sorter,
		searchKey: 'topic',
		defaultSortOrder: 'descend',
	},
	{
		title: 'Image',
		dataIndex: 'assets',
		width: 150,
		render: (assets: { asset: TAsset }[]) => (
			<Row justify="center">
				{assets.map((el) => (
					<Avatar
						size="large"
						icon={<Icons.PictureOutlined />}
						shape="square"
						src={compileImageUri(el.asset, true)}
					/>
				))}
			</Row>
		),
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 120,
		render: formatToDay,
		sorter,
	},
]
