import { useState, useMemo, useCallback } from '@hooks'
import { TProfile } from '@typings'
import { useRequest } from 'react-axios-helpers'
import { api } from '@services'
import * as Sentry from '@sentry/browser'

const DEFAULT = {}
const useUserProfile = (args: TUseUserProfileProps): TUseUserProfileData => {
	const [profile, setProfile] = useState<TProfile>(DEFAULT as TProfile, 'profile')

	const clearProfile = useCallback(() => setProfile(DEFAULT as TProfile), [])
	const { fetch: fetchProfile } = useRequest<TProfile, void>({
		request: () => api.getProfile(),
		onSuccess: (profile) => {
			setProfile(profile)
			Sentry.setUser({
				id: String(profile.id),
				email: profile.email,
				username: `${profile.firstName} ${profile.lastName}`,
			})
		},
		onError: (error) => console.error(error),
	})

	return useMemo(() => ({ profile, setProfile, clearProfile, fetchProfile }), [profile])
}

type TUseUserProfileProps = void
type TUseUserProfileData = {
	/** Object with user profile data */
	profile: TProfile
	/** Set local profile data (without API requests) */
	setProfile: (profile: TProfile) => void
	/** Remove profile information */
	clearProfile: () => void
	/** Fetch profile from API by userId  */
	fetchProfile: (props: void) => Promise<void>
}

export default useUserProfile
