import React from 'react'
import { useEffect, useCallback, useMemo, useTranslation } from '@hooks'
import { Title, Text, Card, Statistic, Col, CountUp, StatisticsCardSlice, StatisticsCard, Row } from '@components'
import styles from './StatisticCardLarge.module.css'
import { TStatisticsSet } from '@typings'

const StatisticCardLarge: React.FC<TProps> = ({ data, loading, onClick }) => {
	const { t } = useTranslation()
	let data2 = data || []

	const totalCommentByCategory = Object.entries(data2)?.map(([target, values]) => ({
		target: target.replace(/StatisticFields|CommentStatisticFields/, ''),
		values,
	}))
	const totalCount = totalCommentByCategory.map((category) => category.values.totalValue).reduce((a, b) => a + b, 0)

	return (
		<Card hoverable loading={loading} className={styles.container} title={t('Top Comments')} onClick={onClick}>
			<CountUp start={0} end={totalCount} duration={2} isCounting={!!totalCount}>
				{({ value }) => <Statistic title={t('Total comments')} value={value as number} />}
			</CountUp>
			<Row gutter={[8, 8]}>
				{totalCommentByCategory.map((category) => (
					<Col span={12} className={styles.container}>
						<StatisticsCard
							title={category.target}
							{...category.values}
							loading={false}
							onClick={() => null}
						/>
					</Col>
				))}
			</Row>
		</Card>
	)
}

type TProps = {
	onClick: () => void
	loading: boolean
	data: {
		articleCommentStatisticFields: TStatisticsSet
	}
}

export default StatisticCardLarge
// articleCommentStatisticFields: {totalValue: 30, valueOfLastDay: 0, valueOfLastWeek: 0, valueOfLastMonth: 0, valueOfLastYear: 0}
// lookStatisticFields: {totalValue: 106, valueOfLastDay: 0, valueOfLastWeek: 0, valueOfLastMonth: 0, valueOfLastYear: 3}
// lookbookStatisticFields: {totalValue: 193, valueOfLastDay: 0, valueOfLastWeek: 0, valueOfLastMonth: 1, valueOfLastYear: 8}
// postCommentStatisticFields: {totalValue: 25, valueOfLastDay: 0, valueOfLastWeek: 0, valueOfLastMonth: 0, valueOfLastYear: 0}
// videoCommentStatisticFields: {totalValue: 38, valueOfLastDay: 0, valueOfLastWeek: 0, valueOfLastMonth: 0, valueOfLastYear: 0}
// wardrobeItemStatisticFields: {totalValue: 150, valueOfLastDay: 0, valueOfLastWeek: 0, v
