import { Input } from '@components'
import { debounce } from '@helpers'
import { useCallback } from '@hooks'
import React from 'react'
import styles from './TableColumnTitle.module.css'

type TProps = {
	children: string
	searchable: boolean
	value: string | undefined
	onChange: (value: string) => void
	onSearch: () => any
	debounceDelay?: number
}

const TableColumnTitle = ({
	children: title,
	searchable = false,
	value,
	onChange,
	onSearch,
	debounceDelay = 400,
}: TProps) => {
	const handleSearch = useCallback(
		debounce((value: string) => onSearch(), debounceDelay),
		[onSearch],
	)

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			event.persist()
			const { value } = event.target
			onChange(value)
			handleSearch(value)
		},
		[handleSearch, onChange],
	)

	const stopPropagation = useCallback((event) => event.stopPropagation(), [])

	return searchable ? (
		<div onClick={stopPropagation}>
			<div className={styles.title}>{title}</div>
			<div>
				<Input.Search allowClear size="small" value={value} onChange={handleChange} onSearch={onSearch} />
			</div>
		</div>
	) : (
		title
	)
}

export default TableColumnTitle
