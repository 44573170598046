import {
	TRequestBuilder,
	TWardrobeItem,
	TFilteredRequest,
	TCommentForm,
	AxiosRequestConfig,
	TMktInformation,
} from '@typings'
import { upDataTransformer } from '../transformers'

export const getMarketplaceItems: TRequestBuilder<(TFilteredRequest & Partial<TWardrobeItem>) | void> = (
	params = {},
) => ({
	method: 'get',
	url: `/wardrobe-items`,
	params: { status: 'sale', ...params },
})

export const getMarketplaceItem: TRequestBuilder<TWardrobeItem['id']> = (id: number) => ({
	method: 'get',
	url: `/marketplaces/informations/${id}`,
	transformResponse: upDataTransformer,
})

export const updateMarketplaceItem: TRequestBuilder<Partial<TMktInformation>> = ({ id, ...form }) => ({
	method: 'put',
	url: `marketplaces/informations/${id}`,
	data: form,
	transformResponse: upDataTransformer,
})
export const addCommentToRejectedItem = (id: TWardrobeItem['id'], data: TCommentForm): AxiosRequestConfig => ({
	method: 'post',
	url: `/marketplaces/informations/${id}/comments`,
	data,
	transformResponse: upDataTransformer,
})
export const markCommentAsRead: TRequestBuilder<TWardrobeItem['id']> = (id) => ({
	method: 'post',
	url: `/marketplaces/informations/${id}/mark-as-viewed`,
	transformResponse: upDataTransformer,
})

export const approveItemFromMarketplace: TRequestBuilder<TWardrobeItem['id']> = (id: TWardrobeItem['id']) => ({
	method: 'post',
	url: `/marketplaces/informations/${id}/approve-marketplace`,
	transformResponse: upDataTransformer,
})
export const rejectItemFromMarketplace: TRequestBuilder<TWardrobeItem['id']> = (id: TWardrobeItem['id']) => ({
	method: 'post',
	url: `/marketplaces/informations/${id}/reject-marketplace`,
	transformResponse: upDataTransformer,
})
export const cancelItemFromMarketplace: TRequestBuilder<TWardrobeItem['id']> = (id: TWardrobeItem['id']) => ({
	method: 'post',
	url: `/marketplaces/informations/${id}/cancel-marketplace`,
	transformResponse: upDataTransformer,
})
export const removeItemFromMarketplace: TRequestBuilder<TWardrobeItem['id']> = (id: TWardrobeItem['id']) => ({
	method: 'delete',
	url: `/wardrobe-items/${id}/remove-from-marketplace`,
	transformResponse: upDataTransformer,
})
