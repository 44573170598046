import { i18n } from '@services'
import { TItemAttributeItem, TUpdateCategoryItem, TVisenzeAttribute } from '@typings'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 12 },
}
export const formButtonLayout = { offset: 6, span: 12 }
export const formFieldRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
	categoryIds: [{ required: true, message: i18n.t('Categories is required') }],
}

export const DEFAULT_INITIAL_VALUES: Partial<TUpdateCategoryItem> = { status: 'active', isShowingInWardrobe: false }

export const normalize = (value: any) => value ?? null

export function parseDefaultVisenzeAttributeIds(
	attributeId: TItemAttributeItem['id'] | undefined,
	visenzeAttributes: TVisenzeAttribute[],
) {
	return attributeId && !!visenzeAttributes?.length
		? visenzeAttributes
				.filter((visenzeAttribute) =>
					visenzeAttribute.attributes.some((attribute) => attribute.attributeId === attributeId),
				)
				.map((visenzeCategory) => visenzeCategory.id)
		: []
}

export function parseDefaultCategoryIds(attribute: TItemAttributeItem | undefined) {
	const defaultCategoryIds = attribute?.categories.map((bundle) => bundle.categoryId) || []
	return defaultCategoryIds
}
