import React from 'react'
import { Link } from '@components'
import { TUser } from '@typings'
import { formatToDay } from '@helpers'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 102,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TUser) => <Link to={`/users/${record.id}`}>{text}</Link>,
		searchKey: 'id',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 200,
		sortDirections: ['descend', 'ascend'],
		sorter,
		searchKey: 'email',
	},
	{
		title: 'Full name',
		dataIndex: 'fullName',
		width: 180,
		sorter,
		searchKey: 'fullName',
	},
	{
		title: 'Code',
		dataIndex: 'code',
		width: 120,
		sorter,
		searchKey: 'code',
	},
	{
		title: 'Source',
		dataIndex: 'linkedUserId',
		width: 120,
		sorter,
		render: (linkedUserId: number | null) => (linkedUserId ? <p>Mobile App</p> : <p>Website</p>),
	},
	{
		title: 'Instagram',
		dataIndex: 'instagramUsername',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TUser) =>
			text ? <a href={`https://www.instagram.com/${text}/`}>{text}</a> : <p>not specified</p>,
		searchKey: 'instagramUsername',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 120,
		sorter,
		searchKey: 'status',
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
