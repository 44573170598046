import React from 'react'
import { useEffect, useCallback, useMemo } from '@hooks'
import { Title, Text, Icons } from '@components'
import styles from './StatisticsCardSlice.module.css'

/** Bar with changing statistics per day, week and month. */
const StatisticsCardSlice: React.FC<TProps> = ({ title, value = 0, displayValue }) => {
	const [color, IconComponent] = useMemo(() => {
		switch (Math.sign(value)) {
			case 1:
				return ['green', Icons.ArrowUpOutlined]
			case -1:
				return ['red', Icons.ArrowDownOutlined]
			default:
				return ['orange', Icons.ArrowRightOutlined]
		}
	}, [value])

	return (
		<>
			<div>
				<Text type="secondary" style={{ fontSize: '12px' }}>
					{title}
				</Text>
			</div>
			<div>
				<IconComponent style={{ color }} />
				&nbsp;
				<Text style={{ color }}>{displayValue || value}</Text>
			</div>
		</>
	)
}

type TProps = {
	/** Text label. */
	title: string
	/** NUmber value. It's used to pick up appropriate color and displaying in case component hasn't got displayValue prop.  */
	value: number
	/** Formatted value displaying in card instead of value. */
	displayValue?: number | string
}

export default StatisticsCardSlice
