import { TRequestBuilder, TFilteredRequest, TSurvey } from '@typings'
import { upDataTransformer } from '../transformers'

export const getSurveys: TRequestBuilder<TFilteredRequest & Partial<TSurvey>> = (params) => ({
	method: 'get',
	url: '/surveys',
	params,
})

export const getSurvey: TRequestBuilder<TSurvey['id']> = (surveyId) => ({
	method: 'get',
	url: `/surveys/${surveyId}`,
	transformResponse: upDataTransformer,
})

export const createSurvey: TRequestBuilder<Partial<TSurvey>> = (survey) => ({
	method: 'post',
	url: '/surveys',
	data: survey,
	transformResponse: upDataTransformer,
})
export const updateSurvey: TRequestBuilder<Partial<TSurvey>> = ({ id: surveyId, ...survey }) => ({
	method: 'put',
	url: `/surveys/${surveyId}`,
	data: survey,
	transformResponse: upDataTransformer,
})
export const removeSurvey: TRequestBuilder<Partial<TSurvey>['id']> = (surveyId) => ({
	method: 'delete',
	url: `/surveys/${surveyId}`,
	transformResponse: upDataTransformer,
})

export const getVotedUsers: TRequestBuilder<TFilteredRequest & Partial<TSurvey>> | any = (params, id) => ({
	method: 'get',
	url: `surveys/${id}/users`,
	params,
})
