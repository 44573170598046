import { Alert, Else, If, Modal, Result, Spin, Then } from '@components'
import { useBoolean, useTranslation } from '@hooks'
import * as React from 'react'
import { TBulkUploadValidation } from '@typings'
import styles from './BulkUploadUsersProcessModal.module.css'

const BulkUploadUsersProcessModal = ({ bulkUploadValidation, loading, description }: TProps, ref) => {
	// Main screen data.
	const messages = bulkUploadValidation?.messages
	const isValid = !!bulkUploadValidation?.isValid
	const { t } = useTranslation()
	const alertProcessingDescription = description || t('Trying to create wardrobe items')

	// Handle visibility of modal window.
	const uploadingResultBoolean = useBoolean(false)
	React.useImperativeHandle(ref, () => ({ open: uploadingResultBoolean.setTrue }))
	return (
		<Modal
			title={t('Uploading result')}
			visible={uploadingResultBoolean.value}
			onOk={uploadingResultBoolean.setFalse}
			onCancel={uploadingResultBoolean.setFalse}
		>
			<If condition={loading}>
				<Then>
					<Spin size="large">
						<Alert message={t('Processing...')} description={alertProcessingDescription} type="info" />
					</Spin>
				</Then>
				<Else>
					<If condition={isValid}>
						<Then>
							<Result
								status="success"
								title={t('Successfully')}
								subTitle={t('{{itemsAmount}} items have been uploaded', {
									itemsAmount: bulkUploadValidation?.ids?.length,
								})}
							/>
						</Then>
						<Else>
							<div className={styles.validationMessagesContainer}>
								{messages?.map((validationMessage, index) => (
									<Alert
										key={index}
										message={t('Error')}
										description={validationMessage}
										type="error"
									/>
								))}
							</div>
						</Else>
					</If>
				</Else>
			</If>
		</Modal>
	)
}

type TProps = {
	/** Response from api.runBulkUpload() request with status of request and list of errors. */
	bulkUploadValidation: TBulkUploadValidation
	/** Status of processing */
	loading: boolean
	description?: string
	type?: 'wardrobe' | 'users'
}

export default React.forwardRef(BulkUploadUsersProcessModal)
