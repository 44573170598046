import { TreeSelect } from '@components'
import { Option, TSupportedChildrenKeys, TTree } from '@typings'
import React from 'react'

function renderTreeLeaf<TChildrenKey extends TSupportedChildrenKeys>(
	array: TTree<TChildrenKey, Option> = [],
	childrenKey: TSupportedChildrenKeys,
	keyExtractor = (node) => node.id,
	canSelect = (node) => true,
) {
	return array.map((node) => {
		const uniqueId = keyExtractor(node)
		const selectable = canSelect(node)
		return (
			<TreeSelect.TreeNode key={uniqueId} title={node.title} value={uniqueId} selectable={selectable}>
				{renderTreeLeaf(node[childrenKey], childrenKey)}
			</TreeSelect.TreeNode>
		)
	})
}
const dropdownStyle = { maxHeight: 500, overflow: 'auto' }

function TreeSelectField<TChildrenKey extends TSupportedChildrenKeys = 'children'>({
	placeholder,
	tree,
	childrenKey = 'subcategories',
	value,
	onChange,
	treeDefaultExpandAll = false,
	multiple,
	keyExtractor,
	canSelect,
}: TProps<TChildrenKey>) {
	return (
		<TreeSelect
			showSearch
			value={value}
			multiple={multiple}
			treeCheckable={multiple}
			dropdownStyle={dropdownStyle}
			placeholder={placeholder}
			allowClear
			treeDefaultExpandAll={treeDefaultExpandAll}
			onChange={onChange}
		>
			{renderTreeLeaf(tree, childrenKey, keyExtractor, canSelect)}
		</TreeSelect>
	)
}

type TProps<TChildrenKey extends TSupportedChildrenKeys> = {
	/** Form field value. Might be passed from Form.Item  */
	value?: Option['id']
	/** Function for changing form field value. Might be passed from Form.Item */
	onChange?: (value: Option['id']) => void
	/** Tree data */
	tree: TTree<TChildrenKey, Option>
	/** Key with subtree, Default 'children' */
	childrenKey: TSupportedChildrenKeys
	/** holder text Place */
	placeholder?: string
	/** Should all nodes be expanded */
	treeDefaultExpandAll?: boolean
	/** Allow multi select to array of values. */
	multiple?: boolean
	/** Custom key extractor  */
	keyExtractor?: (node: any) => string | number
	/** Custom definer can user check node  */
	canSelect?: (node: any) => boolean
}

export default TreeSelectField
