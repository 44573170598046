import { TNumberOption, TWardrobeItemStatus } from '@typings'

const ACTIVE_STATUSES: TWardrobeItemStatus[] = ['gift_accepted', 'gift_offered']

/** Format data from API for chart. */
export const adaptWardrobeStatisticsToPieChart = (wardrobeStatistic): TNumberOption[] => {
	if (!Array.isArray(wardrobeStatistic)) return []

	const formattedStatistic = wardrobeStatistic.reduce((result, el) => {
		if (ACTIVE_STATUSES.includes(el.status)) {
			return { ...result, active: result.active + el.pricePurchased }
		}
		return { ...result, [el.status]: el.pricePurchased }
	}, {})
	const chartData: any = Object.entries(formattedStatistic).map(([status, pricePurchased]) => ({
		title: status,
		value: pricePurchased,
	}))
	return chartData
}

export const adaptWardrobeCategoryStatistic = (wardrobeStatistic): TNumberOption[] => {
	console.log(wardrobeStatistic && Object.entries(wardrobeStatistic), 'wardrobeStatistic')
	// if (!Array.isArray(wardrobeStatistic)) return []
	let data = wardrobeStatistic || []

	const chartData: any = Object.entries(data).map(([category, value]) => {
		const title = category.replace(/amountOfWardrobe|amountOf/, '').match(/[A-Z][a-z]+|[0-9]+/g) || ''
		return {
			title: title.join(' ').toLowerCase(),
			value,
		}
	})
	return chartData
}
