import { Else, If, Tag, Text, Then } from '@components'
import { useTranslation } from '@hooks'
import { TWardrobeItem, TLookbook } from '@typings'
import React from 'react'
import styles from './ItemTagsSelection.module.css'

const ItemTagsSelection: React.FC<TProps> = ({ items = [], onRemove }) => {
	const { t } = useTranslation()
	return (
		<div className={styles.tagsContainer}>
			<If condition={!!items.length}>
				<Then>
					{items.map((item) => (
						<Tag key={item.id} closable onClose={() => onRemove(item.id)}>
							{`${item.name || item.title} [${item.id}]`}
						</Tag>
					))}
				</Then>
				<Else>
					<Text>{t('Haven`t selected items yet')}</Text>
				</Else>
			</If>
		</div>
	)
}

type TProps = {
	/** List of wardrobe items. */
	items: TWardrobeItem[] | TLookbook[]
	/** Function called when user want to remove an item. Passed wardrobeItemId. */
	onRemove: (wardrobeItemId: TWardrobeItem['id']) => void
}

export default React.memo(ItemTagsSelection)
