import {
	TBulkUpload,
	TFilteredRequest,
	TMakeGoogleSheetFromBulkUpload,
	TRequestBuilder,
	TSpreadSheetForUsersBulkUpload,
	TBulkUploadUserData,
} from '@typings'
import { upDataTransformer } from '../transformers'

export const getBulkUploads: TRequestBuilder<TFilteredRequest & Partial<TBulkUpload>> = (params) => ({
	method: 'get',
	url: '/bulk-uploads/my',
	params,
})

export const removeBulkUpload: TRequestBuilder<TBulkUpload['id']> = (id) => ({
	method: 'delete',
	url: `/bulk-uploads/${id}`,
	transformResponse: upDataTransformer,
})

export const makeGoogleSheetFromBulkUpload: TRequestBuilder<TMakeGoogleSheetFromBulkUpload> = ({
	title,
	assetIds,
}) => ({
	method: 'post',
	url: `/bulk-uploads/my/make-google-sheet`,
	data: { title, assetId: assetIds },
	transformResponse: upDataTransformer,
	timeout: 1000 * 60 * 5, // 5 minute. increase timeout because request is long.
})

export const runBulkUpload: TRequestBuilder<Pick<TBulkUpload, 'id' | 'key'>> = ({ id }) => ({
	method: 'post',
	url: `/bulk-uploads/my/${id}/run-bulk-upload`,
	transformResponse: upDataTransformer,
})

export const getUserBulkUploads: TRequestBuilder<TFilteredRequest & Partial<TBulkUpload>> = (params) => ({
	method: 'get',
	url: '/bulk-uploads/users/my',
	params,
})

export const removeUserBulkUpload: TRequestBuilder<TBulkUpload['id']> = (id) => ({
	method: 'delete',
	url: `/bulk-uploads/users/my/${id}`,
	transformResponse: upDataTransformer,
})

export const runUserBulkUpload: TRequestBuilder<Pick<TBulkUpload, 'id' | 'key'> & TBulkUploadUserData> = ({
	id,
	...data
}) => ({
	method: 'post',
	url: `bulk-uploads/users/${id}/google-spreadsheet-bulk-upload`,
	data,
	transformResponse: upDataTransformer,
})

export const createSpreadSheetForUsersBulkUpload: TRequestBuilder<TSpreadSheetForUsersBulkUpload> = (data) => ({
	method: 'post',
	url: `/bulk-uploads/users/generate-google-sheet`,
	data,
	transformResponse: upDataTransformer,
	timeout: 1000 * 60 * 5, // 5 minute. increase timeout because request is long.
})

//Shops

export const getShopsBulkUploads: TRequestBuilder<TFilteredRequest & Partial<TBulkUpload>> = (params) => ({
	method: 'get',
	url: '/bulk-uploads/shopify/',
	params,
})
export const removeShopBulkUpload: TRequestBuilder<TBulkUpload['id']> = (id) => ({
	method: 'delete',
	url: `/bulk-uploads/shopify/${id}`,
	transformResponse: upDataTransformer,
})

export const runShopBulkUpload: TRequestBuilder<Pick<TBulkUpload, 'id' | 'key'> & TBulkUploadUserData> = ({
	id,
	...data
}) => ({
	method: 'post',
	url: `/bulk-uploads/shopify/${id}/run-bulk-upload`,
	data,
	transformResponse: upDataTransformer,
})
