import { DatePicker, Checkbox } from '@components'
import { useCallback, useTranslation } from '@hooks'
import { DatePickerProps, UTCDate } from '@typings'
import moment from 'moment'
import React, { useState } from 'react'

const RangePickerField: React.FC<TProps> = ({ value, onChange }) => {
	const { t } = useTranslation()
	const defaultDateRange = Array.isArray(value) ? value.map((dateString) => moment(dateString)) : null
	const [dateRange, setDateRange] = useState<moment.Moment | null[]>(defaultDateRange)
	const handleChange = useCallback(
		(value: moment.Moment | null[], dateString: [string, string]) => {
			setDateRange(value)
			onChange?.(dateString)
		},
		[onChange],
	)
	const showAll = useCallback(() => {
		setDateRange(null)
		onChange?.(null)
	}, [onChange])

	return (
		<>
			<DatePicker.RangePicker value={dateRange} onChange={handleChange} />
			<Checkbox style={{ marginLeft: 10 }} onChange={showAll} checked={!dateRange}>
				{t('Show all')}
			</Checkbox>
		</>
	)
}
type TProps = DatePickerProps & {
	/** Function for changing form field value. */
	onChange?: (value: string[] | null) => void
}

export default RangePickerField
