import React from 'react'
import { useEffect, useCallback, useMemo, useTranslation, useRequest } from '@hooks'
import { Title, Text, Popconfirm, Button } from '@components'
import styles from './RemoveWardrobeItemLink.module.css'

import { api, message } from '@services'
import { ButtonType, TWardrobeItem } from '@typings'

const RemoveWardrobeItemLink: React.FC<TProps> = ({
	wardrobeItemId,
	buttonType = 'default',
	onSuccess,
	isArchived,
}) => {
	const { t } = useTranslation()

	// Remove user.
	const { fetch: removeUser, fetching: removing } = useRequest<void, any>({
		request: () => (isArchived ? api.deleteWardrobeItem(wardrobeItemId) : api.archiveWardrobeItem(wardrobeItemId)),
		onSuccess: () => onSuccess?.(),
		onError: (error) => message.error(error.message),
	})
	return (
		<Popconfirm title={t('Are you sure?')} okText={t('Yes')} cancelText={t('No')} onConfirm={removeUser}>
			<Button loading={removing} danger type={buttonType}>
				{isArchived ? t('Remove') : t('Archive')}
			</Button>
		</Popconfirm>
	)
}

type TProps = {
	wardrobeItemId: TWardrobeItem['id']
	onSuccess: () => void
	buttonType?: ButtonType
	isArchived: boolean
}

export default RemoveWardrobeItemLink
