import {
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Input,
	Popconfirm,
	Row,
	SelectField,
	StatusSwitcher,
	UploadImageField,
	When,
	Switcher,
	Spin,
	Space,
	Modal,
} from '@components'
import { showFormErrors, toNumber } from '@helpers'
import { useHistory, useMemo, useTranslation, useUserProfileContext, useParams } from '@hooks'
import {
	TCategoryItem,
	TSizeChartType,
	TUpdateCategoryItem,
	TUseEntityListItemAPI,
	TVisenzeCategory,
	TCategoriesTree,
} from '@typings'
import * as React from 'react'
import {
	DEFAULT_INITIAL_VALUES,
	formButtonLayout,
	formFieldRules,
	formLayout,
	parseDefaultVisenzeCategoryIds,
	prepareForm,
	normalize,
} from './EditCategory.formOptions'

/** Form for editing current category and create new subcategory. */
const EditCategoryScreen: React.FC<TProps> = ({
	isAdding,
	Category,
	visenzeCategories,
	chartTypes,
	categoriesTree = [],
}) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const user = useUserProfileContext()
	const params = useParams<TParams>()
	const id = params?.id ? toNumber(params?.id) : null

	// Actions.
	const handleSubmit = (form: TUpdateCategoryItem) => {
		if (categoriesTree.some((item) => item.title?.toLowerCase() === form.title?.toLowerCase())) {
			const { info } = Modal
			return new Promise(() => {
				info({
					title: 'The category is already exist',
				})
			})
		}
		isAdding
			? Category.create({ parentId: id, ...prepareForm(form) })
			: Category.update({ id: Category.data?.id, ...prepareForm(form) })
	}

	const defaultVisenzeCategoryIds = useMemo(
		() => parseDefaultVisenzeCategoryIds(Category.data?.id, visenzeCategories),
		[Category.data?.id, visenzeCategories],
	)
	const initialValues = isAdding
		? DEFAULT_INITIAL_VALUES
		: { ...Category.data, visenzeCategoryIds: defaultVisenzeCategoryIds }
	const isArchivedCategory = Category.data?.title === 'Archive'

	return (
		<ErrorBoundary>
			<Spin spinning={Category.creating || Category.updating}>
				<Card
					loading={Category.fetching}
					title={
						isAdding
							? t('Add new category to {{categoryName}}', {
									categoryName: id ? Category.data?.title : 'root',
							  })
							: Category.data?.title
					}
					extra={
						// <When condition={!isArchivedCategory}>
						<Row gutter={16} align="middle">
							<Col>
								<Popconfirm
									title={t('Are you sure?')}
									onConfirm={Category.remove}
									okText={t('Yes')}
									cancelText={t('No')}
								>
									<Button
										disabled={user.profile.role !== 'super-admin'}
										loading={Category.removing}
										danger
									>
										{t('Remove')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
						// </When>
					}
				>
					<Form
						key={`category-form-${Category.data?.id}`} // Need for reinitialize form on change category.
						{...formLayout}
						onFinish={handleSubmit}
						initialValues={initialValues}
						onFinishFailed={showFormErrors}
					>
						<Form.Item required name="title" label="Title" rules={formFieldRules.title}>
							<Input disabled={isArchivedCategory} />
						</Form.Item>
						<Form.Item name="chartTypeId" label={t('Chart type')} normalize={normalize}>
							<SelectField
								showSearch
								selectOptions={chartTypes}
								filterOption
								optionFilterProp="title"
								allowClear
								placeholder={t('Choose chart type...')}
								disabled={isArchivedCategory}
							/>
						</Form.Item>
						<Form.Item label={t('Image')}>
							<UploadImageField name="asset" multiple={false} />
						</Form.Item>
						<Form.Item name="visenzeCategoryIds" label={t('Visenze categories')}>
							<SelectField
								showSearch
								selectOptions={visenzeCategories}
								filterOption
								optionFilterProp="title"
								allowClear
								placeholder={t('Choose visenze categories...')}
								mode="multiple"
								disabled={isArchivedCategory}
							/>
						</Form.Item>
						<When condition={!isAdding}>
							<Form.Item name="status" label={t('Status')}>
								<StatusSwitcher disabled={isArchivedCategory} />
							</Form.Item>
						</When>
						<Form.Item name="isShowingInWardrobe" label={t('Show in Wardrobe')} valuePropName="checked">
							<Switcher disabled={isArchivedCategory} />
						</Form.Item>
						<Form.Item wrapperCol={formButtonLayout}>
							<Space>
								<Button
									type="primary"
									htmlType="submit"
									loading={Category.updating || Category.creating}
								>
									{t('Save changes')}
								</Button>
								<Button htmlType="button" onClick={history.goBack}>
									{t('Cancel')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TParams = { id: string | undefined }
type TProps = {
	/** Form mode. Should form work in adding new subcategory mode. Otherwise form will edit current category. */
	isAdding: boolean
	/** API for manipulating category. */
	Category: TUseEntityListItemAPI<TCategoryItem, TUpdateCategoryItem>
	/** List of visenze categories for select component */
	visenzeCategories: TVisenzeCategory[]
	/** List of chart types for select component */
	chartTypes: TSizeChartType[]
	categoriesTree?: TCategoriesTree
}

export default EditCategoryScreen
