import { useMemo, useState, useLocalStorage } from '@hooks'
import { useEffect } from 'react'

const DEFAULT_PAGE_SIZE = 20

/** Store common setting for each table. { pageSize } */
const useTablesSettings = (args: any): any => {
	const [persistedTablesSettings, setPersistedTablesSettings] = useLocalStorage('lbdadmin:tablesSettings', {
		pageSize: DEFAULT_PAGE_SIZE,
	})
	const [tablesSettings, setTablesSettings] = useState(persistedTablesSettings, 'user:tablesSettings')
	useEffect(() => {
		setPersistedTablesSettings(tablesSettings)
	}, [tablesSettings])

	return useMemo(() => [tablesSettings, setTablesSettings], [tablesSettings])
}

export default useTablesSettings
