import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import ApiSettings from './ApiSettings'
import { httpCodes, config } from '@constants'
import * as Sentry from '@sentry/browser'
import { setupCache } from 'axios-cache-adapter'

export const apiClient = axios.create({
	// baseURL: apiSettings.getSettings('API_URL'), // will set by ApiSettings.
	timeout: config.API_REQUEST_TIMEOUT,

	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		// app_version: 'admin_panel',
	},
	adapter: setupCache({
		// Instance of cache storage.
		maxAge: 5 * 60 * 1000, // 5 minutes
		// Disable for all requests by default.
		exclude: { filter: () => true },
		// Read stale cache data on network error
		clearOnError: true,
		clearOnStale: false, // we can actually read stale cache data
		readOnError: (error: any) => !error.status, // Attempt reading stale cache data when axios returns Network Error
		// Use response headers to automatically set maxAge
		readHeaders: true,
		// Generate a unique cache key for the request.
	}).adapter,
})
export const apiSettings = new ApiSettings('staging', apiClient)

export const apiRequest = async <TResponseData>(axiosConfig: AxiosRequestConfig): Promise<TResponseData> => {
	const response = await apiClient.request(axiosConfig)
	const responseData = response.data
	return responseData
}

apiClient.interceptors.response.use(
	(response: AxiosResponse) => {
		return response
	},
	(error: any) => {
		// Format request error. https://github.com/axios/axios/issues/960
		error.data = error?.request?.response?.error
		error.message = error?.data?.message
		const responseStatus = error?.response?.status ?? 0 // 0 = 'Network Error'

		// Handle backend auth errors.
		if ([httpCodes.UNAUTHORIZED, httpCodes.FORBIDDEN].includes(responseStatus)) {
			Sentry.captureMessage(`API STATUS ${responseStatus}`, Sentry.Severity.Warning)
		}
		// Handle 'Network Error'
		else if (!responseStatus) {
			Sentry.captureMessage('Network Error', Sentry.Severity.Error)
		}
		// Send report about error to bug tracker.
		else {
			Sentry.setExtra('api', {
				screen: document.location.href,
				api_url: error?.config?.url,
				api_status: responseStatus ?? 'Network Error',
				request: error.request,
				response: error.response,
			})
			Sentry.captureException(error)
		}

		return Promise.reject(error)
	},
)
