import { pick, mapIds, toString, isEmpty, pickBy, isPlainObject, isNil } from '@helpers'
import { TUser, TUserForm, TUserContactWay, AxiosRequestConfig } from '@typings'
import i18n from 'i18next'
import moment from 'moment'
import { api, apiRequest } from '@services'

export const formLayout = {
	labelCol: { span: 6, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formTailLayout = {
	wrapperCol: { span: 10, offset: 4 },
}
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const normalize = (value: any) => (isNil(value) || value?.length === 0 ? null : value)
export const sanitizeObject = (object) => (isPlainObject(object) ? pickBy(object, (value) => !isEmpty(value)) : {}) // remove undefined & null fields from object.
export const formFieldRules = {
	username: [
		{ required: true, message: i18n.t('User name is required.') },
		{ max: 20, message: i18n.t('Username can contain alphanumeric values up to 20 symbols.') },
		{ regexp: /^[a-zA-Z0-9()_.]+$/, message: i18n.t('Username can contain alphanumeric values and underscore') },
		{
			validator: async (rule, value) => {
				if (value === toString(parseInt(value, 10))) {
					return Promise.reject(i18n.t('Username cannot consist only of numbers'))
				}
				return Promise.resolve()
			},
		},
	],
	email: [
		{ required: true, message: i18n.t('Email is required') },
		{ type: 'email', message: i18n.t('Invalid email address') },
	],
	password: [
		{
			validator: async (rule, value) => {
				if (!value || passwordRegex.test(toString(value))) return Promise.resolve()
				return Promise.reject(
					'Try one more time, your password needs to be a min.8 characters, with at least one uppercase letter, one lowercase letter, one number & one special character',
				)
			},
			message: i18n.t(
				'Try one more time, your password needs to be a min.8 characters, with at least one uppercase letter, one lowercase letter, one number & one special character',
			),
		},
	],
	birthdayDate: [
		{
			validator: async (rule, value) => {
				if (!value || moment(value, 'YYYY-MM-DD').diff(moment()) < 0) return Promise.resolve()
				return Promise.reject('Invalid date.')
			},
			message: i18n.t('Invalid date.'),
		},
	],
	confirmNewPassword: [
		({ getFieldValue }) => ({
			validator(rule, value) {
				if (!value || getFieldValue('newPassword') === value) {
					return Promise.resolve()
				}
				return Promise.reject(i18n.t('The two passwords that you entered do not match!'))
			},
		}),
	],
	phoneNumber: [{ type: 'number', message: i18n.t('Telephone must consist only of numbers') }],
	billingAddressCity: [
		({ getFieldValue }) => ({
			validator(rule, city) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !city
					? Promise.reject(i18n.t('Billing address requires city.'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressStreet: [
		({ getFieldValue }) => ({
			validator(rule, street) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !street
					? Promise.reject(i18n.t('Billing address requires street.'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressCountry: [
		({ getFieldValue }) => ({
			validator(rule, country) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !country
					? Promise.reject(i18n.t('Billing address requires country.'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressLastName: [
		({ getFieldValue }) => ({
			validator(rule, lastName) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !lastName
					? Promise.reject(i18n.t('Billing address requires surname.'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressFirstName: [
		({ getFieldValue }) => ({
			validator(rule, firstName) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !firstName
					? Promise.reject(i18n.t('Field first name is required'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressPhoneNumber: [
		({ getFieldValue }) => ({
			validator(rule, phoneNumber) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !phoneNumber
					? Promise.reject(i18n.t('Billing address telephone is required'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressPostcode: [
		({ getFieldValue }) => ({
			validator(rule, postcode) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !postcode
					? Promise.reject(i18n.t('Field postcode is required'))
					: Promise.resolve()
			},
		}),
	],
	billingAddressBuildNumber: [
		({ getFieldValue }) => ({
			validator(rule, buildNumber) {
				const billingAddress = sanitizeObject(getFieldValue('billingAddress'))
				return !isEmpty(billingAddress) && !buildNumber
					? Promise.reject(i18n.t('Field house number is required'))
					: Promise.resolve()
			},
		}),
	],
}
export const createFormRules = {
	username: [
		{
			validator: async (rule, value) => {
				try {
					const {
						data: { isExist },
					} = await apiRequest(api.validateUsername(value) as AxiosRequestConfig)
					if (isExist) {
						throw new Error('Username already taken')
					}
					return Promise.resolve()
				} catch (error) {
					return Promise.reject(error?.data?.message || error.message)
				}
			},
		},
	],
	email: [
		{
			validator: async (rule, value) => {
				try {
					const {
						data: { isExist },
					} = await apiRequest(api.validateEmail(value) as AxiosRequestConfig)
					if (isExist) {
						throw new Error('Email already taken')
					}
					return Promise.resolve()
				} catch (error) {
					return Promise.reject(error?.data?.message || error.message)
				}
			},
		},
	],
}

export const contactWays: TUserContactWay[] = ['both', 'email', 'phone']

export const parseInitialValues = (user: TUser): TUserForm | Record<string, unknown> =>
	user
		? {
				// Account.
				// Billing address.
				// Privacy settings.
				...pick(user, [
					'biography',
					'firstName',
					'lastName',
					'username',
					'phoneNumber',
					'email',
					'avatar',
					'avatarBackground',
					'isInfluencer',
					'notificationsEmail',
					'notificationsPush',
					'howToContact',
					'billingAddress',
					'isAcceptedRegistration',
					'isAccountPrivate',
					'role',
					'accountStatus',
					'isShareWithFriends',
					'isCanTagMe',
					'isPreRegister',
					'isReseller',
					'isKnowWardrobeValue',
					'isWantToSellInLbd',
					'instagramAccountId',
					'location',
				]),
				birthdayDate: user?.birthdayDate,
				// Body.
				bodyShapeId: user?.bodyShape?.id,
				sizeTopId: user?.sizeTopId,
				sizeBottomId: user?.sizeBottomId,
				sizeShoeId: user?.sizeShoeId,
				sizeJeanId: user?.sizeJeanId,
				// Style.
				categoryIds: mapIds(user?.categories, 'category'),
				clothesStyleIds: mapIds(user?.clothesStyles, 'clothesStyle'),
				brandIds: mapIds(user?.brands, 'brand'),
				onboardingInfluencerIds: mapIds(user?.onboardingInfluencers, 'onboardingInfluencer'),
				fashionSourceIds: mapIds(user?.fashionSources, 'fashionSource'),
				storeIds: mapIds(user?.stores, 'store'),
		  }
		: {
				howToContact: contactWays[0],
				isAcceptedRegistration: false,
				notificationsEmail: true,
				notificationsPush: true,
				isKnowWardrobeValue: true,
				isInfluencer: false,
				isAccountPrivate: false,
		  }
