import * as React from 'react'
import { useTranslation, useRequest, useTableLocationParams } from '@hooks'
import {
	ErrorBoundary,
	Space,
	DataTable,
	ExportButton,
	VerifyUserLink,
	RemoveCodeLink,
	VerifyShopLink,
	RemoveShopCodeLink,
} from '@components'
import { api, message } from '@services'
import { TResponseWithFilters, TFilteredRequest, TProfile } from '@typings'
import { columns } from './AwaitingVerification.tableOptions'

const AwaitingVerificationShopsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const tableRef = React.useRef<any>(null)
	const { t } = useTranslation()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Users data.
	const { data: { data: users, meta } = {}, fetch, fetching } = useRequest<TResponseWithFilters<TProfile[]>, void>(
		{
			request: () => api.getShopCodes(searchParams),
			onError: (error) => message.error(error),
		},
		[searchParams],
	)

	return (
		<ErrorBoundary>
			<Space size="large" direction="vertical">
				<DataTable<TProfile, Partial<TProfile>>
					ref={tableRef}
					loading={fetching}
					columns={columns}
					dataSource={users}
					meta={meta}
					searchInputValues={searchParams}
					autoFetchOnMount={false}
					onChange={setSearchParams}
					HeaderComponent={<ExportButton fileName="awaiting_verification_users.csv" />}
					renderActionsComponent={(shop: TProfile) => (
						<>
							<VerifyShopLink buttonType="link" shopId={shop.id} onSuccess={tableRef.current.refresh} />
							<RemoveShopCodeLink buttonType="link" shopId={shop.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Space>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default AwaitingVerificationShopsScreen
