import {
	TRequestBuilder,
	TStatisticsPayload,
	TLookbook,
	TSharingRequest,
	TAssistType,
	TWardrobeItemStatus,
	TWardrobeItem,
	TMktInformation,
	TProfile,
} from '@typings'
import { upDataTransformer } from '../transformers'

type TWardrobeStatisticsPayload = Partial<
	Pick<TWardrobeItem, 'isBorrowed'> & {
		status: TWardrobeItemStatus | TWardrobeItemStatus[]
		'brand.status': TWardrobeItem['brand']['status']
		'brand.title': string
		marketplaceInformationId: TWardrobeItem['marketplaceInformationId'] | 'null'
		isNotPotentialWardrobeItems: 1 | 0
	}
>

type TMKTStatisticsPayload = {
	status?: TMktInformation['status'] | TMktInformation['status'][]
}

/* Wardrobe */

export const getTotalWardrobeItemsAmount: TRequestBuilder<TWardrobeStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getWardrobeItemsIncrease: TRequestBuilder<TWardrobeStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/quantity`,
	params,
})

export const getTotalWardrobeSumAmount: TRequestBuilder<TWardrobeStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/sum/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getWardrobeSumIncrease: TRequestBuilder<TWardrobeStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/sum`,
	params,
})

export const getTotalWardrobeLikesAmount: TRequestBuilder<TWardrobeStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/quantity/dashboard/likes`,
	params,
	transformResponse: upDataTransformer,
})
export const getWardrobeLikesIncrease: TRequestBuilder<TWardrobeStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/wardrobe-items/statistics/common/quantity/likes`,
	params,
})

/* Users */
///users/statistics/common/quantity/dashboard/influencers
export const getTotalUsersAmount: TRequestBuilder<Pick<TProfile, 'isInfluencer'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getTotalInfluencersAmount: TRequestBuilder<Pick<TProfile, 'isInfluencer'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard/influencers`,
	params,
	transformResponse: upDataTransformer,
})
export const getTotalInfluencersIncrease: TRequestBuilder<Pick<TProfile, 'isInfluencer'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/influencers`,
	params,
	transformResponse: upDataTransformer,
})
export const getActiveUsersAmount: TRequestBuilder<Pick<TProfile, 'isCreatedFromBrand'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard`,
	params: { ...params, isCreatedFromBrand: false },
	transformResponse: upDataTransformer,
})
export const getUnlockedUsersAmount: TRequestBuilder<Pick<TProfile, 'isCreatedFromBrand'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard`,
	params: { ...params, role: ['user', 'admin', 'super-admin'], isCreatedFromBrand: false },
	transformResponse: upDataTransformer,
})
export const getLockedUsersAmount: TRequestBuilder<Pick<TProfile, 'isCreatedFromBrand'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard`,
	params: { ...params, role: 'restricted-user', isCreatedFromBrand: false },
	transformResponse: upDataTransformer,
})

export const getBrandUsersAmount: TRequestBuilder<Pick<TProfile, 'isCreatedFromBrand'>> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard`,
	params: { ...params, isCreatedFromBrand: true },
	transformResponse: upDataTransformer,
})

export const getUsersIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity`,
	params,
})

export const getTotalUsersFollowersAmount: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard/followers`,
	transformResponse: upDataTransformer,
})
export const getUsersFollowersIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/followers`,
	params,
})

export const getTotalUsersWishlistAmount: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/users/statistics/common/quantity/dashboard/wishlist`,
	transformResponse: upDataTransformer,
})
export const getUsersWishlistIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/users/statistics/common/quantity/wishlist`,
	params,
})

/* Lookbooks */

export const getTotalLookbooksAmount: TRequestBuilder<Pick<TLookbook, 'isPrivate'>> = (params) => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getLookbooksIncrease: TRequestBuilder<TStatisticsPayload & Pick<TLookbook, 'isPrivate'>> = (params) => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity`,
	params,
})

export const getTotalLookbooksContributingsAmount: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/dashboard/contributing`,
	transformResponse: upDataTransformer,
})
export const getLookbooksContributingsIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/contributing`,
	params,
})

export const getTotalLookbooksContributorsAmount: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/dashboard/contributor`,
	transformResponse: upDataTransformer,
})
export const getLookbooksContributorsIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/contributor`,
	params,
})

export const getTotalLookbooksLikesAmount: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/dashboard/likes`,
	transformResponse: upDataTransformer,
})
export const getLookbooksLikesIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/lookbooks/statistics/common/quantity/likes`,
	params,
})

/* Sharing */

export const getTotalSharingsAmount: TRequestBuilder<Pick<TSharingRequest, 'objectStatus'>> = (params) => ({
	method: 'get',
	url: `/sharings/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getSharingsIncrease: TRequestBuilder<TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/sharings/statistics/common/quantity`,
	params,
})

/* Assist */

export const getTotalAssistAmount: TRequestBuilder<{ assistType: TAssistType }> = (params) => ({
	method: 'get',
	url: `/assists/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getAssistIncrease: TRequestBuilder<TStatisticsPayload & { assistType: TAssistType }> = (params) => ({
	method: 'get',
	url: `/assists/statistics/common/quantity`,
	params,
})

/* Marketplace */

export const getTotalMarketplaceInformationAmount: TRequestBuilder<TMKTStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/marketplaces/informations/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})
export const getMarketplaceInformationIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (
	params,
) => ({
	method: 'get',
	url: `/marketplaces/informations/statistics/common/quantity`,
	params,
})

export const getTotalPotentialMKTItemsAmount: TRequestBuilder<TWardrobeStatisticsPayload> = (params) =>
	getTotalWardrobeItemsAmount({
		...params,
		status: 'active',
		marketplaceInformationId: 'null',
		'brand.status': 'active',
		isBorrowed: 0,
	})
export const getPotentialMKTItemsIncrease: TRequestBuilder<TWardrobeStatisticsPayload & TStatisticsPayload> = (
	params,
) =>
	getWardrobeItemsIncrease({
		...params,
		status: 'active',
		marketplaceInformationId: 'null',
		'brand.status': 'active',
		isBorrowed: 0,
	})

export const getTotalNotPotentialMKTItemsAmount: TRequestBuilder<TWardrobeStatisticsPayload> = (params) =>
	getTotalWardrobeItemsAmount({
		...params,
		isNotPotentialWardrobeItems: 1,
	})

export const getNotPotentialMKTItemsIncrease: TRequestBuilder<TWardrobeStatisticsPayload & TStatisticsPayload> = (
	params,
) =>
	getWardrobeItemsIncrease({
		...params,
		isNotPotentialWardrobeItems: 1,
	})

/* Feed */
export const getTotalArticlesAmount: TRequestBuilder<TMKTStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/articles/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})

export const getTotalPostsAmount: TRequestBuilder<TMKTStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/posts/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})

export const getTotalSurveysAmount: TRequestBuilder<TMKTStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/surveys/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})

export const getArticlesIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/articles/statistics/common/quantity`,
	params,
})

export const getPostsIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/posts/statistics/common/quantity`,
	params,
})

export const getSurveysIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/surveys/statistics/common/quantity`,
	params,
})

//Brands
export const getTopBrandsByAmount: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/lists/brands/statistics/top-brands`,
	params,
	transformResponse: upDataTransformer,
})

//Comments
export const getCommentsAmount: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/comments/statistics/common/quantity/dashboard`,
	params,
	transformResponse: upDataTransformer,
})

export const getCommentsIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/comments/statistics/common/quantity`,
	params,
})
export const getCommentsByTypeAmount: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/comments/statistics//quantity/dashboard/by-types`,
	params,
	transformResponse: upDataTransformer,
})
export const getCommentsByTypeIncrease: TRequestBuilder<TMKTStatisticsPayload & TStatisticsPayload> = (params) => ({
	method: 'get',
	url: `/comments/statistics/quantity/by-types`,
	params,
})
