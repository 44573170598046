import { SelectField } from '@components'
import { useRequest } from '@hooks'
import { Option, SelectProps, TRequestBuilder } from '@typings'
import React from 'react'

const ApiListSelectField: React.FC<TProps> = ({ request, ...selectOptions }) => {
	const { data: list, fetching } = useRequest<Option[]>({ request }, [])

	return (
		<SelectField
			showSearch
			loading={fetching}
			selectOptions={list}
			filterOption
			optionFilterProp="title"
			allowClear
			{...selectOptions}
		/>
	)
}

type TProps = SelectProps<Option['id']> & {
	/** Function returned axios config for passing to useRequest hook.  */
	request: TRequestBuilder<any>
}

export default ApiListSelectField
