import { TAsset } from './assets'

export { RouteProps } from 'react-router'
export { AxiosRequestConfig, AxiosError, AxiosPromise, AxiosResponse } from 'axios'
export { FieldMetaState } from 'react-final-form'
export { ValidationErrors } from 'final-form'
export { TUseRequestOptions, TUseRequestData } from 'react-axios-helpers'
export { TFunction } from 'i18next'
export { UseArray } from 'react-hanger'
export { TableProps, ColumnProps, ColumnsType, ColumnType } from 'antd/lib/table'
export { InputProps } from 'antd/lib/input'
export { PaginationProps } from 'antd/lib/pagination'
export { ButtonType } from 'antd/lib/button'
export { FormInstance } from 'rc-field-form'
export { SelectProps } from 'antd/lib/select'
export { InputNumberProps } from 'antd/lib/input-number'
export { DatePickerProps } from 'antd/lib/date-picker'
export { FormProps } from 'antd/lib/form'
export { ValidateErrorEntity } from 'rc-field-form/lib/interface'
export { ListItemProps } from 'antd/lib/list'
export { HttpRequestHeader, RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload/interface'
export { FormItemProps, Rule } from 'antd/lib/form'
export { RadioChangeEvent } from 'antd/lib/radio'
export { SwitchChangeEventHandler } from 'antd/lib/switch'
export { TreeProps } from 'antd/lib/tree'

export * from './assets'
export * from './api'
export * from './assist'
export * from './articles'
export * from './bulkUpload'
export * from './categorizedFilters'
export * from './forms'
export * from './hooks'
export * from './posts'
export * from './profile'
export * from './router'
export * from './statistics'
export * from './wardrobe'
export * from './lookbook'
export * from './sharing'
export * from './transactions'
export * from './visenze'
export * from './surveys'
export * from './pushNotification'

export type UTCDate = string
export type Option = {
	id: number
	title: string
	asset?: TAsset
}
export type TOption = { title: string; value: string }
export type TNumberOption = { title: string; value: number }
export type TTitlesMap = { [key in string]: string }
export type TValueView = {
	label: string
	value: string | boolean
}
export type TStatus = 'active' | 'disabled' | 'sale' | 'sold' | 'long_term' | 'gift_accepted' | 'gift_offered'
export type TRole = 'super-admin' | 'admin' | 'user' | 'restricted-user'
export type TImageQuality =
	| 'large' // 1800px
	| 'medium' // 1280px
	| 'small' // 768px
	| 'thumbnail' // 256px
export interface FieldInputProps<FieldValue> {
	name: string
	onBlur: (event?: any) => void
	onChange: (event: any) => void
	onFocus: (event?: any) => void
	type?: string
	value: FieldValue
	checked?: boolean
	multiple?: boolean
}
export type TStatusColor = '#FFFFFF' | '#C4B36A' | '#FC4C4C'

export type TableSortOrder = 'descend' | 'ascend' | null | undefined

export type TTreeItem = Option
export type TSupportedChildrenKeys = 'children' | 'subcategories' | 'sizes' | 'subattributes' | 'subCategorizedFilters'
export type TTree<TChildrenKey extends TSupportedChildrenKeys = 'children', TItem = TTreeItem> = (TItem &
	{
		[key in TSupportedChildrenKeys]: TTree<TChildrenKey, TItem>
	})[]

export type TImageTag = 'sharing-emoji' | 'upload-item-image-suggestion'
