import React from 'react'
import {
	DatePickerField,
	Form,
	Input,
	UploadImageField,
	Switcher,
	SelectField,
	Row,
	Col,
	LocationField,
} from '@components'
import { normalize, formFieldRules, createFormRules } from '../EditUser.formOptions'
import { TFunction } from 'i18next'
import { roles, statuses } from '@constants'

const AccountFields: React.FC<TProps> = ({ updating, t, isAdding }) => (
	<>
		<Form.Item label={t('First Name')} name="firstName" normalize={normalize}>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item label={t('Last Name')} name="lastName" normalize={normalize}>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('User name')}
			name="username"
			normalize={normalize}
			rules={isAdding ? formFieldRules.username.concat(createFormRules.username) : formFieldRules.username}
			hasFeedback={isAdding}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item label={t('Bio')} name="biography" normalize={normalize} hasFeedback={isAdding}>
			<Input disabled={updating} maxLength={150} />
		</Form.Item>
		<Form.Item label={t('Phone number')} name="phoneNumber" normalize={normalize}>
			<Input disabled={updating} type="tel" />
		</Form.Item>
		<Form.Item
			label={t('Date of birth')}
			name="birthdayDate"
			rules={formFieldRules.birthdayDate}
			normalize={normalize}
		>
			<DatePickerField placeholder={t('Choose date')} format="YYYY-MM-DD" mode="date" />
		</Form.Item>
		<Form.Item
			label={t('Email')}
			name="email"
			rules={isAdding ? formFieldRules.email.concat(createFormRules.email as Array<any>) : formFieldRules.email}
		>
			<Input disabled={updating} type="email" />
		</Form.Item>
		<Form.Item label={t('Instagram')} name="instagramAccountId" help={t('coming soon')}>
			<Input disabled />
		</Form.Item>
		<Form.Item label={t('Avatar')} normalize={normalize}>
			<UploadImageField name="avatar" multiple={false} />
		</Form.Item>
		<Form.Item label={t('Avatar background')} normalize={normalize}>
			<UploadImageField name="avatarBackground" multiple={false} />
		</Form.Item>
		<Form.Item label={t('Password')} name="newPassword" hasFeedback rules={formFieldRules.password}>
			<Input.Password placeholder="input password" disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Confirm password')}
			name="confirmNewPassword"
			dependencies={['password']}
			hasFeedback={isAdding}
			rules={formFieldRules.confirmNewPassword}
		>
			<Input.Password placeholder="confirm password" disabled={updating} />
		</Form.Item>
		<Form.Item label={t('Role')} name="role" normalize={normalize}>
			<SelectField
				disabled={updating}
				showSearch
				selectOptions={roles as any}
				filterOption
				optionFilterProp="title"
				allowClear
			/>
		</Form.Item>
		{!isAdding && (
			<Form.Item label={t('Status')} name="accountStatus" normalize={normalize}>
				<SelectField
					disabled={updating}
					showSearch
					selectOptions={statuses as any}
					filterOption
					optionFilterProp="title"
					allowClear
				/>
			</Form.Item>
		)}
		<Form.Item name="location" label={t('Location')}>
			<LocationField withoutCity />
		</Form.Item>
		<Row gutter={[8, 8]}>
			<Col span={12}>
				<Form.Item label={t('Influencer')} name="isInfluencer" valuePropName="checked" normalize={Boolean}>
					<Switcher />
				</Form.Item>
				<Form.Item
					label={t('Private account')}
					name="isAccountPrivate"
					valuePropName="checked"
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item>
				<Form.Item
					label={t('Share with friends')}
					name="isShareWithFriends"
					valuePropName="checked"
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item>
				<Form.Item label={t('Reseller')} name="isReseller" valuePropName="checked" normalize={Boolean}>
					<Switcher />
				</Form.Item>
				<Form.Item label={t('Allows Tagging')} name="isCanTagMe" valuePropName="checked" normalize={Boolean}>
					<Switcher />
				</Form.Item>
			</Col>
			<Col span={12}>
				<Form.Item
					label={t('Knows wardrobe value')}
					name="isKnowWardrobeValue"
					valuePropName="checked"
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item>
				<Form.Item
					label={t('Happy to Sell on LBD')}
					name="isWantToSellInLbd"
					valuePropName="checked"
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item>
				{/* <Form.Item
					label={t('PreRegister')}
					name="isPreRegister"
					valuePropName="checked"
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item> */}
				<Form.Item
					label={t('User accepted rules')}
					name="isAcceptedRegistration"
					valuePropName="checked"
					help={t('It means user have approved Privacy&Policy and Terms&Conditions.')}
					normalize={Boolean}
				>
					<Switcher />
				</Form.Item>
			</Col>
		</Row>
	</>
)

type TProps = {
	updating: boolean
	t: TFunction
	isAdding: boolean
}

export default AccountFields
