import { TRequestBuilder } from '@typings'
import { upDataTransformer, visenzeListTransformer } from '../transformers'

export const getVisenzeCategories: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/services/visenze/categories`,
	params: { order: ['visenzeKey', 'asc'], status: 'active' },
	transformResponse: visenzeListTransformer,
})
export const getVisenzeAttributes: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/services/visenze/attributes`,
	params: { order: ['visenzeKey', 'asc'], status: 'active' },
	transformResponse: visenzeListTransformer,
})
