import { formatToDay, compileImageUri, formatPrice } from '@helpers'
import { ColumnProps, TUser, TShopifyOrder, TWardrobeItem, TCategory } from '@typings'
import { Table, Button, Avatar, Icons, Tag, Link } from '@components'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'ID',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		searchKey: 'id',
	},
	{
		title: 'Number of items',
		dataIndex: 'wardrobeItems',
		align: 'center',
		sorter,
		render: (wardrobeItems: TShopifyOrder['wardrobeItems']) => wardrobeItems.length,
	},
	{
		title: 'Shopify trx ID',
		dataIndex: 'shopifyTransactionId',
		searchKey: 'shopifyTransactionId',
		sorter,
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		render: formatToDay,
		sorter,
		defaultSortOrder: 'descend',
	},
]

export const wardrobeItemsColumns = [
	{
		title: 'ID',
		dataIndex: ['wardrobeItem', 'id'],
		width: 80,
		render: (wardrobeItemId: TWardrobeItem['id']) => (
			<Link to={`/wardrobe-items/${wardrobeItemId}`}>{wardrobeItemId}</Link>
		),
	},
	{
		title: 'Image',
		dataIndex: ['wardrobeItem', 'assets'],
		width: 60,
		render: (assets: TWardrobeItem['assets']) => (
			<Avatar
				shape="square"
				size="large"
				icon={<Icons.UserOutlined />}
				src={compileImageUri(assets?.[0]?.asset, true)}
			/>
		),
	},
	{
		title: 'Categories',
		dataIndex: ['wardrobeItem', 'categories'],
		width: 240,
		render: (categories: TCategory[]) =>
			categories?.map((item: TCategory) => (
				<Tag key={item.category.id} color="orange">
					{item.category.title}
				</Tag>
			)),
	},
	{
		title: 'Name',
		dataIndex: ['wardrobeItem', 'name'],
		width: 120,
	},
	{
		title: 'Purchased price',
		dataIndex: ['wardrobeItem', 'pricePurchased'],
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
	{
		title: 'Estimated price',
		dataIndex: ['wardrobeItem', 'priceEstimated'],
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
	{
		title: 'Actual price',
		dataIndex: ['wardrobeItem', 'priceActual'],
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
]
