import { Button, Card, ErrorBoundary, Form, Input, Popconfirm, When } from '@components'
import { showFormErrors } from '@helpers'
import { useEntityListItemAPI, useHistory, useMemo, useParams, useTranslation } from '@hooks'
import { TMaterial } from '@typings'
import { formFieldRules, formLayout, formButtonLayout } from './EditMaterial.formOptions'
import * as React from 'react'

const EditMaterialScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const id = Number(params?.id)

	const Material = useEntityListItemAPI<TMaterial>('/lists/materials', id, {
		onCreate: history.goBack,
		onUpdate: history.goBack,
		onRemove: history.goBack,
	})

	return (
		<ErrorBoundary>
			<Card
				loading={(id && Material.fetching) || Material.creating || Material.updating}
				title={t('Material')}
				extra={
					<Popconfirm
						title="Are you sure?"
						onConfirm={Material.remove}
						okText={t('Yes')}
						cancelText={t('No')}
					>
						<Button disabled={!Material?.data?.id} loading={Material.removing} type="danger">
							{t('Remove material')}
						</Button>
					</Popconfirm>
				}
			>
				<When condition={Material.fetched || !id}>
					<Form
						onFinish={id ? Material.update : Material.create}
						onFinishFailed={showFormErrors}
						initialValues={Material.data}
						{...formLayout}
					>
						<Form.Item label={t('Title')} name="title" rules={formFieldRules.title}>
							<Input required disabled={Material.updating} />
						</Form.Item>
						<Form.Item wrapperCol={formButtonLayout}>
							<Button type="primary" htmlType="submit" loading={Material.creating || Material.updating}>
								{!!Material?.data?.id ? t('Save') : t('Create')}
							</Button>
							<Button htmlType="button" onClick={history.goBack}>
								{t('Cancel')}
							</Button>
						</Form.Item>
					</Form>
				</When>
			</Card>
		</ErrorBoundary>
	)
}

type TParams = { id?: string }
type TProps = {
	/** */
}

export default EditMaterialScreen
