import { Descriptions, Divider, Tag, List, ImageSquare } from '@components'
import { formatPrice, formatToDay, compileImageUri } from '@helpers'
import { useTranslation } from '@hooks'
import { TWardrobeItem, TWardrobeItemImage } from '@typings'
import React, { useCallback } from 'react'
import BooleanIcon from '../BooleanIcon'

/** It shows information related to wardrobe item. */
const WardrobeItemDescription: React.FC<TProps> = ({ wardrobeItem }) => {
	const { t } = useTranslation()

	const renderImageItem = useCallback(
		({ asset }: TWardrobeItemImage) => <ImageSquare key={asset.id} src={compileImageUri(asset, true)} />,
		[],
	)

	return wardrobeItem ? (
		<>
			<Descriptions bordered={false} title={t('Basic')} size="small" column={1}>
				<Descriptions.Item label={t('Name')}>{wardrobeItem.name}</Descriptions.Item>
				<Descriptions.Item label={t('Description')}>{wardrobeItem.description}</Descriptions.Item>
				<Descriptions.Item label={t('Purchased price')}>
					{formatPrice(wardrobeItem.pricePurchased)}
				</Descriptions.Item>
				<Descriptions.Item label={t('Created at')}>{formatToDay(wardrobeItem.createdAt)}</Descriptions.Item>
				<Descriptions.Item label={t('Updated at')}>{formatToDay(wardrobeItem.updatedAt)}</Descriptions.Item>
			</Descriptions>
			<Divider />
			<Descriptions bordered={false} title={t('Images')} size="small" column={1}>
				<Descriptions.Item>
					<List
						dataSource={wardrobeItem?.assets}
						grid={{ gutter: 16, column: 4 }}
						renderItem={renderImageItem}
					/>
				</Descriptions.Item>
			</Descriptions>
			<Divider />
			<Descriptions bordered title={t('Characteristics')} size="small" column={1}>
				<Descriptions.Item label={t('Categories')}>
					{wardrobeItem.categories?.map((item) => (
						<Tag key={item.category.id} color="purple">
							{item.category.title}
						</Tag>
					))}
				</Descriptions.Item>
				<Descriptions.Item label={t('Brand')}>{wardrobeItem.brand.title}</Descriptions.Item>
				<Descriptions.Item label={t('Attributes')}>
					{wardrobeItem.attributes?.map((item) => (
						<Tag key={item.attribute.id} color="purple">
							{item.attribute.title}
						</Tag>
					))}
				</Descriptions.Item>
				<Descriptions.Item label={t('Styles')}>
					{wardrobeItem.clothesStyles?.map((item) => (
						<Tag key={item.clothesStyle.id} color="orange">
							{item.clothesStyle.title}
						</Tag>
					))}
				</Descriptions.Item>
				<Descriptions.Item label={t('Occasions')}>
					{wardrobeItem.occasions?.map((item) => (
						<Tag key={item.occasion.id} color="volcano">
							{item.occasion.title}
						</Tag>
					))}
				</Descriptions.Item>
				<Descriptions.Item label={t('Condition')}>
					{wardrobeItem.itemConditions?.map((item) => (
						<Tag key={item.itemCondition.id} color="silver">
							{item.itemCondition.title}
						</Tag>
					))}
				</Descriptions.Item>
				<Descriptions.Item label={t('Designers')}>{wardrobeItem.designer?.title}</Descriptions.Item>
				<Descriptions.Item label={t('Season')}>{wardrobeItem.season?.title}</Descriptions.Item>
				<Descriptions.Item label={t('Store')}>{wardrobeItem.store?.title}</Descriptions.Item>
				<Descriptions.Item label={t('Material')}>{wardrobeItem.material?.title}</Descriptions.Item>
				<Descriptions.Item label={t('Color')}>{wardrobeItem.color?.title}</Descriptions.Item>
				<Descriptions.Item label={t('Country')}>{wardrobeItem.country?.title}</Descriptions.Item>
			</Descriptions>
			<Divider />
			<Descriptions bordered={false} title={t('Additional')} size="small" column={1}>
				<Descriptions.Item label={t('Serial number')}>{wardrobeItem.serialNumber}</Descriptions.Item>
				<Descriptions.Item label={t('Has dustbag')}>
					<BooleanIcon value={!!wardrobeItem.dustbag} />
				</Descriptions.Item>
				<Descriptions.Item label={t('Receipt included')}>
					<BooleanIcon value={!!wardrobeItem.receiptIncluded} />
				</Descriptions.Item>
				<Descriptions.Item label={t('Authenticity card')}>
					<BooleanIcon value={!!wardrobeItem.authenticityCard} />
				</Descriptions.Item>
			</Descriptions>
		</>
	) : null
}

type TProps = {
	/** Wardrobe item entity to be displayed. */
	wardrobeItem: TWardrobeItem | undefined
}

export default WardrobeItemDescription
