import * as React from 'react'
import {
	useEffect,
	useCallback,
	useMemo,
	useTranslation,
	useRequest,
	useState,
	useHistory,
	useSurveyAPI,
	useParams,
	useLBDOfficialUserId,
	useTableLocationParams,
	useCsvDownloader,
} from '@hooks'
import {
	ErrorBoundary,
	UploadImageField,
	Button,
	Icons,
	Form,
	Input,
	Card,
	Row,
	Col,
	Popconfirm,
	Radio,
	When,
	DataTable,
	ImageSquare,
} from '@components'
import { api, message, apiSettings } from '@services'
import styles from './EditSurvey.module.css'
import { compileImageUri, showFormErrors, toNumber } from '@helpers'
import { TSurvey, TProfile, TResponseWithFilters, TFilteredRequest, TUser } from '@typings'
import {
	castSurveyToSurveyForm,
	castSurveyFormToSurveyEdit,
	castSurveyFormToSurveyCreate,
	formRules,
	columnsComments,
} from './EditSurvey.formOptions'
import Images from '@images'

const EditSurveyScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const surveyId = toNumber(params?.id)
	const Survey = useSurveyAPI(surveyId, { onAction: () => history.goBack() })
	const [type, setType] = useState('two-answer-options', '')
	const [searchParams, setSearchParams] = useTableLocationParams()
	const initialValues = useMemo(() => castSurveyToSurveyForm(Survey.data), [Survey.data, type])
	const isTwoOptionSurvey = type === 'two-answer-options'
	const isOneAnswerOption = type === 'one-image-options'
	const downloadCsv = useCsvDownloader()

	const userId = useLBDOfficialUserId() || null
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	useEffect(() => {
		const type =
			Survey?.data?.type === 'two-answer-options' && Survey?.data?.assets.length === 1
				? 'one-image-options'
				: Survey?.data?.type
		Survey?.data?.type && type && setType(type)
	}, [Survey.data])

	const submitForm = useCallback(
		(form: Partial<TSurvey>) =>
			Survey?.data
				? Survey.update(castSurveyFormToSurveyEdit({ ...form }, Survey?.data!))
				: Survey.create(castSurveyFormToSurveyCreate({ ...form })),
		[surveyId, userId, initialValues],
	)
	const onChangeType = (e) => {
		setType(e.target.value)
	}

	//Voted Users
	const { data: { data: users, meta: usersMeta } = {}, fetching } = useRequest<
		TResponseWithFilters<TUser[]>,
		TFilteredRequest & Partial<TUser>
	>(
		{
			request: () => surveyId && api.getVotedUsers({ ...searchParams }, surveyId),
			onError: (error) => message.error(error),
		},
		[surveyId, searchParams],
	)
	const exportData = () => {
		downloadCsv(
			users?.map((user) => ({
				'Email Address': user.email,
				'First Name': user.firstName,
				'Last Name:': user.lastName,
				Username: user.username,
			})),
			'voted_users.csv',
		)
	}

	return (
		<ErrorBoundary>
			<When condition={Survey.fetched || !surveyId}>
				<Form initialValues={initialValues} onFinish={submitForm} onFinishFailed={showFormErrors}>
					<Card
						title={t('Survey editor')}
						extra={
							<Row gutter={[16, 16]} align="middle">
								<Col flex={0}>
									<Button htmlType="button" onClick={history.goBack}>
										{t('Cancel')}
									</Button>
								</Col>
								<Col flex={0}>
									<Popconfirm
										title={t('Are you sure? Delete this survey?')}
										onConfirm={Survey.remove}
										okText={t('Yes')}
										cancelText={t('No')}
									>
										<Button
											disabled={!Survey?.data?.id}
											loading={Survey.removing}
											type="primary"
											danger
										>
											{t('Remove survey')}
										</Button>
									</Popconfirm>
								</Col>
								<Col flex={0}>
									<Button
										type="primary"
										htmlType="submit"
										loading={Survey.creating || Survey.updating}
									>
										{Survey?.data?.id ? t('Save') : t('Create')}
									</Button>
								</Col>
							</Row>
						}
					>
						<Row align="middle" justify="center">
							<Form.Item name="type" label="Type" required rules={formRules.type}>
								<Radio.Group onChange={onChangeType} value={type}>
									<Row>
										<When condition={API_SERVER_NAME === 'staging'}>
											<div>
												<Radio disabled={!!surveyId} value={'one-image-options'}>
													{t('One image')}
												</Radio>
												<div>
													<ImageSquare src={Images.ONE_IMAGE} style={{ height: 145 }} />
												</div>
											</div>
										</When>
										<div>
											<Radio disabled={!!surveyId} value={'two-answer-options'}>
												{t('Two images')}
											</Radio>
											<div>
												<ImageSquare src={Images.TWO_OPTIONS} style={{ height: 145 }} />
											</div>
										</div>
										<div>
											<Radio disabled={!!surveyId} value={'three-answer-options'}>
												{t('Tree answers')}
											</Radio>
											<div>
												<ImageSquare src={Images.THREE_OPTIONS} style={{ height: 145 }} />
											</div>
										</div>
									</Row>
								</Radio.Group>
							</Form.Item>
						</Row>
						<div className={styles.cardContent}>
							<div className={styles.mobileContainer}>
								<div className={styles.headerContainer}>
									<div className={styles.iconContainer}>
										<UploadImageField
											withFileList={false}
											name="icon"
											multiple={false}
											listType="text"
											required
											hasFeedback
											showUploadList={false}
											rules={formRules.asset1}
											renderChildren={({ fileList }) => (
												<>
													<div className={styles.iconButton}>
														<Button icon={<Icons.EditOutlined />} />
													</div>
													<img
														className={styles.iconImage}
														src={compileImageUri(fileList[0]?.response?.data, false)}
														alt="icon"
													/>
												</>
											)}
										/>
									</div>

									<div className={styles.mobileContent}>
										<div className={styles.mobileContent}>
											<Form.Item
												name="topic"
												required
												noStyle
												rules={formRules.topic}
												hasFeedback
											>
												<Input.TextArea
													autoSize
													className={styles.headerInputField}
													placeholder="topic"
													maxLength={60}
												/>
											</Form.Item>
										</div>
										<div className={styles.mobileContent}>
											<Form.Item
												name="question"
												required
												noStyle
												rules={formRules.question}
												hasFeedback
											>
												<Input.TextArea
													autoSize
													className={styles.headerInputField}
													placeholder="question"
													maxLength={60}
												/>
											</Form.Item>
										</div>
									</div>
								</div>
								<div className={styles.imageWrapper}>
									<div
										className={isTwoOptionSurvey ? styles.twoImageContainer : styles.imageContainer}
									>
										<UploadImageField
											name="asset1"
											withFileList={false}
											multiple={false}
											listType="text"
											required
											hasFeedback
											showUploadList={false}
											rules={formRules.asset1}
											renderChildren={({ fileList }) =>
												!fileList.length ? (
													<div className={styles.emptyImageContainer}>
														<Button ghost icon={<Icons.UploadOutlined />}>
															{t('Click to Upload')}
														</Button>
													</div>
												) : (
													<>
														<div className={styles.imageButton}>
															<Button icon={<Icons.UploadOutlined />}>{t('Edit')}</Button>
														</div>
														<img
															className={styles.backgroundImage}
															src={compileImageUri(fileList[0]?.response?.data, false)}
															alt="article cover"
														/>
													</>
												)
											}
										/>
									</div>
									{isTwoOptionSurvey && (
										<div
											className={
												isTwoOptionSurvey ? styles.twoImageContainer : styles.imageContainer
											}
										>
											<UploadImageField
												name="asset2"
												withFileList={false}
												multiple={false}
												listType="text"
												required
												hasFeedback
												showUploadList={false}
												rules={formRules.asset2}
												renderChildren={({ fileList }) => {
													return !fileList.length ? (
														<div className={styles.emptyImageContainer}>
															<Button ghost icon={<Icons.UploadOutlined />}>
																{t('Click to Upload')}
															</Button>
														</div>
													) : (
														<>
															<div className={styles.imageButton}>
																<Button icon={<Icons.UploadOutlined />}>
																	{t('Edit')}
																</Button>
															</div>
															<img
																className={styles.backgroundImage}
																src={compileImageUri(
																	fileList[0]?.response?.data,
																	false,
																)}
																alt="article cover"
															/>
														</>
													)
												}}
											/>
										</div>
									)}
								</div>
								<div className={isTwoOptionSurvey ? styles.imageWrapper : styles.answersWrapper}>
									<Form.Item
										name="answerOption1"
										required
										noStyle
										rules={formRules.answerOption1}
										hasFeedback
									>
										<Input.TextArea
											autoSize
											className={styles.headerInputField}
											placeholder="option 1"
											maxLength={30}
										/>
									</Form.Item>
									<Form.Item
										name="answerOption2"
										required
										noStyle
										rules={formRules.answerOption2}
										hasFeedback
									>
										<Input.TextArea
											autoSize
											className={styles.headerInputField}
											placeholder="option 2"
											maxLength={30}
										/>
									</Form.Item>
									{!isTwoOptionSurvey && !isOneAnswerOption && (
										<Form.Item
											name="answerOption3"
											required
											noStyle
											rules={formRules.answerOption3}
											hasFeedback
										>
											<Input.TextArea
												autoSize
												className={styles.headerInputField}
												placeholder="option 3"
												maxLength={30}
											/>
										</Form.Item>
									)}
								</div>
							</div>
						</div>
					</Card>
				</Form>
			</When>
			<When condition={!!surveyId}>
				<Card title={t('Voted Users')}>
					<DataTable<TProfile, Partial<TProfile>>
						loading={fetching}
						columns={columnsComments(surveyId)}
						dataSource={users}
						meta={usersMeta}
						searchInputValues={searchParams}
						autoFetchOnMount={false}
						onChange={setSearchParams}
						withDateRange={false}
						HeaderComponent={
							<Button type="primary" icon={<Icons.DownloadOutlined />} size="middle" onClick={exportData}>
								{t('Export')}
							</Button>
						}
					/>
				</Card>
			</When>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}
type TParams = { id?: string }
export default EditSurveyScreen
