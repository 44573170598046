import { useRequest } from '@hooks'
import { message } from '@services'
import { TResponseWithFilters, TUseArticleCallbacks, TUseEntityListItemAPI } from '@typings'

export default function useEntityListItemAPI<TListItem, TUpdateListItem = Partial<TListItem>>(
	path: string,
	id: number | undefined,
	callbacks: TUseArticleCallbacks<TListItem>,
): TUseEntityListItemAPI<TListItem, TUpdateListItem> {
	const { data: { data } = {}, fetch, fetching, fetched } = useRequest<TResponseWithFilters<TListItem>, void>(
		{
			request: () => (id ? { method: 'get', url: `${path}/${id}` } : null),
			onError: (error) => message.error(error.message),
		},
		[id],
	)

	const { fetch: create, fetching: creating, fetched: created } = useRequest<
		TResponseWithFilters<TListItem>,
		TUpdateListItem
	>({
		request: (newData) => ({ method: 'post', url: `${path}`, data: newData }),
		onSuccess: (response) => {
			fetch()
			callbacks?.onCreate?.(response.data)
			callbacks?.onAction?.(response.data)
		},
		onError: (error) => message.error(error.message),
		cancelOnUnmount: false,
	})

	const { fetch: update, fetching: updating, fetched: updated } = useRequest<
		TResponseWithFilters<TListItem>,
		TUpdateListItem
	>({
		request: (newData) => ({ method: 'put', url: `${path}/${id || newData?.id}`, data: newData }),
		onSuccess: (response) => {
			fetch()
			callbacks?.onUpdate?.(response.data)
			callbacks?.onAction?.(response.data)
		},
		onError: (error) => message.error(error.message),
		cancelOnUnmount: false,
	})

	const { fetch: remove, fetching: removing, fetched: removed } = useRequest<TListItem, void>({
		request: (targetId) => {
			return id ? { method: 'delete', url: `${path}/${id || targetId}` } : null
		},
		onSuccess: () => {
			callbacks?.onRemove?.(data as TListItem)
			callbacks?.onAction?.(data as TListItem)
		},
		onError: (error) => message.error(error.message),
		cancelOnUnmount: false,
	})

	return {
		data,
		create,
		update,
		remove,
		fetch,
		fetching,
		fetched,
		creating,
		created,
		updating,
		updated,
		removing,
		removed,
	}
}
