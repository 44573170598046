import React from 'react'
import { useEffect, useCallback, useMemo, useTranslation } from '@hooks'
import { Title, Text, Switcher } from '@components'
import styles from './ArchiveSwitcher.module.css'

const ArchiveSwitcher: React.FC<TProps> = ({ value, loading = false, disabled = false, onChange }) => {
	const { t } = useTranslation()
	return (
		<Switcher
			loading={loading}
			checked={value}
			checkedChildren={t('Yes')}
			unCheckedChildren={t('No')}
			onChange={onChange}
			disabled={disabled}
		/>
	)
}

type TProps = {
	value?: boolean
	loading?: boolean
	disabled?: boolean
	onChange?: () => void
}

export default ArchiveSwitcher
