import * as React from 'react'
import {
	useEffect,
	useCallback,
	useMemo,
	useTranslation,
	useRequest,
	useState,
	useTableLocationParams,
	useHistory,
} from '@hooks'
import { ErrorBoundary, DataTable, Button, Icons, SurveyRow } from '@components'
import { api, message } from '@services'
import styles from './Promotions.module.css'
import { TPost } from 'src/typings/posts'
import { columns } from '../Users/Users.tableOptions'
import { TSurvey } from 'src/typings/surveys'

const PromotionsScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const [searchParams, setSearchParams] = useTableLocationParams()

	const goToAddNotification = () => history.push(`/promotions/add`)

	return (
		<ErrorBoundary>
			<DataTable<TPost, Partial<TPost>>
				loading={false}
				columns={columns}
				dataSource={[]}
				meta={null}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} onClick={goToAddNotification}>
						{t('Send new push')}
					</Button>
				}
				expandable={{
					expandedRowRender: (survey: TSurvey) => <SurveyRow survey={survey} />,
				}}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default PromotionsScreen
