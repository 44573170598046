import { Form, SelectField, TreeSelectField, Request } from '@components'
import { api } from '@services'
import { TFunction } from 'i18next'
import React from 'react'
import { TBrand, TCategoriesTree, TClothesStyle, TFashionSource, TInfluencer, TStore } from '@typings'
import { normalize } from '../EditUser.formOptions'

const StyleFields: React.FC<TProps> = ({ updating, t }) => (
	<>
		<Request<TCategoriesTree, void> request={() => api.getCategoriesTree({ status: undefined })}>
			{({ data: categoriesTree }) => (
				<Form.Item label={t('Wardrobe interests')} name="categoryIds" normalize={normalize}>
					<TreeSelectField<'subcategories'>
						multiple
						placeholder={t('Choose category')}
						childrenKey="subcategories"
						tree={categoriesTree}
					/>
				</Form.Item>
			)}
		</Request>
		<Request<TClothesStyle[]> request={api.getWardrobeStyles}>
			{({ data: styles, fetching }) => (
				<Form.Item label={t('Style selection')} name="clothesStyleIds">
					<SelectField
						disabled={updating}
						showSearch
						loading={fetching}
						selectOptions={styles}
						filterOption
						optionFilterProp="title"
						allowClear
						mode="multiple"
					/>
				</Form.Item>
			)}
		</Request>
		<Request<TBrand[]> request={api.getBrands}>
			{({ data: brands, fetching }) => (
				<Form.Item label={t('Brands')} name="brandIds">
					<SelectField
						disabled={updating}
						showSearch
						loading={fetching}
						selectOptions={brands}
						filterOption
						optionFilterProp="title"
						allowClear
						mode="multiple"
					/>
				</Form.Item>
			)}
		</Request>
		<Request<TInfluencer[]> request={api.getOnboardingInfluencers}>
			{({ data: influencers, fetching }) => (
				<Form.Item label={t('Influencers')} name="onboardingInfluencerIds">
					<SelectField
						disabled={updating}
						showSearch
						loading={fetching}
						selectOptions={influencers}
						filterOption
						optionFilterProp="title"
						allowClear
						mode="multiple"
					/>
				</Form.Item>
			)}
		</Request>
		<Request<TFashionSource[]> request={api.getFashionSources}>
			{({ data: fashionSources, fetching }) => (
				<Form.Item label={t('Trends')} name="fashionSourceIds">
					<SelectField
						disabled={updating}
						showSearch
						loading={fetching}
						selectOptions={fashionSources}
						filterOption
						optionFilterProp="title"
						allowClear
						mode="multiple"
					/>
				</Form.Item>
			)}
		</Request>
		<Request<TStore[]> request={api.getStores}>
			{({ data: stores, fetching }) => (
				<Form.Item label={t('Shops')} name="storeIds">
					<SelectField
						disabled={updating}
						showSearch
						loading={fetching}
						selectOptions={stores}
						filterOption
						optionFilterProp="title"
						allowClear
						mode="multiple"
					/>
				</Form.Item>
			)}
		</Request>
	</>
)

type TProps = {
	updating: boolean
	t: TFunction
}

export default StyleFields
