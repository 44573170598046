import { useRequest } from '@hooks'
import { TArticle, TArticleEdit } from 'src/typings/articles'
import { message, api, i18n } from '@services'

const EMPTY_ARTICLE_ID_ERROR = i18n.t('useArticleAPI: articleId not passed to mutate request.')

export default function useArticleAPI(
	id: TArticle['id'] | undefined,
	{ onCreate, onUpdate, onRemove, onAction }: TUseArticleCallbacks = {},
): TArticleAPI {
	const { data: article, fetch: fetchArticle, fetching, fetched } = useRequest<TArticle, void>(
		{
			request: () => {
				if (!id) return // create mode
				return api.getArticle(id)
			},
			onError: (error) => message.error(error.message),
		},
		[id],
	)

	const { fetch: create, fetching: creating, fetched: created } = useRequest<TArticle, TArticleEdit>({
		request: (form) => api.createArticle(form),
		onSuccess: (article) => {
			onAction?.()
			onCreate?.(article)
			fetchArticle()
		},
		onError: (error) => message.error(error.message),
	})

	const { fetch: update, fetching: updating, fetched: updated } = useRequest<TArticle, TArticleEdit>({
		request: (form) => api.updateArticle({ id, ...form }),
		onSuccess: (article) => {
			onAction?.()
			onUpdate?.(article)
			fetchArticle()
		},
		onError: (error) => message.error(error.message),
	})

	const { fetch: remove, fetching: removing, fetched: removed } = useRequest<TArticle, void>({
		request: () => {
			if (!id) throw new Error(EMPTY_ARTICLE_ID_ERROR)
			return api.removeArticle(id)
		},
		onSuccess: () => {
			onAction?.()
			onRemove?.(article as TArticle)
		},
		onError: (error) => message.error(error.message),
	})

	const { fetch: setPublish, fetching: publishing, fetched: published } = useRequest<TArticle, boolean>({
		request: (published) => {
			if (!id) throw new Error(EMPTY_ARTICLE_ID_ERROR)
			return published ? api.publishArticle(id) : api.hideArticle(id)
		},
		onSuccess: () => fetchArticle(),
		onError: (error) => message.error(error.message),
	})

	return {
		data: article,
		create,
		update,
		remove,
		setPublish,
		fetching,
		fetched,
		creating,
		created,
		updating,
		updated,
		removing,
		removed,
		publishing,
		published,
	}
}

type TUseArticleCallbacks = {
	onCreate?: (article: TArticle) => void
	onUpdate?: (article: TArticle) => void
	onRemove?: (article: TArticle) => void
	onAction?: () => void
}
type TArticleAPI = {
	data: TArticle | undefined
	create: (form: TArticleEdit) => void | Promise<void>
	update: (form: TArticleEdit) => void | Promise<void>
	remove: (args: any) => void | Promise<void>
	setPublish: (published: boolean) => void | Promise<void>
	fetching: boolean
	fetched: boolean
	creating: boolean
	created: boolean
	updating: boolean
	updated: boolean
	removing: boolean
	removed: boolean
	publishing: boolean
	published: boolean
}
