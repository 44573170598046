import { ImageSquare } from '@components'
import React from 'react'

/** Field allows upload images using native file input and display as raw File. */
const DefferedUploadField: React.FC<TProps> = ({ value = [], onChange }) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files ? Array.from(event.target.files) : []
		onChange?.(fileList)
	}

	return (
		<div>
			{value.map((imageFile: File) => (
				<ImageSquare key={imageFile.name + imageFile.lastModified} src={URL.createObjectURL(imageFile)} />
			))}
			<input id="file" type="file" accept="image/*,.pdf" multiple onChange={handleChange} />
		</div>
	)
}

type TProps = {
	/** Form field userId value */
	value?: File[] | undefined
	/** Callback with new userId, changed by user. */
	onChange?: (value: File[] | undefined) => void
}

export default DefferedUploadField
