export const rowStyle = { flexGrow: 1 }
export const formLayout = {
	labelCol: { span: 4 },
	wrapperCol: { span: 16 },
}
export const formButtonLayout = { offset: 4, span: 16 }
export const formFieldRules = {
	email: [{ required: true, message: 'Please input your email!' }],
	password: [{ required: true, message: 'Please input your password!' }],
}
