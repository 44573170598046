import { editorStateFromRaw, MegadraftEditor } from 'megadraft'
import { plugins } from './ArticleEditor.config'
import 'megadraft/dist/css/megadraft.css'
import React from 'react'
import { EditorState } from 'draft-js'
import styles from './ArticleEditor.module.css'

const ArticleEditor: React.FC<TProps> = ({ value = editorStateFromRaw(null), onChange }) => {
	return <MegadraftEditor movableBlocks resetStyleNewLine editorState={value} onChange={onChange} plugins={plugins} />
}

type TProps = {
	/** Value of editor. */
	value?: EditorState // string | undefined
	/**  Function called on each change of editor value. */
	onChange?: (value: EditorState) => void | Promise<void>
}

export default ArticleEditor
