import {
	Button,
	Card,
	Case,
	ErrorBoundary,
	Switch,
	Switcher,
	Avatar,
	Col,
	Row,
	Spin,
	When,
	Redirect,
	Default,
} from '@components'
import { toNumber, compileImageUri } from '@helpers'
import { useCallback, useHistory, useParams, useState, useTranslation, useRequest } from '@hooks'
import * as React from 'react'
import { UserViewInfo, UserViewWardrobeItems, UserViewLookbooks, UserViewOrders } from '@screens'
import { TUser, TRequestCodeForm } from '@typings'
import { message, api } from '@services'
import { tabList } from './UserView.tabsOptions'

const UserViewScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const userId = toNumber(params?.id)
	const tab = params?.tab

	// User API requests.
	const [user, setUser] = useState<TUser>(undefined as any, 'UserView:user')
	const { fetching } = useRequest<TUser, TUser['id'] | undefined>(
		{
			request: () => (userId ? api.getUser(userId) : null),
			onSuccess: (user) => setUser(user),
			onError: (error) => message.error(error.message),
		},
		[],
	)
	const { fetch: updateRole, fetching: roleUpdating } = useRequest<TUser, boolean>({
		request: (roleChecked) => api.updateUser({ id: userId, role: roleChecked ? 'admin' : 'user' }),
		onSuccess: (user) => setUser(user),
		onError: (error) => message.error(error.message),
	})
	const { fetch: updateAccountUpdating, fetching: accountStatusUpdating } = useRequest<TUser, boolean>({
		request: (statusChecked) =>
			api.updateUser({ id: userId, accountStatus: statusChecked ? 'active' : 'disabled' }),
		onSuccess: (user) => setUser(user),
		onError: (error) => message.error(error.message),
	})

	// Requests access API.
	const { fetch: generateOtpForLogin, fetching: OtpForLoginGenerating } = useRequest({
		request: () => api.generateOtpForLogin(userId),
		onSuccess: () => message.success(t('OTP code successful sended to user email.')),
		onError: (error) => message.error(error.message),
	})

	const { fetch: createCode, fetching: fetchingCreateAccessCode } = useRequest<any, TRequestCodeForm>({
		request: api.createCode,
		onSuccess: (data, form) => {
			history.goBack()
			message.success(t('User is successfully added in awaiting verification list'))
		},
		onError: (error) => message.error(error.message),
	})
	const requestVerificationCode = useCallback(() => {
		createCode({ email: user.email, fullName: `${user.firstName} ${user.lastName}` })
	}, [user])

	// Actions
	const goToEdit = useCallback(() => history.push(`/users/${userId}/edit`), [])
	const goToTab = (newTab: string) => history.push(`/users/${userId}/${newTab}`)

	// Render.
	const avatar = compileImageUri(user?.avatar, true)
	const fullName = `${user?.firstName} ${user?.lastName}`
	const userName = `@${user?.username}`
	const isAdmin = user?.role === 'admin'
	const isRoleSelectorDisabled = user?.role === 'super-admin'
	const isActive = user?.accountStatus === 'active'

	return (
		<ErrorBoundary>
			<Spin spinning={fetching}>
				<Card>
					<When condition={!!user?.id}>
						<Row gutter={[16, 16]} align="middle">
							<Col flex={1}>
								<Card.Meta
									avatar={<Avatar src={avatar} size="large" />}
									title={fullName}
									description={userName}
								/>
							</Col>
							<Col flex={0}>
								<Switcher
									loading={roleUpdating}
									disabled={isRoleSelectorDisabled}
									checked={isAdmin}
									checkedChildren="admin"
									unCheckedChildren="regular"
									onChange={updateRole}
								/>
							</Col>
							<Col flex={0}>
								<Switcher
									loading={accountStatusUpdating}
									checked={isActive}
									checkedChildren="active"
									unCheckedChildren="inactive"
									onChange={updateAccountUpdating}
								/>
							</Col>
							<Col flex={0}>
								<Button loading={OtpForLoginGenerating} onClick={generateOtpForLogin}>
									{t('Request access by OTP')}
								</Button>
							</Col>
							<Col flex={0}>
								<Button type="primary" onClick={goToEdit}>
									{t('Edit')}
								</Button>
							</Col>
						</Row>
					</When>
				</Card>
			</Spin>

			<Card tabList={tabList} activeTabKey={tab} onTabChange={goToTab}>
				<Switch>
					<Case condition={tab === 'info'}>
						<UserViewInfo user={user} fetching={fetching} />
					</Case>
					<Case condition={tab === 'wardrobe_items'}>
						<UserViewWardrobeItems />
					</Case>
					<Case condition={tab === 'lookbooks'}>
						<UserViewLookbooks />
					</Case>
					<Case condition={tab === 'orders'}>
						<UserViewOrders />
					</Case>
					<Default>
						<Redirect to={`/users/${userId}/info`} />
					</Default>
				</Switch>
			</Card>
		</ErrorBoundary>
	)
}

type TParams = { id?: string; tab?: string }
type TProps = {
	/** */
}

export default UserViewScreen
