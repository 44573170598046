import {
	Card,
	CommonStatisticsChart,
	LookbooksStatisticsChart,
	ErrorBoundary,
	Form,
	RouteSwitch,
	Route,
} from '@components'
import { startCase } from '@helpers'
import { useHistory, useMemo, useParams, useTranslation } from '@hooks'
import { message } from '@services'
import { RouteComponentProps } from '@typings'
import * as React from 'react'
import { collectSearchParamsToLocation, defineSearchParams } from './DashboardChart.utils'

/** Display line chart with statistics per period. Supports some set of charts. */
const DashboardChartScreen: React.FC<TProps> = ({ location }) => {
	// Main screen data.
	const { t } = useTranslation()
	const params = useParams<TParams>()
	const history = useHistory()
	const searchParams = useMemo(() => defineSearchParams(location.search), [location.search])

	const handleError = (errorMessage) => {
		history.goBack()
		message.error(errorMessage)
	}

	return (
		<ErrorBoundary>
			<Card title={startCase(params.chart)}>
				<Form
					initialValues={searchParams}
					onValuesChange={(changedValues) =>
						history.replace(collectSearchParamsToLocation(location, changedValues))
					}
				>
					<RouteSwitch>
						<Route
							path="/dashboard/lookbooksIncrease"
							component={() => (
								<LookbooksStatisticsChart searchParams={searchParams} onError={handleError} />
							)}
						/>
						<Route
							path="*"
							component={() => (
								<CommonStatisticsChart
									chart={params.chart}
									searchParams={searchParams}
									onError={handleError}
								/>
							)}
						/>
					</RouteSwitch>
				</Form>
			</Card>
		</ErrorBoundary>
	)
}

type TParams = {
	chart: string
}
type TProps = RouteComponentProps<TParams>

export default DashboardChartScreen
