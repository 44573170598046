import { Button, ErrorBoundary, Result } from '@components'
import { useHistory, useTranslation } from '@hooks'
import * as React from 'react'

const NotFoundScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	return (
		<ErrorBoundary>
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={
					<Button type="primary" onClick={() => history.replace('/dashboard')}>
						{t('Back Home')}
					</Button>
				}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default NotFoundScreen
