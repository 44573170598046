import { sha256 } from '@helpers'
import {
	TChangePasswordForm,
	TFilteredRequest,
	TLoginForm,
	TProfile,
	TUserForm,
	TRequestBuilder,
	TUser,
	TUserUpdateForm,
	TComment,
	TRequestCodeForm,
} from '@typings'
import { upDataTransformer } from '../transformers'

export const login: TRequestBuilder<TLoginForm> = ({ email, password }) => ({
	method: 'post',
	url: `/users/login`,
	data: {
		email,
		password: sha256(password),
	},
	transformResponse: upDataTransformer,
})
// export const loginViaOTP: TRequestBuilder<TLoginForm> = ({ email, password }) => ({
// 	method: 'post',
// 	url: `/users/login-by-otp`,
// 	data: {
// 		email,
// 		otpCode: password,
// 	},
// 	transformResponse: upDataTransformer,
// })
// export const loginViaGoogle: TRequestBuilder<TGoogleTokens> = tokens => ({
// 	method: 'post',
// 	url: `/users/login-google`,
// 	data: tokens,
// 	transformResponse: upDataTransformer,
// })
// export const registerUser: TRequestBuilder<TLoginForm> = loginForm => ({
// 	method: 'post',
// 	url: `/users/registration`,
// 	data: loginForm,
// 	transformResponse: upDataTransformer,
// })

export const getProfile: TRequestBuilder<void> = () => ({
	method: 'get',
	url: `/users/current`,
	transformResponse: upDataTransformer,
})
export const getUser: TRequestBuilder<TProfile['id']> = (userId) => ({
	method: 'get',
	url: `/users/${userId}`,
	transformResponse: upDataTransformer,
})
export const updateUser: TRequestBuilder<Partial<TUser> & { id: number }> = ({ id, avatar, ...form }) => ({
	method: 'put',
	url: `/users/${id}`,
	data: form,
	transformResponse: upDataTransformer,
})
export const requestCode: TRequestBuilder<TRequestCodeForm> = (data) => ({
	method: 'post',
	url: '/pilot-stage/codes/request-code',
	transformResponse: upDataTransformer,
	data,
})
export const createCode: TRequestBuilder<TRequestCodeForm> = (data) => ({
	method: 'post',
	url: '/pilot-stage/codes/',
	transformResponse: upDataTransformer,
	data,
})
// export const getInfluencers = (params?: any) => ({
// 	method: 'get',
// 	url: '/users/influencers',
// 	params,
// })

// export const getInfluencer = (id: number) => ({
// 	method: 'get',
// 	url: `/users/influencers/${id}`,
// 	transformResponse: upDataTransformer,
// })

export const updateProfile: TRequestBuilder<Partial<TUserForm>> = (profileForm) => ({
	method: 'put',
	url: `/users/current`,
	data: profileForm,
	transformResponse: upDataTransformer,
})
export const createUser: TRequestBuilder<TUserUpdateForm> = (profileForm) => ({
	method: 'post',
	url: `/users`,
	params: profileForm,
	transformResponse: upDataTransformer,
})

export const deleteProfile: TRequestBuilder<void> = () => ({
	method: 'post',
	url: '/users/current/deactivate',
})

// export const forgotPassword: TRequestBuilder<Pick<TRestorePasswordForm, 'email'>> = form => ({
// 	method: 'post',
// 	url: '/users/forgot-password',
// 	data: form,
// 	transformResponse: upDataTransformer,
// })

// export const verifyOtpCode: TRequestBuilder<Pick<TRestorePasswordForm, 'email' | 'otpCode'>> = ({
// 	email,
// 	otpCode,
// }) => ({
// 	method: 'post',
// 	url: '/users/forgot-password-verify-otp-code',
// 	data: { email, otpCode },
// 	transformResponse: upDataTransformer,
// })

// export const restorePassword: TRequestBuilder<Pick<TRestorePasswordForm, 'email' | 'otpCode' | 'newPassword'>> = ({
// 	email,
// 	otpCode,
// 	newPassword,
// }) => ({
// 	method: 'post',
// 	url: `/users/set-new-password`,
// 	data: { email, otpCode, newPassword: sha256(newPassword) },
// 	transformResponse: upDataTransformer,
// })

export const changePassword: TRequestBuilder<Pick<TChangePasswordForm, 'oldPassword' | 'newPassword'>> = ({
	oldPassword,
	newPassword,
}) => ({
	method: 'post',
	url: `/users/current/change-password`,
	data: { oldPassword: sha256(oldPassword), newPassword: sha256(newPassword) },
})

export const getUsers: TRequestBuilder<(TFilteredRequest & Partial<TProfile>) | void> = (params = {}) => ({
	method: 'get',
	url: `/users`,
	params,
})

export const deleteUser: TRequestBuilder<TProfile['id']> = (userId) => ({
	method: 'delete',
	url: `/users/${userId}`,
})

export const generateOtpForLogin: TRequestBuilder<TUser['id']> = (userId) => ({
	method: 'post',
	url: `/users/${userId}/generate-otp-for-login`,
})

/* Check */
export const validateUsername: TRequestBuilder<string> = (username) => ({
	method: 'post',
	url: '/users/validations/username',
	data: { username },
})
export const validateEmail: TRequestBuilder<string> = (email) => ({
	method: 'post',
	url: '/users/validations/email',
	data: { email },
})

// Codes verification
export const getUsersCodes: TRequestBuilder<(TFilteredRequest & Partial<TProfile>) | void> = (params = {}) => ({
	method: 'get',
	url: '/pilot-stage/codes',
	params,
})

export const sendCode: TRequestBuilder<number> = (id) => ({
	method: 'post',
	url: `/pilot-stage/codes/${id}/send-code`,
})

export const deleteCode: TRequestBuilder<number> = (id) => ({
	method: 'delete',
	url: `/pilot-stage/codes/${id}`,
})

export const requestLongTermCode: TRequestBuilder<void> = () => ({
	method: 'post',
	url: `/pilot-stage/codes/long-term-access-code`,
	transformResponse: upDataTransformer,
})

export const deleteLongTermCode: TRequestBuilder<number> = (id) => ({
	method: 'delete',
	url: `pilot-stage/codes/long-term-access-code/${id}`,
})

//Comments
export const getComments: TRequestBuilder<TFilteredRequest & TCommentsPayload> = (params = {}) => ({
	method: 'get',
	url: 'comments',
	params,
})

export const deleteComment: TRequestBuilder<(TFilteredRequest & number) | void> = (id) => ({
	method: 'delete',
	url: `comments/${id}`,
})

type TCommentsPayload = {
	'wardrobeItems.wardrobeItemId'?: number
}
