import React from 'react'
import { Avatar, Icons, Link, Text } from '@components'
import { TRole, TStatus, TUser, TFollowerStatistic, TProfile, TWardrobeItem, TBrand, TAsset } from '@typings'
import { formatToDay, compileImageUri, formatPrice } from '@helpers'

const sorter = () => 0

export const userСolumns = [
	{
		title: 'id',
		dataIndex: 'followingId',
		width: 50,
		align: 'center',
		fixed: 'left',
		render: (text: string, record: TUser) => <Link to={`/users/${text}`}>{text}</Link>,
	},
	{
		title: 'avatar',
		dataIndex: 'followingUser',
		width: 60,
		render: (followingUser: TProfile) => (
			<Avatar size="default" icon={<Icons.UserOutlined />} src={compileImageUri(followingUser?.avatar, true)} />
		),
	},
	{
		title: 'First name',
		dataIndex: 'followingUser',
		width: 100,
		render: (followingUser: TProfile) => <p>{followingUser?.firstName}</p>,
	},
	{
		title: 'Last name',
		dataIndex: 'followingUser',
		width: 100,
		render: (followingUser: TProfile) => <p>{followingUser?.lastName}</p>,
	},
	{
		title: 'followers',
		dataIndex: 'amountOfFollowers',
		width: 100,
		render: (amountOfFollowers: TProfile) => <Text strong>{amountOfFollowers}</Text>,
	},
]
export const topUsersWardrobeItemsСolumns = [
	{
		title: 'id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		fixed: 'left',
		render: (text: string, record: TUser) => <Link to={`/users/${text}`}>{text}</Link>,
	},
	{
		title: 'avatar',
		dataIndex: 'avatar',
		width: 60,
		render: (avatar: TAsset) => (
			<Avatar size="default" icon={<Icons.UserOutlined />} src={compileImageUri(avatar, true)} />
		),
	},
	{
		title: 'First name',
		dataIndex: 'firstName',
		width: 100,
		render: (firstName: TProfile['firstName']) => <p>{firstName}</p>,
	},
	{
		title: 'Last name',
		dataIndex: 'lastName',
		width: 100,
		render: (lastName: TProfile['lastName']) => <p>{lastName}</p>,
	},
	{
		title: 'quantity of wardrobe items',
		dataIndex: 'quantityOfWardrobeItems',
		width: 100,
		render: (quantityOfWardrobeItems: TProfile) => <Text strong>{quantityOfWardrobeItems}</Text>,
	},
]

export const topUsersAmountWardrobeItemsСolumns = [
	{
		title: 'id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		fixed: 'left',
		render: (text: string, record: TUser) => <Link to={`/users/${text}`}>{text}</Link>,
	},
	{
		title: 'avatar',
		dataIndex: 'avatar',
		width: 60,
		render: (avatar: TAsset) => (
			<Avatar size="default" icon={<Icons.UserOutlined />} src={compileImageUri(avatar, true)} />
		),
	},
	{
		title: 'First name',
		dataIndex: 'firstName',
		width: 100,
		render: (firstName: TProfile['firstName']) => <p>{firstName}</p>,
	},
	{
		title: 'Last name',
		dataIndex: 'lastName',
		width: 100,
		render: (lastName: TProfile['lastName']) => <p>{lastName}</p>,
	},
	{
		title: 'amount of wardrobe items',
		dataIndex: 'amountOfWardrobeItemsPricePurchase',
		width: 100,
		render: (amountOfWardrobeItemsPricePurchase: TWardrobeItem['pricePurchased']) => (
			<Text strong>{formatPrice(amountOfWardrobeItemsPricePurchase)}</Text>
		),
	},
]

//formatPrice
export const wardrobeTopСolumns = [
	{
		title: 'id',
		dataIndex: 'id',
		width: 50,
		align: 'center',
		render: (text: string, record: TWardrobeItem) => <Link to={`/wardrobe-items/${record.id}`}>{text}</Link>,
	},
	{
		title: 'image',
		dataIndex: 'assets',
		width: 80,
		render: (assets: TWardrobeItem['assets']) => (
			<Avatar
				shape="square"
				size="large"
				icon={<Icons.UserOutlined />}
				src={compileImageUri(assets?.[0]?.asset, true)}
			/>
		),
	},
	{
		title: 'name',
		dataIndex: 'name',
		width: 100,
	},
	{
		title: 'price',
		dataIndex: 'pricePurchased',
		width: 100,
		render: (price: number) => formatPrice(price, false),
	},
]

export const brandsTopСolumns = [
	{
		title: 'id',
		dataIndex: 'brandId',
		width: 50,
		render: (brandId: string) => <Link to={`/brands/${brandId}`}>{brandId}</Link>,
		// align: 'center',
		// render: (text: string, record: TWardrobeItem) => <Link to={`/wardrobe-items/${record.id}`}>{text}</Link>,
	},
	{
		title: 'brand',
		dataIndex: 'brand',
		width: 130,
		render: (brand: TBrand) => <p>{brand.title}</p>,
	},
	{
		title: 'sum',
		dataIndex: 'sumOfWardrobeItemPricePurchased',
		width: 100,
		render: (price: number) => formatPrice(price, false),
	},
]
