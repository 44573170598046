import {
	Button,
	Card,
	DatePickerField,
	Divider,
	ErrorBoundary,
	Form,
	Input,
	InputNumber,
	IntervalField,
	Space,
	StatusSwitcher,
	Switcher,
	UploadImageField,
	SearchableUserField,
	Popconfirm,
	YouTubeField,
	DescriptionEditorField,
	DataTable,
	RemoveCommentLink,
} from '@components'
import { showFormErrors, toNumber, HtmlToDraftEditorState, draftEditorStateToHtml } from '@helpers'
import { TPost, TUpdatePost, TAsset, TResponseWithFilters, TComment, TFilteredRequest, TProfile } from '@typings'
import {
	useHistory,
	useTranslation,
	useParams,
	useEntityListItemAPI,
	useLBDOfficialUserId,
	useRequest,
	useLocation,
} from '@hooks'
import * as React from 'react'
import { formButtonLayout, formFieldRules, formLayout, defaultFormValues, normalize } from './EditPost.formOptions'
import { editorDecorator } from './EditPost.editorDecorator'
import { api, message } from '@services'
import { columnsComments } from '../LookbookView/LookbookView.tableOptions'

/** Form for editing posts on feed. */
const EditPostScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const postId = params?.id ? toNumber(params?.id) : undefined

	// API data.
	const Post = useEntityListItemAPI<TPost, TUpdatePost>(`/posts`, postId, { onAction: () => history.goBack() })
	const postCreatorId = useLBDOfficialUserId()

	// Handle form.
	const initialValues = postId
		? { ...Post.data, description: HtmlToDraftEditorState(String(Post.data?.description), editorDecorator) }
		: defaultFormValues
	const submitForm = ({ asset, ...values }: TPostForm) => {
		const submit = postId ? Post.update : Post.create
		const postData = {
			...values,
			postCreatorId,
			assetId: asset?.id,
			description: normalize(draftEditorStateToHtml(values.description)),
		}
		submit(postData)
	}
	//Comments
	const { data: { data: comments, meta: commentsMeta } = {}, fetch } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TComment>
	>(
		{
			request: () => postId && api.getComments({ 'posts.post.id': postId }),
			onError: (error) => message.error(error),
		},
		[postId],
	)
	const newCommentsData = comments?.map((el) => (el.replies?.length ? { ...el, children: el.replies } : { ...el }))

	return (
		<ErrorBoundary>
			<Card
				title={t('Post')}
				loading={Post.creating || Post.updating}
				extra={
					<Popconfirm
						title={t('Are you sure?')}
						onConfirm={Post.remove}
						okText={t('Yes')}
						cancelText={t('No')}
					>
						<Button loading={Post.removing} danger>
							{t('Remove')}
						</Button>
					</Popconfirm>
				}
			>
				<Form
					key={`post-form-${Post.data?.id}`}
					initialValues={initialValues}
					onFinish={submitForm}
					onFinishFailed={showFormErrors}
					{...formLayout}
				>
					<Form.Item name="postCreatorId" noStyle>
						<span />
					</Form.Item>
					<Form.Item label={t('Title')} name="title" rules={formFieldRules.title} normalize={normalize}>
						<Input maxLength={128} />
					</Form.Item>
					<Form.Item label={t('Description')} name="description">
						<DescriptionEditorField />
					</Form.Item>
					<Form.Item
						label={t('YouTube link')}
						name="videoUrl"
						rules={formFieldRules.videoUrl}
						normalize={normalize}
					>
						<YouTubeField />
					</Form.Item>
					<Form.Item label={t('Image')}>
						<UploadImageField name="asset" multiple={false} />
					</Form.Item>
					<Form.Item label={t('Attached user')} name="userId" normalize={normalize}>
						<SearchableUserField />
					</Form.Item>
					<Divider />
					<Form.Item label={t('Is promotional')} name="isPromotional" valuePropName="checked">
						<Switcher checkedChildren={t('Yes')} unCheckedChildren={t('No')} />
					</Form.Item>
					<Form.Item label={t('Comments allowed')} name="isCommentsAllowed" valuePropName="checked">
						<Switcher checkedChildren={t('Yes')} unCheckedChildren={t('No')} />
					</Form.Item>
					<Form.Item label={t('Status')} name="status">
						<StatusSwitcher />
					</Form.Item>
					<Divider />
					<Form.Item required label={t('Start date')} name="scheduleSettingsStartTime">
						<DatePickerField placeholder={t('Choose date')} showTime format="YYYY-MM-DD HH:mm" />
					</Form.Item>
					<Form.Item label={t('Repetitions')} name="scheduleSettingsRepetitionsLeft">
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item label={t('Interval')} name="scheduleSettingsInterval">
						<IntervalField />
					</Form.Item>
					<Form.Item {...formButtonLayout}>
						<Space>
							<Button type="primary" htmlType="submit" loading={false}>
								{t('Save changes')}
							</Button>
							<Button htmlType="button" onClick={history.goBack}>
								{t('Cancel')}
							</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
			<Card title={t('Comments')}>
				<DataTable<TProfile, Partial<TProfile>>
					columns={columnsComments}
					dataSource={newCommentsData}
					meta={commentsMeta}
					autoFetchOnMount={false}
					renderActionsComponent={(comment: TComment) => (
						<>
							<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Card>
		</ErrorBoundary>
	)
}

type TPostForm = TUpdatePost & { asset?: TAsset }
type TParams = { id?: string }
type TProps = {
	/** */
}

export default EditPostScreen
