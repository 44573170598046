import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { languages } from '@constants'
import en from './translations/en.json'

const defaultLanguage = languages.EN

i18n.use(initReactI18next).init({
	resources: {
		[languages.EN]: { translation: en },
	},
	lng: defaultLanguage,
	fallbackLng: languages.EN,
	react: {
		wait: true,
		nsMode: 'default',
	},
	interpolation: {
		escapeValue: false, // react already safes from xss
		format: (value, format, language) => {
			switch (format) {
				case 'amount':
					return new Intl.NumberFormat(language, { style: 'decimal' }).format(value) //
				default:
					return value
			}
		},
	},
	nsSeparator: false,
	keySeparator: false,
	// debug: __DEV__,
})

export default i18n
