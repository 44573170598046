import { Input } from '@components'
import { debounce } from '@helpers'
import { useCallback, useState, useTranslation } from '@hooks'
import React from 'react'
import styles from './SearchBar.module.css'

/** Live search input with debounce */
const SearchBar: React.FC<TProps> = ({ debounceDelay = 400, onSearch }) => {
	const [value, onChange] = useState('', '')
	const { t } = useTranslation()
	const handleSearch = useCallback(
		debounce((queryText: string | undefined) => onSearch(queryText), debounceDelay),
		[onSearch],
	)

	const changeHandler = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			event.persist()
			const queryText = event.target.value
			onChange(queryText)
			handleSearch(queryText)
		},
		[handleSearch, onChange],
	)

	return (
		<div className={styles.searchContainer}>
			<Input.Search allowClear enterButton placeholder={t('search...')} value={value} onChange={changeHandler} />
		</div>
	)
}

type TProps = {
	/**  Function called on debounce change of search input. */
	onSearch: (value?: string) => void | Promise<void>
	/** Debounce delay affecting calling onSearch */
	debounceDelay?: number
	/** Component rendered on right side of table header component. */
	children?: React.ReactNode
}

export default SearchBar
