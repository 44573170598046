import { Select } from '@components'
import { Option, SelectProps } from '@typings'
import React from 'react'

const SelectField: React.FC<TProps> = ({ selectOptions = [], ...selectProps }) => {
	return (
		<Select defaultActiveFirstOption={false} {...selectProps} filterOption={filterOption}>
			{selectOptions.map((option) => (
				<Select.Option key={option.id} value={option.id}>
					{option.title}
				</Select.Option>
			))}
		</Select>
	)
}

function filterOption(inputValue: Option['title'] = '', option: { children: string }) {
	return !!~option.children.toUpperCase().indexOf(inputValue.toUpperCase())
}

type TProps = SelectProps<Option['id']> & {
	/** Array of options with keys id and title.  */
	selectOptions: Option[]
}

export default SelectField
