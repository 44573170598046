import { useRequest } from '@hooks'
import { TUseRequestData, TUseRequestOptions } from '@typings'

const Request = <TRData, TRProps = any>({ children, dependencies = [], ...props }: TProps<TRData, TRProps>) => {
	const request = useRequest(props, dependencies)
	return children?.(request) ?? null
}

type TProps<TRData = any, TRProps = any> = TUseRequestOptions<TRData, TRProps> & {
	dependencies?: any[]
	children: (requestData: TUseRequestData<TRData, TRProps>) => any
}

export default Request
