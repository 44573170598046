import { Button, Case, Icons, Input, SearchableUserField, Space, Switch, Tooltip } from '@components'
import {
	insertLinkToEditorState,
	removeLinkFromEditorStateSelection,
	makeUserDeepLink,
	editorStateHaveSelection,
} from '@helpers'
import { useState, useTranslation } from '@hooks'
import { Editor, EditorState } from 'draft-js'
import React from 'react'
import styles from './DescriptionEditorField.module.css'

// Utils.
const NOPE = () => null

/** Editor for making html values with link support. Toolbar take ability to add single link and user deep link, remove any link. */
function DescriptionEditorField({ value: editorState = EditorState.createEmpty(), onChange = NOPE }: TProps) {
	const { t } = useTranslation()

	// Toolbar state.
	const [activeTool, setActiveTool] = useState<TEditorTool | null>(null, '')
	const toggleTool = (tool: TEditorTool) => setActiveTool((currentTool) => (currentTool === tool ? null : tool))

	// Link Tool state.
	const [urlValue, setUrlValue] = useState('', '')
	const onURLChange = (event) => setUrlValue(event.target.value)

	// User Tool state.
	const [userValue, setUserValue] = useState<number | undefined>(undefined, '')

	// Actions
	const confirmUrlLink = () => {
		onChange(insertLinkToEditorState(editorState, urlValue))
		setActiveTool(null)
	}
	const confirmUserLink = () => {
		const userLink = makeUserDeepLink(userValue as number)
		onChange(insertLinkToEditorState(editorState, userLink))
		setActiveTool(null)
	}
	const removeLink = (e) => {
		e.preventDefault()
		onChange(removeLinkFromEditorStateSelection(editorState))
		setActiveTool(null)
	}

	// Render states.
	const allowLinksManipulations = !editorStateHaveSelection(editorState)

	return (
		<div>
			<Space direction="vertical">
				<div className={styles.editor}>
					<Editor editorState={editorState} onChange={onChange} />
				</div>
				<Space>
					<Button
						disabled={allowLinksManipulations}
						size="small"
						icon={<Icons.LinkOutlined />}
						onClick={() => toggleTool('link')}
					>
						{t('Add link')}
					</Button>
					<Button
						disabled={allowLinksManipulations}
						size="small"
						icon={<Icons.UserAddOutlined />}
						onClick={() => toggleTool('user')}
					>
						{t('Add user')}
					</Button>
					<Button
						disabled={allowLinksManipulations}
						size="small"
						icon={<Icons.DeleteOutlined />}
						onClick={removeLink}
					>
						{t('Remove link')}
					</Button>
					<Tooltip
						placement="right"
						title={t('Select some text, then use the buttons to add or remove links on the selected text.')}
					>
						<Icons.QuestionCircleOutlined />
					</Tooltip>
				</Space>
				<Switch>
					<Case condition={activeTool === 'link'}>
						<Space>
							<Input value={urlValue} onChange={onURLChange} />
							<Button onClick={confirmUrlLink}>{t('Confirm')}</Button>
						</Space>
					</Case>
					<Case condition={activeTool === 'user'}>
						<Space>
							<div className={styles.inputContainer}>
								<SearchableUserField value={userValue} onChange={setUserValue} />
							</div>
							<Button onClick={confirmUserLink}>{t('Confirm')}</Button>
						</Space>
					</Case>
				</Switch>
			</Space>
		</div>
	)
}

type TEditorTool = 'link' | 'user'
type TProps = {
	/** Form field value with DraftJS EditorState  */
	value?: EditorState
	/** Function for changing form field value as DraftJS EditorState */
	onChange?: (value: EditorState) => void
}

export default DescriptionEditorField
