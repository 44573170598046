import { Link } from '@components'
import { TBrand, TStatus } from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		searchKey: 'id',
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TBrand) => <Link to={`/brands/${record.id}`}>{text}</Link>,
	},
	{
		title: 'Title',
		dataIndex: 'title',
		width: 200,
		searchKey: 'title',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 120,
		filters: [
			{
				text: 'Active',
				value: 'active',
			},
			{
				text: 'Disabled',
				value: 'disabled',
			},
		] as { text: string; value: TStatus }[],
	},
]
