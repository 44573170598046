import { Button, Modal, UploadImage } from '@components'
import { compileImageUri } from '@helpers'
import { useMemo, useState, useTranslation } from '@hooks'
import { TAsset } from '@typings'
import { EditorState } from 'draft-js'
import { insertDataBlock } from 'megadraft'
import React from 'react'
import { TArticleImageData } from 'src/typings/articles'
import styles from './ArticleImageBlockDialog.module.css'

const ArticleImageBlockDialog: React.FC<TProps> = ({ initialValue, onChange, editorState, updateData, onClose }) => {
	const { t } = useTranslation()

	const initialImage = useMemo(
		() => (initialValue ? { key: initialValue?.['data-key'], id: initialValue?.['data-id'] } : undefined),
		[],
	)
	const [image, setImage] = useState<TAsset | undefined>(initialImage, '')

	const insertImage = () => {
		const blockData = {
			type: 'image',
			src: compileImageUri(image, false),
			'data-key': image?.key,
			'data-id': image?.id,
		}
		if (initialValue) {
			updateData?.(blockData)
		} else {
			onChange(insertDataBlock(editorState, blockData))
		}
		onClose()
	}

	return (
		<Modal
			visible
			title={t('Add image')}
			centered
			destroyOnClose
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose}>
					{t('Cancel')}
				</Button>,
				<Button key="submit" type="primary" disabled={!image} onClick={insertImage}>
					{t('Attach')}
				</Button>,
			]}
		>
			<div className={styles.modalContainer}>
				<UploadImage multiple={false} value={image} onChange={setImage} />
			</div>
		</Modal>
	)
}

type TProps = {
	/** ID of current image (if editing). */
	initialValue?: TArticleImageData
	/** Current EditorState passed by draftjs editor. */
	editorState: EditorState
	/** Function for changing EditorState passed by draftjs editor. */
	onChange: (editorState: EditorState) => void
	/** Callback passed for updating selected block. */
	updateData?: (blockData: any) => void
	/** Callback called after releasing dialog. */
	onClose: () => void
}

export default ArticleImageBlockDialog
