import { ErrorBoundary } from '@components'
import * as React from 'react'
import styles from './Documentation.module.css'

/** Documentation section with embedded styleguidist application. */
const DocumentationScreen: React.FC<TProps> = () => {
	return (
		<ErrorBoundary>
			<div
				className={styles.iFrameContainer}
				dangerouslySetInnerHTML={{
					__html: `<iframe src="styleguidist/index.html" width="100%" ></iframe>`,
				}}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default DocumentationScreen
