import { EditorState, RichUtils } from 'draft-js'

/** Remove all links in selected text in DraftJS editor state. */
export default function removeLinkFromEditorStateSelection(editorState: EditorState): EditorState {
	const selection = editorState.getSelection()
	if (!selection.isCollapsed()) {
		const readyEditorState = RichUtils.toggleLink(editorState, selection, null)
		return readyEditorState
	}
	return editorState
}
