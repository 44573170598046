import { Form, Input } from '@components'
import { TFunction } from 'i18next'
import React from 'react'
import { normalize, formFieldRules } from '../EditUser.formOptions'

const BillingAddressFields: React.FC<TProps> = ({ updating, t }) => (
	<>
		<Form.Item
			label={t('First name')}
			name={['billingAddress', 'firstName']}
			rules={formFieldRules.billingAddressFirstName}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Surname')}
			name={['billingAddress', 'lastName']}
			rules={formFieldRules.billingAddressLastName}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Telephone')}
			name={['billingAddress', 'phoneNumber']}
			rules={formFieldRules.billingAddressPhoneNumber}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('House number')}
			name={['billingAddress', 'buildNumber']}
			rules={formFieldRules.billingAddressBuildNumber}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Street')}
			name={['billingAddress', 'street']}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
			rules={formFieldRules.billingAddressStreet}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('City')}
			name={['billingAddress', 'city']}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'postcode'],
				['billingAddress', 'country'],
			]}
			rules={formFieldRules.billingAddressCity}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Postcode')}
			name={['billingAddress', 'postcode']}
			rules={formFieldRules.billingAddressPostcode}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'country'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
		<Form.Item
			label={t('Country')}
			name={['billingAddress', 'country']}
			rules={formFieldRules.billingAddressCountry}
			normalize={normalize}
			dependencies={[
				['billingAddress', 'firstName'],
				['billingAddress', 'lastName'],
				['billingAddress', 'phoneNumber'],
				['billingAddress', 'buildNumber'],
				['billingAddress', 'street'],
				['billingAddress', 'city'],
				['billingAddress', 'postcode'],
			]}
		>
			<Input disabled={updating} />
		</Form.Item>
	</>
)

type TProps = {
	updating: boolean
	t: TFunction
}

export default BillingAddressFields
