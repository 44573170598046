import { Avatar, BooleanIcon, Icons, Tag, Link } from '@components'
import { compileImageUri, formatPrice, formatToDay } from '@helpers'
import {
	TBrand,
	TCategory,
	TColor,
	TCondition,
	TCountry,
	TDesigner,
	TItemAttribute,
	TMaterial,
	TOccasion,
	TSeason,
	TStore,
	TStyle,
	TWardrobeItem,
	TStatus,
} from '@typings'
import React from 'react'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TWardrobeItem) => <Link to={`/wardrobe-items/${record.id}`}>{text}</Link>,
		searchKey: 'id',
	},
	{
		title: 'Image',
		dataIndex: 'assets',
		width: 80,
		render: (assets: TWardrobeItem['assets']) => (
			<Avatar
				shape="square"
				size="large"
				icon={<Icons.UserOutlined />}
				src={compileImageUri(assets?.[0]?.asset, true)}
			/>
		),
	},
	{
		title: 'Categories',
		dataIndex: 'categories',
		width: 240,
		render: (categories) =>
			categories.map((item: TCategory) => (
				<Tag key={item.category.id} color="orange">
					{item.category.title}
				</Tag>
			)),
		searchKey: 'categories.category.title',
	},
	{
		title: 'Attributes',
		dataIndex: 'attributes',
		width: 240,
		render: (attributes: TItemAttribute[]) =>
			attributes?.map((item) => (
				<Tag key={item.attribute.id} color="purple">
					{item.attribute.title}
				</Tag>
			)),
		searchKey: 'attributes.attribute.title',
	},
	{
		title: 'Name',
		dataIndex: 'name',
		width: 180,
		searchKey: 'name',
	},
	{
		title: 'Purchased price',
		dataIndex: 'pricePurchased',
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
	{
		title: 'Estimated price',
		dataIndex: 'priceEstimated',
		width: 80,
		render: (price: number) => formatPrice(price, false),
	},
	{
		title: 'Occasions',
		dataIndex: 'occasions',
		width: 200,
		render: (occasions: TOccasion[]) =>
			occasions?.map((item) => (
				<Tag key={item.occasion.id} color="volcano">
					{item.occasion.title}
				</Tag>
			)),
		searchKey: 'occasions.occasion.title',
	},
	{
		title: 'Designers',
		dataIndex: 'designers',
		width: 200,
		render: (designers: TDesigner[]) =>
			designers?.map((item) => (
				<Tag key={item.id} color="gold">
					{item.title}
				</Tag>
			)),
		searchKey: 'designer.title',
	},
	{
		title: 'Season',
		dataIndex: 'seasons',
		width: 200,
		render: (seasons: TSeason[]) =>
			seasons?.map((item) => (
				<Tag key={item.id} color="geekblue">
					{item.title}
				</Tag>
			)),
		searchKey: 'season.title',
	},
	{
		title: 'Store',
		dataIndex: 'store',
		width: 120,
		render: (store: TStore) => store?.title,
		searchKey: 'store.title',
	},
	{
		title: 'Material',
		dataIndex: 'materials',
		width: 200,
		render: (materials: TMaterial[]) =>
			materials?.map((item) => (
				<Tag key={item.id} color="lightblue">
					{item.title}
				</Tag>
			)),
		searchKey: 'material.title',
	},
	{
		title: 'Color',
		dataIndex: 'color',
		width: 120,
		render: (color: TColor) => color?.title,
		searchKey: 'color.title',
	},
	{
		title: 'Country',
		dataIndex: 'country',
		width: 120,
		render: (country: TCountry) => country?.title,
	},
	{
		title: 'Condition',
		dataIndex: 'itemConditions',
		width: 200,
		render: (itemConditions: TCondition[]) =>
			itemConditions?.map((item) => (
				<Tag key={item.itemCondition.id} color="silver">
					{item.itemCondition.title}
				</Tag>
			)),
		searchKey: 'itemConditions.itemCondition.title',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 80,
		filters: [
			{
				text: 'Active',
				value: 'active',
			},
			{
				text: 'Disabled',
				value: 'disabled',
			},
			{
				text: 'Sale',
				value: 'sale',
			},
			{
				text: 'Sold',
				value: 'sold',
			},
		] as { text: string; value: TStatus }[],
	},
	{
		title: 'Serial number',
		dataIndex: 'serialNumber',
		width: 80,
		searchKey: 'serialNumber',
	},
	{
		title: 'Has dustbag',
		dataIndex: 'dustbag',
		align: 'center',
		width: 80,
		render: (dustbag: boolean) => <BooleanIcon value={dustbag} />,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'Now',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'Receipt included',
		dataIndex: 'receiptIncluded',
		align: 'center',
		width: 80,
		render: (receiptIncluded: boolean) => <BooleanIcon value={receiptIncluded} />,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'Now',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'Authenticity card',
		dataIndex: 'authenticityCard',
		align: 'center',
		width: 80,
		render: (authenticityCard: boolean) => <BooleanIcon value={authenticityCard} />,
		filters: [
			{
				text: 'Yes',
				value: true,
			},
			{
				text: 'Now',
				value: false,
			},
		] as { text: string; value: boolean }[],
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
