import { toString } from '@helpers'
import { useEffect, useMemo, useRequest, useState, useTranslation } from '@hooks'
import { api } from '@services'
import { TLocation, TLocationItem, SelectProps } from '@typings'
import { Select } from 'antd'
import React from 'react'
import {
	castSelectCountryToLocation,
	castSelectCityToLocation,
	getCountrySuggestions,
	getCitySuggestions,
	filterOption,
} from './LocationField.utils'

const LocationField: React.FC<TProps> = ({ value, onChange, debounceDelay = 400, withoutCity = false }) => {
	const { t } = useTranslation()

	// Selected location ids.
	const [countryPlaceId, setCountryPlaceId] = useState<TLocation['countryPlaceId']>(value?.countryPlaceId || null, '')
	const [cityName, setCityName] = useState<TLocation['cityPlaceId']>(value?.cityPlaceId || null, '')
	useEffect(() => {
		setCountryPlaceId(toString(value?.countryPlaceId))
		setCityName(toString(value?.cityPlaceId))
	}, [value])

	// Search suggestions.
	const { fetch: searchCountry, data: countrySuggestions = [], fetching: countrySuggestionsFetching } = useRequest<
		TLocationItem[],
		string
	>({
		request: (countryName = '') => (countryName ? api.searchCountry({ query: countryName }) : null),
	})
	const { fetch: searchCity, data: citySuggestions = [], fetching: citySuggestionsFetching } = useRequest<
		TLocationItem[],
		string
	>({
		request: (cityName = '') =>
			cityName && countryPlaceId ? api.searchCity({ query: cityName, countryPlaceId }) : null,
	})

	// Input handling.
	const handleSelectCountry: SelectProps<TLocation>['onSelect'] = (_, option) =>
		onChange(castSelectCountryToLocation(value, option))
	const handleSelectCity: SelectProps<TLocation>['onSelect'] = (_, option) =>
		onChange(castSelectCityToLocation(value, option))
	const handleResetCountry = (value: string) => !value && handleSelectCountry(value, {} as TSelectOption)
	const handleResetCity = (value: string) => !value && handleSelectCity(value, {} as TSelectOption)

	// Render options for Select lists.
	const CountryOptions = useMemo(() => getCountrySuggestions(countrySuggestions, value), [countrySuggestions])
	const CityOptions = useMemo(() => getCitySuggestions(citySuggestions, value), [citySuggestions])

	return (
		<>
			<Select
				allowClear
				showSearch
				loading={countrySuggestionsFetching}
				value={value?.countryPlaceId || undefined}
				defaultActiveFirstOption={false}
				filterOption={filterOption}
				placeholder={t('Input country...')}
				onChange={handleResetCountry}
				onSearch={searchCountry}
				onSelect={handleSelectCountry}
			>
				{CountryOptions}
			</Select>
			{!withoutCity && (
				<Select
					allowClear
					showSearch
					loading={citySuggestionsFetching}
					value={value?.cityPlaceId || undefined}
					defaultActiveFirstOption={false}
					filterOption={filterOption}
					placeholder={t('Input city...')}
					onChange={handleResetCity}
					onSearch={searchCity}
					onSelect={handleSelectCity}
				>
					{CityOptions}
				</Select>
			)}
		</>
	)
}

type TProps = {
	/** Value of search input. */
	value?: TLocation | null | undefined
	/**  Function called on each change of search input. */
	onChange?: (value?: TLocation | null) => void | Promise<void>
	/** Delay for debounce before make requests to Google API. Default 400. */
	debounceDelay?: number
	withoutCity?: boolean
}
type TSelectOption = { value: string; children: string }

export default LocationField
