import { TRequestBuilder, RcFile, TAsset } from '@typings'
import { upDataTransformer } from '../transformers'

export const uploadImage: TRequestBuilder<RcFile> = (photo) => {
	const data = new FormData()
	data.append('image', photo, photo.fileName)

	return {
		data,
		method: 'post',
		url: `/assets/images/`,
		headers: {
			'Content-Type': 'multipart/form-data',
			'Content-Length': photo.size,
		},
		transformResponse: upDataTransformer,
	}
}

export const getImages: TRequestBuilder<Partial<TAsset>> = (params) => ({
	method: 'get',
	url: `/assets`,
	params: {
		...params,
	},
	transformResponse: upDataTransformer,
})

export const removeImage: TRequestBuilder<TAsset['id']> = (assetId) => ({
	method: 'delete',
	url: `/assets/${assetId}`,
	transformResponse: upDataTransformer,
})

export const uploadVideo = (video: any) => {
	const data = new FormData()
	data.append('video', video, video.fileName)
	return {
		method: 'post',
		url: `/assets/videos`,
		data,
		headers: {
			'Content-Type': 'multipart/form-data',
			'Content-Length': video.size,
		},
		transformResponse: upDataTransformer,
	}
}
export const saveImage = (url: string) => ({
	method: 'get',
	url,
})

export const downloadAssets: TRequestBuilder<{ ids: TAsset['id'][] }> = (params) => ({
	method: 'get',
	url: `/assets/download`,
	params,
	transformResponse: upDataTransformer,
})

export const downloadAsset: TRequestBuilder<TAsset['id']> = (id) => ({
	method: 'get',
	url: `/assets/${id}/download`,
	transformResponse: upDataTransformer,
})
