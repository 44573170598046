import { Form, FormSpy, Modal, Upload, Button } from '@components'
import { config } from '@constants'
import { compileImageUri, takeRight } from '@helpers'
import { useAuthContext, useCallback, useEffect, useMemo, useRef, useState, useRequest, useTranslation } from '@hooks'
import { message, apiSettings, api } from '@services'
import {
	FormInstance,
	TAsset,
	HttpRequestHeader,
	RcFile,
	FormItemProps,
	UploadProps,
	Rule,
	TImageTag,
	UploadFile,
} from '@typings'
import React from 'react'
import { renderUploadChildren, TChangeFileEvent, TFileList, showUploadList } from './UploadImageField.utils'
import styles from './UploadVideo.module.css'
import { UploadOutlined } from '@ant-design/icons'
import { When } from 'react-if'

const initialModalState = {
	isVisible: false,
	image: null,
}

const UploadVideo: React.FC<TProps> = ({ name, renderChildren = renderUploadChildren, rules, tag, ...uploadProps }) => {
	const formInstanceRef = useRef<FormInstance>()
	const { authToken } = useAuthContext()
	const [fileList, setFileList] = useState<TFileList>([], '')
	const [file, setFile] = useState<TAsset | null>(null, '')
	const [modalState, setModalState] = useState<TModalState>(initialModalState, '')
	const image = compileImageUri(file, false)

	const toggleModal = useCallback(
		({ response = null }) => setModalState((st) => ({ ...st, image: response?.data, isVisible: !st.isVisible })),
		[modalState],
	)
	const requestHeaders = useMemo(
		() => ({
			[String(config.API_AUTH_HEADER)]: authToken,
			'X-Requested-With': null,
		}),
		[authToken],
	)

	const getValueFromEvent = useCallback((event: TChangeFileEvent) => {
		const fileIds = event?.fileList?.filter((file) => file.status === 'done').map((file) => file?.response?.data)
		return fileIds[0]?.video
	}, [])

	const beforeUpload = useCallback((file: RcFile, FileList: RcFile[]) => {
		const isSupportedFormat = file.type.startsWith('image/')
		if (!isSupportedFormat) {
			message.error('Unsupported format of file!')
			return false
		}
		return true
	}, [])

	const handleChange = useCallback((event: TChangeFileEvent) => {
		const status = String(event?.file?.status)
		if (status === 'error') {
			message.error(event?.file?.error)
		} else {
			const newFileList = takeRight(event.fileList)
			setFileList(newFileList)
		}
	}, [])

	const props = {
		action: `${apiSettings.getSettings('API_URL')}/assets/videos`,
		listType: 'picture' as 'picture',
	}
	const { fetch: handleRemove } = useRequest<void, UploadFile<{ data: TAsset }>>({
		request: (file) => {
			const assetId = file?.response?.data?.id
			return assetId ? api.removeImage(assetId) : null
		},
		onError: (error) => message.error(error.message),
	})

	const init = useCallback(() => {
		const avatarValue = formInstanceRef.current?.getFieldValue(name)
		if (!avatarValue?.videoKey) return
		const assets: TAsset[] = Array.isArray(avatarValue) ? avatarValue : [avatarValue]
		const initialFileList: TFileList = assets.map((asset) => ({
			uid: String(asset.id),
			size: 0,
			name: `video-${asset.id}`,
			type: 'image/jpg',
			url: compileImageUri(asset, false),
			status: 'done',
			thumbUrl: compileImageUri(asset, true),
			response: {
				data: asset,
			},
		}))
		setFileList(initialFileList)
	}, [])

	useEffect(() => init(), [])

	return (
		<>
			<FormSpy ref={formInstanceRef} />
			<Form.Item name={name} valuePropName="image" getValueFromEvent={getValueFromEvent} noStyle rules={rules}>
				<Upload
					name="image"
					listType="picture-card"
					headers={requestHeaders as HttpRequestHeader}
					onChange={handleChange}
					fileList={fileList}
					onRemove={handleRemove}
					{...props}
				>
					{!fileList.length && <Button icon={<UploadOutlined />}>Upload</Button>}
				</Upload>
			</Form.Item>
			<Modal
				visible={modalState.isVisible}
				centered
				onOk={toggleModal}
				onCancel={toggleModal}
				bodyStyle={{
					textAlign: 'center',
				}}
				width="60%"
			>
				<img src={compileImageUri(modalState.image, true)} alt="" className={styles.image} />
			</Modal>
		</>
	)
}

type TProps = UploadProps &
	Pick<FormItemProps, 'rules' | 'required' | 'hasFeedback' | 'name'> & {
		/** Function that can be render children of Antd Upload component */
		renderChildren?: (props: { fileList: TFileList; multiple: boolean }) => React.ReactNode
		/** Form Item validation rules. */
		rules?: Rule[]
		/** Custome tag for uploading images */
		tag?: TImageTag
	}
type TModalState = {
	isVisible: boolean
	image: null | TAsset
}

export default UploadVideo

///assets/videos
