import { TRequestBuilder, TWardrobeItem } from '@typings'

// export const requestAssistUploading: TRequestBuilder<void> = () => ({
// 	method: 'post',
// 	url: `/assists/request-wardrobe-uploading`,
// })

// export const requestAssistStylist: TRequestBuilder<void> = () => ({
// 	method: 'post',
// 	url: `/assists/request-wardrobe-stylist`,
// })

// export const requestAssistCleaning: TRequestBuilder<void> = () => ({
// 	method: 'post',
// 	url: `/assists/request-wardrobe-cleaning`,
// })

// export const requestAssistBulkMoveToMKT: TRequestBuilder<TWardrobeItem['id'][]> = wardrobeItemIds => ({
// 	method: 'post',
// 	url: `/assists/request-bulk-move-to-marketplace/`,
// 	data: { wardrobeItemIds },
// })
