import {
	Button,
	Card,
	Case,
	Col,
	CommentsBar,
	Divider,
	Else,
	ErrorBoundary,
	Form,
	If,
	List,
	Popconfirm,
	PriceField,
	Row,
	Space,
	Spin,
	Switch,
	Then,
	WardrobeItemDescription,
	When,
	Text,
	ImageSquare,
	Input,
} from '@components'
import { compileImageUri, showFormErrors, toNumber, formatPrice } from '@helpers'
import { useHistory, useMarketplaceItemAPI, useParams, useRequest, useState, useTranslation } from '@hooks'
import { api, message } from '@services'
import { TWardrobeItem } from '@typings'
import * as React from 'react'
import { formFieldRules, normalize } from './MarketplaceItem.formOptions'
import styles from './MarketplaceItem.module.css'
import { tabList, TTabs } from './MarketplaceItem.tabsOptions'
import { isItemInMkt, isItemOnReview } from './MarketplaceItem.utils'

const MarketplaceItemScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const wardrobeItemId = toNumber(params.wardrobeItemId)

	// API data.
	const { data: wardrobeItem, fetching: wardrobeItemFetching } = useRequest<TWardrobeItem, void>(
		{
			request: () => api.getWardrobeItem(wardrobeItemId),
			onError: (error) => message.error(error.message),
		},
		[wardrobeItemId],
	)
	const MKTItem = useMarketplaceItemAPI(wardrobeItem?.marketplaceInformation.id, wardrobeItem?.id, {
		onChangeStatus: (status) => {
			history.goBack()
			message.success(t('Item has been {{status}}', { status }))
		},
	})

	// Form handlers.
	const initialValues: TMktReviewForm = {
		priceEstimated: wardrobeItem?.priceEstimated,
	}
	const { fetch: updateItem } = useRequest<void, TMktReviewForm>({
		request: ({ priceEstimated }) => api.updateWardrobeItem({ id: wardrobeItemId, priceEstimated }),
		onSuccess: (_, values) => MKTItem.approve(),
		onError: (error) => message.error(error.message),
	})

	// Tabs state.
	const [activeTab, setActiveTab] = useState<TTabs>(tabList[0].key, 'MarketplaceItemScreen:activeTab')

	// Actions.
	const goToEditWardrobeItem = () => history.push(`/wardrobe-items/${wardrobeItem.id}`)

	return (
		<ErrorBoundary>
			<Row gutter={16}>
				<Col span={12}>
					<Card
						title={t('Review marketplace request')}
						loading={wardrobeItemFetching}
						extra={
							<If condition={isItemInMkt(wardrobeItem)}>
								<Then>
									<Popconfirm
										title={t('Are you sure?that you want to remove?')}
										onConfirm={MKTItem.remove}
										okText={t('Yes')}
										cancelText={t('No')}
									>
										<Button type="danger">{t('Remove from MKT')}</Button>
									</Popconfirm>
								</Then>
								<Else>
									<Popconfirm
										title={t('Are you sure?that you want to cancel?')}
										onConfirm={MKTItem.cancel}
										okText={t('Yes')}
										cancelText={t('No')}
									>
										<Button type="danger">{t('Cancel request')}</Button>
									</Popconfirm>
								</Else>
							</If>
						}
					>
						<Spin spinning={wardrobeItemFetching || MKTItem.canceling || MKTItem.removing}>
							<Form
								key={`approve-mkt-item-${wardrobeItem?.id}`}
								layout="vertical"
								initialValues={initialValues}
								onFinish={updateItem}
								onFinishFailed={showFormErrors}
							>
								<Form.Item label={t('Images')}>
									<List
										dataSource={wardrobeItem?.marketplaceInformation?.assets}
										grid={{ gutter: 16, column: 4 }}
										renderItem={({ asset }) => (
											<ImageSquare key={asset.id} src={compileImageUri(asset, true)} />
										)}
									/>
								</Form.Item>

								<Space direction="horizontal">
									<Form.Item
										required
										normalize={normalize}
										label={t('Price estimated')}
										name="priceEstimated"
										rules={formFieldRules.priceEstimated}
									>
										<PriceField disabled={!isItemOnReview(wardrobeItem)} />
									</Form.Item>
									<Text code>
										{t('purchased for {{price}}', {
											price: formatPrice(toNumber(wardrobeItem?.pricePurchased)),
										})}
									</Text>
								</Space>
								<Form.Item label={t('Item condition')}>
									<Input
										readOnly
										value={MKTItem.mktInformation?.itemCondition?.title ?? t('Not selected')}
									/>
								</Form.Item>
								<Divider />
								<Form.Item>
									<Space direction="vertical">
										<When condition={isItemOnReview(wardrobeItem)}>
											<Text type="secondary">
												{`* ${t(
													'If you want to reject marketplace request had better send to user message with explanation using chat.',
												)}`}
											</Text>
										</When>
										<Space>
											<Button disabled={!isItemOnReview(wardrobeItem)} onClick={MKTItem.reject}>
												{t('Reject')}
											</Button>
											<Button
												disabled={!isItemOnReview(wardrobeItem)}
												type="primary"
												htmlType="submit"
											>
												{t('Approve')}
											</Button>
										</Space>
									</Space>
								</Form.Item>
							</Form>
						</Spin>
					</Card>
				</Col>
				<Col span={12}>
					<Card tabList={tabList} activeTabKey={activeTab} onTabChange={setActiveTab}>
						<Switch>
							<Case condition={activeTab === 'details'}>
								<WardrobeItemDescription wardrobeItem={wardrobeItem} />
								<Divider />
								<Button disabled={!wardrobeItem?.id} onClick={goToEditWardrobeItem}>
									{t('Edit')}
								</Button>
							</Case>
							<Case condition={activeTab === 'chat'}>
								<Spin spinning={MKTItem.fetching}>
									<When condition={!!MKTItem.mktInformation}>
										<CommentsBar
											disabled={!isItemOnReview(wardrobeItem)}
											comments={MKTItem.comments}
											sending={MKTItem.sending}
											header={t('{{amount}} unviewed by admin', {
												amount: toNumber(MKTItem.mktInformation?.unviewedByAdmin),
											})}
											onSend={MKTItem.sendComment}
										/>
									</When>
								</Spin>
							</Case>
						</Switch>
					</Card>
				</Col>
			</Row>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}
type TParams = {
	wardrobeItemId: string // TWardrobeItem['id']
}
type TMktReviewForm = {
	priceEstimated: TWardrobeItem['priceEstimated']
}

export default MarketplaceItemScreen
