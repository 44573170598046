import {
	ApiListSelectField,
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Popconfirm,
	Row,
	Space,
	Spin,
	TreeSelectField,
	StatusSwitcher,
	Switcher,
	ArchiveSwitcher,
} from '@components'
import { showFormErrors, toNumber } from '@helpers'
import { useEntityListItemAPI, useHistory, useMemo, useParams, useRequest, useTranslation } from '@hooks'
import { api } from '@services'
import {
	TCategoriesTree,
	TCategorizedFilterRule,
	TItemAttributesTree,
	TUpdateCategorizedFilter,
	TUseEntityListItemAPI,
	TCategorizedFilter,
} from '@typings'
import * as React from 'react'
import { castCategorizedFilterToForm, formButtonLayout, formLayout } from './EditCategorizedFilterRule.formOptions'

/** Form which allow edit particular rule in categorized filter or create new rule. */
const EditCategorizedFilterRuleScreen: React.FC<TProps> = ({ CategorizedFilter }) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const categorizedFilterId = toNumber(params.id)
	const ruleId = toNumber(params?.ruleId) || undefined

	// API data.
	const CategorizedFilterRule = useEntityListItemAPI<TCategorizedFilterRule, TUpdateCategorizedFilter>(
		`/categorized-filters/filters/`,
		ruleId,
		{
			onAction: () => {
				CategorizedFilter.fetch()
				history.goBack()
			},
		},
	)
	const { data: categoriesTree } = useRequest<TCategoriesTree>({ request: () => api.getCategoriesTree({}) }, [])
	const { data: attributesTree } = useRequest<TItemAttributesTree>(
		{ request: () => api.getItemAttributesTree({}) },
		[],
	)

	// Handle form.
	const initialValues = useMemo(() => castCategorizedFilterToForm(CategorizedFilterRule.data, categorizedFilterId), [
		CategorizedFilterRule.data?.id,
	])
	const submit = (categorizedFilter: TUpdateCategorizedFilter) =>
		ruleId ? CategorizedFilterRule.update(categorizedFilter) : CategorizedFilterRule.create(categorizedFilter)

	return (
		<ErrorBoundary>
			<Spin spinning={CategorizedFilterRule.fetching || CategorizedFilterRule.updating}>
				<Card
					title={t('Edit categorized filter rule')}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Popconfirm
									title={t('Are you sure?')}
									onConfirm={CategorizedFilterRule.remove}
									okText={t('Yes')}
									cancelText={t('No')}
								>
									<Button loading={CategorizedFilterRule.removing} danger>
										{t('Remove')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					}
				>
					<Form
						key={`categorizedFilterRuleForm-${CategorizedFilterRule.data?.id}`}
						{...formLayout}
						onFinish={submit}
						initialValues={initialValues}
						onFinishFailed={showFormErrors}
					>
						<Form.Item name="categorizedFilterIds" noStyle>
							<span />
						</Form.Item>
						<Form.Item label="Categories" name="categoryIds">
							<TreeSelectField<'subcategories'>
								multiple
								placeholder={t('Choose category')}
								childrenKey="subcategories"
								tree={categoriesTree}
							/>
						</Form.Item>
						<Form.Item label={t('Attributes')} name="attributeIds">
							<TreeSelectField<'subattributes'>
								multiple
								placeholder={t('Choose attributes')}
								childrenKey="subattributes"
								tree={attributesTree}
							/>
						</Form.Item>
						<Form.Item label={t('Brands')} name="brandIds">
							<ApiListSelectField
								placeholder={t('Select brands')}
								mode="multiple"
								request={api.getBrands}
							/>
						</Form.Item>
						<Form.Item label={t('Designers')} name="designerIds">
							<ApiListSelectField
								placeholder={t('Select designers')}
								mode="multiple"
								request={api.getDesigners}
							/>
						</Form.Item>
						<Form.Item label={t('Seasons')} name="seasonIds">
							<ApiListSelectField
								placeholder={t('Select seasons')}
								mode="multiple"
								request={api.getSeasons}
							/>
						</Form.Item>
						<Form.Item label={t('Styles')} name="clothesStyleIds">
							<ApiListSelectField
								mode="multiple"
								placeholder={t('Select styles')}
								request={api.getWardrobeStyles}
							/>
						</Form.Item>
						<Form.Item label={t('Occasions')} name="occasionIds">
							<ApiListSelectField
								mode="multiple"
								placeholder={t('Select occasions')}
								request={api.getOccasions}
							/>
						</Form.Item>
						<Form.Item label={t('Store')} name="storeIds">
							<ApiListSelectField
								placeholder={t('Select stores')}
								mode="multiple"
								request={api.getStores}
							/>
						</Form.Item>
						<Form.Item label={t('Color')} name="colorIds">
							<ApiListSelectField
								placeholder={t('Select colors')}
								mode="multiple"
								request={api.getColours}
							/>
						</Form.Item>
						<Form.Item label={t('Material')} name="materialIds">
							<ApiListSelectField
								placeholder={t('Select materials')}
								mode="multiple"
								request={api.getMaterials}
							/>
						</Form.Item>
						<Form.Item label={t('Is Archive')} name="isArchived">
							<ArchiveSwitcher />
						</Form.Item>
						<Form.Item {...formButtonLayout}>
							<Space>
								<Button type="primary" htmlType="submit" loading={CategorizedFilterRule.updating}>
									{t('Save changes')}
								</Button>
								<Button htmlType="button" onClick={history.goBack}>
									{t('Cancel')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TParams = {
	id: string
	ruleId?: string
}
type TProps = {
	/** API for manipulating category. */
	CategorizedFilter: TUseEntityListItemAPI<TCategorizedFilter, TUpdateCategorizedFilter>
}

export default EditCategorizedFilterRuleScreen
