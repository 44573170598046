import React from 'react'
import { Tooltip } from '@components'
import styles from './IconText.module.css'

const iconProps = { style: { marginRight: 8 } }

/** Display text label with icon and tooltip. */
const IconText: React.FC<TProps> = ({ icon, children: text, title }) => {
	return (
		<Tooltip placement="bottom" title={title}>
			<span className={styles.container} title={title}>
				{React.createElement(icon, iconProps)}
				{text}
			</span>
		</Tooltip>
	)
}

type TProps = {
	/** React icon component from Antd set */
	icon: React.ReactNode
	/** Text content */
	children: string
	/** Title for tooltip */
	title?: string
}

export default IconText
