import { Button, DataTable, ErrorBoundary, Icons, StatusSwitcher } from '@components'
import { useCallback, useHistory, useRef, useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import { TBrand, TFilteredRequest, TResponseWithFilters } from '@typings'
import * as React from 'react'
import { columns } from './BrandsList.tableOptions'

const BrandsListScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = useRef()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Obtain data.
	const { data: { data: brands, meta } = {}, fetch, fetching, fetched } = useRequest<
		TResponseWithFilters<TBrand[]>,
		TFilteredRequest & Partial<TBrand>
	>(
		{
			request: () => api.getBrandsTable(searchParams),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddBrand = useCallback(() => history.push('/brands/add'), [])
	const { fetch: updateBrand } = useRequest<void, Partial<TBrand>>({
		request: (brandValues) => api.updateBrand(brandValues),
		onSuccess: () => tableRef.current.refresh(),
	})

	return (
		<ErrorBoundary>
			<DataTable<TBrand, Partial<TBrand>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={brands}
				meta={meta}
				searchInputValues={searchParams}
				autoFetchOnMount={false}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToAddBrand}>
						{t('Add brand')}
					</Button>
				}
				renderActionsComponentWidth={100}
				renderActionsComponent={(value: object, brand: TBrand) => (
					<StatusSwitcher
						value={brand.status}
						onChange={(status: TBrand['status']) => updateBrand({ id: brand.id, status })}
					/>
				)}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default BrandsListScreen
