import React from 'react'
import { useTranslation, useAuthContext } from '@hooks'
import { Button } from '@components'
import styles from './LayoutLogoutButton.module.css'

const LayoutLogoutButton: React.FC<TProps> = () => {
	const { t } = useTranslation()
	const { logout } = useAuthContext()

	return (
		<Button type="link" size="large" onClick={logout}>
			{t('logout')}
		</Button>
	)
}

type TProps = {
	/**  */
}

export default LayoutLogoutButton
