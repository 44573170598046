import { mapArrayEntityItems } from '@helpers'
import { TCategorizedFilterRule, TUpdateCategorizedFilterRule, TCategorizedFilter } from '@typings'

export function castCategorizedFilterToForm(
	categorizedFilter: TCategorizedFilterRule | undefined,
	categorizedFilterId: TCategorizedFilter['id'],
): Partial<TUpdateCategorizedFilterRule> {
	return categorizedFilter
		? {
				categorizedFilterIds: [categorizedFilter.categorizedFilters?.[0]?.categorizedFilterId],
				brandIds: mapArrayEntityItems(categorizedFilter?.brands, 'brand.id'),
				categoryIds: mapArrayEntityItems(categorizedFilter?.categories, 'category.id'),
				designerIds: mapArrayEntityItems(categorizedFilter?.designers, 'designer.id'),
				seasonIds: mapArrayEntityItems(categorizedFilter?.seasons, 'season.id'),
				storeIds: mapArrayEntityItems(categorizedFilter?.stores, 'store.id'),
				colorIds: mapArrayEntityItems(categorizedFilter?.colors, 'color.id'),
				materialIds: mapArrayEntityItems(categorizedFilter?.materials, 'material.id'),
				attributeIds: mapArrayEntityItems(
					categorizedFilter?.attributes?.filter((item) => !!item.attribute.parentId), // cut parent nodes
					'attribute.id',
				),
				clothesStyleIds: mapArrayEntityItems(categorizedFilter?.clothesStyles, 'clothesStyle.id'),
				occasionIds: mapArrayEntityItems(categorizedFilter?.occasions, 'occasion.id'),
				isArchived: categorizedFilter?.isArchived,
		  }
		: { categorizedFilterIds: [categorizedFilterId] }
}

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 10 },
}
export const formButtonLayout = { wrapperCol: { offset: 6, span: 12 } }
export const formFieldRules = {}
