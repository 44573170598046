import { TableSortOrder, TOrderDirection, ColumnType, ColumnsType, TGlobalSearchParams } from '@typings'
import { toNumber, mapValues, isPlainObject } from '@helpers'

export const defaultMeta = { offset: 0, limit: 0, totalCounts: 0 }
export function tableSortToAPI<RecordType>(tableSort: TableSortOrder): TOrderDirection | undefined {
	switch (tableSort) {
		case 'ascend':
			return 'asc'
		case 'descend':
			return 'desc'
		default:
			return undefined
	}
}
export function APISortToTable<RecordType>(apiSort: TOrderDirection | undefined): TableSortOrder {
	switch (apiSort) {
		case 'asc':
			return 'ascend'
		case 'desc':
			return 'descend'
		default:
			return null
	}
}

// Parse defaultFilteredValue from columns for passing to fetch function.
export function getDefaultFilters<RecordType>(columns: ColumnType<RecordType>[] = []) {
	return columns.reduce(
		(defaultFilters: { [column: string]: any } = {}, column) =>
			column.defaultFilteredValue
				? { ...defaultFilters, [prepareDataIndex(column.dataIndex)]: column.defaultFilteredValue }
				: defaultFilters,
		{},
	)
}

export const calcTableWidth = (columns: ColumnsType<any>): number =>
	columns?.reduce((sum, column) => sum + toNumber(column.width) || 40, 0)

export function prepareDataIndex(dataIndex: string | string[]): string {
	return Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex
}

export function getDefaultSorting(columns: ColumnType<any>[] = []) {
	const sortedItem = columns.find((column) => !!column.defaultSortOrder)
	return sortedItem
		? { order: [prepareDataIndex(sortedItem.dataIndex), tableSortToAPI(sortedItem.defaultSortOrder)] }
		: {}
}

export function getGlobalSearchColumns(columns: ColumnType<any>[] = []) {
	return columns?.filter((column) => !!column.searchKey)?.map((column) => column.searchKey) || []
}

// for example, transform '[substring]:query' to 'query'.
export const parseSearchInputValues = (searchParams) => {
	const parsedParams = mapValues(searchParams, (value) =>
		typeof value === 'string' ? value?.match(/(\[.*\]:)?(.*)/)?.[2] || '' : value,
	)

	// Pick first value from global search object.
	try {
		if ('globalSearch' in parsedParams)
			parsedParams.globalSearch = Object.values(JSON.parse(parsedParams.globalSearch))?.[0]?.match(
				/(\[.*\]:)?(.*)/,
			)?.[2]
	} catch (error) {
		null
	}

	return parsedParams
}
