import { Form, SelectField, TreeSelectField, Request } from '@components'
import { api } from '@services'
import { TBodyShape, TChartType, TTree, Option } from '@typings'
import { TFunction } from 'i18next'
import React from 'react'
import { useCallback } from '@hooks'
import { normalize } from '../EditUser.formOptions'

const BodyFields: React.FC<TProps> = ({ updating, t }) => {
	// Sizes tree view helpers.
	const keyExtractor = useCallback(
		(node) => (node?.chartType?.title ? node?.chartType?.title + node?.title : node.id),
		[],
	)
	const canSelect = useCallback((node) => !node?.chartType?.id, [])

	return (
		<>
			<Request<TBodyShape[]> request={api.getBodyShapes}>
				{({ data: bodyShapes, fetching }) => (
					<Form.Item label={t('Body shape')} name="bodyShapeId" normalize={normalize}>
						<SelectField
							disabled={updating}
							showSearch
							loading={fetching}
							selectOptions={bodyShapes}
							filterOption
							optionFilterProp="title"
							allowClear
						/>
					</Form.Item>
				)}
			</Request>
			<Request<TTree<'sizes', Option>> request={() => api.getSizeCharts({ chartTypeId: TChartType.SHOES })}>
				{({ data: clothesSizes }) => (
					<>
						<Form.Item label={t('Top size')} name="sizeTopId" normalize={normalize}>
							<TreeSelectField<'sizes'>
								placeholder={t('Choose size')}
								childrenKey="sizes"
								tree={clothesSizes}
								keyExtractor={keyExtractor}
								canSelect={canSelect}
							/>
						</Form.Item>
						<Form.Item label={t('Bottom size')} name="sizeBottomId" normalize={normalize}>
							<TreeSelectField<'sizes'>
								placeholder={t('Choose size')}
								childrenKey="sizes"
								tree={clothesSizes}
								keyExtractor={keyExtractor}
								canSelect={canSelect}
							/>
						</Form.Item>
					</>
				)}
			</Request>
			<Request<TTree<'sizes', Option>> request={() => api.getSizeCharts({ chartTypeId: TChartType.SHOES })}>
				{({ data: shoesSizes }) => (
					<Form.Item label={t('Shoe size')} name="sizeShoeId" normalize={normalize}>
						<TreeSelectField<'sizes'>
							placeholder={t('Choose size')}
							childrenKey="sizes"
							tree={shoesSizes}
							keyExtractor={keyExtractor}
							canSelect={canSelect}
						/>
					</Form.Item>
				)}
			</Request>
			<Request<TTree<'sizes', Option>> request={() => api.getSizeCharts({ chartTypeId: TChartType.JEANS })}>
				{({ data: jeanSizes }) => (
					<Form.Item label={t('Jean size')} name="sizeJeanId" normalize={normalize}>
						<TreeSelectField<'sizes'>
							placeholder={t('Choose size')}
							childrenKey="sizes"
							tree={jeanSizes}
							keyExtractor={keyExtractor}
							canSelect={canSelect}
						/>
					</Form.Item>
				)}
			</Request>
		</>
	)
}

type TProps = {
	updating: boolean
	t: TFunction
}

export default BodyFields
