import {
	Button,
	Card,
	Col,
	ErrorBoundary,
	Form,
	Input,
	Popconfirm,
	Row,
	Spin,
	StatusSwitcher,
	Switcher,
	UploadImageField,
	When,
	Space,
	Modal,
} from '@components'
import { showFormErrors, toNumber } from '@helpers'
import { useHistory, useParams, useTranslation, useUserProfileContext } from '@hooks'
import { TCategorizedFilter, TUpdateCategorizedFilter, TUseEntityListItemAPI, TCategorizedFiltersTree } from '@typings'
import * as React from 'react'
import {
	DEFAULT_INITIAL_VALUES,
	formButtonLayout,
	formFieldRules,
	formLayout,
	prepareForm,
} from './EditCategorizedFilter.formOptions'

/** Form for editing current category and create new subcategory. */
const EditCategorizedFilterScreen: React.FC<TProps> = ({
	isAdding,
	CategorizedFilter,
	categorizedFiltersTree = [],
}) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const id = params?.id ? toNumber(params?.id) : null

	// Actions.
	const handleSubmit = async (form: TUpdateCategorizedFilter) => {
		if (categorizedFiltersTree.some((item) => item.title?.toLowerCase() === form.title?.toLowerCase())) {
			const { info } = Modal
			return new Promise(() => {
				info({
					title: 'The category is already exist',
				})
			})
		}
		if (isAdding) {
			await CategorizedFilter.create({ parentId: id, ...prepareForm(form) })
		} else {
			await CategorizedFilter.update(prepareForm(form))
		}
		history.goBack()
	}

	const initialValues = isAdding ? DEFAULT_INITIAL_VALUES : { ...CategorizedFilter.data }

	return (
		<ErrorBoundary>
			<Spin spinning={CategorizedFilter.creating || CategorizedFilter.updating}>
				<Card
					loading={CategorizedFilter.fetching}
					title={
						isAdding
							? t('Add new categorized filter to {{categoryName}}', {
									categoryName: id ? CategorizedFilter.data?.title : 'root',
							  })
							: CategorizedFilter.data?.title
					}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Popconfirm
									title={t('Are you sure?')}
									onConfirm={CategorizedFilter.remove}
									okText={t('Yes')}
									cancelText={t('No')}
								>
									<Button loading={CategorizedFilter.removing} danger>
										{t('Remove')}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					}
				>
					<Form
						key={`categorizedFilter-form-${CategorizedFilter.data?.id}`} // Need for reinitialize form on change category.
						{...formLayout}
						onFinish={handleSubmit}
						initialValues={initialValues}
						onFinishFailed={showFormErrors}
					>
						<Form.Item required name="title" label="Title" rules={formFieldRules.title}>
							<Input />
						</Form.Item>
						<Form.Item label={t('Image')}>
							<UploadImageField name="asset" multiple={false} />
						</Form.Item>
						<When condition={!isAdding}>
							<Form.Item name="status" label={t('Status')}>
								<StatusSwitcher />
							</Form.Item>
						</When>
						{/* Disabled due to complicated logic for users and bug on backend side (Bogdan Burdin knows more) @dmitry.nikolenko.
							<Form.Item
								name="isUsingSubcategoryFilters"
								label={t('Use sub filters')}
								valuePropName="checked"
							>
								<Switcher />
							</Form.Item> 
						*/}
						<Form.Item wrapperCol={formButtonLayout}>
							<Space>
								<Button
									type="primary"
									htmlType="submit"
									loading={CategorizedFilter.updating || CategorizedFilter.creating}
								>
									{t('Save changes')}
								</Button>
								<Button htmlType="button" onClick={history.goBack}>
									{t('Cancel')}
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TParams = { id: string | undefined }
type TProps = {
	/** Form mode. Should form work in adding new subcategory mode. Otherwise form will edit current category. */
	isAdding: boolean
	/** API for manipulating category. */
	CategorizedFilter: TUseEntityListItemAPI<TCategorizedFilter, TUpdateCategorizedFilter>
	categorizedFiltersTree?: TCategorizedFiltersTree
}

export default EditCategorizedFilterScreen
