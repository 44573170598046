import { Button, Card, ErrorBoundary, Spin, Icons, Tooltip, Row, Col, TreeView } from '@components'
import { last } from '@helpers'
import { useHistory, useMemo, useTranslation } from '@hooks'
import {
	TreeProps,
	TUseEntityListItemAPI,
	TCategorizedFiltersTree,
	TCategorizedFilter,
	TUpdateCategorizedFilter,
} from '@typings'
import * as React from 'react'
import { prepareCategoriesTree } from './CategorizedFiltersList.utils'

/** Tree view with all categorized filters. */
const CategorizedFiltersListScreen: React.FC<TProps> = ({
	categorizedFiltersTree: categoriesTree,
	loading,
	CategorizedFilter,
	selectedKeys,
	setSelectedKeys,
}) => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()

	// Handle tree.
	const categoriesTreeData: TreeProps['treeData'] = useMemo(() => prepareCategoriesTree(categoriesTree), [
		categoriesTree,
	])

	// Actions.
	const goToAdding = () => {
		const key = last(selectedKeys)
		history.push(key ? `/categorized-filters/${key}/add` : `/categorized-filters/add`)
	}
	const handleDrop: TreeProps['onDrop'] = ({ node, dragNode, dropToGap }) => {
		const parentId = dropToGap ? node?.parentId || null : node?.id
		CategorizedFilter.update({ id: dragNode.id, parentId })
	}

	return (
		<ErrorBoundary>
			<Spin spinning={loading}>
				<Card
					title={t('Categorized filters')}
					extra={
						<Row gutter={16} align="middle">
							<Col>
								<Tooltip
									placement="top"
									title={t(
										'You have ability to change position of categorized filter using drag&drop',
									)}
								>
									<Icons.QuestionCircleOutlined />
								</Tooltip>
							</Col>
							<Col>
								<Button onClick={goToAdding}>{t('Add')}</Button>
							</Col>
						</Row>
					}
				>
					<TreeView<TCategorizedFiltersTree>
						draggable
						tree={categoriesTreeData}
						selectedKeys={selectedKeys}
						onSelect={setSelectedKeys}
						onDrop={handleDrop}
						childrenKey="subCategorizedFilters"
					/>
				</Card>
			</Spin>
		</ErrorBoundary>
	)
}

type TProps = {
	/** Tree of all categorized filters in database. */
	categorizedFiltersTree: TCategorizedFiltersTree
	/** Category which had been selected by user. */
	selectedKeys: string[]
	/** Callback for changing selected category. */
	setSelectedKeys: (selectedKeys: string[]) => void
	/** API for manipulating category. */
	CategorizedFilter: TUseEntityListItemAPI<TCategorizedFilter, TUpdateCategorizedFilter>
	/** Should display loading state. */
	loading: boolean
}

export default CategorizedFiltersListScreen
