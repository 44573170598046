import {
	ChartPie,
	Col,
	ErrorBoundary,
	Request,
	Row,
	StatisticsCard,
	Title,
	Gap,
	Card,
	DataTable,
	StatisticCardLarge,
} from '@components'
import { formatPrice } from '@helpers'
import { useHistory, useTranslation } from '@hooks'
import { api, apiSettings } from '@services'
import styles from './Dashboard.module.css'
import { TStatisticsSet, TProfile } from '@typings'
import React from 'react'
import { adaptWardrobeStatisticsToPieChart, adaptWardrobeCategoryStatistic } from './Dashboard.utils'
import {
	userСolumns as userColumns,
	wardrobeTopСolumns,
	brandsTopСolumns,
	topUsersWardrobeItemsСolumns,
	topUsersAmountWardrobeItemsСolumns,
} from './Dashboard.tableOptions'
import { Collapse } from 'antd'
const { Panel } = Collapse

/** Dashboard with widget of total count of statistics values. */
function DashboardScreen() {
	const { t } = useTranslation()
	const history = useHistory()
	const API_SERVER_NAME = apiSettings.getSettings('API_SERVER_NAME')

	return (
		<ErrorBoundary>
			<Row gutter={[17, 8]} align="stretch">
				<Col span={5}>
					<Request<TStatisticsSet> request={api.getUsersStatistic}>
						{({ data, fetching }) => (
							<ChartPie
								data={adaptWardrobeStatisticsToPieChart(data)}
								loading={fetching}
								onClick={() => history.push('/wardrobe-items')}
								title={t('Wardrobe values')}
							/>
						)}
					</Request>
				</Col>
				<Col span={7}>
					<Request<TStatisticsSet>
						request={() =>
							api.getTopWardrobeItems({ limit: 5, offset: 0, order: ['pricePurchased', 'desc'] })
						}
					>
						{({ data, fetching }) => (
							<Card className={styles.topUsersContainer} title={t('Top 5 most expensive wardrobe items')}>
								<DataTable<TProfile, Partial<TProfile>>
									loading={fetching}
									columns={wardrobeTopСolumns}
									dataSource={data}
									autoFetchOnMount={false}
									withDateRange={false}
								/>
							</Card>
						)}
					</Request>
				</Col>
				<Col span={7}>
					<Request<TStatisticsSet> request={() => api.getTopBrandsByAmount({ limit: 5 })}>
						{({ data, fetching }) => (
							<Card className={styles.topUsersContainer} title={t('Top 5 most expensive brands')}>
								<DataTable<TProfile, Partial<TProfile>>
									loading={fetching}
									columns={brandsTopСolumns}
									dataSource={data}
									autoFetchOnMount={false}
									withDateRange={false}
								/>
							</Card>
						)}
					</Request>
				</Col>
				<Col span={5}>
					<Request<TStatisticsSet> request={api.getWardrobeItemsByCategoryStatistic}>
						{({ data, fetching }) => (
							<ChartPie
								data={adaptWardrobeCategoryStatistic(data)}
								loading={fetching}
								onClick={() => history.push('/wardrobe-items')}
								title={t('Wardrobe categories')}
								type="amount"
							/>
						)}
					</Request>
				</Col>
			</Row>
			<Row gutter={[12, 8]}>
				<Col span={12}>
					<Request<TStatisticsSet>
						request={() => api.getTopUsersStatistic({ limit: 10, basedOn: 'followers' })}
					>
						{({ data, fetching }) => (
							<Card className={styles.topUsersContainer} title={t('Top 10 users based on followers')}>
								<DataTable<TProfile, Partial<TProfile>>
									loading={fetching}
									columns={userColumns}
									dataSource={data}
									autoFetchOnMount={false}
									withDateRange={false}
								/>
							</Card>
						)}
					</Request>
				</Col>
				<Col span={12}>
					<Request<{ articleCommentStatisticFields: TStatisticsSet }> request={api.getCommentsByTypeAmount}>
						{({ data, fetching }) => (
							<StatisticCardLarge
								data={data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newCommentsByCategory')}
							/>
						)}
					</Request>
				</Col>
				<Col span={12}>
					<Request<TStatisticsSet>
						request={() => api.getTopUsersStatistic({ limit: 10, basedOn: 'wardrobe_item_count' })}
					>
						{({ data, fetching }) => (
							<Card
								className={styles.topUsersContainer}
								title={t('Top 10 users based on wardrobe item count')}
							>
								<DataTable<TProfile, Partial<TProfile>>
									loading={fetching}
									columns={topUsersWardrobeItemsСolumns}
									dataSource={data}
									autoFetchOnMount={false}
									withDateRange={false}
								/>
							</Card>
						)}
					</Request>
				</Col>
				<Col span={12}>
					<Request<TStatisticsSet>
						request={() => api.getTopUsersStatistic({ limit: 10, basedOn: 'wardrobe_item_price_purchase' })}
					>
						{({ data, fetching }) => (
							<Card
								className={styles.topUsersContainer}
								title={t('Top 10 users based on total wardrobe spent')}
							>
								<DataTable<TProfile, Partial<TProfile>>
									loading={fetching}
									columns={topUsersAmountWardrobeItemsСolumns}
									dataSource={data}
									autoFetchOnMount={false}
									withDateRange={false}
								/>
							</Card>
						)}
					</Request>
				</Col>
			</Row>
			<Title level={4}>{t('Users')}</Title>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalUsersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total users')}
								{...data}
								loading={fetching}
								onClick={() => null}
								// onClick={() => history.push('/dashboard/newUsers')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getActiveUsersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Active users')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/activeUsers')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getUnlockedUsersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Unlocked Users')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/unlockedUsers')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getBrandUsersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Users from brand')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/brandUsers')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalUsersFollowersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total followers')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newUsersFollowers')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalInfluencersAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total influencers')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newInfluencerUsers')}
							/>
						)}
					</Request>
				</Col>
			</Row>
			<Title level={4}>{t('Wardrobe items')}</Title>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<Request<TStatisticsSet>
						request={() =>
							api.getTotalWardrobeItemsAmount({
								status: ['active', 'sale', 'gift_accepted', 'gift_offered'],
							})
						}
					>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newWardrobeItems')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet>
						request={() =>
							api.getTotalWardrobeSumAmount({
								status: ['active', 'sale', 'gift_accepted', 'gift_offered'],
							})
						}
					>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total wardrobe sum')}
								{...data}
								format={formatPrice}
								loading={fetching}
								onClick={() => history.push('/dashboard/newWardrobeSum')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalWardrobeLikesAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total item likes')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newItemLikes')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalUsersWishlistAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total items in wishlists')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newUsersWishlist')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet>
						request={() =>
							api.getTotalWardrobeItemsAmount({
								'brand.title': '*BRAND NOT LISTED',
							})
						}
					>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total "brands not listed"')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newWardrobeNotListed')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet>
						request={() =>
							api.getTotalWardrobeItemsAmount({
								'brand.title': 'UNKNOWN',
							})
						}
					>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total "brand unknown"')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newWardrobeBrandUnknown')}
							/>
						)}
					</Request>
				</Col>
			</Row>
			<Gap />
			<Title level={4}>{t('Lookbooks')}</Title>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalLookbooksAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total lookbooks')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/lookbooksIncrease')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalLookbooksAmount({ isPrivate: false })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total public lookbooks')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newPublicLookbooks')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalLookbooksAmount({ isPrivate: true })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total private lookbooks')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newPrivateLookbooks')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalLookbooksContributorsAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total lookbook contributors')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newLookbooksContributings')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalLookbooksContributingsAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total contributing lookbooks')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newLookbooksContributors')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalLookbooksLikesAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total lookbook likes')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newLookbooksLikes')}
							/>
						)}
					</Request>
				</Col>
			</Row>
			<Title level={4}>{t('Feed')}</Title>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalArticlesAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total articles')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newArticles')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalPostsAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total posts')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newPosts')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getTotalSurveysAmount}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total surveys')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newSurveys')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={api.getCommentsAmount}>
						{({ data, fetching }) => {
							console.log(data, 'data')
							return (
								<StatisticsCard
									title={t('Total comments')}
									{...data}
									loading={fetching}
									onClick={() => history.push('/dashboard/newComments')}
								/>
							)
						}}
					</Request>
				</Col>
			</Row>
			<Title level={4}>{t('Borrowing')}</Title>
			<Row gutter={8}>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalSharingsAmount({ objectStatus: 'requested' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total requested items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/requestedSharings')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalSharingsAmount({ objectStatus: 'approved' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total borrowed items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/loanedSharings')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalSharingsAmount({ objectStatus: 'returned' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total returned items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/returnedSharings')}
							/>
						)}
					</Request>
				</Col>

				<Col span={6}>
					<Request<TStatisticsSet>
						request={() => api.getTotalSharingsAmount({ objectStatus: 'expired_ready_for_collection' })}
					>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total uncollected items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/uncollectedSharings')}
							/>
						)}
					</Request>
				</Col>
			</Row>
			<Gap />
			<Title level={4}>{t('Assist')}</Title>
			<Row gutter={[8, 8]}>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalAssistAmount({ assistType: 'upload' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total uploaded items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newAssistUploadedItems')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalAssistAmount({ assistType: 'clear' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total cleared items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newAssistClearedItems')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalAssistAmount({ assistType: 'upload' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total uploaded items')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newAssistUploadedItems')}
							/>
						)}
					</Request>
				</Col>
				<Col span={6}>
					<Request<TStatisticsSet> request={() => api.getTotalAssistAmount({ assistType: 'styling' })}>
						{({ data, fetching }) => (
							<StatisticsCard
								title={t('Total item stylist requests')}
								{...data}
								loading={fetching}
								onClick={() => history.push('/dashboard/newAssistStylistItems')}
							/>
						)}
					</Request>
				</Col>
			</Row>

			<Collapse>
				<Panel header={t('Marketplace')} key="1">
					<Row gutter={[8, 8]}>
						<Col span={12}>
							<Request<TStatisticsSet>
								request={() => api.getTotalWardrobeItemsAmount({ status: ['sale'] })}
							>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total item on MKT')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newMarketplaceItems')}
									/>
								)}
							</Request>
						</Col>
						<Col span={6}>
							<Request<TStatisticsSet>
								request={() => api.getTotalWardrobeSumAmount({ status: ['sold'] })}
							>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total money sold')}
										{...data}
										format={formatPrice}
										loading={fetching}
										onClick={() => history.push('/dashboard/newMarketplaceItemsSum')}
									/>
								)}
							</Request>
						</Col>
						<Col span={6}>
							<Request<TStatisticsSet> request={api.getTotalPotentialMKTItemsAmount}>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total potential LBDMKT items')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newPotentialWardrobeItems')}
									/>
								)}
							</Request>
						</Col>
					</Row>
					<Row gutter={[8, 8]}>
						<Col span={6}>
							<Request<TStatisticsSet>
								request={() => api.getTotalMarketplaceInformationAmount({ status: 'on-review' })}
							>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total items on review')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newMarketplaceItemsOnReview')}
									/>
								)}
							</Request>
						</Col>
						<Col span={6}>
							<Request<TStatisticsSet>
								request={() => api.getTotalMarketplaceInformationAmount({ status: 'rejected' })}
							>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total rejected items')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newMarketplaceRejectedItems')}
									/>
								)}
							</Request>
						</Col>
						<Col span={6}>
							<Request<TStatisticsSet>
								request={() => api.getTotalWardrobeItemsAmount({ status: ['sold'] })}
							>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total items being sold')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newWardrobeItemsBeingSold')}
									/>
								)}
							</Request>
						</Col>
						<Col span={6}>
							<Request<TStatisticsSet> request={api.getTotalNotPotentialMKTItemsAmount}>
								{({ data, fetching }) => (
									<StatisticsCard
										title={t('Total NOT-potential LBDMKT items')}
										{...data}
										loading={fetching}
										onClick={() => history.push('/dashboard/newPotentialWardrobeItems')}
									/>
								)}
							</Request>
						</Col>
					</Row>
					<Gap size="large" />
				</Panel>
			</Collapse>
		</ErrorBoundary>
	)
}

export default DashboardScreen
