import React from 'react'
import { Avatar, Icons, Link } from '@components'
import { TRole, TStatus, TUser } from '@typings'
import { formatToDay, compileImageUri } from '@helpers'

const sorter = () => 0

export const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		width: 142,
		align: 'center',
		fixed: 'left',
		sorter,
		render: (text: string, record: TUser) => <Link to={`/users/${record.id}`}>{text}</Link>,
		searchKey: 'id',
	},
	{
		title: 'Avatar',
		dataIndex: 'avatar',
		width: 60,
		render: (avatar: TUser['avatar']) => (
			<Avatar size="default" icon={<Icons.UserOutlined />} src={compileImageUri(avatar, true)} />
		),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		width: 200,
		sortDirections: ['descend', 'ascend'],
		sorter,
		searchKey: 'email',
	},
	{
		title: 'User name',
		dataIndex: 'username',
		width: 180,
		sorter,
		searchKey: 'username',
	},
	{
		title: 'First name',
		dataIndex: 'firstName',
		width: 180,
		sorter,
		searchKey: 'firstName',
	},
	{
		title: 'Last name',
		dataIndex: 'lastName',
		width: 180,
		sorter,
		searchKey: 'lastName',
	},
	{
		title: 'Birthday date',
		dataIndex: 'birthdayDate',
		width: 120,
		sorter,
		render: formatToDay,
	},
	{
		title: 'Role',
		dataIndex: 'role',
		width: 80,
		filters: [
			{
				text: 'User',
				value: 'user',
			},
			{
				text: 'Admin',
				value: 'admin',
			},
			{
				text: 'Super-Admin',
				value: 'super-admin',
			},
		] as { text: string; value: TRole }[],
	},
	{
		title: 'Account status',
		dataIndex: 'accountStatus',
		width: 80,
		filters: [
			{
				text: 'Active',
				value: 'active',
			},
			{
				text: 'Disabled',
				value: 'disabled',
			},
		] as { text: string; value: TStatus }[],
	},
	{
		title: 'Created at',
		dataIndex: 'createdAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
	{
		title: 'Updated at',
		dataIndex: 'updatedAt',
		width: 100,
		render: formatToDay,
		sorter,
	},
]
