import { ArticleImageBlockDialog, WithRemoveButton } from '@components'
import { useBoolean } from '@hooks'
import { TArticleImageData } from '@typings'
import React from 'react'
import { When } from 'react-if'
import styles from './ArticleImageBlock.module.css'

const ArticleImageBlock: React.FC<TProps> = ({ data, blockProps, container }) => {
	const isVisibleBoolean = useBoolean(false)
	return (
		<WithRemoveButton onRemove={container.remove}>
			<div onClick={isVisibleBoolean.toggle}>
				<img className={styles.image} {...data} />
			</div>
			<When condition={isVisibleBoolean.value}>
				<ArticleImageBlockDialog
					initialValue={data}
					onChange={blockProps.onChange}
					updateData={container.updateData}
					editorState={blockProps.editorState}
					onClose={isVisibleBoolean.setFalse}
				/>
			</When>
		</WithRemoveButton>
	)
}

type TProps = {
	/** Any data passed into insertDataBlock function after editorState */
	data: TArticleImageData
	/** Instance of Media Component with helper functions remove to remove the block from editorState and updateData to update plugin data */
	container: any
	/** Object containing editorState, ReadOnly getters & setters from MegadraftEditor, onChange as a proxy to container.updateData, as well as the defined plugin object structure. */
	blockProps: any
}

export default ArticleImageBlock
