import { Input, InputNumber, Select } from '@components'
import { last } from '@helpers'
import { useMemo, useTranslation } from '@hooks'
import React from 'react'

const selectInput = { width: 200 }

/** Combo form field for choosing time interval. It converts milliseconds to human understandable view. */
const IntervalField: React.FC<TProps> = ({ value, onChange }) => {
	const { t } = useTranslation()

	// List of values.
	const intervalDivider = useMemo(
		() => [
			{ title: t('Days'), value: 86400000 },
			{ title: t('Hours'), value: 3600000 },
			{ title: t('Minutes'), value: 60000 },
		],
		[t],
	)
	const defaultIntervalDivider = last(intervalDivider).value

	// Calculate values of each interval and divider.
	const [sectionValue, numberValue] = useMemo(() => {
		if (!value) return [undefined, undefined]
		const suitableSection = intervalDivider.find((intervalSection) => !(value % intervalSection.value))
		const suitableSectionValue = suitableSection?.value || defaultIntervalDivider
		return [suitableSectionValue || undefined, value / suitableSectionValue || undefined]
	}, [value, intervalDivider])

	return (
		<Input.Group compact>
			<Select
				value={sectionValue}
				onChange={(newSectionValue) => {
					const newValue = newSectionValue * (numberValue || 1)
					onChange?.(newValue)
				}}
				defaultActiveFirstOption={false}
				style={selectInput}
			>
				{intervalDivider.map((option) => (
					<Select.Option key={option.value} value={option.value}>
						{option.title}
					</Select.Option>
				))}
			</Select>
			<InputNumber
				value={numberValue}
				onChange={(newNumberValue = 0) => {
					const newValue = (sectionValue || defaultIntervalDivider) * newNumberValue
					onChange?.(newValue)
				}}
				min={1}
			/>
		</Input.Group>
	)
}

type TProps = {
	/** Form field value. */
	value?: number
	/** Function for changing form field value. */
	onChange?: (value: number) => void
}

export default IntervalField
