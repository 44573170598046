import { Avatar, Card, ErrorBoundary, List } from '@components'
import { useCallback, useMemo, useTranslation } from '@hooks'
import * as React from 'react'
import { compileImageUri } from '@helpers'
import { TCategoriesTree, TCategoryItem } from '@typings'

/** List of categories enabled for displaying in user Wardrobe screen on client. */
const CategoriesInWardrobeScreen: React.FC<TProps> = ({ categoriesTree }) => {
	// Main screen data.
	const { t } = useTranslation()
	const displayedCategories = useMemo(
		() => categoriesTree.filter((category) => category.isShowingInWardrobe && category.status === 'active'),
		[categoriesTree],
	)
	const renderItem = useCallback(
		(category: TCategoriesTree[0]) => (
			<List.Item>
				<List.Item.Meta
					avatar={<Avatar src={compileImageUri(category.asset, true)} />}
					title={category.title}
					description={t('{{subcategoryCount}} subcategories', {
						subcategoryCount: category.subcategories.length,
					})}
				/>
			</List.Item>
		),
		[],
	)

	return (
		<ErrorBoundary>
			<Card title={t('Displayed in Wardrobe')}>
				<List itemLayout="horizontal" dataSource={displayedCategories} renderItem={renderItem} />
			</Card>
		</ErrorBoundary>
	)
}

type TProps = {
	/** Tree of all categories */
	categoriesTree: TCategoriesTree
}

export default CategoriesInWardrobeScreen
