import { DataTable, ErrorBoundary } from '@components'
import { useRequest, useTranslation } from '@hooks'
import { api, message } from '@services'
import { TFilteredRequest, TResponseWithFilters, TWardrobeItem } from '@typings'
import * as React from 'react'
import { columns } from './ItemsCanBeSoldScreen.tableOptions'

const ItemsCanBeSoldScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const tableRef = React.useRef()

	// API data..
	const { data: { data: wardrobeItems, meta } = {}, fetch, fetching } = useRequest<
		TResponseWithFilters<TWardrobeItem[]>,
		TFilteredRequest & Partial<TWardrobeItem>
	>({
		request: (params) =>
			api.getWardrobeItems({
				...params,
				status: 'active',
				marketplaceInformationId: 'null',
				'brand.status': 'active',
			}),
		onError: (error) => message.error(error.message),
	})

	return (
		<ErrorBoundary>
			<DataTable<TWardrobeItem, Partial<TWardrobeItem>>
				ref={tableRef}
				loading={fetching}
				columns={columns}
				dataSource={wardrobeItems}
				meta={meta}
				onChange={fetch}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default ItemsCanBeSoldScreen
