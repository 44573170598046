import { Button, DataTable, ErrorBoundary, Icons } from '@components'
import { useCallback, useHistory, useRequest, useTranslation, useTableLocationParams } from '@hooks'
import { api, message } from '@services'
import * as React from 'react'
import { TFilteredRequest, TResponseWithFilters } from 'src/typings/api'
import { TArticle } from 'src/typings/articles'
import { columns } from './Articles.tableOptions'

const ArticlesScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const [searchParams, setSearchParams] = useTableLocationParams()

	// Obtain articles.
	const { data: { data: articles, meta } = {}, fetching, fetched } = useRequest<
		TResponseWithFilters<TArticle[]>,
		TFilteredRequest & Partial<TArticle>
	>(
		{
			request: () => api.getArticles({ order: ['id', 'desc'], ...searchParams }),
			onError: (error) => message.error(error.message),
		},
		[searchParams],
	)

	// Actions.
	const goToAddArticle = useCallback(() => history.push('/articles/add'), [])

	return (
		<ErrorBoundary>
			<DataTable<TArticle, Partial<TArticle>>
				loading={fetching}
				columns={columns}
				dataSource={articles}
				meta={meta}
				scrollX={800}
				autoFetchOnMount={false}
				searchInputValues={searchParams}
				onChange={setSearchParams}
				HeaderComponent={
					<Button type="primary" icon={<Icons.PlusCircleOutlined />} size="middle" onClick={goToAddArticle}>
						{t('Add article')}
					</Button>
				}
			/>
		</ErrorBoundary>
	)
}

type TProps = {
	/** */
}

export default ArticlesScreen
