import { useMemo, useRequest } from '@hooks'
import { api, message } from '@services'
import { mapArrayEntityItems } from '@helpers'
import { TMktInformation, TComment, TCommentForm, TMktStatus, TWardrobeItem } from '@typings'

const useMarketplaceItemAPI = (
	mktInformationId: TMktInformation['id'],
	wardrobeItemId: TWardrobeItem['id'],
	{ onChangeStatus }: TUseMarketplaceItemAPIArgs,
) => {
	const { fetch: markCommentAsRead } = useRequest<void, TMktInformation['id']>({
		request: (marketplaceInformationId) => false || api.markCommentAsRead(marketplaceInformationId),
	})
	const { data: mktInformation, fetch: fetchMKTInformation, fetching, fetched } = useRequest<
		TMktInformation,
		TMktInformation['id']
	>(
		{
			request: () => (mktInformationId ? api.getMarketplaceItem(mktInformationId) : null),
			onSuccess: () => markCommentAsRead(mktInformationId),
		},
		[mktInformationId],
	)
	const comments = useMemo(() => mapArrayEntityItems<TComment>(mktInformation?.comments, 'comment'), [mktInformation])

	const { fetch: sendComment, fetching: sending } = useRequest<void, TCommentForm>({
		request: (comment) => api.addCommentToRejectedItem(mktInformationId, comment),
		onSuccess: () => fetchMKTInformation(mktInformationId),
	})

	const { fetch: approve, fetching: approving } = useRequest<TWardrobeItem, void>({
		request: () => (wardrobeItemId ? api.approveItemFromMarketplace(mktInformationId) : null),
		onSuccess: () => onChangeStatus('approved'),
		onError: (error) => message.error(error.message),
	})
	const { fetch: reject, fetching: rejecting } = useRequest<TWardrobeItem, void>({
		request: () => (wardrobeItemId ? api.rejectItemFromMarketplace(mktInformationId) : null),
		onSuccess: () => onChangeStatus('rejected'),
		onError: (error) => message.error(error.message),
	})
	const { fetch: cancel, fetching: canceling } = useRequest<TWardrobeItem, void>({
		request: () => (wardrobeItemId ? api.cancelItemFromMarketplace(mktInformationId) : null),
		onSuccess: () => onChangeStatus('cancelled'),
		onError: (error) => message.error(error.message),
	})
	const { fetch: remove, fetching: removing } = useRequest<TWardrobeItem, void>({
		request: () => (wardrobeItemId ? api.removeItemFromMarketplace(wardrobeItemId) : null),
		onSuccess: () => onChangeStatus('removed'),
		onError: (error) => message.error(error.message),
	})

	return {
		mktInformation,
		comments,
		fetching,
		fetched,
		sendComment,
		sending,
		approve,
		reject,
		cancel,
		canceling,
		remove,
		removing,
	}
}

type TUseMarketplaceItemAPIArgs = {
	onChangeStatus: (status: TMktStatus | 'cancelled' | 'removed') => void
}
export default useMarketplaceItemAPI
