import { Avatar, Card, Link, Tag } from '@components'
import { compileImageUri } from '@helpers'
import { TLookbook } from '@typings'
import React from 'react'

const LookbookCardMeta: React.FC<TProps> = ({ lookbook }) => {
	if (!lookbook) return null

	const description = lookbook?.description
	const fullName = `${lookbook?.user?.firstName} ${lookbook?.user?.lastName}`
	const location = `${lookbook?.location?.countryName || '...'}, ${lookbook?.location?.cityName || '...'}`

	return (
		<Card.Meta
			avatar={<Avatar src={compileImageUri(lookbook?.asset, true)} shape="square" size="large" />}
			title={
				<span>
					{lookbook?.title}{' '}
					<Tag color={lookbook?.isPrivate ? 'success' : 'error'}>
						{lookbook?.isPrivate ? 'private' : 'public'}
					</Tag>
				</span>
			}
			description={
				<>
					<div>{description}</div>
					<Link to={`/users/${lookbook?.user.id}`}>{fullName}</Link>
					<div>{location}</div>
				</>
			}
		/>
	)
}

type TProps = {
	/**  */
	lookbook: TLookbook
}

export default LookbookCardMeta
