import { config } from '@constants'
import { useTranslation } from '@hooks'
import React from 'react'
import styles from './AppVersionLabel.module.css'
import { apiSettings } from '@services'

/** Text label for displaying version of app and used API server. */
const AppVersionLabel: React.FC<TProps> = () => {
	const { t } = useTranslation()
	return (
		<div className={styles.container}>
			<span className={styles.version}>
				{t('ver. {{version}} ({{serverName}})', {
					version: config.PACKAGE_VERSION,
					serverName: apiSettings.getSettings('API_SERVER_NAME'),
				})}
			</span>
		</div>
	)
}

type TProps = {
	/**  */
}

export default AppVersionLabel
