import { Tree } from '@components'
import { last } from '@helpers'
import { useMemo } from '@hooks'
import { TreeProps, TSupportedChildrenKeys, TTree, TUpdateCategoryItem } from '@typings'
import * as React from 'react'

/** Display data as tree. */
const TreeView = <TTreeType extends TTree>({
	tree,
	draggable = true,
	onDrop,
	selectedKeys,
	onSelect,
	childrenKey,
}: TProps<TTreeType>) => {
	// Tree.
	const attributesTreeData: TreeProps['treeData'] = useMemo(() => prepareTree<TTreeType>(tree, childrenKey), [tree])

	// Actions.
	const handleSelect: TreeProps['onSelect'] = (selectedKeys) => {
		const key = last(selectedKeys)
		onSelect(key ? [String(key)] : [])
	}

	return (
		<Tree
			treeData={attributesTreeData}
			selectedKeys={selectedKeys}
			onSelect={handleSelect}
			draggable={draggable}
			onDrop={onDrop}
		/>
	)
}

type TProps<TTreeType extends TTree> = {
	/** Tree of all categories in database. */
	tree: TTreeType
	/** Category which had been selected by user. */
	selectedKeys: string[]
	/** Callback for changing selected category. */
	onSelect: (selectedKeys: string[]) => void
	/** Function called when item have been dropped after drag&drop. */
	onDrop: TreeProps['onDrop']
	/** Should tree nodes be dragged to new place. */
	draggable: boolean
	/** Name of key with children nodes in tree. */
	childrenKey: TSupportedChildrenKeys
}

/** Cast categories tree to Antd Tree component format. */
function prepareTree<TTreeType extends TTree>(
	data: TTreeType,
	childrenKey: TSupportedChildrenKeys,
): TreeProps['treeData'] {
	return data
		? data.map((attribute) => ({
				...attribute,
				key: String(attribute.id),
				children: prepareTree(attribute[childrenKey], childrenKey),
		  }))
		: []
}

export default TreeView
