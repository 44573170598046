import { Button, Icons, Input, ModalVideo } from '@components'
import { useBoolean } from '@hooks'
import { InputProps } from '@typings'
import React from 'react'
import 'react-modal-video/scss/modal-video.scss'
import { getYouTubeVideoId } from '@helpers'

/** Single text input for YouTube video link with built-in video player in modal window. */
const YouTubeField: React.FC<TProps> = ({ value, ...otherProps }) => {
	const { value: isOpen, toggle: togglePlayer, setFalse: closePlayer } = useBoolean(false)
	const videoId = getYouTubeVideoId(value)

	return (
		<>
			<Input
				value={value}
				{...otherProps}
				suffix={
					<Button
						type="link"
						size="small"
						disabled={!videoId}
						onClick={togglePlayer}
						icon={<Icons.EyeOutlined />}
					/>
				}
			/>

			<ModalVideo channel="youtube" isOpen={isOpen} videoId={videoId} onClose={closePlayer} />
		</>
	)
}

type TProps = InputProps & {
	/** Form field video link value */
	value?: string | undefined
}

export default YouTubeField
