import { BooleanIcon, Descriptions, Divider, Spin, Tag, When } from '@components'
import { formatToDay } from '@helpers'
import { useTranslation } from '@hooks'
import { TUser } from '@typings'
import * as React from 'react'

const UserViewInfoTab: React.FC<TProps> = ({ user, fetching }) => {
	// Main screen data.
	const { t } = useTranslation()

	return (
		<Spin spinning={fetching}>
			<When condition={!!user?.id}>
				<Descriptions title={t('Biography')} size="middle">
					<Descriptions.Item label={t('Bio')}>{user?.biography}</Descriptions.Item>
				</Descriptions>
				<Descriptions bordered={false} title={t('Personal')} size="small">
					<Descriptions.Item label={t('First name')}>{user?.firstName}</Descriptions.Item>
					<Descriptions.Item label={t('Last name')}>{user?.lastName}</Descriptions.Item>
					<Descriptions.Item label={t('Username')}>{`@${user?.username}`}</Descriptions.Item>
					<Descriptions.Item label={t('Email')}>{user?.email}</Descriptions.Item>
					<Descriptions.Item label={t('Phone number')}>{user?.phoneNumber}</Descriptions.Item>
					<Descriptions.Item label={t('Date of birthday')}>
						{formatToDay(user?.birthdayDate)}
					</Descriptions.Item>
					<Descriptions.Item label={t('Role')}>{user?.role}</Descriptions.Item>
					<Descriptions.Item label={t('ID')}>{user?.id}</Descriptions.Item>
					<Descriptions.Item label={t('Account status')}>{user?.accountStatus}</Descriptions.Item>
					<Descriptions.Item label={t('User since')}>{formatToDay(user?.createdAt)}</Descriptions.Item>
					<Descriptions.Item label={t('Updated at')}>{formatToDay(user?.updatedAt)}</Descriptions.Item>
					<Descriptions.Item label={t('Instagram')}>{user?.instagramAccount}</Descriptions.Item>
					<Descriptions.Item label={t('How to contact')}>{user?.howToContact}</Descriptions.Item>
					<Descriptions.Item label={t('Is created from brand')}>
						{user?.isCreatedFromBrand ? 'Yes' : 'No'}
					</Descriptions.Item>
					<Descriptions.Item label={t('Country')}>{user?.location?.countryName}</Descriptions.Item>
				</Descriptions>
				{/* <a href={`https://www.instagram.com/${user?.instagramAccount}/`}>Instagram</a> */}
				<Divider />
				<Descriptions bordered={false} title={t('Body & Sizes')} size="small">
					<Descriptions.Item label={t('Body shape')}>{user?.bodyShape?.title}</Descriptions.Item>
					<Descriptions.Item label={t('Size top')}>{user?.sizeTop?.title}</Descriptions.Item>
					<Descriptions.Item label={t('Size bottom')}>{user?.sizeBottom?.title}</Descriptions.Item>
					<Descriptions.Item label={t('Size shoe')}>{user?.sizeShoe?.title}</Descriptions.Item>
					<Descriptions.Item label={t('Jean size')}>{user?.sizeJean?.title}</Descriptions.Item>
					<Descriptions.Item label={t('Measurement inside leg')}>{user?.sizeInsideLeg}</Descriptions.Item>
				</Descriptions>
				<Divider />
				<Descriptions bordered title={t('Preferences')} size="small" column={2}>
					<Descriptions.Item label={t('Favorite categories')}>
						{user?.categories.map((item) => (
							<Tag key={item.category.id} color="orange">
								{item.category.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite brands')}>
						{user?.brands?.map((item) => (
							<Tag key={item.brand.id} color="lime">
								{item.brand.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite influencers')}>
						{user?.onboardingInfluencers?.map((item) => (
							<Tag key={item.onboardingInfluencer.id} color="green">
								{item.onboardingInfluencer.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite stores')}>
						{user?.stores?.map((item) => (
							<Tag key={item.store.id} color="cyan">
								{item.store.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite fashion sources')}>
						{user?.fashionSources?.map((item) => (
							<Tag key={item.fashionSource.id} color="blue">
								{item.fashionSource.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite resale platforms')}>
						{user?.resalePlatforms?.map((item) => (
							<Tag key={item.resalePlatform.id} color="geekblue">
								{item.resalePlatform.title}
							</Tag>
						))}
					</Descriptions.Item>
					<Descriptions.Item label={t('Favorite clothes styles')}>
						{user?.clothesStyles?.map((item) => (
							<Tag key={item.clothesStyle.id} color="purple">
								{item.clothesStyle.title}
							</Tag>
						))}
					</Descriptions.Item>
				</Descriptions>
				<Divider />
				<Descriptions bordered={false} title={t('Billing address')} size="small">
					<Descriptions.Item label={t('First name')}>{user?.billingAddress?.firstName}</Descriptions.Item>
					<Descriptions.Item label={t('Last name')}>{user?.billingAddress?.lastName}</Descriptions.Item>
					<Descriptions.Item label={t('Country')}>{user?.billingAddress?.country}</Descriptions.Item>
					<Descriptions.Item label={t('City')}>{user?.billingAddress?.city}</Descriptions.Item>
					<Descriptions.Item label={t('Street address')}>{user?.billingAddress?.street}</Descriptions.Item>
					<Descriptions.Item label={t('House number')}>{user?.billingAddress?.buildNumber}</Descriptions.Item>
					<Descriptions.Item label={t('Postcode')}>{user?.billingAddress?.postcode}</Descriptions.Item>
					<Descriptions.Item label={t('Telephone')}>{user?.billingAddress?.phoneNumber}</Descriptions.Item>
					<Descriptions.Item label={t('Address d')}>{user?.billingAddress?.id}</Descriptions.Item>
				</Descriptions>
				<Divider />
				<Descriptions
					bordered={false}
					title={t('Private Settings & Additional settings')}
					size="small"
					column={4}
				>
					<Descriptions.Item label={t('Contact by email')}>
						<BooleanIcon value={['both', 'email'].includes(user?.howToContact)} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Contact by phone')}>
						<BooleanIcon value={['both', 'phone'].includes(user?.howToContact)} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Notification by email')}>
						<BooleanIcon value={user?.notificationsEmail} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Notification by push')}>
						<BooleanIcon value={user?.notificationsPush} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Shares with friends')}>
						<BooleanIcon value={user?.isShareWithFriends} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Reseller')}>
						<BooleanIcon value={user?.isReseller} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Knows wardrobe value')}>
						<BooleanIcon value={user?.isKnowWardrobeValue} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Happy to Sell on LBD')}>
						<BooleanIcon value={user?.isWantToSellInLbd} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Accepts T&C')}>
						<BooleanIcon value={user?.isAcceptedRegistration} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Accepted Registration')}>
						<BooleanIcon value={user?.isAcceptedRegistration} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Influencer')}>
						<BooleanIcon value={user?.isInfluencer} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Private')}>
						<BooleanIcon value={user?.isAccountPrivate} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Username matched brand')}>
						<BooleanIcon value={user?.isUsernameMatchedBrand} />
					</Descriptions.Item>
					<Descriptions.Item label={t('PreRegister')}>
						<BooleanIcon value={user?.isPreRegister} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Allows Tagging')}>
						<BooleanIcon value={user?.isCanTagMe} />
					</Descriptions.Item>
				</Descriptions>
			</When>
		</Spin>
	)
}

type TProps = {
	/** User entity */
	user: TUser
	/** Request to API fetching status */
	fetching: boolean
}

export default UserViewInfoTab
