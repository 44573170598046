import { TRequestBuilder, TWardrobeItem, TFilteredRequest, TArticle, TArticleEdit } from '@typings'
import { upDataTransformer } from '../transformers'

export const getArticles: TRequestBuilder<TFilteredRequest> = (params = {}) => ({
	method: 'get',
	url: '/articles',
	params,
})
export const getArticle: TRequestBuilder<TArticle['id']> = (articleId) => ({
	method: 'get',
	url: `/articles/${articleId}`,
	transformResponse: upDataTransformer,
})
export const createArticle: TRequestBuilder<TArticleEdit> = (article) => ({
	method: 'post',
	url: '/articles',
	data: article,
	transformResponse: upDataTransformer,
})
export const updateArticle: TRequestBuilder<TArticleEdit> = ({ id: articleId, ...article }) => ({
	method: 'put',
	url: `/articles/${articleId}`,
	data: article,
	transformResponse: upDataTransformer,
})
export const removeArticle: TRequestBuilder<TArticle['id']> = (articleId) => ({
	method: 'delete',
	url: `/articles/${articleId}`,
	transformResponse: upDataTransformer,
})
export const publishArticle: TRequestBuilder<TArticle['id']> = (articleId) => ({
	method: 'post',
	url: `/articles/${articleId}/publish`,
	transformResponse: upDataTransformer,
})
export const hideArticle: TRequestBuilder<TArticle['id']> = (articleId) => ({
	method: 'post',
	url: `/articles/${articleId}/hide`,
	transformResponse: upDataTransformer,
})
