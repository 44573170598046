import {
	Button,
	Card,
	CardsList,
	Col,
	Divider,
	ErrorBoundary,
	Form,
	Input,
	Popconfirm,
	Row,
	UploadImageField,
	Space,
	DataTable,
	RemoveCommentLink,
} from '@components'
import { showFormErrors, toNumber } from '@helpers'
import { useEntityListItemAPI, useHistory, useMemo, useParams, useTranslation, useRequest } from '@hooks'
import { TLook, TLookForm, TProfile, TComment, TResponseWithFilters, TFilteredRequest } from '@typings'
import * as React from 'react'
import {
	formButtonLayout,
	formFieldRules,
	formLayout,
	normalize,
	prepareForm,
	castLookToForm,
} from './EditLook.formOptions'
import { formatLookWardrobeItems } from './EditLook.listOptions'
import { columnsComments } from '../LookbookView/LookbookView.tableOptions'
import { api, message } from '@services'

const EditLookScreen: React.FC<TProps> = () => {
	// Main screen data.
	const { t } = useTranslation()
	const history = useHistory()
	const params = useParams<TParams>()
	const lookbookId = toNumber(params.lookbookId)
	const lookId = toNumber(params.lookId)

	const Look = useEntityListItemAPI<TLook>(`/lookbooks/${lookbookId}/looks`, lookId, { onAction: history.goBack })
	const wardrobeItems = useMemo(() => formatLookWardrobeItems(Look.data?.wardrobeItems), [Look.data])
	const handleSubmit = (form: TLookForm) => {
		if (lookId) {
			Look.update(prepareForm(form, lookId))
		} else {
			Look.create(prepareForm(form))
		}
	}

	// Actions.
	const goToWardrobeItem = (wardrobeItemId: number) => history.push(`/wardrobe-items/${wardrobeItemId}`)

	// Render helps.
	const initialValues = useMemo(() => (Look.data ? castLookToForm(Look.data) : { isPrivate: true }), [Look.data])

	//Comments
	const { data: { data: comments, meta: commentsMeta } = {}, fetch } = useRequest<
		TResponseWithFilters<TComment[]>,
		TFilteredRequest & Partial<TComment>
	>(
		{
			request: () => lookId && api.getComments({ 'looks.lookId': lookId }),
			onError: (error) => message.error(error),
		},
		[lookId],
	)
	const newCommentsData = comments?.map((el) => (el.replies?.length ? { ...el, children: el.replies } : { ...el }))

	return (
		<ErrorBoundary>
			<Card
				title={t('Edit look')}
				loading={!!lookbookId && Look.fetching}
				extra={
					<Row gutter={16} align="middle">
						<Col>
							<Popconfirm
								title={t('Are you sure?')}
								onConfirm={Look.remove}
								okText={t('Yes')}
								cancelText={t('No')}
							>
								<Button disabled={!Look?.data?.id} loading={Look.removing} type="danger">
									{t('Remove lookbook')}
								</Button>
							</Popconfirm>
						</Col>
					</Row>
				}
			>
				<Form
					key={`look-form-${Look.data?.id}`}
					initialValues={initialValues}
					onFinish={handleSubmit}
					onFinishFailed={showFormErrors}
					{...formLayout}
				>
					<Form.Item name="description" label={t('Description')} normalize={normalize}>
						<Input />
					</Form.Item>
					<Form.Item required normalize={normalize} label={t('Images')}>
						<UploadImageField name="assets" multiple rules={formFieldRules.assets} />
					</Form.Item>
					<Form.Item wrapperCol={formButtonLayout}>
						<Space>
							<Button type="primary" htmlType="submit" loading={Look.updating || Look.removing}>
								{t('Save changes')}
							</Button>
							<Button onClick={history.goBack}>{t('Cancel')}</Button>
						</Space>
					</Form.Item>
				</Form>
			</Card>
			<Divider />
			<Card title={t('Attached wardrobe items')}>
				<CardsList items={wardrobeItems} onPress={goToWardrobeItem} />
			</Card>
			<Card title={t('Comments')}>
				<DataTable<TProfile, Partial<TProfile>>
					columns={columnsComments}
					dataSource={newCommentsData}
					meta={commentsMeta}
					autoFetchOnMount={false}
					renderActionsComponent={(comment: TComment) => (
						<>
							<RemoveCommentLink buttonType="link" commentId={comment.id} onSuccess={fetch} />
						</>
					)}
				/>
			</Card>
		</ErrorBoundary>
	)
}

type TParams = { lookbookId: string; lookId: string }
type TProps = {
	/** */
}

export default EditLookScreen
