import React from 'react'
import 'reset-css'
import 'antd/dist/antd.css'
import './index.css'
import {
	BrowserRouter as Router,
	AppShell,
	RouteSwitch,
	Route,
	Redirect,
	If,
	Then,
	Else,
	RoutingListenManager,
	Helmet,
} from '@components'
import {
	Login,
	Dashboard,
	DashboardChart,
	Users,
	WardrobeItems,
	UserView,
	EditUser,
	EditWardrobeItem,
	Articles,
	EditArticle,
	MaterialsList,
	EditMaterial,
	StoresList,
	EditStore,
	BrandsListRoutes,
	EditBrand,
	Attributes,
	Lookbooks,
	EditLookbook,
	LookbookView,
	EditLook,
	MarketplaceItems,
	MarketplaceOrders,
	CategorizedFilters,
	Posts,
	EditPost,
	Documentation,
	ApiDocumentation,
	Changelog,
	BulkUpload,
	AddBulkUpload,
	ImageSets,
	MarketplaceItemsOnReview,
	ItemsCanBeSold,
	MarketplaceItem,
	NotFound,
	CategoriesTaxonomy,
	AwaitingVerification,
	Comments,
	Surveys,
	EditSurvey,
	BulkUploadUsers,
	Promotions,
	CreatePromotion,
	AwaitingVerificationShops,
	BulkUploadShops,
} from '@screens'
import { useAuthContext } from '@hooks'

function App() {
	const { isLoggedIn } = useAuthContext()

	return (
		<Router>
			<Helmet>
				<title>LBD Admin</title>
				<meta name="google-site-verification" content="3SK6Vrr1jBMQLQLpAx6ED0vzzbCj9a9lzKEW1PEEqYI" />
			</Helmet>
			<RoutingListenManager />
			<RouteSwitch>
				<If condition={isLoggedIn}>
					<Then>
						<AppShell>
							<RouteSwitch>
								<Route path="/dashboard" exact component={Dashboard} />
								<Route path="/dashboard/:chart" exact component={DashboardChart} />
								<Route path="/users" exact component={Users} />
								<Route path="/users/add" exact component={EditUser} />
								<Route path="/users/:id(\d+)/edit" exact component={EditUser} />
								<Route path="/users/:id(\d+)/:tab?" exact component={UserView} />
								<Route path="/promotions" exact component={Promotions} />
								<Route path="/promotions/add" exact component={CreatePromotion} />
								<Route path="/wardrobe-items" exact component={WardrobeItems} />
								<Route path="/wardrobe-items/:id(\d+)" exact component={EditWardrobeItem} />
								<Route path="/bulk-upload" exact component={BulkUpload} />
								<Route path="/bulk-upload-users" exact component={BulkUploadUsers} />
								<Route path="/bulk-upload-shops" exact component={BulkUploadShops} />
								<Route path="/bulk-upload/add" exact component={AddBulkUpload} />
								<Route path="/articles" exact component={Articles} />
								<Route path="/articles/add" exact component={EditArticle} />
								<Route path="/articles/:id(\d+)" exact component={EditArticle} />
								<Route path="/materials" exact component={MaterialsList} />
								<Route path="/materials/add" exact component={EditMaterial} />
								<Route path="/materials/:id(\d+)" exact component={EditMaterial} />
								<Route path="/stores" exact component={StoresList} />
								<Route path="/stores/add" exact component={EditStore} />
								<Route path="/stores/:id(\d+)" exact component={EditStore} />
								<Route path="/brands" exact component={BrandsListRoutes} />
								<Route path="/brands/add" exact component={EditBrand} />
								<Route path="/brands/:id(\d+)" exact component={EditBrand} />
								<Route path="/categories-taxonomy" component={CategoriesTaxonomy} />
								<Route path="/attributes" component={Attributes} />
								<Route path="/categorized-filters" component={CategorizedFilters} />
								<Route path="/lookbooks" exact component={Lookbooks} />
								<Route path="/lookbooks/:id(\d+)" exact component={LookbookView} />
								<Route path="/lookbooks/:id(\d+)/edit" exact component={EditLookbook} />
								<Route path="/lookbooks/add" exact component={EditLookbook} />
								{/* <Route path="/lookbooks/:lookbookId(\d+)/looks/add" exact component={EditLook} /> */}
								<Route path="/marketplace-items-on-review" exact component={MarketplaceItemsOnReview} />
								<Route
									path="/marketplace-items-on-review/:wardrobeItemId(\d+)"
									exact
									component={MarketplaceItem}
								/>
								<Route path="/marketplace-items" exact component={MarketplaceItems} />
								<Route path="/items-can-be-sold" exact component={ItemsCanBeSold} />
								<Route path="/marketplace-orders" exact component={MarketplaceOrders} />
								<Route
									path="/marketplace-items/:wardrobeItemId(\d+)"
									exact
									component={MarketplaceItem}
								/>
								<Route
									path="/lookbooks/:lookbookId(\d+)/looks/:lookId(\d+)"
									exact
									component={EditLook}
								/>
								<Route path="/posts" exact component={Posts} />
								<Route path="/posts/:id(\d+)" exact component={EditPost} />
								<Route path="/posts/add" exact component={EditPost} />
								<Route path="/surveys" exact component={Surveys} />
								<Route path="/surveys/:id(\d+)" exact component={EditSurvey} />
								<Route path="/surveys/add" exact component={EditSurvey} />
								<Route path="/image-sets" exact component={ImageSets} />
								<Route path="/documentation" exact component={Documentation} />
								<Route path="/api-documentation" exact component={ApiDocumentation} />
								<Route path="/changelog" exact component={Changelog} />
								<Route path="/website-subscriber-list" exact component={AwaitingVerification} />
								<Route path="/shopify-subscriber-list" exact component={AwaitingVerificationShops} />
								<Route path="/comments" exact component={Comments} />
								<Redirect from="/login" exact to="/dashboard" />
								<Redirect from="/" exact to="/dashboard" />
								<Route component={NotFound} />
							</RouteSwitch>
						</AppShell>
					</Then>
					<Else>
						<Route path="/login" component={Login} />
						<Redirect to="/login" />
					</Else>
				</If>
			</RouteSwitch>
		</Router>
	)
}

export default App
