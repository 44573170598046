import { i18n } from '@services'
import { TLookbook, TLookbookForm } from '@typings'

export const formLayout = {
	labelCol: { span: 4, offset: 2 },
	wrapperCol: { span: 12 },
}
export const formButtonLayout = { offset: 6, span: 12 }
export const formFieldRules = {
	title: [{ required: true, message: i18n.t('Title is required') }],
	titleAssetId: [{ required: true, message: i18n.t('Image or video is required') }],
}

export const normalize = (value: any) => value ?? null

export const prepareForm = (form: TCreateLookBookPayload, lookbookId?: TLookbook['id']): TLookbookForm => {
	const { asset, ...formValues } = form

	return {
		...formValues,
		titleAssetId: asset?.id || null,
		id: lookbookId,
	}
}
